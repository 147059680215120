import React from "react"

const Toolbar = (props) => {
  return (
    <div className="o-top-menu">
      {props.children}
    </div>
  );
};

export default Toolbar;