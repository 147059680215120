import React, { useEffect, useState } from "react";

export interface CheckboxProps {
  children?: React.ReactNode;
  label?: string;
  isChecked: boolean;
  className: string;
  disabled?: boolean;
  onChange(isChecked: boolean): undefined;
}

const defaultCheckboxProps = {
  label: "",
  isChecked: false,
  className: "",
  disabled: false,
  onChange: (isChecked: boolean) => {}
}

const Checkbox = (props: CheckboxProps) => {

  props = { ...defaultCheckboxProps, ...props };

  const [isChecked, setIsChecked] = useState(props.isChecked);

  const checkChangedHandler = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (props.onChange)
      props.onChange(isChecked);
  }, [isChecked]);

  useEffect(() => {
    // console.log("isChecked changed by parent scope")
    setIsChecked(props.isChecked);
  }, [props.isChecked])

  return (
    <label className={`a-checkbox ${props.className}`}>
      <span>{props.label}{props.children}</span>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={checkChangedHandler}
        disabled={props.disabled}
      />
      <div className="b-input"></div>
    </label>
  );
};

export default Checkbox;
