// Package imports
import React, { useState, useEffect, useContext } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Button, Snackbar } from "cai-fusion";

// Contexts & services
import { ChatProvider } from "./contexts/ChatContext";
import { AccessProvider } from "./contexts/AccessContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { AssistantProvider } from "./contexts/AssistantContext";
import { SkillsProvider } from "./contexts/SkillsContext";
import { ResourceProvider } from "./contexts/ResourceContext";
import { MessagesProvider } from "./contexts/MessageContext";
import { SignalRProvider } from "./contexts/SignalRContext";
import { TypingProvider } from "./contexts/TypingContext";
import { TemplateProvider } from "./contexts/TemplateContext";

// Modals and Drawers
import AttachFileModal from "./overlay/attachFileModal";
import AuditLogDrawer from "./overlay/auditLogDrawer";
import ChatHistoryDrawer from "./overlay/chatHistoryDrawer";
import ChatSettingsDrawer from "./overlay/chatSettingsDrawer";
import DataSourceDrawer from "./overlay/dataSourceDrawer";
import DeleteChatModal from "./overlay/deleteChatModal";
import EulaModal from "./overlay/eulaModal";
import PlagiarismDisclaimerModal from "./overlay/plagiarismDisclaimerModal";
import RenameChatModal from "./overlay/renameChatModal";
import ShareChatDrawer from "./overlay/shareChatDrawer";

// Misc
import SymphonyMainView from "./symphonyMainView";
import UnlicensedModal from "./overlay/unlicensedModal";
import { useCompliance } from "./contexts/ComplianceContext";
import { useLicensing } from "./contexts/LicenseContext";
import { ProfilePictureProvider } from "./contexts/ProfilePictureContext";


const SymphonyApp = () => {

    const { agreedToEULA, agreeToEULA, setAgreedToEULA, outstandingEULA } = useCompliance();
    const { isLicensedEnv, hasLicense } = useLicensing();

    // UX States
    const [hideSettings, setHideSettings] = useState(true);
    const [hideDataDrawer, setHideDataDrawer] = useState(true);
    const [hideDeleteChatModal, setHideDeleteChatModal] = useState(true);
    const [hideRenameChatModal, setHideRenameChatModal] = useState(true);
    const [hidePlagiarismModal, setHidePlagiarismModal] = useState(true);
    const [hidePlagiarismSnackbar, setHidePlagiarismSnackbar] = useState(true);
    const [hideShareDrawer, setHideShareDrawer] = useState(true);
    const [hideFileUploadModal, setHideFileUploadModal] = useState(true);
    const [hideAuditLogDrawer, setHideAuditLogDrawer] = useState(true);

    const [fileUploads, setFileUploads] = useState([]);
    const [externalDataSources, setExternalDataSources] = useState([]);
    const [lockInput, setLockInput] = useState(false);
    const [editingMessageId, setEditingMessageId] = useState(null);

    const ChatContextElements = agreedToEULA && (
        <ChatProvider>
            <AccessProvider>
                <SettingsProvider>
                    <AssistantProvider>
                        <SkillsProvider>
                            <SignalRProvider>
                                <ResourceProvider>
                                    <ProfilePictureProvider>
                                        <MessagesProvider>
                                            <TypingProvider>
                                                <TemplateProvider>
                                                    <div className="o-page-section o-page-section l-mt-md l-pt-none">
                                                        <div className="o-page-section__content-container o-page-section__content-container--med-wide-fixed-width l-pt-none l-mt-neg-sm">
                                                            <div className="l-row l-pt-sm">
                                                                <ChatHistoryDrawer />
                                                                {/* <div className="col-md-9 l-pb-xl">  */}
                                                                <div className="col-md-9">
                                                                    <SymphonyMainView
                                                                        lockInput={lockInput}
                                                                        editingMessageId={editingMessageId}
                                                                        setEditingMessageId={setEditingMessageId}
                                                                        setHideSettings={setHideSettings}
                                                                        setHideDataDrawer={setHideDataDrawer}
                                                                        setHideFileUploadModal={setHideFileUploadModal}
                                                                        setHideDeleteChatModal={setHideDeleteChatModal}
                                                                        setHidePlagiarismSnackbar={setHidePlagiarismSnackbar}
                                                                        setHideRenameChatModal={setHideRenameChatModal}
                                                                        setHideShareDrawer={setHideShareDrawer}
                                                                        setHideAuditLogDrawer={setHideAuditLogDrawer}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ChatSettingsDrawer
                                                        isHidden={hideSettings}
                                                        clickOutsideToClose={true}
                                                        onClose={() => setHideSettings(true)}
                                                    />
                                                    <DataSourceDrawer
                                                        isHidden={hideDataDrawer}
                                                        clickOutsideToClose={true}
                                                        onClose={() => setHideDataDrawer(true)}
                                                        files={fileUploads}
                                                        onUpdateFiles={(newFiles) => setFileUploads(newFiles)}
                                                        externalDataSources={externalDataSources}
                                                        onSave={(files, dataSources) => {
                                                            setFileUploads(files);
                                                            setExternalDataSources(dataSources);
                                                        }}
                                                        disableInput={lockInput}
                                                    />
                                                    <ShareChatDrawer
                                                        isHidden={hideShareDrawer}
                                                        clickOutsideToClose={true}
                                                        onClose={() => setHideShareDrawer(true)}
                                                    />
                                                    <AttachFileModal
                                                        isHidden={hideFileUploadModal}
                                                        onClose={() => setHideFileUploadModal(true)}
                                                        onUpload={() => setHideDataDrawer(false)}
                                                        clickOutsideToClose={true}
                                                    />
                                                    <AuditLogDrawer
                                                        isHidden={hideAuditLogDrawer}
                                                        onClose={() => setHideAuditLogDrawer(true)}
                                                    />
                                                    <DeleteChatModal
                                                        isHidden={hideDeleteChatModal}
                                                        onClose={() => setHideDeleteChatModal(true)}
                                                        clickOutsideToClose={true}
                                                    />
                                                    <RenameChatModal
                                                        isHidden={hideRenameChatModal}
                                                        onClose={() => setHideRenameChatModal(true)}
                                                        clickOutsideToClose={true}
                                                    />
                                                    <PlagiarismDisclaimerModal
                                                        isHidden={hidePlagiarismModal}
                                                        onClose={() => setHidePlagiarismModal(true)}
                                                    />
                                                    <Snackbar
                                                        isHidden={hidePlagiarismSnackbar}
                                                        onClose={() => setHidePlagiarismSnackbar(true)}
                                                        type="warning"
                                                        primaryMessage="Plagiarism Warning"
                                                        secondaryMessage="You should be aware about the risks of plagiarism from copying AI-generated responses."
                                                    >
                                                        <Button
                                                            children="Learn More"
                                                            onClick={() => {
                                                                setHidePlagiarismModal(false);
                                                                setHidePlagiarismSnackbar(true);
                                                            }}
                                                        />
                                                    </Snackbar>
                                                    {outstandingEULA &&
                                                        <EulaModal
                                                            content={outstandingEULA.text}
                                                            onAccept={agreeToEULA}
                                                            onDecline={() => setAgreedToEULA(false)}
                                                        />
                                                    }
                                                    {(isLicensedEnv && !hasLicense) &&
                                                        <UnlicensedModal />
                                                    }
                                                </TemplateProvider>
                                            </TypingProvider>
                                        </MessagesProvider>
                                    </ProfilePictureProvider>
                                </ResourceProvider>
                            </SignalRProvider>
                        </SkillsProvider>
                    </AssistantProvider>
                </SettingsProvider>
            </AccessProvider>
        </ChatProvider>
    );

    return (
        <Routes>
            <Route path=":ChatId?/*" element={ChatContextElements} />
        </Routes>
    );
};

export default SymphonyApp;