import React, { Fragment, useEffect, useState } from "react";

import Checkbox from "./Checkbox";

const CheckboxGroupMember = (props) => {
  const [isChecked, setIsChecked] = useState(true);

  const checkboxOnChangedHandler = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (props.parentIsChecked !== undefined)
      setIsChecked(props.parentIsChecked);
  }, [props.parentIsChecked]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(isChecked);
    }
  }, [isChecked]);

  return (
    <label className="a-checkbox">
      <span>{props.label}</span>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={checkboxOnChangedHandler}
      />
      <div className="b-input"></div>
    </label>
  );
};

const CheckboxGroup = (props) => {
  const [groupIsChecked, setGroupIsChecked] = useState(true);

  const groupCheckboxEnabled = props.groupCheckboxEnabled ?? true;

  // WHEN THE GROUP IS CHANGED
  const checkboxGroupIsCheckedChangeHandler = () => {
    setGroupIsChecked(!groupIsChecked);
  };

  // WHEN A MEMEBER IS CHECKED
  const checkboxMemberIsCheckedChangeHandler = (key, isChecked) => {
    if (props.onChange) {
      props.onChange(key, isChecked)
    }
  };

  useEffect(() => {
    if (props.onGroupChange) {
      props.onGroupChange(groupIsChecked)
    }
  }, [groupIsChecked]);

  // console.log(props.options)

  return (
    <Fragment>
      <label className="a-checkbox a-checkbox__group-heading">
        <span>{props.heading}</span>
        {groupCheckboxEnabled && <input
          type="checkbox"
          defaultChecked={true}
          onChange={checkboxGroupIsCheckedChangeHandler}
        />}
        <div className="b-input"></div>
      </label>
      {
        props.options && props.options.map((x) => {
          return <CheckboxGroupMember key={x.key} label={x.label} parentIsChecked={groupIsChecked} onChange={(checked) => { checkboxMemberIsCheckedChangeHandler(x.key, checked) }} />
        })
      }
    </Fragment>
  );
};

export default CheckboxGroup;