import React, { useState, Fragment, useEffect, useId } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
} from "react-router-dom";
import myCaiApiService from "../../services/mycai-api-v1";
import { Checkbox } from "../fusion";
import { getMsGraphUserPhotoUrl } from "../../services/graph"
import { SettingContextWrapper } from "../../contexts/SettingsContext";
// import PayrollNewPositionPanel from "../payroll/PayrollNewPositionPanel";

const annualizedHours = 1880;

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

var hourlyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0
});

var indiaFormatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR"
})

var phpFormatter = new Intl.NumberFormat("en-PH", {
  style: "currency",
  currency: "PHP"
})

var meritStatuses = { 0: "HR Rejected", 1: "Executive Review", 2: "HR Admin Review", 3: "HR Review", 4: "Financial Review", 5: "Finalizing" };
var meritStatusColors = { 0: "a-status-indicator--yellow", 1: "a-status-indicator--red", 2: "a-status-indicator--red", 3: "a-status-indicator--red", 4: "a-status-indicator--red", 5: "a-status-indicator--green" };

export const MeritChangeRow = (props) => {
  const [imgUrl, setImgUrl] = useState("");

  const [role, setRole] = useState([]);
  // const [isChecked, setIsChecked] = useState(props.data.selected ? props.data.selected : false);

  const [isChecked, setIsChecked] = useState(false);

  const [bonusChange, setBonusChange] = useState(0);
  const [propCommission, setPropCommission] = useState(0);
  const [propBonus, setPropBonus] = useState(null);
  const [currBonus, setCurrBonus] = useState(0);

  const checkboxOnChangedHandler = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (props.parentIsChecked !== undefined) {
      // console.log("Parent Changed")
      setIsChecked(props.parentIsChecked);
    }
  }, [props.parentIsChecked]);

  useEffect(() => {
    if (props.onChange) {
      // console.log("Child Changed");
      props.onChange(isChecked);
    }
  }, [isChecked]);

  useEffect(() => {
    // getMsGraphPhotoUrl(graphAccessToken)
    //       .then((response) => {
    //         const url = window.URL || window.webkitURL;
    //         const blobUrl = url.createObjectURL(response);
    //         setImgUrl(blobUrl);
    //       })
    // getMsGraphUserPhotoUrl(props.data.upn, props.accessToken)
    /*
    myCaiApiService.GetUserPhotoUrl(props.accessToken, props.data.upn)
      .then((response) => {
        const url = window.URL || window.webkitURL;
        // const blobUrl = url.createObjectURL(response);
        // setImgUrl(blobUrl);
        // setImgUrl(response.body.arrayBuffer());


        // const reader = response.body.getReader();
        // reader.read().then((value) => {
        //   // setImgUrl(value.value);

        //   const blobUrl = url.createObjectURL(
        //     new Blob([value.value.buffer], { type: 'image/png' })
        //   );
        //   setImgUrl(blobUrl);
        // });

      })
      */
    setImgUrl(`https://ui-avatars.com/api/?name=${props.data.employeeName}}`);
  }, [props.data])

  useEffect(() => {
    let currentBase = props.data.curBaseComp ?? 0.0;
    const currentBonus = props.data.currentBonus ?? 0.0;
    let currentCommission = props.data.currentCommission ?? 0.0;
    let proposedBase = props.data.proposedBaseComp ?? undefined;
    const proposedBonus = props.data.proposedBonus ?? 0.0;
    let proposedCommission = props.data.proposedCommission ?? 0.0;
    // calculates inc amount and checks if its negative, if so, set increase amount to 0.0
    const compGrade =
      props.data?.curCompGrade == "Hourly" ? "hourly" : "salary";
    // if (compGrade === "hourly") {
    //   currentBase = currentBase * annualizedHours * ftePct;

    //   if (proposedBase !== undefined) {
    //     proposedBase = proposedBase * annualizedHours * ftePct;
    //   }
    // }
    const currentTotalComp = currentBase + currentBonus + currentCommission;

  let proposedTotalComp = undefined;
    if (proposedBase !== undefined) {
      proposedTotalComp = proposedBase + proposedBonus + proposedCommission;
    }

    let percentChange = (proposedBonus - currentBonus) / currentBonus;
    setBonusChange(percentChange);
    setPropCommission(proposedCommission);
    setPropBonus(proposedBonus);
    setCurrBonus(currentBonus);
  }, [props.data])
  return (
    <tr className={`o-treegrid__row o-treegrid__row--shown${props.data.isEligible ? '' : ' o-treegrid__row--striped-bg'}`}>
      <td className="o-treegrid__cell o-treegrid__cell--centered">
        <label className={`a-checkbox a-checkbox--hidden-label`}>
          <span>Select {props.data.employeeName}</span>
          <input
            type="checkbox"
            //checked={props.data.isEligible && props.data.assignedToUser && isChecked}
            onChange={checkboxOnChangedHandler}
          //disabled={!props.data.isEligible /* || !props.data.assignedToUser*/}
          />
          <div className="b-input"></div>
        </label>
      </td>
      <td className="o-treegrid__cell">
        <div className="m-subject-highlight">
          <div className="a-avatar m-subject-highlight__avatar">
            <img
              src={imgUrl}
              alt=""
              className="a-avatar__img"
            />
            {/* <img src={`data:image/jpeg;base64,${imgUrl}`} className="a-avatar__img" /> */}
          </div>
          <div className="m-subject-highlight__content">
            <p className="a-bold-text m-subject-highlight__title">
              {props.data.employeeName}
            </p>
            <p className="a-muted-text m-subject-highlight__subtitle">
              {props.data.jobTitle}
            </p>
            <p className="a-accent-text m-subject-highlight__meta">{props.data.costCenter?.substring(0, 6)}</p>
          </div>
        </div>
      </td>

      {/* 2023 Target Bonus*/}
      {/* Placeholder here for currency conversion if the user is non-USD */}
      <td className="o-treegrid__cell">
        {formatter.format(currBonus)} <br />
        {(props.data.curBaseCurCode === "USD") ?
          ""
          // TODO: swap value for the value thats gonnna be pulled from workday
          : (props.data.curBaseCurCode === "INR") ?
            indiaFormatter.format(1000000)
            : phpFormatter.format(1000000)
        }
      </td>
      {/* Proposed 2024 Target Total */}
      <td className="o-treegrid__cell">{(props.data.hasObjectives) ? formatter.format(propBonus) : <></>}</td>
      {/* Bonus Change %*/}
      <td className="o-treegrid__cell">
        {props.data.hasObjectives ? (
          bonusChange < 0 ?
            percentFormatter.format(bonusChange) : `+${percentFormatter.format(bonusChange)}`
        ) : <></>
        }
      </td>
      {/* Commission */}
      <td className="o-treegrid__cell">{formatter.format(propCommission)}</td>
      {/* Status */}
      <td className="o-treegrid__cell">
        <div className="m-status-block">
          <div
            className={"a-status-indicator m-status-block__indicator " + ((props.data.status == "") ? "a-status-indicator--blue" : meritStatusColors[props.data.status])}>
          </div>
          <span className="m-status-block__item">{(props.data.status == "") ? "Not Submitted" : meritStatuses[props.data.status]}</span> </div> </td> <td className="o-treegrid__cell o-treegrid__cell--actions"> {/*props.data.isEligible && props.data.assignedToUser &&*/
            <a
              href="#"
              className="a-button a-button--outline a-button--small o-treegrid__hidden-button"
              onClick={(e) => {
                e.preventDefault();
                props.onDetailsClicked && props.onDetailsClicked();
              }}
            >
              Details
            </a>}
      </td>
    </tr>
  );
};
