import React, { Fragment } from "react";

const pittLogo = require("../../../images/pitt-shield.png");

export const ToolbarLinks = (props) => {
	return <ul className="o-top-menu__links">{props.children}</ul>;
};

export const ToolbarLink = (props) => {
	return (
		<li className="o-top-menu__link-item">
			<a
				href={props.href ?? "#"}
				className="o-top-menu__link"
				target={"_blank"}
			>
				{props.text ? `${props.text} &#x2197;` : ""}
				{props.children}
			</a>
		</li>
	);
};

export const ToolbarLogo = (props) => {
	let logo = <></>;
	process.env.REACT_APP_THEME === "pitt"
		? (logo = <img className="o-top-menu__logo" src={pittLogo} />)
		: (logo = <img className="o-top-menu__logo" src={props.imgSrc} />);

	return logo;
};

export const ToolbarAppLauncher = (props) => {
	const handleClick = (e) => {
		if (props.onClick) {
			props.onClick();
			e.preventDefault();
		}
	};

	return (
		<button
			className="o-top-menu__app-switcher"
			id="launcher-open"
			onClick={handleClick}
		>
			<span className="o-top-menu__app-switcher-label">
				{process.env.REACT_APP_BRANDING_APP_NAME ?? "My CAI"} Apps
			</span>
			<svg
				className="o-top-menu__app-switcher-icon"
				id="glyph"
				height="512"
				viewBox="0 0 64 64"
				width="512"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect height="14.68" rx="1" width="14.68" x="4" y="4" />
				<rect height="14.68" rx="1" width="14.68" x="4" y="24.66" />
				<rect height="14.68" rx="1" width="14.68" x="4" y="45.32" />
				<rect height="14.68" rx="1" width="14.68" x="24.66" y="4" />
				<rect height="14.68" rx="1" width="14.68" x="24.66" y="24.66" />
				<rect height="14.68" rx="1" width="14.68" x="24.66" y="45.32" />
				<rect height="14.68" rx="1" width="14.68" x="45.32" y="4" />
				<rect height="14.68" rx="1" width="14.68" x="45.32" y="24.66" />
				<rect height="14.68" rx="1" width="14.68" x="45.32" y="45.32" />
			</svg>
		</button>
	);
};

export const ToolbarRight = (props) => {
	return <div className="o-top-menu__right">{props.children}</div>;
};
