import React, { Fragment } from "react"


const StatRowItem = props => {
    return (
        <div className={`o-stat-row__stat m-stat ${props.className}`}>
            <span className="m-stat__value">{props.value}</span>
            <span className="m-stat__label">{props.label}</span>
        </div>
    )
}

export default StatRowItem;