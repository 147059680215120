// Package imports
import React, { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Redirect, NavLink, useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, IconProvider, Button, Modal } from "cai-fusion";
import { symbolPlus } from "d3";
// import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";

import "./styles/toolkit.css";

// Services, Configs & Contexts
import { getGraphUserData, getMsGraphPhotoUrl, callMsGraphUsers, getMsGraphGroups } from "./services/graph";
import myCaiApiService from "./services/mycai-api-v1";
import { symphonySVGs } from "./configs/iconConfig";
// import { apiRequest, loginRequest } from "./configs/authConfig";
import { useAuthentication } from "./contexts/AuthContext";
// import { UserProfileContextWrapper } from "./components/symphony/contexts/UserProfileContext";
import { SettingContextWrapper, SettingsContext } from "./contexts/SettingsContext";
import { LicensingProvider } from "./components/symphony/contexts/LicenseContext";
import { ComplianceProvider } from "./components/symphony/contexts/ComplianceContext";
import { FactsProvider } from "./components/symphony/contexts/FactsContext";
import { ModelsProvider } from "./components/symphony/contexts/ModelsContext";
import { ChatHistoryProvider } from "./components/symphony/contexts/ChatHistoryContext";
import { UserProfileProvider } from "./components/symphony/contexts/UserProfileContext";

// Admin Config Wrapper
import { AppProvider } from "./contexts/AppContext";

// Local Fusion component imports & Toolbar stuff
import { Overlay, OverlayTitle, PageContent } from "./components/fusion";
import { Toolbar, ToolbarAppLauncher, ToolbarLink, ToolbarLogo } from "./components/fusion/Toolbar";
import { ToolbarLinks, ToolbarRight } from "./components/fusion/Toolbar/ToolbarLink";
import { Navbar, NavbarAvatarMenu, NavbarItem } from "./components/fusion/Navbar";
import { Form, FormGroup } from "./components/fusion/Input";
import { Option, Select } from "./components/fusion/Select";
import { Progress } from "./components/fusion/Progress";
import NavbarDropdownItem from "./components/fusion/Navbar/NavbarDropdownItem";
import ReportBugLink from "./components/toolbarLinks/ReportBugLink";
import SettingsLink from "./components/toolbarLinks/SettingsLink";

// Pages & Apps
import BonusPlanningApp from "./components/pages/bonusPlanning";
import AdminSettingsPage from "./components/pages/adminSettingsPage";
import SettingsPage from "./components/pages/settingsPage";
import EngagementReportingApp from "./components/pages/engagementReportingApp";
import HomePage from "./components/pages/homePage";
import PayrollPlanningApp from "./components/pages/payrollPlanningApp";
import EnterpriseDataReportingApp from "./components/pages/enterpriseDataReportingApp";
import CAIGPTApp from "./components/caigpt/caigptApp";
import SymphonyApp from "./components/symphony/symphonyApp";
import FormsApp from "./components/forms/formsApp";

// const HomePage = lazy(() => import("./components/pages/homePage"));
// const PayrollApp = lazy(() => import("./components/pages/paryollPlanningApp"));

// Images
const imgLogo = require("./images/myCAI-white.svg").default;
// const imgLogo = "https://www.pitt.edu/themes/custom/pitt_default/logo.svg";
const imgGo = require("./images/icon-go-white.svg").default;
const imgAppEngagementReporting =
    require("./images/app-engagement-reporting.svg").default;
const imgAppPayrollPlanning = require("./images/app-payroll.svg").default;
const imgAppSift = require("./images/app-sift.svg").default;
const imgAppTwilio = require("./images/app-twilio.svg").default;
const imgAppSettings = require("./images/icon-settings-white.svg").default;

const myCAIApplications = [
    // {
    //     path: "/payroll",
    //     imgSrc: imgAppPayrollPlanning,
    //     appName: "Payroll Planning",
    // },
    // {
    //   path: "/bonus",
    //   imgSrc: imgAppPayrollPlanning,
    //   appName: "Bonus Planning",
    // },
    // {
    //   path: "/sift",
    //   imgSrc: imgAppSift,
    //   appName: "Sift",
    // },
    // {
    //   path: "/communications",
    //   imgSrc: imgAppTwilio,
    //   appName: "Communications Admin (Twilio)",
    // },
    // {
    //   path: "/enterprisereporting",
    //   imgSrc: imgAppEngagementReporting,
    //   appName: "Enterprise Reporting",
    // },
    // {
    //   path: "/engagementreporting",
    //   imgSrc: imgAppEngagementReporting,
    //   appName: "Engagement Reporting",
    // },
    // {
    //     path: "/cainlp/playground",
    //     imgSrc: imgAppEngagementReporting,
    //     appName: "CAI NLP",
    // },
    {
        path: "/symphony",
        imgSrc: imgAppTwilio,
        appName: process.env.REACT_APP_BRANDING_SYMPHONY_NAME ?? "Symphony",
    },
    {
        path: "/settings/symphony",
        imgSrc: imgAppSettings,
        appName: "My Settings"
    },
    // {
    //     path: "/forms/governance",
    //     imgSrc: imgAppSettings,
    //     appName: "Forms"
    // }
];

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    const {
        isAuthenticated,
        graphData,
        imgUrl,
        impersonating,
        graphAccessToken,
        apiAccessToken,
        logOut,
        setImpersonating,
    } = useAuthentication();

    const [hideImpersonateModal, setHideImpersonateModal] = useState(true);
    const [userQuery, setUserQuery] = useState("");
    const [userQueryOptions, setUserQueryOptions] = useState([]);
    const [selectedUserIndex, setSelectedUserIndex] = useState(-1);
    const [impersonationInProgress, setImpersonationInProgress] = useState(false);
    const [resetInProgress, setResetInProgress] = useState(false);

    const [showAppLauncher, setShowAppLauncher] = useState(false);

    // TEMPORARY FEATURE MANAGEMENT.. WILL MOVE TO SERVICE
    const [impersonateAvailable, setImpersonateAvailable] = useState(false);
    const [resetDbAvailable, setResetDbAvailable] = useState(false);

    // Used to determine the platform the code is being run on
    const [isProd, setIsProd] = useState(false)

    // const navigate = useNavigate();

    const TEST_allowedDbReseters = [
        "Melissa Michael",
        "Tammy Harper",
        "Kelly Butz",
        "Christopher Zumberge",
        "Alex Noel",
    ];
    const TEST_allowedImpersonators = [
        "Melissa Michael",
        "Tammy Harper",
        "Kelly Butz",
        "Christopher Zumberge",
    ];
    const PROD_allowedDbReseters = ["Christopher Zumberge"];
    const PROD_allowedImpersonators = ["Christopher Zumberge", "Melissa Michael", "Tammy Harper"];

    useEffect(() => {
        if (graphData) {
            let currentHref = window.location.href;
            let isDev =
                currentHref.indexOf("mycai-dev") >= 0 ||
                currentHref.indexOf("localhost:") >= 0;
            let isTest =
                currentHref.indexOf("testmy.cai.io") >= 0 ||
                currentHref.indexOf("mycai-test") >= 0;
            let isProd = !isDev && !isTest;
            //currentHref.indexOf("my.cai.io") >= 0 ||
            //currentHref.indexOf("mycai-prod") >= 0;

            if (isDev || isTest) {
                console.log("[DEBUG] Hello from test environment! If you are seeing this on prod, then uh oh");
                console.log("This is the " + (isDev ? "local " : "development ") + "environment.");
            }
            setIsProd(isProd);

            if (isDev || impersonating) {
                setImpersonateAvailable(true);
                setResetDbAvailable(true);
            } else if (isTest) {
                if (
                    TEST_allowedImpersonators.map((x) => x.toLowerCase()).indexOf(
                        graphData.displayName.toLowerCase()
                    ) >= 0
                ) {
                    setImpersonateAvailable(true);
                } else {
                    setImpersonateAvailable(false);
                }

                if (
                    TEST_allowedDbReseters.map((x) => x.toLowerCase()).indexOf(
                        graphData.displayName.toLowerCase()
                    ) >= 0
                ) {
                    setResetDbAvailable(true);
                } else {
                    setResetDbAvailable(false);
                }
            } else if (isProd) {
                if (
                    PROD_allowedImpersonators.map((x) => x.toLowerCase()).indexOf(
                        graphData.displayName.toLowerCase()
                    ) >= 0
                ) {
                    setImpersonateAvailable(true);
                } else {
                    setImpersonateAvailable(false);
                }

                if (
                    PROD_allowedDbReseters.map((x) => x.toLowerCase()).indexOf(
                        graphData.displayName.toLowerCase()
                    ) >= 0
                ) {
                    setResetDbAvailable(true);
                } else {
                    setResetDbAvailable(false);
                }
            } else {
                setImpersonateAvailable(false);
                setResetDbAvailable(false);
            }
        } else {
            setImpersonateAvailable(false);
            setResetDbAvailable(false);
        }
    }, [impersonating, graphData]);
    // end TEMPORARY FEATURE MANAGEMENT

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        let value = target.value;

        if (name === "userQuery") setUserQuery(value);
    };

    useEffect(() => {
        if (hideImpersonateModal) {
            setSelectedUserIndex(-1);
            setUserQueryOptions([]);
            setUserQuery("");
            setImpersonationInProgress(false);
        }
    }, [hideImpersonateModal]);

    useEffect(() => {
        if (userQuery) {
            callMsGraphUsers(graphAccessToken, userQuery).then((data) => {
                // bind to select
                //console.log(data);
                setUserQueryOptions(data.value);
                setSelectedUserIndex(0);
            });
        }
    }, [userQuery]);

    const myCAIAvatarMenu = (
        <NavbarAvatarMenu
            imgUrl={imgUrl}
            displayName={
                impersonating
                    ? impersonating.displayName
                    : graphData == null
                        ? ""
                        : graphData.displayName
            }
        >
            {impersonateAvailable && (
                <NavbarDropdownItem
                    onClick={() => {
                        setHideImpersonateModal(false);
                    }}
                >
                    Impersonate User
                    <span className="o-navbar__dropdown-list-item-help o-navbar__dropdown-list-item-help--warning">
                        This is a UAT function only.
                    </span>
                </NavbarDropdownItem>
            )}
            {(
                <NavLink to={"/settings/display"} className="o-navbar__dropdown-list-item">
                    My Settings
                </NavLink>
            )}
            {resetDbAvailable && (
                <NavbarDropdownItem
                    onClick={() => {
                        myCaiApiService.Reset(apiAccessToken).then(() => {
                            setResetInProgress(false);
                        });
                        setResetInProgress(true);
                    }}
                >
                    Reset Payroll Database
                    <span className="o-navbar__dropdown-list-item-help o-navbar__dropdown-list-item-help--warning">
                        This is a UAT function only.
                    </span>
                </NavbarDropdownItem>
            )}
            {/* <NavbarDropdownItem>
                View Profile
            </NavbarDropdownItem> */}
            <a
                className="o-navbar__dropdown-list-item o-navbar__dropdown-list-item--top-hr"
                onClick={(e) => {
                    e.preventDefault();
                    logOut();
                }}
            >
                Sign Out
            </a>
        </NavbarAvatarMenu>
    );

    return (
        <Router>
            <Toolbar>
                <ToolbarLogo imgSrc={imgLogo} />
                <ToolbarRight>
                    <ToolbarLinks>
                        <ReportBugLink reportedBy={graphData && graphData.displayName} />
                        <ToastProvider>
                            <SettingContextWrapper>
                                {/* Must be within SettingContext */}
                                <SettingsLink />
                            </SettingContextWrapper>
                        </ToastProvider>
                    </ToolbarLinks>
                    <ToolbarAppLauncher
                        onClick={() => {
                            setShowAppLauncher(true);
                        }}
                    />
                </ToolbarRight>
            </Toolbar>

            {/* https://stackoverflow.com/a/69880162/1442991 */}
            <Routes>
                <Route
                    exact
                    path="/"
                    element={
                        <>
                            <Navbar
                                // relative to app in context
                                appTitle=""
                                // relative to app in context
                                navigations={[]}
                            >
                                {myCAIAvatarMenu}
                            </Navbar>
                            <HomePage
                                onHomePageLoad={() => setShowAppLauncher(true)}
                                onHomePageUnload={() => setShowAppLauncher(false)}
                            />
                        </>
                    }
                />
                {/* <Route
                    path="/sift/*"
                    element={
                        <>
                            <Navbar
                                // relative to app in context
                                appTitle="Sift"
                                // relative to app in context
                                navigations={[
                                    {
                                        path: "/sift/inbox",
                                        title: "Inbox",
                                    },
                                    {
                                        path: "/keywords",
                                        title: "Keywords",
                                    },
                                    {
                                        path: "/sift/metrics",
                                        title: "Metrics",
                                    },
                                ]}
                            >
                                {myCAIAvatarMenu}
                            </Navbar>
                            <PageContent sectionId="home-content">
                                <div className="l-row">
                                    <div className="col-md-1 col-log-2 col-xl-2"></div>
                                    <div className="col-md-10 col-lg-8 col-xl-8 l-pt-sm">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Heading size={4}>
                                                    Welcome to the future home of the new, updated Sift
                                                    application!
                                                </Heading>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-log-2 col-xl-2"></div>
                                </div>
                            </PageContent>
                        </>
                    }
                /> */}
                <Route
                    path="/bonus/*"
                    element={
                        <>
                            <Navbar
                                // relative to app in context
                                appTitle="Bonus Planning"
                                // relative to app in context
                                navigations={[
                                    {
                                        path: "/bonus",
                                        title: "My Organizations",
                                    },
                                ]}
                            >
                                {myCAIAvatarMenu}
                            </Navbar>
                            <BonusPlanningApp
                                imgUrl={imgUrl}
                                accessToken={apiAccessToken}
                                graphAccessToken={graphAccessToken}
                                impersonation={impersonating}
                                upn={
                                    impersonating
                                        ? impersonating.userPrincipalName
                                        : graphData?.userPrincipalName
                                }
                            />
                        </>
                    }
                />
                <Route
                    path="/payroll/*"
                    element={
                        <>
                            <Navbar
                                // relative to app in context
                                appTitle="Payroll Planning"
                                // relative to app in context
                                navigations={[
                                    {
                                        path: "/payroll",
                                        title: "My Organizations",
                                    },
                                ]}
                            >
                                {myCAIAvatarMenu}
                            </Navbar>
                            <PayrollPlanningApp
                                imgUrl={imgUrl}
                                accessToken={apiAccessToken}
                                graphAccessToken={graphAccessToken}
                                impersonation={impersonating}
                                upn={
                                    impersonating
                                        ? impersonating.userPrincipalName
                                        : graphData?.userPrincipalName
                                }
                            />
                        </>
                    }
                />
                {/* <Route
                    path="/enterprisereporting"
                    element={
                        <>
                            <Navbar
                                // relative to app in context
                                appTitle="Enterprise Reporting"
                                // relative to app in context
                                navigations={[
                                    {
                                        path: "/enterprisereporting",
                                        title: "My Clients",
                                    },
                                ]}
                            >
                                {myCAIAvatarMenu}
                            </Navbar>
                            <EnterpriseDataReportingApp
                                imgUrl={imgUrl}
                                accessToken={apiAccessToken}
                                graphAccessToken={graphAccessToken}
                                impersonation={impersonating}
                            />
                        </>
                    }
                /> */}
                {/* <Route
                    path="/engagementreporting"
                    element={
                        <>
                            <Navbar
                                // relative to app in context
                                appTitle="Engagement Reporting"
                                // relative to app in context
                                navigations={[
                                    {
                                        path: "/engagementreporting",
                                        title: "My Clients",
                                    },
                                ]}
                            >
                                {myCAIAvatarMenu}
                            </Navbar>
                            <EngagementReportingApp
                                imgUrl={imgUrl}
                                accessToken={apiAccessToken}
                                graphAccessToken={graphAccessToken}
                                impersonation={impersonating}
                            />
                        </>
                    }
                /> */}
                {/* <Route
                    path="/communications"
                    element={
                        <>
                            <Navbar
                                // relative to app in context
                                appTitle="Communications Admin (Twilio)"
                                // relative to app in context
                                navigations={[
                                    {
                                        path: "/communications/",
                                        title: "Messaging",
                                    },
                                    {
                                        path: "/communications/groups",
                                        title: "Distribution Groups",
                                    },
                                    {
                                        path: "/communications/chat",
                                        title: "Live Chat",
                                    },
                                ]}
                            >
                                {myCAIAvatarMenu}
                            </Navbar>
                            <PageContent sectionId="home-content">
                                <div className="l-row">
                                    <div className="col-md-1 col-log-2 col-xl-2"></div>
                                    <div className="col-md-10 col-lg-8 col-xl-8 l-pt-sm">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Heading size={4}>
                                                    Welcome to the future home of the new and improved
                                                    Twilio Admin application!
                                                </Heading>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-log-2 col-xl-2"></div>
                                </div>
                            </PageContent>
                        </>
                    }
                /> */}
                {/* <Route path="/details/:rfpid" element={<DetailsPage />} />*/}
                {/* <Route
                    path="/cainlp/*"
                    element={
                        <>
                            <Navbar
                                // relative to app in context
                                appTitle="CAI NLP"
                                // relative to app in context
                                navigations={[
                                    {
                                        path: "playground",
                                        title: "Playground",
                                    },
                                    {
                                        path: "chat",
                                        title: "Chat",
                                    },
                                ]}
                            >
                                {myCAIAvatarMenu}
                            </Navbar>
                            <CAIGPTApp
                                imgUrl={imgUrl}
                                accessToken={apiAccessToken}
                                graphAccessToken={graphAccessToken}
                                graphData={graphData}
                                impersonation={impersonating}
                            />
                        </>
                    }
                /> */}
                <Route
                    path="/symphony/*"
                    element={
                        <>
                            <Navbar 
                            appTitle={process.env.REACT_APP_BRANDING_SYMPHONY_TITLE ?? "Symphony"} 
                            appTitleSuffix={process.env.REACT_APP_BRANDING_SYMPHONY_TITLE_SUFFIX ?? ""}
                            appSubtitle={process.env.REACT_APP_BRANDING_SYMPHONY_SUBTITLE ?? ""}
                            navigations={[
                                {
                                    path: "/settings/symphony",
                                    title: "App Settings",
                                },
                                // {
                                //     path: "/symphony/data",
                                //     title: "Data Stores",
                                // },
                            ]}>
                                {myCAIAvatarMenu}
                            </Navbar>
                            {/* new contexts */}
                            <UserProfileProvider>
                                <ChatHistoryProvider>
                                    {/* old contexts */}
                                    {/* <UserProfileContextWrapper> */}
                                        <LicensingProvider>
                                            <ComplianceProvider>
                                                <IconProvider svgs={symphonySVGs}> {/* Provides icons for Fusion components. */}
                                                    <ToastProvider> {/* Allows the ability to create a toast on command. */}
                                                        <SymphonyApp />
                                                    </ToastProvider>
                                                </IconProvider>
                                            </ComplianceProvider>
                                        </LicensingProvider>
                                    {/* </UserProfileContextWrapper> */}
                                </ChatHistoryProvider>
                            </UserProfileProvider>
                        </>
                    }
                />
                <Route
                    path="/settings/admin/*"
                    element={
                        <>
                            <Navbar appTitle="Admin Settings" navigations={[]}>
                                {myCAIAvatarMenu}
                            </Navbar>
                            <IconProvider svgs={symphonySVGs}>
                                <ToastProvider>
                                    <SettingContextWrapper>
                                        <FactsProvider>
                                            <ModelsProvider>
                                                <AdminSettingsPage />
                                            </ModelsProvider>
                                        </FactsProvider>
                                    </SettingContextWrapper>
                                </ToastProvider>
                            </IconProvider>
                        </>
                    }
                />
                <Route
                    path="/settings/*"
                    element={
                        <>
                            <Navbar appTitle="Settings" navigations={[]}>
                                {myCAIAvatarMenu}
                            </Navbar>
                            <UserProfileProvider>
                                <IconProvider svgs={symphonySVGs}>
                                    <ToastProvider>
                                        <SettingContextWrapper>
                                            <SettingsPage />
                                        </SettingContextWrapper>
                                    </ToastProvider>
                                </IconProvider>
                            </UserProfileProvider>
                        </>
                    }
                />
                <Route
                    path="/forms/*"
                    element={
                        <>
                            <Navbar appTitle="Forms" navigations={[]}>
                                {myCAIAvatarMenu}
                            </Navbar>
                            <UserProfileProvider>
                                <IconProvider svgs={symphonySVGs}>
                                    <ToastProvider>
                                        <FormsApp />
                                    </ToastProvider>
                                </IconProvider>
                            </UserProfileProvider>
                        </>
                    }
                />
                
                {/* Catch Other */}
                <Route
                    path="*"
                    element={
                        <>
                            <Navbar
                                // relative to app in context
                                appTitle=""
                                // relative to app in context
                                navigations={[]}
                            >
                                {myCAIAvatarMenu}
                            </Navbar>
                            <HomePage />
                        </>
                    }
                />
            </Routes>
            <IconProvider svgs={symphonySVGs}>
                <Modal
                    isHidden={hideImpersonateModal}
                    clickOutsideToClose
                    onClose={() => setHideImpersonateModal(true)}
                    title="Impersonate"
                    actions={<>
                        <Button outline children="Cancel" onClick={() => setHideImpersonateModal(true)} />
                        <Button children="Confirm" onClick={() => {
                            setImpersonationInProgress(true);
                            //console.log(selectedUserIndex);
                            let selectedUser = userQueryOptions[selectedUserIndex];
                            myCaiApiService
                                .ConvertUPNtoEmployeeId(
                                    apiAccessToken,
                                    selectedUser.userPrincipalName
                                )
                                .then((data) => {
                                    setImpersonating({
                                        displayName: selectedUser.displayName,
                                        employeeId: data["employeeId"],
                                        userPrincipalName: selectedUser.userPrincipalName,
                                    });
                                    setHideImpersonateModal(true);
                                });
                        }} />
                    </>}
                >
                    <div className="l-row">
                        {!impersonationInProgress && (
                            <div className="l-one-col">
                                <p>Who would you like to impersonate?</p>
                                <Form>
                                    <FormGroup>
                                        <label htmlFor="" className="a-label a-label--required">
                                            New Active User
                                        </label>
                                        <input
                                            type="text"
                                            className="a-text-input"
                                            name="userQuery"
                                            placeholder="Begin typing a name..."
                                            value={userQuery}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                    {userQueryOptions && userQueryOptions.length > 0 && (
                                        <FormGroup>
                                            <label htmlFor="" className="a-label">
                                                Select Here
                                            </label>
                                            <Select
                                                name="reassignSelector"
                                                id="reassignSelector"
                                                onChange={(e) => {
                                                    setSelectedUserIndex(e.target.selectedIndex);
                                                }}
                                            >
                                                {userQueryOptions.map((e) => (
                                                    <Option value={e.id} key={e.id}>
                                                        {`${e.displayName}`}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    )}
                                </Form>
                            </div>
                        )}
                        {impersonationInProgress && (
                            <div className="l-one-col">
                                <p>Impersonation in Progress</p>
                                <Progress>Loading New User Settings</Progress>
                            </div>
                        )}
                    </div>
                </Modal>
            </IconProvider>
            <Overlay
                show={showAppLauncher}
                onClose={() => {
                    setShowAppLauncher(false);
                }}
            >
                <OverlayTitle>{process.env.REACT_APP_BRANDING_APP_NAME ?? "My CAI"} Apps</OverlayTitle>
                <div className="o-app-launcher__list">
                    {myCAIApplications.map((app, index) => {
                        return (
                            <NavLink
                                className="o-app-launcher__app"
                                to={app.path}
                                onClick={() => {
                                    setShowAppLauncher(false);
                                }}
                                key={index}
                            >
                                <img className="o-app-launcher__app-icon" src={app.imgSrc} />
                                <span className="o-app-launcher__app-title">{app.appName}</span>
                                <img className="o-app-launcher__go-icon" src={imgGo} />
                            </NavLink>
                        );
                    })}
                </div>
            </Overlay>
        </Router>
    );
};

function App() {
    return (
        <AppProvider>
            <MainContent />
        </AppProvider>
    );
}

export default App;
