import React, { Fragment, useEffect, useState } from "react";

const Modal = (props) => {

    const [isVisible, setIsVisible] = useState(true);

    // TODO temp, until class
    const modalStyling = {
        "display": "block",
        "paddingRight": "17px",
        "OverflowX": "hidden",
        "OverflowY": "auto",
        "backgroundColor": "rgba(80, 80, 80, 0.8)"
    }

    useEffect(() => {
        // placeholder
    }, [isVisible])

    useEffect(() => {
        if (props.show !== undefined) {
            if (props.show)
                setIsVisible(true);
            else
                setIsVisible(false);
        }
    }, [props.show])

    const closeClickedHandler = () => {
        if (props.onClose) {
            props.onClose();
        }
    }
    const cancelClickedHandler = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }
    const confirmClickedHandler = () => {
        if (props.onConfirm) {
            props.onConfirm();
        }
    }

    return (
        <Fragment>
            <div className={`o-modal ${isVisible ? "o-modal--shown" : "o-modal--hidden"}`} id="exampleModal">
                <div className="o-modal__dialog o-modal__dialog--wide">
                    <div className="o-modal__content">
                        <div className="o-modal__header">
                            {/* <h3 className="o-modal__title l-mb-xs">Yo! You didn't save your data.</h3> */}
                            <h3 className="o-modal__title l-mb-xs">{props.title}</h3>
                            <button type="button" className="close o-modal__close" data-dismiss="modal" aria-label="Close"
                                onClick={(e) => {
                                    closeClickedHandler();
                                    e.preventDefault();
                                }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="o-modal__body">
                            {props.body}
                        </div>
                        <div className="o-modal__footer">
                            <div className="m-button-row">
                                <a href="#" className="m-button-row__button a-button a-button--outline" onClick={(e) => {
                                    cancelClickedHandler();
                                    e.preventDefault();
                                }}>{props.cancel}</a>
                                <a href="#" className="m-button-row__button a-button" onClick={(e) => {
                                    confirmClickedHandler();
                                    e.preventDefault();
                                }}>{props.confirm}</a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default Modal;