import { servicesAtCAIConfig } from "../configs/servicesAtCAIConfig";

const serviceName = "Observability";
const observabilityVersion = "v1";

const createOptions = (method, subscriptionKey, applicationKey) => {
    const headers = new Headers();

    if (subscriptionKey) {
        headers.append("Ocp-Apim-Subscription-Key", subscriptionKey);
    }
    if (applicationKey) {
        headers.append("applicationKey", applicationKey);
    }

    const options = {
        method: method,
        headers: headers
    };

    return options;
}

const observabilityIntentService = {
    LogEntry: function (logEntry) {
        let options = { ...createOptions("POST", servicesAtCAIConfig.subscriptionKey, process.env.REACT_APP_OBSERVABILITY_APP_ID), body: JSON.stringify(logEntry) };
        options.headers.append("content-type", "application/json")
        options.headers.append("redirect", "follow");
        let url = `${servicesAtCAIConfig.baseURL}/${serviceName}/${observabilityVersion}/logging`;
        return fetch(url, options)
            .then((response) => {
                console.log(response.status);

                if (!response.ok) {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && response.json();
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
            })
    }
}

export default observabilityIntentService;