import React from "react";
import { Chip } from "../Chip";

export interface FilterManagerProps {
    children?: React.ReactNode;
    className: string;
}

const defaultFilterManagerProps = {
    className: undefined
};

export const FilterManager = (props: FilterManagerProps) => {
    props = { ...defaultFilterManagerProps, ...props };

    let classes = "o-filter-manager"
    if (props.className && props.className.length > 0)
      classes = `${classes} ${props.className}`;

    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}

export interface FilterManagerTermsProps {
    children?: React.ReactNode;
}

export const FilterManagerTerms = (props: FilterManagerTermsProps) => {
    return (
        <div className="o-filter-manager__terms">
            {props.children}
        </div>
    )
}

export interface FilterManagerTermProps {
    children?: React.ReactNode;
}

export const FilterManagerTerm = (props: FilterManagerTermProps) => {
    return (
        <Chip className="o-filter-manager__term">
            {props.children}
        </Chip>
    )
}