import { useState, useRef } from "react";
import { Heading, Button } from "cai-fusion";

import { useTemplate } from "../contexts/TemplateContext";

const FindLearning = () => {
	const textWidthElement = useRef(null);

	const [topic, setTopic] = useState("");
	const [subtopic, setSubtopic] = useState("");
	const [feature, setFeature] = useState("");

    const { executeTemplate } = useTemplate();

	const handleInputChange = (e, setInput) => {
		setInput(e.target.value);
		textWidthElement.current.textContent = e.target.value;
		const newWidth =
			textWidthElement.current.getBoundingClientRect().width + 10;
		e.target.style.width = newWidth + "px";
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const learningString = `I want to learn more about ${topic}. Can you recommend some good resources such as books, online courses or tutorials that cover ${subtopic}? It would be helpful if these resources include ${feature}.`;
		executeTemplate(null, [learningString])
	};

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin finding learning resources, just fill in the blanks
				below and hit that Send Message button!
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<p className="o-nl-form__text">
					"I want to learn more about
					<input
						type="text"
						placeholder="Topic"
						className="o-nl-form__text-input"
						value={topic}
						onChange={(e) => handleInputChange(e, setTopic)}
					/>
					. Can you recommend some good resources such as books,
					online courses, or tutorials that cover
					<input
						type="text"
						placeholder="Subtopic or Level of Detail"
						className="o-nl-form__text-input"
						value={subtopic}
						onChange={(e) => handleInputChange(e, setSubtopic)}
					/>
					? It would be helpful if these resources include
					<input
						type="text"
						placeholder="Desired Feature, Such as Hands-On Exercises or Real-World Examples"
						className="o-nl-form__text-input"
                        style={{width: "500px"}}
						value={feature}
						onChange={(e) => handleInputChange(e, setFeature)}
					/>
					."
				</p>
				<div className="m-button-row">
					<Button onClick={handleSubmit} disabled={!topic.trim()}>
						Send Message
					</Button>
				</div>
			</form>
			<span id="text-width" ref={textWidthElement}>
				And another text that's very long
			</span>
		</>
	);
};

export default FindLearning;