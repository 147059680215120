import React, { Fragment } from "react"
import { NavLink } from "react-router-dom"

const IconLink = props => {
  return (
    <NavLink
      to={props.path}
      className="a-icon-link m-link-list__link"
      activeClassName="a-icon-link--active"
    >
      <img className="a-icon-link__icon" src={props.iconSrc} alt="" />
      <span className="a-icon-link__text">{props.linkText}</span>
      {/* {props.count && props.count > 0 && (
        <span className="a-badge">{props.count}</span>
      )} */}
      {props.children}
    </NavLink>
  )
}

export default IconLink;
