import React, { useState, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  Routes,
} from "react-router-dom";
import CAIGPTPlayground from "./caigptPlayground";
import CAIGPTChat from "./caigptChat";
import SymphonyBanner from "./symphonyBanner";

const CAIGPTApp = (props) => {
  return (
    <Fragment>
      <Routes>
        <Route
          path={``}
          exact
          element={
            <Fragment>
              <div className="o-page-section o-page-section l-mt-md l-pt-none">
                <div className="o-page-section__content-container o-page-section__content-container--wide-fixed-width l-pt-none l-mt-neg-sm">
                  <div className="l-row">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <SymphonyBanner />
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          }
        />
        <Route
          path={"playground"}
          element={
            <>
              <CAIGPTPlayground
                graphData={props.graphData}
                impersonation={props.impersonating} />
            </>
          }
        />
        <Route
          path={"chat"}
          element={
            <>
              <CAIGPTChat
                graphData={props.graphData}
                impersonation={props.impersonating} />
            </>
          }
        />
      </Routes>
    </Fragment>
  );
};

export default CAIGPTApp;
