// import axios from "axios";
// import backend from "../configs/axiosConfig";
import { now } from "d3-timer";
import { apiConfig } from "../configs/mycaiApiConfig";
import {
    EngagementClientData,
    EngagementData,
    EngagementSLADetailData,
} from "../data/EngagementData";

const apiUriVersion = "v1";

const createOptions = (accessToken, impersonation = undefined) => {
    const headers = new Headers();
    if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
    }
    if (impersonation) {
        headers.append("MYCAI-IMPERSONATE", impersonation);
    }
    const options = {
        method: "GET",
        headers: headers,
    };

    return options;
};

const myCaiApiService = {
    Ping: function (accessToken, impersonation = undefined) {
        // return backend.get(`${apiUriVersion}/rfps${queryString}`)
        let options = createOptions(accessToken, impersonation);

        // return fetch(`${apiConfig.baseURL}/WeatherForecast`, options)
        return (
            fetch(
                `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/payroll/ping`,
                options
            )
                // .then((response) => response.json())
                .then(() => console.log("PING success"))
                .catch((error) => console.log(error))
        );
    },
    Reset: function (accessToken) {
        let options = {
            ...createOptions(accessToken),
            method: "POST",
            body: JSON.stringify({}),
        };
        let url = `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/payroll/reset`;
        return fetch(url, options).then((response) => {
            const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
            const data = isJson && response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    },
    GetOwnedChangeGroups: function (accessToken, impersonation = undefined) {
        let options = createOptions(accessToken, impersonation);

        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/payroll/changeGroups/Owned`,
            options
        )
            .then((response) => response.json())
            .catch((error) => console.log(error));
    },
    GetAssignedChangeGroups: function (accessToken, impersonation = undefined) {
        let options = createOptions(accessToken, impersonation);

        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/payroll/changeGroups/Assigned`,
            options
        )
            .then((response) => response.json())
            .catch((error) => console.log(error));
    },
    ConvertUPNtoEmployeeId: function (accessToken, upn) {
        let options = createOptions(accessToken);

        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/users/convert?upn=${upn}`,
            options
        )
            .then((response) => response.json())
            .catch((error) => console.log(error));
    },
    GetChangeGroups: function (accessToken, impersonation = undefined) {
        let options = createOptions(accessToken, impersonation);

        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/payroll/changeGroups`,
            options
        )
            .then((response) => {
                return response.json();
                //     const isJson = response.headers.get('content-type')?.includes('application/json');
                //     const data = isJson && response.json();
                //     // check for error response
                //     if (!response.ok) {
                //         // get error message from body or default to response status
                //         const error = (data && data.message) || response.status;
                //         return Promise.reject(error);
                //     }
                //     else {
                //         return data;
                //     }
            })
            .catch((error) => console.log(error));
    },
    GetBonusChangeGroups: function (accessToken, impersonation = undefined) {
        let options = createOptions(accessToken, impersonation);
        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/bonus/bonusChangeGroups`,
            options
        )
            .then((response) => {
                return response.json();
                //     const isJson = response.headers.get('content-type')?.includes('application/json');
                //     const data = isJson && response.json();
                //     // check for error response
                //     if (!response.ok) {
                //         // get error message from body or default to response status
                //         const error = (data && data.message) || response.status;
                //         return Promise.reject(error);
                //     }
                //     else {
                //         return data;
                //     }
            })
            .catch((error) => console.log(error));
    },
    GetRole: async function (
        accessToken,
        impersonation = undefined,
        employeeId = undefined
    ) {
        let options = createOptions(accessToken, impersonation);
        const response = await fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/bonus/getRole`,
            options
        );
        const data = await response.json();
        return data.result;
    },
    GetLastStatus: async function (
        accessToken,
        bonusId,
        impersonation = undefined
    ) {
        let options = createOptions(accessToken, impersonation);
        const response = await fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/bonus/getLastStatus/${bonusId}`,
            options
        )
        const data = await response.json();
        console.log("data in mycaiapi:")
        console.log(data);
        return data.result;
    },
    UpdateStatus: function (
        accessToken,
        bonusId,
        newStatusId,// = undefined,
        oldStatusId = "",
        statusReason = "",
        direction,
        modifiedBy,
        impersonation = undefined
    ) {
        let options = {
            ...createOptions(accessToken, impersonation),
            method: "POST",
            body: JSON.stringify({}),
        };
        let url = `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/bonus/updateStatus/${bonusId}?newStatusId=${newStatusId}&oldStatusId=${oldStatusId}&statusReason=${statusReason}&direction=${direction}&modifiedBy=${modifiedBy}`;
        return fetch(url, options).then((response) => {
            const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
            const data = isJson && response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    },
    UpdateMeritChangeAmount: function (
        accessToken,
        newMeritAmt,
        Guid,
        impersonation = undefined
    ) {
        let options = {
            ...createOptions(accessToken, impersonation),
            method: "POST",
            body: JSON.stringify({}),
        };
        let url = `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/payroll/updateAmount/${Guid}?totalAmount=${newMeritAmt}`;
        return fetch(url, options).then((response) => {
            const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
            const data = isJson && response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    },
    BulkUpdateMeritChangeAmounts: function (
        accessToken,
        newAmts,
        impersonation = undefined
    ) {
        let options = {
            ...createOptions(accessToken, impersonation),
            method: "POST",
            body: JSON.stringify(newAmts),
        };
        options.headers.append("content-type", "application/json");
        let url = `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/payroll/updateAmount/bulk`;
        return fetch(url, options).then((response) => {
            const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
            const data = isJson && response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    },
    BulkUpdateMeritChangeEligibilities: function (
        accessToken,
        newEligibilities,
        impersonation = undefined
    ) {
        let options = {
            ...createOptions(accessToken, impersonation),
            method: "POST",
            body: JSON.stringify(newEligibilities),
        };
        options.headers.append("content-type", "application/json");
        let url = `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/bonus/updateEligibility/bulk`;
        return fetch(url, options).then((response) => {
            console.log("response")
            console.log(response);
            const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
            const data = isJson && response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    },
    UpdateCommissionAmount: function (
        accessToken,
        bonusId,
        newComAmt,
        impersonation = undefined
    ) {
        let options = {
            ...createOptions(accessToken, impersonation),
            method: "POST",
            body: JSON.stringify({}),
        };
        let url = `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/bonus/updateCommission/${bonusId}?totalAmount=${newComAmt}`;
        console.log(`this is the url: ${url}`);
        console.log(options)
        return fetch(url, options).then((response) => {
            const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
            const data = isJson && response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    },
    UpdateBonusAmount: function (
        accessToken,
        newBonusAmt,
        bonusId,
        impersonation = undefined
    ) {
        let options = {
            ...createOptions(accessToken, impersonation),
            method: "POST",
            body: JSON.stringify({}),
        };
        let url = `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/bonus/updateBonus/${bonusId}?totalAmount=${newBonusAmt}`;
        return fetch(url, options).then((response) => {
            const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
            const data = isJson && response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    },
    GetBonusObjectives: function (
        accessToken,
        bonusId,
        impersonation = undefined
    ) {
        let options = createOptions(accessToken, impersonation);

        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/bonus/getObjectives/${bonusId}`,
            options
        )
            .then((response) => {
                return response.json();
            })
            .catch((error) => console.log(error));
    },
    UpdateBonusObjectives: function (
        accessToken,
        bonusId,
        objectives,
        impersonation = undefined
    ) {
        let options = {
            ...createOptions(accessToken, impersonation),
            method: "POST",
            body: JSON.stringify(objectives),
        };
        options.headers.append("content-type", "application/json");
        // console.log(options);
        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/bonus/updateObjectives/${bonusId}`,
            options
        )
            .then((response) => {
                const isJson = response.headers
                    .get("content-type")
                    ?.includes("application/json");
                const data = isJson && response.json();
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
            })
            .catch((error) => console.log(error));
    },
    GetUserPhotoUrl: function (accessToken, upn) {
        let options = createOptions(accessToken);

        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/users/${upn}/photo`,
            options
        )
            .then((response) => response)
            .catch((error) => console.log(error));
    },
    //This will submit changeGroup and all child changeGroups
    SubmitChangeGroups: function (
        accessToken,
        changeGroupId,
        impersonation = undefined
    ) {
        let options = {
            ...createOptions(accessToken, impersonation),
            method: "POST",
            body: JSON.stringify({}),
        };
        let url = `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/payroll/submit?changeGroupIds=${changeGroupId}`;
        return fetch(url, options).then((response) => {
            const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
            const data = isJson && response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    },
    GetEnterpriseReportingData: function (
        accessToken,
        impersonation = undefined
    ) {
        let options = createOptions(accessToken, impersonation);

        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/EnterpriseData`,
            options
        )
            .then((response) => response.json())
            .catch((error) => console.log(error));
    },
    UpdateEnterpriseReportingResult: function (
        accessToken,
        impersonation = undefined,
        resultId,
        result
    ) {
        let options = {
            ...createOptions(accessToken, impersonation),
            method: "Put",
            body: JSON.stringify({}),
        };
        let url = `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/EnterpriseData/${resultId}?result=${result}`;

        return fetch(url, options).then((response) => {
            const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
            const data = isJson && response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    },
    BulkUpdateEnterpriseReportingResult: function (
        accessToken,
        impersonation = undefined,
        newResults
    ) {
        let options = {
            ...createOptions(accessToken, impersonation),
            method: "Patch",
            body: JSON.stringify({ Patches: newResults }),
        };
        options.headers.append("content-type", "application/json");
        let url = `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/EnterpriseData/results`;

        return fetch(url, options).then((response) => {
            const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
            const data = isJson && response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    },
    GetEngagementReportingClientData: function (
        accessToken,
        impersonation = undefined
    ) {
        let options = createOptions(accessToken, impersonation);

        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/EngagementReporting/clients`,
            options
        )
            .then((response) => response.json())
            .catch((error) => console.log(error));
    },
    GetEngagementReportingEngagementData: function (
        accessToken,
        impersonation = undefined,
        engagementId
    ) {
        console.log("Loading engagement: ", engagementId);

        let options = createOptions(accessToken, impersonation);

        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/EngagementReporting/engagement/${engagementId}`,
            options
        )
            .then((response) => response.json())
            .catch((error) => console.log(error));
    },

    GetEngagementReportingSLADetailData: function (
        accessToken,
        impersonation = undefined,
        slaId
    ) {
        console.log("Loading SLA: ", slaId);

        let options = createOptions(accessToken, impersonation);

        return fetch(
            `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/EngagementReporting/sla/${slaId}`,
            options
        )
            .then((response) => response.json())
            .catch((error) => console.log(error));
    },

    //Not sure whether we should nest the services like this, organize these service calls by endpoint?
    SymphonyApiService: {
        //Backend API to return the chatId.
        SendMessage: function (
            accessToken,
            impersonation = undefined,
            chatId,
            message,
            assistantInstruction,
            model,
            messageParameters,
            dataSources,
            includedSkills,
            messageTextOverride
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "POST",
                body: JSON.stringify({
                    chatId: chatId,
                    messageText: message,
                    AssistantInstruction: assistantInstruction,
                    Model: model,
                    MessageParameters: messageParameters,
                    DataSources: dataSources,
                    Skills: includedSkills,
                    MessageTextOverride: messageTextOverride
                }),
            };
            options.headers.append("content-type", "application/json");

            console.log("Sending a message");
            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Messages`,
                options
            )
                .then((response) => {
                    if (response.status === 401) {
                        // If status is 401, reauthorize and retry
                        console.log("Received 401.");
                        // return Promise.resolve(reauthorizeFunction())
                        //   .then(() => {
                        //     // Retry the message send after reauthorization
                        //     console.log("Retrying message send...");
                        //     return fetch(
                        //       `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Messages`,
                        //       options
                        //     );
                        //   })
                        //   .then((retryResponse) => {
                        //     if (!retryResponse.ok) {
                        //       throw new Error(retryResponse);
                        //     }
                        //     return retryResponse.json();
                        //   });
                    } else if (!response.ok) {
                        console.log(response);
                        throw new Error(response);
                    }

                    // TODO, with this, does "throw new Error" need to happen?
                    // return status and response
                    // return { status: response.status, body: response.json() };
                    if (response.status === 202)
                        return {
                            status: response.status,
                            body: null,
                        };
                    return response.json().then((json) => {
                        return {
                            status: response.status,
                            body: json,
                        };
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        PinChat: function (accessToken, impersonation = undefined, chatId) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "Put",
            };

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/pin`,
                options
            )
                .then((response) => {
                    const isJson = response.headers
                        .get("content-type")
                        ?.includes("application/json");
                    const data = isJson && response.json();

                    if (!response.ok) {
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }
                })
                .catch((error) => console.log(error));
        },
        UnpinChat: function (accessToken, impersonation = undefined, chatId) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "Put",
            };

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/unpin`,
                options
            )
                .then((response) => {
                    const isJson = response.headers
                        .get("content-type")
                        ?.includes("application/json");
                    const data = isJson && response.json();

                    if (!response.ok) {
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }
                })
                .catch((error) => console.log(error));
        },
        GetDefaultChatSettings: function (accessToken, impersonation = undefined) {
            let options = createOptions(accessToken, impersonation);

            //To-Do
            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/UserProfile/Settings`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetAssistants: function (accessToken, impersonation = undefined) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Assistants`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },

        GetModelOptions: function (accessToken, impersonation = undefined) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Models`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetExternalDataSources: function (accessToken, impersonation = undefined) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/DataSources`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        CreateUserProfile: function (accessToken) {
            let options = {
                ...createOptions(accessToken),
                method: "POST",
            };

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/UserProfile`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetUserProfile: function (accessToken, impersonation = undefined) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/UserProfile`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetChats: function (accessToken, impersonation = undefined) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetChatSettings: function (accessToken, impersonation = undefined, chatId) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/settings`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetChatMessages: function (accessToken, impersonation = undefined, chatId) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/messages`,
                options
            )
                .then((response) => response.json())
                .catch((error) => {
                    console.log(error);
                    throw new Error(error);
                });
        },
        GetChatMembers: function (accessToken, impersonation = undefined, chatId) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/members`,
                options
            )
                .then((response) => response.json())
                .catch((error) => {
                    console.log(error);
                    throw new Error(error);
                });
        },
        GetChatAssistant: function (
            accessToken,
            impersonation = undefined,
            chatId
        ) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/assistant`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetChatModel: function (accessToken, impersonation = undefined, chatId) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/model`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetChatUserAccess: function (
            accessToken,
            impersonation = undefined,
            chatId
        ) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/access`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetUserDefaultAssistant: function (accessToken, impersonation = undefined) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/UserProfile/Assistant`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetUserUPN: function (
            accessToken,
            impersonation = undefined,
            UserProfileId
        ) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/UserProfile/${UserProfileId}/UPN`,
                options
            )
                .then((response) => response.text())
                .catch((error) => console.log(error));
        },
        DeleteChatMessages: function (
            accessToken,
            impersonation = undefined,
            chatId,
            messagePairIds
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "DELETE",
                body: JSON.stringify(messagePairIds),
            };
            options.headers.append("content-type", "application/json");

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/messages`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.json();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        RateMessage: function (accessToken, impersonation = undefined, messagePairId, rating) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: 'PUT',
            };

            return fetch(`${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Messages/${messagePairId}/rate/${rating}`, options)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.text();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        AddResource: async function (
            accessToken,
            impersonation = undefined,
            chatId,
            files
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "POST",
                body: JSON.stringify({
                    files,
                }),
            };
            options.headers.append("content-type", "application/json");

            chatId = chatId || "new";

            let response, badResponse, badFiles;
            try {
                response = await fetch(
                    `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/Resources`,
                    options
                );
                if (!response.ok) {
                    throw new Error(response.status);
                }
            } catch (AddResourceError) {
                console.error("Error in mycai-api AddResource: " + AddResourceError.message);
                if (response.status === 413) {
                    console.error("AddResource: Big Request: " + response.message)
                    throw 'big';
                }
                badResponse = await response.text();
                try {
                    badFiles = JSON.parse(badResponse);
                } catch (ParsingError) {
                    console.error("AddResource: Parsing Error: " + ParsingError.message)
                    // const parseError = new Error("Error Parsing Error Response: "+ ParsingError.message);
                    // parseError.name = 'parse';
                    throw 'parse';
                }
                throw badFiles;
            }
            let data = await response.json();
            return data;
        },
        GetChatResources: function (
            accessToken,
            impersonation = undefined,
            chatId
        ) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/resources`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        GetChatRecommendations: function (
            accessToken,
            impersonation = undefined,
            chatId
        ) {
            let options = createOptions(accessToken, impersonation);

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/recommendations`,
                options
            )
                .then((response) => response.json())
                .catch((error) => console.log(error));
        },
        DeleteChatResource: function (
            accessToken,
            impersonation = undefined,
            chatId,
            resourceId
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "DELETE",
            };

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/resources/${resourceId}`,
                options
            );
        },
        SaveUserProfileSettings: function (
            accessToken,
            impersonation = undefined,
            modelParams,
            model,
            assistantInstruction
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "PATCH",
                body: JSON.stringify({
                    model: model,
                    assistantInstrution: assistantInstruction,
                    modelParameters: modelParams,
                }),
            };
            options.headers.append("content-type", "application/json");

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/UserProfile/settings`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        console.log(response);
                        throw new Error(response);
                    }
                    return response.text();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        SaveChatSettings: function (
            accessToken,
            impersonation = undefined,
            modelParams,
            model,
            assistantInstruction,
            chatId
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "PATCH",
                body: JSON.stringify({
                    model: model,
                    assistantInstruction: assistantInstruction,
                    modelParameters: modelParams,
                }),
            };
            options.headers.append("content-type", "application/json");

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/settings`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        console.log(response);
                        throw new Error(response);
                    }
                    return response.text();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        RenameChat: function (accessToken, impersonation = undefined, chatId, name) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "Put",
            };

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/rename?name=${name}`,
                options
            )
                .then((response) => {
                    const isJson = response.headers
                        .get("content-type")
                        ?.includes("application/json");
                    const data = isJson && response.json();

                    if (!response.ok) {
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }
                })
                .catch((error) => console.log(error));
        },
        DeleteChat: function (
            accessToken,
            impersonation = undefined,
            chatId
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "DELETE",
            };
            options.headers.append("content-type", "application/json");

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.json();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        GetPendingEULA: function (
            accessToken,
            impersonation = undefined
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "GET",
            };

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/UserProfile/EULA`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.json();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        AgreeToEULA: function (
            accessToken,
            impersonation = undefined,
            eulaId
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "POST",
            };

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/UserProfile/EULA/${eulaId}/agree`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.text();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        SearchUsers: function (
            accessToken,
            impersonation = undefined,
            query
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "GET",
            };

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/UserProfile/search/${query}`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.json();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        ShareChat: function (
            accessToken,
            impersonation = undefined,
            chatId,
            obj,
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "Post",
                body: JSON.stringify(
                    obj
                ),
            };
            options.headers.append("content-type", "application/json");

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/share`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        console.log(response);
                        throw new Error(response);
                    }
                    return response
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        UnShareChat: function (
            accessToken,
            impersonation = undefined,
            chatId,
            obj,
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "Delete",
                body: JSON.stringify(
                    obj
                ),
            };
            options.headers.append("content-type", "application/json");

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}/share`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        console.log(response);
                        throw new Error(response);
                    }
                    return response
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        CreatePlaceholderChat: function (
            accessToken,
            impersonation = undefined,
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "Post"
            };

            return fetch(
                `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        console.log(response);
                        throw new Error(response);
                    }
                    return response.json();
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        //Not in use
        //UpdateChatAssistant: function (
        //    accessToken,
        //    impersonation = undefined,
        //    chatId,
        //    assistantInstructionId,
        //    assistantInstructionText,
        //    assistantName = "Custom",
        //) {
        //    let options = {
        //        ...createOptions(accessToken, impersonation),
        //        body: {
        //            assistantInstructionId: assistantInstructionId,
        //            systemMessage: assistantInstructionText,
        //            displayName: assistantName,
        //        },
        //        method: "PATCH",
        //    };
        //    options.headers.append("content-type", "application/json");

        //    return fetch(
        //        `${apiConfig.baseApiURL}/Symphony/${apiConfig.apiUriVersion}/Chats/${chatId}`,
        //        options
        //    )
        //        .then((response) => {
        //            if (!response.ok) {
        //                throw new Error(response);
        //            }
        //            return response.json();
        //        })
        //        .catch((error) => {
        //            console.log(error);
        //        });
        //}
    },
    LicensingApiService: {
        IsUserLicenseManager: function (
            accessToken,
            impersonation = undefined
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "GET",
            };

            return fetch(
                `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/License/IsLicenseAdmin`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.text();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        IsLicensedEnvironment: function (
            accessToken,
            impersonation = undefined
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "GET",
            };

            return fetch(
                `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/License/IsLicensedEnvironment`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.text();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        GetLicensedUsers: function (
            accessToken,
            impersonation = undefined
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "GET",
            };

            return fetch(
                `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/License/Users`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.json();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        SearchUsersToLicense: function (
            accessToken,
            impersonation = undefined,
            queryString
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "GET",
            };

            return fetch(
                `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/License/search?query=${queryString}`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.json();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        UnlicenseUser: function (
            accessToken,
            impersonation = undefined,
            UPN
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "Delete"
            };

            return fetch(
                `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/License/${UPN}`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        console.log(response);
                        throw new Error(response);
                    }
                    return response
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        LicenseUser: function (
            accessToken,
            impersonation = undefined,
            UPN
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "Post"
            };

            return fetch(
                `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/License/${UPN}`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        console.log(response);
                        throw new Error(response);
                    }
                    return response
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        GetLicenseCount: function (
            accessToken,
            impersonation = undefined
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "GET",
            };

            return fetch(
                `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/License/count`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.text();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        GetUnusableUsers: function (
            accessToken,
            impersonation = undefined
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "GET",
            };

            return fetch(
                `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/License/Unusable`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.json();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        AmILicensed: function (
            accessToken,
            impersonation = undefined
        ) {
            let options = {
                ...createOptions(accessToken, impersonation),
                method: "GET",
            };

            return fetch(
                `${apiConfig.baseApiURL}/${apiConfig.apiUriVersion}/License`,
                options
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response);
                    }
                    return response.text();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};

export default myCaiApiService;
