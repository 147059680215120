// decouples the chat input from the chat window while
// allowing the input to use an app specific context without binding
// the chat component

import { useContext, useEffect, useState } from "react";
import { ChatInput } from "../../fusion/Chat";
import { useParams } from "react-router-dom";
import { ReactComponent as IconTemplate } from "../../../images/icon-template.svg";
import { ReactComponent as IconShare } from "../../../images/icon-share.svg";
import { ReactComponent as IconPinned } from "../../../images/icon-pinned.svg";
import { ReactComponent as IconRename } from "../../../images/icon-rename.svg";
import { ReactComponent as IconTrash } from "../../../images/icon-trash.svg";
import { ReactComponent as IconSharedChat } from "../../../images/icon-shared-chat.svg";

import { useChat } from "../contexts/ChatContext";
import { useMessages } from "../contexts/MessageContext";
import { useAccess } from "../contexts/AccessContext";

// WRAPPER For ChatInput to keep chat input decoupled from Symphony specific logic such as useAuthentication and useContext
const SymphonyChatInput = ({
    onModifySettings,
    onManageDataSources,
    onDeleteChat,
    onAttach,
    disableInput,
    onRenameChat,
    onShareChat,
}) => {
    const params = useParams();

    const { isChatInContext, togglePinChat, isPinned, assistantThinking } = useChat();
    const { chatMembers, canShareChat } = useAccess();
    const { sendMessage } = useMessages();

    const [contextMenuShown, setContextMenuShown] = useState(false);

    //
    //const { userProfile } = useContext(UserProfileContext);
    //const [canShareChat, setCanShareChat] = useState(false);


    //useEffect(() => {
    //    setCanShareChat(whoCanShareChats.includes(userProfile?.identifier));
    //}, [userProfile])

    useEffect(() => {
        setContextMenuShown(false);
    }, [params.ChatId])

    return (
        <ChatInput
            onAttach={onAttach}
            onModifySettings={onModifySettings}
            onManageDataSources={onManageDataSources}
            onSendMessage={(message) => {
                sendMessage(message);
            }}
            disableInput={disableInput}
            showContextButton={!(params.ChatId === undefined)}
            contextMenuShown={contextMenuShown}
            setContextMenuShown={setContextMenuShown}
            chatActions={[
                {
                    className: "m-context-menu__item",
                    buttonClassName: `a-icon-link a-icon-link--block${isPinned ? " a-icon-link--active" : ""
                        }`,
                    text: isPinned ? "Unpin Chat" : "Pin Chat",
                    IconElement: <IconPinned className="a-icon-link__icon" />,
                    actionFunction: (e) => {
                        e.preventDefault();
                        togglePinChat();
                    },
                },
                {
                    className: "m-context-menu__item",
                    buttonClassName: "a-icon-link a-icon-link--block",
                    modalTarget: "rename-modal",
                    text: "Rename Chat",
                    IconElement: <IconRename className="a-icon-link__icon" />,
                    actionFunction: (e) => {
                        e.preventDefault();
                        if (onRenameChat) onRenameChat();
                    },
                },
                 {
                     className: "m-context-menu__item",
                     buttonClassName: `a-icon-link a-icon-link--block ${!canShareChat ? "a-icon-link--disabled" : ""}`,
                     text: "Share Chat",
                     //helpText: "Coming Soon",
                     IconElement: <IconShare className="a-icon-link__icon" />,
                     actionFunction: (e) => {
                         e.preventDefault();
                         if (onShareChat && canShareChat) onShareChat();
                     },
                 },
                {
                    className: "m-context-menu__item",
                    buttonClassName:
                        "a-icon-link a-icon-link--block a-icon-link--disabled",
                    text: "Create Template from Chat",
                    helpText: "Coming Soon",
                    IconElement: <IconTemplate className="a-icon-link__icon" />,
                    actionFunction: (e) => {
                        e.preventDefault();
                    },
                },
                {
                    className: "m-context-menu__item",
                    buttonClassName:
                        `a-icon-link a-icon-link--block  a-icon-link--destructive ${assistantThinking ? "a-icon-link--disabled" : ""}`,
                    text: "Delete Chat",
                    IconElement: <IconTrash className="a-icon-link__icon" />,
                    actionFunction: (e) => {
                        onDeleteChat();
                        e.preventDefault();
                    }
                },
            ]}
            preContextMenuComponents={
                //Check if chatMembers > 1
                (isChatInContext() && chatMembers.length > 1) ?
                    //Return button tag if so
                    (
                        <button className="a-tag a-tag--interactive" data-drawer-target="sharing-drawer" onClick={(e) => { e.preventDefault(); onShareChat(); }}>
                            <IconSharedChat/>
                            <p className="a-tag__label">{ chatMembers.length } people in this chat</p>
                        </button>
                    )
                    :
                    //Else empty fragment
                    (<></>)
            }
        />
    );
};

export default SymphonyChatInput;
