import React from "react";

const NavbarDropdownItem = (props) => {

    const clickHandler = (e) => {
        e.preventDefault();
        if (props.onClick)
            props.onClick();
    };

    return (
        // <a href="benefits.html" className="o-navbar__dropdown-list-item">
        //         Change CAI Password
        //         <span className="o-navbar__dropdown-list-item-help o-navbar__dropdown-list-item-help--warning">
        //             Your password expires in 3 days
        //         </span>
        //     </a>
        //     <a href="#" className="o-navbar__dropdown-list-item">

        //     </a>
        <a href={props.href} className="o-navbar__dropdown-list-item" onClick={clickHandler}>
            {props.children}
        </a>
        // <a
        //     href="#"
        //     className="o-navbar__dropdown-list-item o-navbar__dropdown-list-item--top-hr"
        // >
        //     Sign Out
        // </a>
    )
}

export default NavbarDropdownItem;