import { useMemo, useEffect } from 'react';
import { useAuthentication } from '../contexts/AuthContext';
import { adminApiConfig } from '../configs/adminApiConfig';
import { apiConfig as mycaiApiConfig } from "../configs/mycaiApiConfig";
import { AdminApiService } from '../services/mycaiApis/AdminServiceApi';

// Define a custom hook that creates and updates the AdminApiService instance
export const useAdminApiService = () => {
    const {
        isAuthenticated,
        apiAccessToken,
        refreshAccessToken
    } = useAuthentication();

    // Use useMemo to create a memoized instance of the service
    const apiServiceClient = useMemo(() => {
        return new AdminApiService({
            baseURL: mycaiApiConfig.baseURL,
            accessToken: apiAccessToken,
            apiVersion: adminApiConfig.apiVersion,
            refreshAccessTokenFunction: refreshAccessToken,
        });
    }, []); // Empty dependency array ensures the service is created only once

    // Use useEffect to update the access token when it changes
    useEffect(() => {
        if (apiAccessToken) {
            apiServiceClient.updateAccessToken(apiAccessToken);
        }
    }, [apiAccessToken, isAuthenticated, apiServiceClient]);

    return apiServiceClient;
};
