import { ReactComponent as IconAttach } from "../../../images/icon-attach.svg";
import { ReactComponent as IconJobDescription } from "../../../images/icon-job-description.svg";
import { ReactComponent as IconSummary } from "../../../images/icon-summary.svg";
import { ReactComponent as IconOutline } from "../../../images/icon-outline.svg";
import { ReactComponent as IconProofreading } from "../../../images/icon-large-proofreading.svg";
import { Heading } from "../Heading";
import { useTemplate } from "../../symphony/contexts/TemplateContext";

const ChatTemplateItem = ({
  IconElement,
  templateId,
  templateName,
  templateDescription,
  templateCallToAction,
}) => {
  const { setTemplateId } = useTemplate();

  const handleClick = (e) => {
    e.preventDefault();
    setTemplateId(templateId);
  };

  return (
    <a className="m-tile o-tile-grid__tile" onClick={handleClick}>
      <div className="m-tile__icon-wrapper">
        {IconElement && <IconElement className="m-tile__icon" />}
      </div>
      <Heading size={5} className="m-tile__title">
        {templateName}
      </Heading>
      <p className="m-tile__description">{templateDescription}</p>
      <span className="m-tile__cta">{templateCallToAction}</span>
    </a>
  );
};

const ChatTemplates = () => {

  return (
    <>
      <Heading size={2}>Start a new chat</Heading>
      <p className="a-muted-text">
        To get started, send a message or choose one of the below scenarios for
        some help!
      </p>
      <div className="o-tile-grid l-mt-sm l-pb-xl">
        <ChatTemplateItem
          IconElement={IconJobDescription}
          templateId={1}
          templateName="Create Job Description"
          templateDescription="Generate a new job description for a specific role."
          templateCallToAction="Get Started"
        />
        <ChatTemplateItem
          IconElement={IconSummary}
          templateId={2}
          templateName="Summarize a Document"
          templateDescription="Distill a lengthy document, highlighting topics of interest, key dates, and action items."
          templateCallToAction="Get Started"
        />
        {(window.location.href.indexOf("cai") > -1 || window.location.href.indexOf("localhost") > - 1) && (<>
        <ChatTemplateItem
          IconElement={IconOutline}
          templateId={3}
          templateName="Perform a Standard RFP Review"
          templateDescription="Analyze an RFP and get a first review on the document's details."
          templateCallToAction="Get Started"
        />
        <ChatTemplateItem
          IconElement={IconProofreading}
          templateId={4}
          templateName="NDS Branding Guidelines Proofreading"
          templateDescription="Submit your content and let Symphony proofread it against the Neurodiverse Solutions Branding Guidelines."
          templateCallToAction="Get Started"
        />
        </>)}
        <ChatTemplateItem
          IconElement={IconProofreading}
          templateId={5}
          templateName="Code Debugging"
          templateDescription={"Request help with debugging a specific issue in your code by providing the problem and the code snippet."}
          templateCallToAction={"Get Started"}
        />
        <ChatTemplateItem
          IconElement={IconProofreading}
          templateId={6}
          templateName="Concept Explanation"
          templateDescription={"Ask for a clear explanation of a technical concept, focusing on a particular aspect or application."}
          templateCallToAction={"Get Started"}
        />
        <ChatTemplateItem
          IconElement={IconProofreading}
          templateId={7}
          templateName="Code Review"
          templateDescription={"Request a comprehensive code review covering correctness, efficiency, readability and adherence to best practices."}
          templateCallToAction={"Get Started"}
        />
        <ChatTemplateItem
          IconElement={IconProofreading}
          templateId={8}
          templateName="Find Learning Resources"
          templateDescription={"Request suggestions for learning resources on a technical topic, specifying the desired subtopics and resource features."}
          templateCallToAction={"Get Started"}
        />
        <ChatTemplateItem
          IconElement={IconProofreading}
          templateId={9}
          templateName="Extract Information"
          templateDescription={"Request extraction of specific information from a document and its formatting into a defined JSON structure."}
          templateCallToAction={"Get Started"}
        />
      </div>
    </>
  );
};

export default ChatTemplates;
