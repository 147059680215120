import React from "react";

export interface AvatarProps {
    src?: string;
    alt?: string;
    className?: string;
}

const defaultAvatarProps = {
    className: ""
}

const Avatar = (props: AvatarProps) => {

    props = { ...defaultAvatarProps, ...props };

    return (
        <div className="a-avatar">
            <img src={props.src} alt={props.alt} className={`a-avatar__img ${props.className}`} />
        </div>
    )
}

export default Avatar;