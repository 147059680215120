import { useState, useRef } from "react";
import { Heading, Button } from "cai-fusion";

import { useTemplate } from "../contexts/TemplateContext";

const ConceptExplanation = () => {
	const [concept, setConcept] = useState("");
	const [field, setField] = useState("");
	const [aspect, setAspect] = useState("");

    const { executeTemplate } = useTemplate();

	const textWidthElement = useRef(null);

	const handleInputChange = (e, setInput) => {
		setInput(e.target.value);
		textWidthElement.current.textContent = e.target.value;
		const newWidth =
			textWidthElement.current.getBoundingClientRect().width + 10;
		e.target.style.width = newWidth + "px";
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const promptString = `Can you explain the concept of ${concept} in ${field}? I am particularly interested in understanding ${aspect}. An example to illustrate this would be very helpful.`;
		executeTemplate(null, [promptString]);
	};

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin this concept exploration, just fill in the blanks below
				and hit that Send Message button!
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<p className="o-nl-form__text">
					"Can you explain the concept of
					<input
						type="text"
						placeholder="Concept"
						className="o-nl-form__text-input"
						value={concept}
						onChange={(e) => handleInputChange(e, setConcept)}
					/>
					in
					<input
						type="text"
						placeholder="Field or Technology or Practical Application"
						className="o-nl-form__text-input"
                        style={{width: "350px"}}
						value={field}
						onChange={(e) => handleInputChange(e, setField)}
					/>
					? I am particularly interested in understanding
					<input
						type="text"
						placeholder="Specific Aspect or Application"
						className="o-nl-form__text-input"
						value={aspect}
						onChange={(e) => handleInputChange(e, setAspect)}
					/>
					. An example to illustrate this would be very helpful."
				</p>
				<div className="m-button-row">
					<Button onClick={handleSubmit} disabled={!concept.trim()}>
						Send Message
					</Button>
				</div>
			</form>
			<span id="text-width" ref={textWidthElement}>
				And another text that's very long
			</span>
		</>
	);
};

export default ConceptExplanation;