import React from "react";

const Option = (props) => {
  return (
    <option
      value={props.value}
      selected={props.isSelected}
      disabled={props.disabled}
    >
      {props.children}
    </option>
  );
};

export default Option;
