import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as IconCheckmark } from '../../../images/icon-approve.svg';

const EditableValueInput = ({ initialValue, onValueChange, validate, onInvalid, title, unitType, children }) => {
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const [value, setValue] = useState(initialValue);
    const [draftValue, setDraftValue] = useState(initialValue);
    const popoverRef = useRef(null); // Reference to the popover

    const handleButtonClick = () => {
        // setPopoverOpen(true);
        setPopoverOpen(prev => !prev);
    };

    const handleOutsideClick = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target) && !event.target.isEqualNode(popoverRef.current)) {
            setPopoverOpen(false);
        }
    };

    const handleValueChange = (e) => {
        setDraftValue(e.target.value);
    };

    const handleAccept = () => {
        if (validate(draftValue)) {
            setValue(draftValue);
            onValueChange(draftValue);
            setPopoverOpen(false);
        } else {
            onInvalid();
        }
    };

    useEffect(() => {
        // Attach the event listener when the popover is open
        if (isPopoverOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            // Clean up the event listener when the component unmounts or the popover is closed
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isPopoverOpen]);

    return (
        <div className={`o-popover-group ${isPopoverOpen && "o-popover-group--shown"}`}>
            <button className="a-editable-value" onClick={handleButtonClick}>
                {children}
            </button>
            {isPopoverOpen && (
                <ValueEditPopover
                    ref={popoverRef}
                    value={draftValue}
                    onValueChange={handleValueChange}
                    onAccept={handleAccept}
                    title={title}
                    unitType={unitType}
                />
            )}
        </div>
    );
};

const ValueEditPopover = React.forwardRef(({ value, onValueChange, onAccept, title, unitType }, ref) => {
    const isDollar = unitType === 'dollar';
    const isPercent = unitType === 'percent';

    return (
        <div ref={ref} className="m-input-popover m-input-popover--shown" role="dialog" style={{ position: 'absolute', bottom: '-120px', left: '0', width: 'max-content' }}>
            <h2 className="m-input-popover__title">{title}</h2>
            <div className="m-input-popover__content">
                {isDollar && <span className="a-text-input__unit-label">$</span>}
                <input type="number" className="a-text-input m-input-popover__input" value={value} onChange={onValueChange} />
                {isPercent && <span className="a-text-input__unit-label">%</span>}
                <button type="submit" className="m-input-popover__button" onClick={onAccept}>
                    <IconCheckmark />
                </button>
            </div>
        </div>
    );
});

export default EditableValueInput;
