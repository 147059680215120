import React, {  useEffect, useRef } from "react";
import ChatEntry from "./ChatEntry";
import ChatSuggestions from "./ChatSuggestions";
import { ReactComponent as IconAttach } from "../../../images/icon-attach.svg";
import LoadingTips from "../../symphony/components/loadingTips";
import { useMessages } from "../../symphony/contexts/MessageContext";
import { useUserProfile } from "../../symphony/contexts/UserProfileContext";
import { useAccess } from "../../symphony/contexts/AccessContext";
import { useChat } from "../../symphony/contexts/ChatContext";

const Chat = ({
  chatEntryActions,
  iconElements,
  editingMessageId,
  setEditingMessageId,
}) => {
  const messagesStartRef = useRef(null);
  const messagesEndRef = useRef(null);

  if (!chatEntryActions) {
    chatEntryActions = {};
  }
  
  const { canSendMessage, chatMembers } = useAccess();

  const { loadingChat } = useChat();

  const {
    messages,
    // loadingMessages,
    editMessage,
    sendMessage,
    chatSuggestions,
    assistantThinking
  } = useMessages();

  const { compileNameHelper } = useUserProfile();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    messagesStartRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // use for when messages load if something needs to happen, like showing a toast or snackbar - Added front-loading of trhe user icons to reduce the number of API calls
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // was acutally super annoying if you scrolled up and it scrolled you back down
    // scrollToBottom();
  }, [chatSuggestions]);

  const handleTipAction = (action, tip) => {
    // Track 'hide' or 'see more' clicks and the tip data
    console.log(`User clicked ${action} on tip: `, tip);
    // Here, you can integrate with your tracking/analytics tool
  }

  return (
    <div
      className="o-chat"
      style={
        (chatSuggestions ? chatSuggestions.length > 0 : false)
          ? { paddingBottom: "0px" }
          : { paddingBottom: "256px" }
      }
    >
      <div ref={messagesStartRef}></div>
      { loadingChat ? 
      <>
        <ChatEntry 
          isLoading
          chatEntryActions={[]}
          IconElement={iconElements["assistant"]}
          content={[{"type": "test"}]}
        />
        <ChatEntry
          role="assistant"
          isLoading
          chatEntryActions={chatEntryActions["assistant"]}
          IconElement={iconElements["assistant"]}
          content={[{"type": "test"}]}
        />
        <ChatEntry 
          isLoading
          chatEntryActions={[]}
          IconElement={iconElements["assistant"]}
          content={[{"type": "test"}]}
        />
        <ChatEntry
          role="assistant"
          isLoading
          chatEntryActions={chatEntryActions["assistant"]}
          IconElement={iconElements["assistant"]}
          content={[{"type": "test"}]}
        />
      </>
      :
      messages.sort((a, b) => a.messageDate - b.messageDate).map((message, index) => {
        // Extract text from content items where type is 'text'
        const messageTexts = message.content
          .filter((contentItem) => contentItem.type === "text")
          .sort((a, b) => a.contentOrder - b.contentOrder)
          .map((contentItem) => contentItem.text);
        const messageText = messageTexts.join("\n");

        if (
          message?.role?.startsWith("info") ||
          message?.role?.startsWith("warn")
        ) {
          if (message.role === "info-datasource-image") {
            // Find an item of type 'image_bytes' or 'imageUrl' in the content array
            const imageContentItem = message.content.find(
              (contentItem) =>
                contentItem.type === "image_bytes" ||
                contentItem.type === "imageUrl"
            );

            // Depending on the type of the imageContentItem, extract the imageBytes or imageUrl
            const imageSrc = imageContentItem
              ? imageContentItem.type === "image_bytes"
                ? `data:image/jpeg;base64,${imageContentItem.imageBytes}`
                : imageContentItem.imageUrl
              : null;

            return (
              <div className="m-chat-entry" key={index}>
                <div className="m-chat-entry__avatar-wrapper">
                  <div className="a-avatar">
                    {iconElements
                      ? iconElements["user"](null, message.userProfileId)
                      : null}
                  </div>
                </div>
                <div className="m-chat-entry__message-wrapper">
                  <div className="m-chat-entry__message">
                    {imageSrc && (
                      <button
                        className="m-chat-entry__image-wrapper"
                        disabled=""
                      >
                        <img
                          className="m-chat-entry__image"
                          src={imageSrc}
                          alt={imageContentItem.imageTextDescription ?? messageText}
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          } else {
            let eventMessage = messageText;

            if (message.role.indexOf("datasource") > -1) {
              eventMessage = (
                <>
                  <IconAttach className="m-chat-event__icon" />
                  <strong>{messageText}</strong>{" "}
                  {messageText.split(",").length > 1 ? "were" : "was"}{" "}
                  {message.role.startsWith("info")
                    ? "included in"
                    : "removed from"}{" "}
                      the chat { chatMembers.length > 1 ?  `by ${compileNameHelper(chatMembers.find(x => x.userProfileId === message.userProfileId).userProfile)}` : "" }.
                </>
              );
            }

            return (
              <div
                className={`m-chat-event${
                  message.role.startsWith("warn")
                    ? " m-chat-event--removal"
                    : ""
                }`}
                key={index}
              >
                <hr className="m-chat-event__line" />
                <p className="m-chat-event__label">{eventMessage}</p>
              </div>
            );
          }
        } else {
          return (
            <ChatEntry
              key={message.messageId}
              messageId={message.messageId}
              messagePairId={message.messagePairId}
              role={message.role}
                  content={message.content}
                  statusMessage={message.statusMessage}
              IconElement={iconElements ? iconElements[message.role] : null}
              isLoading={message.isLoading}
              chatEntryActions={
                chatEntryActions[message.role]
                  ? chatEntryActions[message.role]
                  : []
              }
              userProfileId={message.userProfileId}
              isEditing={message.messageId === editingMessageId}
              setEditingMessageId={setEditingMessageId}
              resendMessage={editMessage}
            />
          );
        }
      })}
      <LoadingTips isLoading={assistantThinking} onTipAction={handleTipAction} />
      {canSendMessage && chatSuggestions && chatSuggestions.length > 0 && (
        <ChatSuggestions
          suggestions={chatSuggestions}
          onSuggestionSelected={(suggestion) => {
            // console.log(suggestion);
            sendMessage(suggestion);
          }}
        />
      )}
      <div ref={messagesEndRef}></div>
    </div>
  );
};

export default Chat;
