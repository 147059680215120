import { useState } from "react";
import { Heading, Button } from "cai-fusion";

import { useTemplate } from "../contexts/TemplateContext";

const ProofreadNDS = () => {
	const [messageText, setMessageText] = useState("");

    const { executeTemplate } = useTemplate();

	const handleInputChange = (e) => {
		setMessageText(e.target.value);
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			if (!event.shiftKey && messageText.trim()) {
				event.preventDefault();
				handleSend();
			}
		}
	};

	const handleSend = () => {
		// setMessageText("");
		let promptText =
			"Leveraging the following Neurodiverse Solutions Branding Guidelines, please review the following text and call out any terminology that is not used correctly.\n\n" +
			"### Begin Guidelines\n" +
			"If you detect any of the following prohibited words in the copy being reviewed, please call them out in your response them in your response.\n" +
			"- NDS\n- Autism2Work\n- A2W\n- Computer Aid, Inc\n- Computer Aid Inc\n- Computer Aid\n- CompAid\n- Autism\n- Neurodiverse solutions\n- Neurodiverse Solution \n- Neurodiverse in the workplace\n- Neurodiverse at work\n- Neurodiverse employment program\n- Neurodiverse hiring\n- Neurodiverse individual\n- Neurodiverse individuals\n- Neurodiverse employee \n- Neurodiverse employees\n- Neurodiverse worker\n- Neurodiverse workers\n- Neurodiverse people\n- Neurodivergent talent\n- Neurodivergent workforce\n- Neurodivergent community\n- Neurodivergent team\n- Gift\n- Gifts\n- Resource\n- Resources\n- Functionality\n- Job carving\n- Honored\n- Celebrated\n- Autism Awareness Month\n- Disability\n- Disabilities\n- Disorder\n- Disorders\n- Think differently\n- Think different\n- Differently abled\n" +
			"The following are acceptable phrases to be used:\n" +
			"- Neurodiversity employment program\n- Neurodiverse talent\n- Neurodivergent individuals\n- Neurodiverse community\n- Neurodiversity in the workplace\n" +
			"### End Guidelines\n\n" +
			`### Begin Content to Review\n${messageText}\n### End Content to Review`;
		let overrideText = `Proofread the following text against the Neurodiverse Solutions Branding Guidelines and call out any terminology that is not used correctly:\n> ${messageText}`;
        executeTemplate(null, [promptText], [overrideText])
	};

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To start the proofreading process, just type or paste your
				content in the box below and hit that Send Message button!
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<textarea
					className="a-textarea-input"
					name=""
					id=""
					rows="2"
					value={messageText}
					onChange={handleInputChange}
					onKeyDown={handleKeyPress}
				></textarea>
				<div className="m-button-row">
					<Button onClick={(e) => {
						e.preventDefault();
						handleSend();
					}} disabled={!messageText.trim()}>
						Send Message
					</Button>
				</div>
			</form>
		</>
	);
};

export default ProofreadNDS;