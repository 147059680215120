import React, { Fragment, useEffect, useState } from "react";
import { NavbarDropdown } from ".";

const NavbarAvatarMenu = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`o-navbar__nav-item o-navbar__nav-item--dropdown-list o-navbar__nav-item--avatar ${isOpen && "o-navbar__nav-item--active"}`}
      onClick={(e) => { setIsOpen(!isOpen); e.preventDefault(); }}>
      <div className="o-navbar__avatar-wrapper">
        <span className="o-navbar__avatar-name">{props.displayName}</span>
        <img src={props.imgUrl} alt="" className="o-navbar__avatar-img" />
      </div>
      {/* <li className="o-navbar__dropdown-list o-navbar__dropdown-list--right-align">
        <a
          href="#"
          className="o-navbar__dropdown-list-item o-navbar__dropdown-list-item--top-hr"
        >
          Sign Out
        </a>
      </li> */}
      <NavbarDropdown>
        {props.children}
      </NavbarDropdown>
      <svg
        className="o-navbar__toggle js-navbar-toggle"
        height="512"
        viewBox="0 0 16 16"
        width="512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="_31" data-name="31">
          <path d="m15.5 4h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 0 1z" />
          <path d="m15.5 9h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 0 1z" />
          <path d="m15.5 14h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 0 1z" />
        </g>
      </svg>
    </div>
  );
};

export default NavbarAvatarMenu;
