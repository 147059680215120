import { ISymphonyApiConfig } from "../../configs/ISymphonyApiConfig";
import { MyCAIApiService } from "../MyCAIApiService";

export class SymphonyApiService extends MyCAIApiService {
	public Assistants: AssistantsService;
	public Chats: ChatsService;
	public ChatHistories: ChatHistoriesService;
	public DataSources: DataSourcesService;
    public Facts: FactsService;
	public Licensing: LicensingService;
	public Messages: MessagesService;
	public Models: ModelsService;
	public UserProfiles: UserProfilesService;

	constructor(config: ISymphonyApiConfig) {
		super(config);

		this.Assistants = new AssistantsService(this);
		this.Chats = new ChatsService(this);
		this.ChatHistories = new ChatHistoriesService(this);
		this.DataSources = new DataSourcesService(this);
        this.Facts = new FactsService(this);
		this.Licensing = new LicensingService(this);
		this.Messages = new MessagesService(this);
		this.Models = new ModelsService(this);
		this.UserProfiles = new UserProfilesService(this);
	}
}

class ChatsService {
	private apiService: SymphonyApiService;

	constructor(apiService: SymphonyApiService) {
		this.apiService = apiService;
	}

	public async getChats(): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,

		});
	}

	public async searchChats(query: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/search?query=${query}`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	// Chat Functions
	public async pinChat(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/pin`;
		return this.apiService.sendRequest({
			method: "put",
			endpoint,
		});
	}

	public async unpinChat(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/unpin`;
		return this.apiService.sendRequest({
			method: "put",
			endpoint,
		});
	}

	public async getChatRecommendations(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/recommendations`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async renameChat(chatId: string, name: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/rename`;
		return this.apiService.sendRequest({
			method: "put",
			endpoint,
			params: { name },
		});
	}

	public async deleteChat(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}`;
		return this.apiService.sendRequest({
			method: "delete",
			endpoint,
		});
	}

	public async createPlaceholderChat(): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats`;
		return this.apiService.sendRequest({
			method: "post",
			endpoint,
		});
	}

	// Chat Content
	public async getChatMessages(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/messages`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	// Delete Messages (test?)
	public async deleteChatMessages(chatId: string, messageIds: any[]): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/messages`;
		return this.apiService.sendRequest({
			method: "delete",
			endpoint,
			data: JSON.stringify(messageIds)
		});
	}

	public async getChatSettings(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/settings`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async saveChatSettings(
		chatId: string,
		modelParams: any,
		model: string,
		assistantInstruction: string
	): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/settings`;
		return this.apiService.sendRequest({
			method: "patch",
			endpoint,
			data: {
				model,
				assistantInstruction,
				modelParameters: modelParams,
			},
		});
	}

	public async getChatMembers(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/members`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async getChatAssistant(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/assistant`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async getChatModel(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/model`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	// Chat Access
	public async getChatUserAccess(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/access`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	// Chat Sharing
	public async shareChat(chatId: string, obj: any): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/share`;
		return this.apiService.sendRequest({
			method: "post",
			endpoint,
			data: obj,
		});
	}

	public async unshareChat(chatId: string, obj: any): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/share`;
		return this.apiService.sendRequest({
			method: "delete",
			endpoint,
			data: obj,
		});
	}

	// Chat Resources
	public async addChatResource(chatId: string, files: any[]): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId || "new"}/Resources`;
		return this.apiService.sendRequest({
			method: "post",
			endpoint,
			data: { files },
		});
	}
	//V2 Version for better uploadHandling
	public async addChatResourceV2(chatId: string, files: any[]): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId || "new"}/ResourcesV2`;
		return this.apiService.sendRequest({
			method: "post",
			endpoint,
			data: { files },
		});
	}

	public async getChatResources(chatId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/resources`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async deleteChatResource(
		chatId: string,
		resourceId: string
	): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Chats/${chatId}/resources/${resourceId}`;
		return this.apiService.sendRequest({
			method: "delete",
			endpoint,
		});
	}
}

class ChatHistoriesService {
	private apiService: SymphonyApiService;

	constructor(apiService: SymphonyApiService) {
		this.apiService = apiService;
	}
}

class MessagesService {
	private apiService: SymphonyApiService;

	constructor(apiService: SymphonyApiService) {
		this.apiService = apiService;
	}

	public async sendMessage(
		chatId: string,
		message: string,
		assistantInstruction: string,
		model: string,
		messageParameters: object,
		dataSources: string[],
		includedSkills: string[],
		messageTextOverride: string
	): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Messages`;
		const data = {
			chatId,
			messageText: message,
			AssistantInstruction: assistantInstruction,
			Model: model,
			MessageParameters: messageParameters,
			DataSources: dataSources,
			Skills: includedSkills,
			MessageTextOverride: messageTextOverride,
		};

		return this.apiService.sendRequest({
			method: "post",
			endpoint,
			data,
		});
	}

	public async rateMessage(
		messagePairId: string,
		rating: string
	): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Messages/${messagePairId}/rate/${rating}`;
		return this.apiService.sendRequest({
			method: "put",
			endpoint,
		});
	}

    public async getMessageDetails(
        messageId: string
    ): Promise<any> {
        const endpoint = `/Symphony/${
            this.apiService.getConfig().apiVersion
        }/Messages/${messageId}/details`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async getMessageAuditTrail(
        messageId: string
    ): Promise<any> {
        const endpoint = `/Symphony/${
            this.apiService.getConfig().apiVersion
        }/Messages/${messageId}/audit`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async getMessageTransactions(
        messageId: string
    ): Promise<any> {
        const endpoint = `/Symphony/${
            this.apiService.getConfig().apiVersion
        }/Messages/${messageId}/transactions`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }
}

class UserProfilesService {
	private apiService: SymphonyApiService;

	constructor(apiService: SymphonyApiService) {
		this.apiService = apiService;
	}

	public async getUserUPN(userProfileId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/UserProfile/${userProfileId}/UPN`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async searchUsers(query: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/UserProfile/search/${query}`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async createUserProfile(): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/UserProfile`;
		return this.apiService.sendRequest({
			method: "post",
			endpoint,
		});
	}

	public async getUserProfile(): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/UserProfile`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async updateUserProfile(
		userProfileId: string,
		updatedUserProfile: any
	): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/UserProfile/${userProfileId}`;
		return this.apiService.sendRequest({
			method: "put",
			endpoint,
			data: updatedUserProfile
		});
	}

	public async getUserDefaultAssistant(): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/UserProfile/Assistant`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async getDefaultChatSettings(): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/UserProfile/Settings`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async saveUserProfileSettings(
		modelParams: any,
		model: string,
		assistantInstruction: string
	): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/UserProfile/settings`;
		return this.apiService.sendRequest({
			method: "patch",
			endpoint,
			data: {
				model,
				assistantInstruction,
				modelParameters: modelParams,
			},
		});
	}

	// EULA
	public async getPendingEULA(): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/UserProfile/EULA`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async agreeToEULA(eulaId: string): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/UserProfile/EULA/${eulaId}/agree`;
		return this.apiService.sendRequest({
			method: "post",
			endpoint,
		});
	}
}

class AssistantsService {
	private apiService: SymphonyApiService;

	constructor(apiService: SymphonyApiService) {
		this.apiService = apiService;
	}

	public async getAssistants(): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Assistants`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}
}

class DataSourcesService {
	private apiService: SymphonyApiService;

	constructor(apiService: SymphonyApiService) {
		this.apiService = apiService;
	}

	public async getExternalDataSources(): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/DataSources`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}
}

class ModelsService {
	private apiService: SymphonyApiService;

	constructor(apiService: SymphonyApiService) {
		this.apiService = apiService;
	}

	public async getModelOptions(): Promise<any> {
		const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Models`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

    public async getAllModels(): Promise<any> {
        const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/models`;
        return this.apiService.sendRequest({
            method: 'get',
            endpoint,
        });
    }

    public async createModel(model: any): Promise<any> {
        const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/models`;
        return this.apiService.sendRequest({
            method: 'post',
            endpoint,
            data: model,
        });
    }

    public async updateModel(id: string, model: any): Promise<any> {
        const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/models/${id}`;
        return this.apiService.sendRequest({
            method: 'put',
            endpoint,
            data: model,
        });
    }

    public async deleteModel(id: string): Promise<any> {
        const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/models/${id}`;
        return this.apiService.sendRequest({
            method: 'delete',
            endpoint,
        });
    }
}

class LicensingService {
	private apiService: SymphonyApiService;

	constructor(apiService: SymphonyApiService) {
		this.apiService = apiService;
	}

	public async isUserLicenseManager(): Promise<any> {
		const endpoint = `/${
			this.apiService.getConfig().apiVersion
		}/License/IsLicenseAdmin`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async isLicensedEnvironment(): Promise<any> {
		const endpoint = `/${
			this.apiService.getConfig().apiVersion
		}/License/IsLicensedEnvironment`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async getLicensedUsers(): Promise<any> {
		const endpoint = `/${
			this.apiService.getConfig().apiVersion
		}/License/Users`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async searchUsersToLicense(queryString: string): Promise<any> {
		const endpoint = `/${
			this.apiService.getConfig().apiVersion
		}/License/search?query=${queryString}`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async unlicenseUser(UPN: string): Promise<any> {
		const endpoint = `/${
			this.apiService.getConfig().apiVersion
		}/License/${UPN}`;
		return this.apiService.sendRequest({
			method: "delete",
			endpoint,
		});
	}

	public async licenseUser(UPN: string): Promise<any> {
		const endpoint = `/${
			this.apiService.getConfig().apiVersion
		}/License/${UPN}`;
		return this.apiService.sendRequest({
			method: "post",
			endpoint,
		});
	}

	public async getLicenseCount(): Promise<any> {
		const endpoint = `/${
			this.apiService.getConfig().apiVersion
		}/License/count`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async getUnusableUsers(): Promise<any> {
		const endpoint = `/${
			this.apiService.getConfig().apiVersion
		}/License/Unusable`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async amILicensed(): Promise<any> {
		const endpoint = `/${
			this.apiService.getConfig().apiVersion
		}/License`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}
}

class FactsService {
    private apiService: SymphonyApiService;

    constructor(apiService: SymphonyApiService) {
        this.apiService = apiService;
    }

    public async createFact(fact: any): Promise<any> {
        const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Facts`;
        return this.apiService.sendRequest({
            method: 'post',
            endpoint,
            data: fact,
        });
    }

    public async getAllFacts(): Promise<any> {
        const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Facts`;
        return this.apiService.sendRequest({
            method: 'get',
            endpoint,
        });
    }

    public async getFactById(factId: string): Promise<any> {
        const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Facts/${factId}`;
        return this.apiService.sendRequest({
            method: 'get',
            endpoint,
        });
    }

    public async updateFact(factId: string, fact: any): Promise<any> {
        const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Facts/${factId}`;
        return this.apiService.sendRequest({
            method: 'put',
            endpoint,
            data: fact,
        });
    }

    public async deleteFact(factId: string): Promise<any> {
        const endpoint = `/Symphony/${
			this.apiService.getConfig().apiVersion
		}/Facts/${factId}`;
        return this.apiService.sendRequest({
            method: 'delete',
            endpoint,
        });
    }
}

// override content type
/*
public async postFormData(endpoint: string, data: any): Promise<any> {
    const formHeaders = this.createHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    try {
      const response = await this.apiClient.post(endpoint, qs.stringify(data), formHeaders);
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  public async fetchProtectedData(endpoint: string, params?: any): Promise<any> {
    try {
      const response = await this.apiClient.get(endpoint, { params });
      return response.data;
    } catch (error) {
      // The interceptor will handle retries for eligible errors.
      // Here you can handle errors that should not be retried, or perform additional error processing.
      throw error;
    }
  }


  // Example usage of apiService.sendRequest to send form data
const formData = new URLSearchParams();
formData.append('key1', 'value1');
formData.append('key2', 'value2');

apiService.sendRequest({
  method: 'post',
  endpoint: '/submit-form',
  data: formData.toString(), // Convert URLSearchParams to string for Axios
  extraHeaders: { 'Content-Type': 'application/x-www-form-urlencoded' },
})
.then(response => {
  console.log('Form submitted successfully', response);
})
.catch(error => {
  console.error('Error submitting form', error);
});

// Example usage of apiService.sendRequest to upload a file
const fileInput = document.querySelector('input[type="file"]'); // Assuming there's a file input element in your HTML
const file = fileInput.files[0];

const fileFormData = new FormData();
fileFormData.append('file', file);
fileFormData.append('description', 'This is a file upload.');

apiService.sendRequest({
  method: 'post',
  endpoint: '/upload-file',
  data: fileFormData,
  // Content-Type is automatically set to multipart/form-data by the browser
})
.then(response => {
  console.log('File uploaded successfully', response);
})
.catch(error => {
  console.error('Error uploading file', error);
});


  */
