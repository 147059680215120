/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";

const TreeIcon = (props) => {
    return (
        <svg
            className="m-tree__icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 434.32"
        >
            <path d="M438.09,234.48h-39.6c4.04,11.05,6.24,22.98,6.24,35.4v149.65c0,5.18-.9,10.16-2.54,14.78h65.46c24.45,0,44.35-19.89,44.35-44.35v-81.58c0-40.75-33.15-73.91-73.91-73.91Z" />
            <path d="M107.26,269.89c0-12.43,2.21-24.35,6.24-35.4h-39.6C33.16,234.48,0,267.64,0,308.39v81.58c0,24.45,19.89,44.35,44.35,44.35H109.81c-1.64-4.63-2.54-9.6-2.54-14.78v-149.65Z" />
            <path d="M301.26,195.98h-90.52c-40.75,0-73.91,33.16-73.91,73.91v149.65c0,8.16,6.62,14.78,14.78,14.78h208.78c8.16,0,14.78-6.62,14.78-14.78v-149.65c0-40.75-33.16-73.91-73.91-73.91Z" />
            <path d="M256,0c-49.01,0-88.89,39.87-88.89,88.89,0,33.24,18.35,62.28,45.45,77.52,12.85,7.23,27.67,11.36,43.44,11.36s30.59-4.13,43.44-11.36c27.1-15.24,45.45-44.28,45.45-77.52C344.89,39.87,305.01,0,256,0Z" />
            <path d="M99.92,82.85c-36.65,0-66.47,29.82-66.47,66.47s29.82,66.48,66.47,66.48c9.3,0,18.15-1.93,26.19-5.39,13.91-5.99,25.37-16.58,32.47-29.86,4.98-9.32,7.81-19.95,7.81-31.23,0-36.65-29.82-66.47-66.48-66.47Z" />
            <path d="M412.08,82.85c-36.65,0-66.48,29.82-66.48,66.47,0,11.28,2.83,21.91,7.81,31.23,7.1,13.28,18.56,23.87,32.47,29.86,8.04,3.46,16.9,5.39,26.2,5.39,36.65,0,66.48-29.82,66.48-66.48s-29.82-66.47-66.48-66.47Z" />
        </svg>
    );
};

const TreeNode = (props) => {
    // todo
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    const toggleClickedHandler = (e) => {
        e.preventDefault();
        setIsExpanded(!isExpanded);
    };

    const onSelectedHandler = (key) => {
        console.log(`CLICKED ${key}`);
        if (props.onSelect) {
            props.onSelect(key);
        }
    };

    useEffect(() => {
        console.log("SELECTED CHANGED");
    }, [isSelected]);

    useEffect(() => {
        // console.log(`SELECTED NODE KEY CHANGED TO ${props.selectedNodeKey}`);
        setIsSelected(props.selectedNodeKey === props.data.key);
    }, [props.selectedNodeKey]);

    const liClassBuilder = ["m-tree__item"];
    if (isSelected) {
        liClassBuilder.push("m-tree__item--selected");
    }
    if (props.data && props.data.children && props.data.children.length > 0) {
        liClassBuilder.push("");
    } else {
        liClassBuilder.push("m-tree__item--no-child");
    }
    const liClasses = liClassBuilder.join(" ");

    return (
        <li className={liClasses}>
            <div className="m-tree__item-inner">
                {props.data && props.data.children && props.data.children.length > 0 && (
                    <a href=""
                        className={`a-collapse-toggle ${isExpanded ? "a-collapse-toggle--expanded" : ""
                            }`}
                        onClick={toggleClickedHandler}
                    >
                        <svg
                            className="a-collapse-toggle__svg-icon"
                            style={{ fill: "#0071bc" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.08"
                            height="15.08"
                            viewBox="0 0 15.08 15.08"
                        >
                            <g transform="translate(-6.46 -6.46)">
                                <g>
                                    <rect
                                        className="a-collapse-toggle__icon-horizontal"
                                        width="15.08"
                                        height="2.15"
                                        transform="translate(6.46 12.92)"
                                    ></rect>
                                    <rect
                                        className="a-collapse-toggle__icon-vertical"
                                        width="2.15"
                                        height="15.08"
                                        transform="translate(12.92 6.46)"
                                    ></rect>
                                </g>
                            </g>
                        </svg>
                    </a>
                )}
                <a href=""
                    className={`m-tree__link`}
                    onClick={(e) => {
                        e.preventDefault();
                        onSelectedHandler(props.data.key);
                        setIsSelected(true);
                    }}
                >
                    <TreeIcon />
                    {props.data.label}
                </a>
            </div>
            {props.data &&
                props.data.children &&
                props.data.children.length > 0 &&
                isExpanded && (
                    <ul className="m-tree__nested-group">
                        {props.data.children.map((x) => {
                            return (
                                <TreeNode
                                    data={x}
                                    key={x.key}
                                    onSelect={onSelectedHandler}
                                    selectedNodeKey={props.selectedNodeKey}
                                />
                            );
                        })}
                    </ul>
                )}
        </li>
    );
};

const Tree = (props) => {
    const [selectedNodeKey, setSelectedNodeKey] = useState("");

    const onSelectHandler = (key) => {
        if (props.onSelect) {
            console.log(`SELECTED ${key}`);
            setSelectedNodeKey(key);
            props.onSelect(key);
        }
    };

    // console.log(props.data)

    return (
        <ul className="m-tree">
            {props.data &&
                props.data.map((x) => {
                    return (
                        <TreeNode
                            data={x}
                            key={x.key}
                            onSelect={onSelectHandler}
                            selectedNodeKey={selectedNodeKey}
                        />
                    );
                })}
        </ul>
    );

    // return (
    //     <ul className="m-tree">
    //         <li className="m-tree__item">
    //             <a className="a-collapse-toggle a-collapse-toggle--expanded" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //             <a href="#" className="m-tree__link">CAI</a>
    //             <ul className="m-tree__nested-group">
    //                 <li className="m-tree__item">
    //                     <a className="a-collapse-toggle a-collapse-toggle--expanded" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                     <a href="#" className="m-tree__link">Enterprise</a></li>
    //                 <ul className="m-tree__nested-group">
    //                     <li className="m-tree__item">
    //                         <a href="#" className="m-tree__link">Marketing</a>
    //                     </li>
    //                     <li className="m-tree__item">
    //                         <a className="a-collapse-toggle" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                         <a href="#" className="m-tree__link">Technology</a>
    //                         <ul className="m-tree__nested-group">
    //                             <li className="m-tree__item m-tree__item--selected">
    //                                 <a className="a-collapse-toggle a-collapse-toggle--expanded" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                                 <a href="#" className="m-tree__link">Technology Solutions</a>
    //                                 <ul className="m-tree__nested-group">
    //                                     <li className="m-tree__item m-tree__item--no-child">
    //                                         <a href="#" className="m-tree__link">Enterprise Architecture</a>
    //                                     </li>
    //                                     <li className="m-tree__item m-tree__item--no-child">
    //                                         <a href="#" className="m-tree__link">Enterprise Solutions</a>
    //                                     </li>
    //                                     <li className="m-tree__item m-tree__item--no-child">
    //                                         <a href="#" className="m-tree__link">UX Design</a>
    //                                     </li>
    //                                 </ul>
    //                             </li>
    //                             <li className="m-tree__item">
    //                                 <a className="a-collapse-toggle" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                                 <a href="#" className="m-tree__link">Security Operations</a>
    //                             </li>
    //                             <li className="m-tree__item">
    //                                 <a className="a-collapse-toggle" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                                 <a href="#" className="m-tree__link">Intelligent Automation</a>
    //                             </li>
    //                             <li className="m-tree__item">
    //                                 <a className="a-collapse-toggle" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                                 <a href="#" className="m-tree__link">Service Desk</a>
    //                             </li>
    //                         </ul>
    //                     </li>
    //                     <li className="m-tree__item">
    //                         <a className="a-collapse-toggle" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                         <a href="#" className="m-tree__link">Finance</a>
    //                     </li>
    //                     <li className="m-tree__item">
    //                         <a className="a-collapse-toggle" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                         <a href="#" className="m-tree__link">Global Operations</a>
    //                     </li>
    //                 </ul>
    //                 <li className="m-tree__item">
    //                     <a className="a-collapse-toggle" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                     <a href="#" className="m-tree__link">Public Sector</a>
    //                 </li>
    //                 <li className="m-tree__item">
    //                     <a className="a-collapse-toggle" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                     <a href="#" className="m-tree__link">Commercial</a>
    //                 </li>
    //                 <li className="m-tree__item">
    //                     <a className="a-collapse-toggle" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //                     <a href="#" className="m-tree__link">NDS</a>
    //                 </li>
    //             </ul>
    //         </li>
    //         <li className="m-tree__item">
    //             <a className="a-collapse-toggle" ><svg className="a-collapse-toggle__svg-icon" style={{ fill: "#0071bc" }} xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08"><g transform="translate(-6.46 -6.46)"><g><rect className="a-collapse-toggle__icon-horizontal" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect><rect className="a-collapse-toggle__icon-vertical" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect></g></g></svg></a>
    //             <a href="#" className="m-tree__link">CompWorks</a>
    //         </li>
    //     </ul>
    // )
};

export default Tree;
