import React from "react";

const CardContent = props => {
    return (
        <div className="o-card__wrapper">
            {props.children}
        </div>
    )
}

export default CardContent;