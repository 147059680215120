import React from "react";
import Heading from "../Heading/Heading";

export interface HeadingRowProps {
    children?: React.ReactNode;
    // size?: 1 | 2 | 3 | 4 | 5 | 6;
    // text?: string;
}

const defaultHeadingRowProps = {

}

const HeadingRow = (props: HeadingRowProps) => {
    props = { ...defaultHeadingRowProps, ...props };

    return (
    <div className="m-heading-row">
        {/* <h3 className="a-heading a-heading--heading-3 l-mb-none">{props.text}</h3> */}
        {/* <div className="m-heading-row__title">
            <Heading size={props.size}>{props.text}</Heading>
        </div>
        <div className="m-heading-row__actions">
            {props.children}
        </div> */}
        {props.children}
    </div>
    )
}

export interface HeadingRowTitleProps {
    children?: React.ReactNode;
}

export const HeadingRowTitle = (props: HeadingRowTitleProps) => {
    return (
        <div className="m-heading-row__title">
            {props.children}
        </div>
    )
}

export interface HeadingRowActionsProps {
    children?: React.ReactNode;
}

export const HeadingRowActions = (props: HeadingRowActionsProps) => {
    return (
        <div className="m-heading-row__actions">
            {props.children}
        </div>
    )
}

export default HeadingRow;