import { useEffect, useState, useContext } from "react";
import { ReactComponent as IconCloseSquare } from "../../../images/close-square.svg";
import { FormGroup } from "../../fusion";
import { Heading, HeadingRow, Drawer, Button } from "cai-fusion";
import { useSettings } from "../contexts/SettingsContext";
import { useSkills } from "../contexts/SkillsContext";
import { useAssistant } from "../contexts/AssistantContext";
import { useChat } from "../contexts/ChatContext";

const ChatSettingsDrawer = ({ isHidden, onClose }) => {
    const [localParams, setLocalParams] = useState([]);
    const { isChatInContext } = useChat();
    const { assistant, assistants, updateAssistant, createCustomAssistant } = useAssistant();
    const { model, models, settings, updateSettings, updateModel } = useSettings();
    const { skills, toggleSkill } = useSkills();

    useEffect(() => {
        setLocalParams([...settings]);
    }, [settings])

    return (
        <Drawer
            clickOutsideToClose
            isHidden={isHidden}
            onClose={() => onClose()}
            header={
                <HeadingRow
                    title="Chat Preferences"
                    size={3}
                    eyebrow="Settings"
                >
                    <div className="m-icon-row m-icon-row--right-align">
                        <a
                            href="#"
                            id="drawerCloseBtn"
                            className="a-icon m-icon-row__icon"
                            title="Close Associate Details"
                            onClick={onClose}
                        >
                            <IconCloseSquare className="a-icon-link__icon">
                                {" "}
                            </IconCloseSquare>
                        </a>
                    </div>
                </HeadingRow>
            }
        >
            <form className="o-form l-mt-sm">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row l-mb-xs">
                            <div className="col-12">
                                <Heading size={3}>Assistant Settings</Heading>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="m-form-group">
                                    <label htmlFor="docSelector" className="a-label">
                                        Persona
                                    </label>
                                    <select
                                        className="a-select-input"
                                        value={assistant?.assistantInstructionId}
                                        onChange={(e) => {
                                            // Grab the assistant that aligns with this index and update the assistant.
                                            updateAssistant(assistants[e.target.selectedIndex]);
                                            e.preventDefault();
                                        }}
                                    >
                                        {assistants.map((option) => {
                                            return (
                                                <option
                                                    key={option.assistantInstructionId}
                                                    value={option.assistantInstructionId}
                                                    children={option.displayName}
                                                />
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="m-form-group">
                                    <label htmlFor="docSelector" className="a-label">
                                        Instructions
                                    </label>
                                    <textarea
                                        className="a-textarea-input"
                                        onChange={(e) => {
                                            if (!isChatInContext()) return
                                            createCustomAssistant(e.target.value);
                                            e.preventDefault();
                                        }}
                                        onBlur={() => {
                                            updateAssistant(assistant)
                                        }}
                                        value={assistant?.systemMessage}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row l-mb-xs">
                            <div className="col-12">
                                <Heading size={3}>Assistant Skills</Heading>
                                <p className="a-muted-text">
                                    These settings let your assistant know which of its
                                    capabilities it should use when responding to your
                                    messages.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {skills?.length === 0 || skills === null
                                    ? "You don't have access to any skills."
                                    : skills?.map((skill, index) => (
                                        <label className="a-checkbox" key={index}>
                                            <span>{skill.name}</span>
                                            <input
                                                checked={skill.included}
                                                type="checkbox"
                                                onChange={() => toggleSkill(index)}
                                            />
                                            <div className="b-input"></div>
                                        </label>
                                    ))}
                            </div>
                        </div>
                        <div className="row l-mt-sm l-mb-xs">
                            <div className="col-12">
                                <Heading size={3}>Parameters</Heading>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <FormGroup>
                                    <label htmlFor="docSelector" className="a-label">
                                        LLM Model
                                    </label>
                                    <select
                                        className="a-select-input "
                                        value={model?.engine}
                                        onChange={(e) => {
                                            updateModel(e.target.value);
                                            e.preventDefault();
                                        }}
                                    >
                                        {models?.map((model) => {
                                            return (
                                                <option key={model.engine} value={model.engine}>
                                                    {model.displayName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {localParams?.map((param, index) => {
                                    return (
                                        (param.modelId === model?.engine) && // Only show ones for the currently active model.
                                        <div key={index} className="m-form-group">
                                            <label htmlFor="" className="a-label">
                                                {param?.name}: {param.value}
                                            </label>
                                            <input
                                                id={param?.name}
                                                type="range"
                                                className="a-text-input"
                                                onChange={(e) => {
                                                    let changedParams = [...localParams];
                                                    changedParams[index].value = e.target.value
                                                    setLocalParams(changedParams);
                                                }}
                                                step={
                                                    param?.maxValue === 1 ? 0.01
                                                        : param?.maxValue === 2 ? 0.1
                                                            : param?.maxValue === 10 ? 0.5
                                                                : param?.maxValue >= 10 ? Math.floor((param?.maxValue ?? 10000) - ((param?.minValue ?? 0) / 100))
                                                                    : 1 // Default step if none of the above conditions are met
                                                }
                                                min={param?.minValue ?? 0}
                                                max={param?.maxValue ?? 10000}
                                                value={param.value}
                                                onBlur={(e) => updateSettings(index, e.target.value)}
                                            />
                                            <p className="a-muted-text a-subtle-text">
                                                {param?.description}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Drawer>
    );
}

export default ChatSettingsDrawer;
