import React from "react";

const Select = (props) => {
    
  return (
    <select
      name={props.name}
      id={props.id}
      value={props.value}
      className={`a-select-input ${props.className ?? ""}`}
      onChange={props.onChange}
      disabled={props.disabled ? props.disabled : false}
    >
      {props.children}
    </select>
  );
};

export default Select;
