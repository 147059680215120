import React, { MouseEventHandler, useEffect, useState } from "react";

const iconCloseSquare = require("../../../images/app-launcher-close-icon.svg").default;

export interface OverlayProps {
    show: boolean;
    children?: React.ReactNode;
    onClose(): undefined;
}

const defaultOverlayProps = {
    show: false
}

export const Overlay = (props: OverlayProps) => {
    props = { ...defaultOverlayProps, ...props };

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // placeholder
    }, [isVisible])

    useEffect(() => {
        if (props.show !== undefined) {
            if (props.show)
                setIsVisible(true);
            else
                setIsVisible(false);
        }
    }, [props.show])

    const closeClickedHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.onClose) {
            props.onClose();
        }
        e.preventDefault();
    }

    return (
        <div className={`o-app-launcher ${isVisible ? "o-app-launcher--shown" : ""}`} id="app-launcher">
            {/* <div className="o-app-launcher__overlay"></div> */}
            <button className="o-app-launcher__close-btn" id="launcher-close"
                onClick={closeClickedHandler}>
                <img className="o-app-launcher__close-icon" src={iconCloseSquare} />
            </button>
            <div className="o-app-launcher__container">
                {props.children}
            </div>
        </div>
    )
}

export interface OverlayTitleProps {
    children?: React.ReactNode;
}

export const OverlayTitle = (props: OverlayTitleProps) => {
    return (
        <h2 className="o-app-launcher__title">{props.children}</h2>
    )
}