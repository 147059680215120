import React from 'react';
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconWarning } from "../../images/warning.svg";

function SymphonyBanner() {
    const navigate = useNavigate();
  
  const navigateToSymphony = (e) => {
    e.preventDefault();
    navigate(`/symphony`, { replace: true });
  };

  return (
    <div className="m-callout m-callout--shaded l-mb-sm">
      <IconWarning className="m-callout__icon" />
      <div className="m-callout__content">
        <h4 className="m-callout__title">Get with the times!</h4>
        <p className="m-callout__text a-muted-text">
          A new version of the NLP Playground, codenamed Symphony, is now available. 
          It's loaded with new features and an upgraded user experience.
        </p>
      </div>
      <div className="m-callout__action">
        <div className="m-button-row">
          <a href="#" className="a-button" onClick={navigateToSymphony}>Try it out!</a>
        </div>
      </div>
    </div>
  );
}

export default SymphonyBanner;
