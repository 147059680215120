import { useChat } from "../contexts/ChatContext";
import { ReactComponent as IconCloseSquare } from "../../../images/cancel.svg";

import { Button, Modal } from "cai-fusion";

const DeleteChatModal = ({ isHidden, onClose, clickOutsideToClose }) => {
    const { chat, deleteChat } = useChat();

    return (
        <Modal
            id="delete-modal"
            isHidden={isHidden}
            clickOutsideToClose={clickOutsideToClose}
            title="Delete Chat"
            onClose={(e) => {
                onClose();
                e.preventDefault();
            }}
            actions={
                <>
                    <Button outline onClick={(e) => { e.preventDefault(); onClose(); }} children="Cancel"/>
                    <Button danger onClick={(e) => { e.preventDefault(); deleteChat(); onClose(); }} children="Delete Chat"/>
                </>
            }
        >
            <div className="l-row">
                <div className="l-one-col">
                    <p><strong>{`Are you sure you want to delete ${chat?.name ? (`"` + chat.name + `"`) : "this chat"}?`}</strong></p>
                    <p>Deleting a chat can't be undone, so think carefully! :) </p>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteChatModal;