import React, { useState, Fragment, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    NavLink,
} from "react-router-dom";
import myCaiApiService from "../../services/mycai-api-v1";
import { callMsGraphUsers } from "../../services/graph";
import { useNavigate } from "react-router-dom";
// import PayrollNewPositionPanel from "../payroll/PayrollNewPositionPanel";

const annualizedHours = 1880;

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const ChangeGroupWarning = props => {

    const meritChanges = props.meritChanges
    const warning = props.warning
    const subtext = props.subtext
    const ccId = props.obj?.id
    const obj = props.obj

    const navigate = useNavigate();
    function handleButtonClicked() {
        if (warning != null) {
            navigate(`${ccId}`); // if relative linking works, otherwise    navigate(`/payroll/${changeGroupId}`);
        } else {
            myCaiApiService.SubmitChangeGroups(props.accessToken, ccId, props.impersonation?.employeeId).then(() => {
                props.onSubmit();
            })
        }
    }

    return (
        <Fragment>
            <tr className="o-treegrid__row o-treegrid__row--shown">
                <td className="o-treegrid__cell"><span className="a-subtle-text"><strong>{obj?.orgName}</strong></span></td>
                <td className="o-treegrid__cell"><span className="a-subtle-text">{warning != null ? warning : "This org is ready to be submitted!"}</span></td>
                <td className="o-treegrid__cell"><button className="a-button a-button--outline a-button--small  a-button--full-width" onClick={() => handleButtonClicked()}>{warning != null ? "View now" : "Submit"}</button></td>
            </tr>
        </Fragment>
    );
};

