import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, Method } from 'axios';
import { IMyCAIApiConfig, CacheEntry } from "../configs/IMyCAIApiConfig";
import { max } from 'd3';
// import { v4 as uuidv4 } from 'uuid'; 

function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  

export class MyCAIApiService {
    // Configuration for the API service
    private apiConfig: IMyCAIApiConfig;

    // Axios client instance
    private apiClient: AxiosInstance;

    // Indicates if the token is currently being refreshed
    private refreshingToken: boolean = false;

    // Cache for storing API responses
    private readonly cache: Map<string, CacheEntry> = new Map();

    // Default values for timeouts and retries
    private readonly DEFAULT_TIMEOUT = 0;
    private readonly DEFAULT_MAX_RETRIES = 3;
    private readonly DEFAULT_RETRY_DELAY = 500;
    private readonly DEFAULT_CACHE_DURATION = 60000; // Default cache duration in milliseconds (1 minute)

    constructor(config: IMyCAIApiConfig) {
        this.apiConfig = config;
        this.apiClient = axios.create({
            baseURL: `${config.baseURL}/api`,
            timeout: config.timeout || this.DEFAULT_TIMEOUT,
            headers: this.createHeaders().headers,
        });

        this.setupInterceptors();
    }

    // Sets up axios interceptors for requests and responses
    private setupInterceptors(): void {
        // Request interceptor for logging and adding a unique request ID
        this.apiClient.interceptors.request.use((config: AxiosRequestConfig) => {
            // Assign a unique ID to the request for tracking purposes
            config.metadata = { startTime: new Date().getTime(), requestId: uuidv4()  };
            return config;
        });

        // Response interceptor to handle logging, retry logic, and token refresh
        this.apiClient.interceptors.response.use(
            (response: AxiosResponse) => {
                // Success handler: log request completion with duration
                const endTime = new Date().getTime();
                const duration = endTime - (response.config.metadata?.startTime || endTime);
                const requestId = response.config.metadata?.requestId || undefined;

                if (requestId) {
                    // TODO observability
                    // console.log(`Request ID: ${requestId} took ${duration}ms`);
                }

                return response;
            },
            async (error: AxiosError) => {
                const { config, response } = error;
                const originalRequest: AxiosRequestConfig & { _retry?: boolean; _retryCount?: number } = config;

                // Check if the request already attempted a retry
                if (originalRequest._retry) {
                    return Promise.reject(error);
                }
                
                // Handle 401 Unauthorized errors
                // Check if the error is due to an expired access token
                if (response && response.status === 401 && this.apiConfig.refreshAccessTokenFunction) {

                    // Mark this request as retried so it doesn't retry forever
                    originalRequest._retry = true;

                    if (!this.refreshingToken) {

                        // Indicate token refresh is in progress
                        this.refreshingToken = true;
                        try {
                            // Attempt to refresh the access token
                            const newAccessToken = await this.apiConfig.refreshAccessTokenFunction();

                            if (newAccessToken) {
                                // this.apiConfig.accessToken = token;
                                this.updateAccessToken(newAccessToken);

                                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                                // Retry the original request with the new token
                                return this.apiClient(originalRequest);
                            }
                        }
                        catch (refreshError) {
                            // Handle errors during token refresh and stop retrying
                            return Promise.reject(refreshError);
                        }
                        finally {
                            this.refreshingToken = false;
                        }
                    }
                }

                // Retry logic for transient errors (e.g., network issues, rate limits) with configurable exponential backoff
                const shouldRetry = response && this.isErrorEligibleForRetry(error);
                const maxRetries = this.apiConfig.maxRetries || this.DEFAULT_MAX_RETRIES;

                const retryCount = originalRequest._retryCount || 0;

                if (shouldRetry && retryCount < maxRetries) {
                    originalRequest._retry = true;
                    originalRequest._retryCount = retryCount + 1;

                    const retryDelay = this.getBackoffDelay(error, this.apiConfig.retryDelay || this.DEFAULT_RETRY_DELAY);
                    
                    console.log(`Retrying request ID: ${originalRequest.metadata?.requestId} in ${retryDelay}ms`);

                    // return this.retryRequest(originalRequest, retryDelay);
                    // return await this.retryRequest(originalRequest, retryDelay);
                    await new Promise(resolve => setTimeout(resolve, retryDelay));
                    return this.apiClient(originalRequest);
                }

                // Handle real errors or fails to handle above error types
                return Promise.reject(error);
            }
        );
    }

    private isErrorEligibleForRetry(error: AxiosError): boolean {
        const statusCode = error.response?.status;
        const errorCode = error.code;
        return statusCode === 503 || statusCode === 429 || errorCode === 'ECONNABORTED';
      }

      private getBackoffDelay(error: AxiosError, retryCount: number): number {
        let delay = this.apiConfig.retryDelay || this.DEFAULT_RETRY_DELAY;
        if (error.response?.status === 429) { // Rate limiting error
          delay *= 2; // More aggressive backoff
        }
        return delay * Math.pow(2, retryCount);
      }

      private retryRequest(config: AxiosRequestConfig, delay: number): Promise<AxiosResponse> {
        return new Promise((resolve) => {
          setTimeout(() => resolve(this.apiClient(config)), delay);
        });
      }

      private generateCacheKey(method: Method, url: string, params?: any, data?: any): string {
        // Simple serialization of method, url, params, and data to create a cache key
        const key = `${method}:${url}:${JSON.stringify(params)}:${JSON.stringify(data)}`;
        return key;
      }
    
      private setCache(key: string, data: any, duration: number): void {
        const expiry = new Date().getTime() + duration;
        this.cache.set(key, { expiry, data });
      }
    
      private getCache(key: string): any | undefined {
        const entry = this.cache.get(key);
        if (entry && new Date().getTime() < entry.expiry) {
          return entry.data;
        }
        // If the cache is expired or doesn't exist, remove the entry
        this.cache.delete(key);
        return undefined;
      }

    protected createHeaders(overrideHeaders: { [key: string]: string } = {}): AxiosRequestConfig {
        const defaultHeaders: Record<string, string> = {
          'Authorization': `Bearer ${this.apiConfig.accessToken}`,
          'Content-Type': 'application/json', // set default type, which is then overriden by the additional headers if done
          ...this.apiConfig.additionalHeaders,
          ...overrideHeaders
        };
    
        if (this.apiConfig.impersonation) {
            defaultHeaders['MYCAI-IMPERSONATE'] = this.apiConfig.impersonation;
        }
    
        return { headers: defaultHeaders };
    }

    public getConfig(): IMyCAIApiConfig {
        return this.apiConfig;
    }

    public updateConfig(newConfig: IMyCAIApiConfig): void {
        this.apiConfig = newConfig;
        this.apiClient.defaults.baseURL = `${newConfig.baseURL}/api`;
        this.apiClient.defaults.timeout = newConfig.timeout || this.apiConfig.timeout || this.DEFAULT_TIMEOUT;
        this.apiClient.defaults.headers = this.createHeaders().headers;

        // If a new access token is provided in the config, update it
        if (newConfig.accessToken) {
            this.updateAccessToken(newConfig.accessToken);
        }
    }

    public updateAccessToken(newAccessToken: string): void {
        this.apiConfig.accessToken = newAccessToken;
        this.apiClient.defaults.headers['Authorization'] = `Bearer ${newAccessToken}`;
    }    

    public async sendPollingRequest({
        method,
        endpoint,
        data,
        params,
        extraHeaders,
        useCache = false,
        cacheDuration = this.DEFAULT_CACHE_DURATION,
        maxPollingDuration = 300000,
        pollingInterval = 3000
    }: {
        method: Method;
        endpoint: string;
        data?: any;
        params?: any;
        extraHeaders?: { [key: string]: string };
        useCache?: boolean;
            cacheDuration?: number;
            maxPollingDuration?: number;
            pollingInterval?: number;
    }): Promise<any> {
        const creationResp = await this.sendRequest({
            method,
            endpoint,
            data,
            params,
            extraHeaders,
            useCache,
            cacheDuration
        })

        const statusUrl = creationResp.statusUrl;

        if (statusUrl === null || statusUrl === undefined) {
            return creationResp;
        }

        return await this.pollForData({endpoint: statusUrl, maxPollingDuration, pollingInterval})
    }

    public async pollForData({
        endpoint,
        maxPollingDuration = 300000,
        pollingInterval = 3000
    }: {
            endpoint: string;
            maxPollingDuration?: number;
            pollingInterval?: number;
        }): Promise<any> {
        const startTime = Date.now();
        try {
            const response = await this.apiClient.request({ method: "get", url: endpoint, baseURL: "" }); // Make request

            if (response.status === 200) {
                return new Promise((resolve) => resolve(response.data));
            }

            const elapsedTime = Date.now() - startTime;

            if (elapsedTime < maxPollingDuration) {
                return new Promise((resolve) => setTimeout(() => this.pollForData({ endpoint, maxPollingDuration, pollingInterval }).then(resp => resolve(resp)), pollingInterval)) // Schedule next request
            } else {
                console.log('Maximum polling duration reached. Stopping polling.');
                throw new Error("Maximum polling duration reached. Stopping polling.");
            }
        } catch (error) {
            console.error('Error making API request:', error);
            const elapsedTime = Date.now() - startTime;

            if (elapsedTime < maxPollingDuration) {
                return new Promise((resolve) => setTimeout(() => this.pollForData({ endpoint, maxPollingDuration, pollingInterval }).then(resp => resolve(resp)), pollingInterval)) // Schedule next request
            } else {
                console.log('Maximum polling duration reached. Stopping polling.');
                throw new Error("Maximum polling duration reached. Stopping polling.");
            }
        }
    }


    public async sendRequest({
        method,
        endpoint,
        data,
        params,
        extraHeaders,
        useCache = false,
        cacheDuration = this.DEFAULT_CACHE_DURATION,  
      }: {
        method: Method;
        endpoint: string;
        data?: any;
        params?: any;
        extraHeaders?: { [key: string]: string };
        useCache?: boolean;
        cacheDuration?: number;
      }): Promise<any> {

        const cacheKey = this.generateCacheKey(method, endpoint, params, data);

        if (useCache) {
            const cachedResponse = this.getCache(cacheKey);
            if (cachedResponse) {
              return cachedResponse;
            }
          }

        try {
          const response = await this.apiClient.request({
            method,
            url: endpoint,
            data,
            params,
            ...this.createHeaders(extraHeaders),
          });

          if (useCache) {
            this.setCache(cacheKey, response.data, cacheDuration);
          }

          return response.data;
        } catch (error) {
          // The interceptor will handle retries for eligible errors if there was an API response error
          // Handle UI errors here
          if (axios.isAxiosError(error)) {
            // Now we can safely assume error is of type AxiosError
            const axiosError = error as AxiosError;
            if (axiosError.code === 'ECONNABORTED') {
              console.error(`Request to ${endpoint} timed out:`, axiosError.message);
            } else if (axiosError.response) {
              console.error(`Request to ${endpoint} failed with status ${axiosError.response.status}:`, axiosError.message);
            } else {
              console.error(`An unknown error occurred in request to ${endpoint}:`, axiosError.message);
            }
          } else {
            // error is not an AxiosError, handle accordingly
            console.error(`An unexpected error occurred in request to ${endpoint}:`, error);
          }
          // The interceptor will handle retries for eligible errors.
          throw error;
        }
      }

    // Method to handle batch requests, so we don't have to worry about it in the Application code
    public async batchRequests(requests: {
        method: Method;
        endpoint: string;
        data?: any;
        params?: any;
        extraHeaders?: { [key: string]: string };
        useCache?: boolean;
        cacheDuration?: number;
      }[]): Promise<any[]> {
        const promiseArray = requests.map((request) => this.sendRequest(request));
        return Promise.all(promiseArray);
      }
}



// const [apiConfig, setApiConfig] = useState({
//     baseURL: 'https://api.example.com',
//     version: 'v1',
//     // ... other initial config
//   });
  
//   const { refreshToken } = useContext(AuthContext);
//   const apiServiceRef = useRef(createApiService(apiConfig, refreshToken));
  
//   useEffect(() => {
//     // When apiConfig changes, update the service config without recreating the service
//     apiServiceRef.current.updateConfig(apiConfig);
//   }, [apiConfig]);


// // Example GET request
// apiService.sendRequest({
//   method: 'get',
//   endpoint: '/data',
//   params: { key: 'value' },
// });

// // Example POST request
// apiService.sendRequest({
//   method: 'post',
//   endpoint: '/submit',
//   data: { field1: 'value1', field2: 'value2' },
// });

// // Example PUT request
// apiService.sendRequest({
//   method: 'put',
//   endpoint: '/update/1',
//   data: { field1: 'newValue' },
// });

// // Example DELETE request
// apiService.sendRequest({
//   method: 'delete',
//   endpoint: '/delete/1',
// });


// Define a batch of requests using the generic sendRequest method
// const batchRequests = [
//     { method: 'get', endpoint: '/data/1', params: { key: 'value1' }, useCache: true, cacheDuration: 30000},
//     { method: 'post', endpoint: '/submit', data: { field1: 'value1' }, useCache: true, cacheDuration: 60000},
//     // ... more requests
//   ];

// // Execute the batch requests with caching
// apiService.batchRequests(batchRequests)
//   .then(responses => {
//     console.log('Batch responses:', responses);
//   })
//   .catch(error => {
//     console.error('Error with batch requests:', error);
//   });