import { useState, useEffect, useContext } from "react";
import { ReactComponent as IconCloseSquare } from "../../../images/close-square.svg";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";
import FileDisplay from "../components/symphonyFileDisplay";
import { Drawer, HeadingRow } from "cai-fusion";
import { useResource } from "../contexts/ResourceContext";
import { useMessages } from "../contexts/MessageContext";
import { useSettings } from "../contexts/SettingsContext";
import { ToastContext } from "cai-fusion";

const DataSourceDrawer = (props) => {
    const { dataSources, handleDataSourcesChange, handleFilesUpload, uploadComplete, setUploadComplete } = useResource(); 
    const { requestedTokens } = useMessages();
    const { tokenLimit } = useSettings();
    const [tokenUsage, setTokenUsage] = useState(0.0);
    const [maxTokens, setMaxTokens] = useState(false);
    
    const { createToast } = useContext(ToastContext);
    
    const closeClickedHandler = () => {
        if (props.onClose) props.onClose();
    };

    useEffect(() => {
        // Close the modal if the upload was successful
        if (uploadComplete) {
            closeClickedHandler();
            setUploadComplete(false);
        }
    }, [uploadComplete]);

    useEffect(() => {
        if (tokenLimit > 1) {
            const percent = Math.floor((requestedTokens / tokenLimit) * 100);
            setTokenUsage(percent);
            setMaxTokens(percent >= 99.98);
        }
    }, [requestedTokens, tokenLimit]);

    // Effect for creating a toast when maxTCHit is set to true
    useEffect(() => {
        if (maxTokens) {
            const toastMessage = (
                <>
                <h4>Max Memory Reached</h4>
                <div>I have so many memories of us chatting! Start a new chat or remove some files.</div>
                </>
            );
            createToast(toastMessage, 'info');
        }
    }, [maxTokens, createToast]);

    return (
        <Drawer
            clickOutsideToClose
            isHidden={props.isHidden}
            id="data-sources-drawer"
            onClose={() => closeClickedHandler()}
            header={
                <HeadingRow
                    title="Data Sources"
                    eyebrow="Settings"
                    size={3}
                >
                    <div className="m-icon-row m-icon-row--right-align">
                        <a
                            id="drawerCloseBtn"
                            className="a-icon m-icon-row__icon"
                            title="Close Associate Details"
                            onClick={props.onClose}
                        >
                            <IconCloseSquare className="a-icon-link__icon">
                                {" "}
                            </IconCloseSquare>
                        </a>
                    </div>
                </HeadingRow>
            }
        >
            <form className="o-form l-mt-sm">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <h5 className="a-heading a-heading--heading-3">
                                    External Data Sources
                                </h5>
                                <p className="a-muted-text">
                                    Choose from available CAI data sources to provide more
                                    context to the assistant.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="o-block">
                                    {dataSources?.length === 0 || dataSources === null
                                        ? "You don't have access to any data sources"
                                        : dataSources?.map((source, index) => (
                                                <label className="a-checkbox" key={index}>
                                                    <span>{source.name}</span>
                                                    <input
                                                        checked={source.included ?? false}
                                                        type="checkbox"
                                                        onChange={() => handleDataSourcesChange(index)}
                                                    />
                                                    <div className="b-input"></div>
                                                </label>
                                            ))}
                                </div>
                            </div>
                        </div>
                        <div className="row l-mt-md">
                            <div className="col-md-12">
                                <h5 className="a-heading a-heading--heading-3">
                                    Web Pages
                                </h5>
                                <p className="a-muted-text">
                                    Provide the URLs of web pages you'd like Symphony to
                                    incorporate into the conversation. Note that Symphony
                                    can't crawl whole websites. It will only scan the
                                    individual pages you provide URLs for below.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="o-block">Coming Soon!</div>
                            </div>
                        </div>
                        <div className="row l-mt-md">
                            <div className="col-12">
                                <h5 className="a-heading a-heading--heading-3">
                                    Included Files
                                </h5>
                                <p className="a-muted-text">
                                    Documents that the assistant is able to reference for
                                    context in this chat.
                                </p>
                            </div>
                        </div>
                        <div className="row l-mb-sm">
                            <div className="col-12">
                                <div className="o-block">
                                    <FileDisplay/>
                                </div>
                            </div>
                        </div>
                        <DnDFileUpload
                            notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1', 
                            '.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx', 
                            '.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
                            '.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
                            overwriteHandleFiles={handleFilesUpload}
                        />
                        <div className="row l-mt-md">
                            <div className="col-md-12">
                                <h5 className="a-heading a-heading--heading-3">Memory Usage</h5>
                                <p className="a-muted-text">
                                    Symphony can only hold so much information in memory for a
                                    given chat. Each file, website, and message use some of
                                    that memory. When the memory limit is reached, it's time
                                    to start a new chat or to remove files and web pages you
                                    no longer need.
                                </p>
                            </div>
                        </div>
                        <div className="l-row">
                            <div className="col-md-12">
                                <div className="m-progress-bar m-progress-bar--">
                                    <div
                                        className="m-progress-bar__inner"
                                        style={{ flexBasis: "100%" }}
                                    >
                                        <div className="m-progress-bar__progress-wrapper">
                                            <p className="m-progress-bar__annotation">{tokenUsage}%</p>
                                            <div
                                                className={`m-progress-bar__bar ${tokenUsage > 80 && tokenUsage < 100 ? 'm-progress-bar__bar--yellow' : tokenUsage >= 100 ? 'm-progress-bar__bar--red' : ''}`}
                                                style={{ width: `${tokenUsage > 100 ? 100 : tokenUsage}%` }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Drawer>
    );
}

export default DataSourceDrawer;
