const enterpriseData = [
    {
        "Sector": "Commercial",
        "Clients": [
            {
                "ClientId": 2,
                "ClientName": "Air Products",
                "Sector": "Commercial",
                "Contracts": [
                    {
                        "ContractId": 2,
                        "ClientId": 2,
                        "Client": null,
                        "Name": "Commercial",
                        "StartDate": null,
                        "EndDate": null,
                        "Engagements": [
                            {
                                "EngagementId": 2,
                                "ContractId": 2,
                                "Contract": null,
                                "Name": "Service Desk",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 8,
                                        "EngagementId": 2,
                                        "Engagement": null,
                                        "MetricId": 1,
                                        "Metric": {
                                            "MetricId": 1,
                                            "Title": "Abandon Percent",
                                            "Description": "Percent of Calls Offered that were Abandoned",
                                            "Formula": "Calls Abandoned/Calls Offered",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.0600,
                                        "Warning": 0.0400,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 23,
                                                "EngagementMetricId": 8,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.0480,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 140,
                                                "EngagementMetricId": 8,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0340,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 253,
                                                "EngagementMetricId": 8,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.0420,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 366,
                                                "EngagementMetricId": 8,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0340,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 479,
                                                "EngagementMetricId": 8,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 592,
                                                "EngagementMetricId": 8,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0540,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 9,
                                        "EngagementId": 2,
                                        "Engagement": null,
                                        "MetricId": 7,
                                        "Metric": {
                                            "MetricId": 7,
                                            "Title": "Average Speed to Answer",
                                            "Description": "Average Speed To Answer",
                                            "Formula": "Average time of all calls answered",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 4,
                                        "TargetType": null,
                                        "Target": 0.5000,
                                        "Warning": 0.4167,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 24,
                                                "EngagementMetricId": 9,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.4500,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 139,
                                                "EngagementMetricId": 9,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.3000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 252,
                                                "EngagementMetricId": 9,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.4000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 365,
                                                "EngagementMetricId": 9,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.3167,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 478,
                                                "EngagementMetricId": 9,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.4833,
                                                "Comment": "Due to a vendor phone number porting issue, CAI expedited the transition to the new Talkdesk ACD system on September 2nd, a full week earlier than planned.   Lack of training on the new tool is contributing to reduced performance"
                                            },
                                            {
                                                "ResultId": 591,
                                                "EngagementMetricId": 9,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.4000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 7,
                                        "EngagementId": 2,
                                        "Engagement": null,
                                        "MetricId": 6,
                                        "Metric": {
                                            "MetricId": 6,
                                            "Title": "Customer Satisfaction",
                                            "Description": "% of Customer Feedback that is Positive",
                                            "Formula": "Positive Responses/All Responses",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9200,
                                        "Warning": 0.9500,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 22,
                                                "EngagementMetricId": 7,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9690,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 141,
                                                "EngagementMetricId": 7,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9550,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 254,
                                                "EngagementMetricId": 7,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9370,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 367,
                                                "EngagementMetricId": 7,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9520,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 480,
                                                "EngagementMetricId": 7,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9350,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 593,
                                                "EngagementMetricId": 7,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.9400,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 6,
                                        "EngagementId": 2,
                                        "Engagement": null,
                                        "MetricId": 3,
                                        "Metric": {
                                            "MetricId": 3,
                                            "Title": "First Level Resolution Rate",
                                            "Description": "% of L1 Tickets Handled Resolved by L1",
                                            "Formula": "Varied",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.7500,
                                        "Warning": 0.8000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 21,
                                                "EngagementMetricId": 6,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.8310,
                                                "Comment": null
                                            },
                                            {
                                                "ResultId": 138,
                                                "EngagementMetricId": 6,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.8010,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 251,
                                                "EngagementMetricId": 6,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.7720,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 364,
                                                "EngagementMetricId": 6,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.7850,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 477,
                                                "EngagementMetricId": 6,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.7800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 590,
                                                "EngagementMetricId": 6,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.7900,
                                                "Comment": ""
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "EngagementId": 34,
                                "ContractId": 2,
                                "Contract": null,
                                "Name": "Application Management Services",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 165,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 98,
                                        "Metric": {
                                            "MetricId": 98,
                                            "Title": "Business Intelligence Batch Job Failure Rate",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.0100,
                                        "Warning": 0.0070,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 48,
                                                "EngagementMetricId": 165,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.0097,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 130,
                                                "EngagementMetricId": 165,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0108,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 243,
                                                "EngagementMetricId": 165,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.0113,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 356,
                                                "EngagementMetricId": 165,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0137,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 469,
                                                "EngagementMetricId": 165,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0211,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 582,
                                                "EngagementMetricId": 165,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0151,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 153,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 86,
                                        "Metric": {
                                            "MetricId": 86,
                                            "Title": "INC HIGH Impact  - Tier - Premium  ",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.7000,
                                        "Warning": 0.6500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 36,
                                                "EngagementMetricId": 153,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 137,
                                                "EngagementMetricId": 153,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 250,
                                                "EngagementMetricId": 153,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 363,
                                                "EngagementMetricId": 153,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 476,
                                                "EngagementMetricId": 153,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 589,
                                                "EngagementMetricId": 153,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 155,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 88,
                                        "Metric": {
                                            "MetricId": 88,
                                            "Title": "INC HIGH Impact  - Tier - Standard",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.7000,
                                        "Warning": 0.6500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 38,
                                                "EngagementMetricId": 155,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 126,
                                                "EngagementMetricId": 155,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 239,
                                                "EngagementMetricId": 155,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.6667,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 352,
                                                "EngagementMetricId": 155,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 465,
                                                "EngagementMetricId": 155,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 578,
                                                "EngagementMetricId": 155,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 154,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 87,
                                        "Metric": {
                                            "MetricId": 87,
                                            "Title": "INC HIGH Impact  - Tier -Enhanced",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.7000,
                                        "Warning": 0.6500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 37,
                                                "EngagementMetricId": 154,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 119,
                                                "EngagementMetricId": 154,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 232,
                                                "EngagementMetricId": 154,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 345,
                                                "EngagementMetricId": 154,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 458,
                                                "EngagementMetricId": 154,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 571,
                                                "EngagementMetricId": 154,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 152,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 85,
                                        "Metric": {
                                            "MetricId": 85,
                                            "Title": "INC HIGH Impact - Tier - Premium Plus",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.7000,
                                        "Warning": 0.6500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 35,
                                                "EngagementMetricId": 152,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 116,
                                                "EngagementMetricId": 152,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 229,
                                                "EngagementMetricId": 152,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 342,
                                                "EngagementMetricId": 152,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 455,
                                                "EngagementMetricId": 152,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 568,
                                                "EngagementMetricId": 152,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 161,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 94,
                                        "Metric": {
                                            "MetricId": 94,
                                            "Title": "INC LOW Impact  - Tier - Premium  ",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.5000,
                                        "Warning": 0.4500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 44,
                                                "EngagementMetricId": 161,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 131,
                                                "EngagementMetricId": 161,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 244,
                                                "EngagementMetricId": 161,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 357,
                                                "EngagementMetricId": 161,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 470,
                                                "EngagementMetricId": 161,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 583,
                                                "EngagementMetricId": 161,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 163,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 96,
                                        "Metric": {
                                            "MetricId": 96,
                                            "Title": "INC LOW Impact  - Tier - Standard",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.5000,
                                        "Warning": 0.4500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 46,
                                                "EngagementMetricId": 163,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 118,
                                                "EngagementMetricId": 163,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 231,
                                                "EngagementMetricId": 163,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 344,
                                                "EngagementMetricId": 163,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 457,
                                                "EngagementMetricId": 163,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 570,
                                                "EngagementMetricId": 163,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 162,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 95,
                                        "Metric": {
                                            "MetricId": 95,
                                            "Title": "INC LOW Impact  - Tier -Enhanced",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.5000,
                                        "Warning": 0.4500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 45,
                                                "EngagementMetricId": 162,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 120,
                                                "EngagementMetricId": 162,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 233,
                                                "EngagementMetricId": 162,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9955,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 346,
                                                "EngagementMetricId": 162,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9943,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 459,
                                                "EngagementMetricId": 162,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9964,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 572,
                                                "EngagementMetricId": 162,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.9964,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 160,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 93,
                                        "Metric": {
                                            "MetricId": 93,
                                            "Title": "INC LOW Impact - Tier - Premium Plus",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.5000,
                                        "Warning": 0.4500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 43,
                                                "EngagementMetricId": 160,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 121,
                                                "EngagementMetricId": 160,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 234,
                                                "EngagementMetricId": 160,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 347,
                                                "EngagementMetricId": 160,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 460,
                                                "EngagementMetricId": 160,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 573,
                                                "EngagementMetricId": 160,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 157,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 90,
                                        "Metric": {
                                            "MetricId": 90,
                                            "Title": "INC MEDIUM Impact  - Tier - Premium  ",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.5000,
                                        "Warning": 0.4500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 40,
                                                "EngagementMetricId": 157,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.7500,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 115,
                                                "EngagementMetricId": 157,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.8750,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 228,
                                                "EngagementMetricId": 157,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 341,
                                                "EngagementMetricId": 157,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.8571,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 454,
                                                "EngagementMetricId": 157,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 567,
                                                "EngagementMetricId": 157,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 159,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 92,
                                        "Metric": {
                                            "MetricId": 92,
                                            "Title": "INC MEDIUM Impact  - Tier - Standard",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.5000,
                                        "Warning": 0.4500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 42,
                                                "EngagementMetricId": 159,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9460,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 127,
                                                "EngagementMetricId": 159,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 240,
                                                "EngagementMetricId": 159,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9756,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 353,
                                                "EngagementMetricId": 159,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9032,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 466,
                                                "EngagementMetricId": 159,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9545,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 579,
                                                "EngagementMetricId": 159,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.9546,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 158,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 91,
                                        "Metric": {
                                            "MetricId": 91,
                                            "Title": "INC MEDIUM Impact  - Tier -Enhanced",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.5000,
                                        "Warning": 0.4500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 41,
                                                "EngagementMetricId": 158,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9939,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 134,
                                                "EngagementMetricId": 158,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9932,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 247,
                                                "EngagementMetricId": 158,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9953,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 360,
                                                "EngagementMetricId": 158,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9877,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 473,
                                                "EngagementMetricId": 158,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9841,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 586,
                                                "EngagementMetricId": 158,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.9841,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 156,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 89,
                                        "Metric": {
                                            "MetricId": 89,
                                            "Title": "INC MEDIUM Impact - Tier - Premium Plus",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.5000,
                                        "Warning": 0.4500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 39,
                                                "EngagementMetricId": 156,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9119,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 122,
                                                "EngagementMetricId": 156,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9455,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 235,
                                                "EngagementMetricId": 156,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9808,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 348,
                                                "EngagementMetricId": 156,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9444,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 461,
                                                "EngagementMetricId": 156,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9429,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 574,
                                                "EngagementMetricId": 156,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.9429,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 149,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 82,
                                        "Metric": {
                                            "MetricId": 82,
                                            "Title": "INC Service Impacting - Tier - Premium  ",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.8500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 32,
                                                "EngagementMetricId": 149,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 135,
                                                "EngagementMetricId": 149,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 248,
                                                "EngagementMetricId": 149,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 361,
                                                "EngagementMetricId": 149,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 474,
                                                "EngagementMetricId": 149,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 587,
                                                "EngagementMetricId": 149,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 148,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 81,
                                        "Metric": {
                                            "MetricId": 81,
                                            "Title": "INC Service Impacting - Tier - Premium Plus",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.8500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 31,
                                                "EngagementMetricId": 148,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 114,
                                                "EngagementMetricId": 148,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 227,
                                                "EngagementMetricId": 148,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 340,
                                                "EngagementMetricId": 148,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 453,
                                                "EngagementMetricId": 148,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 566,
                                                "EngagementMetricId": 148,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 151,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 84,
                                        "Metric": {
                                            "MetricId": 84,
                                            "Title": "INC Service Impacting - Tier - Standard",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.8500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 34,
                                                "EngagementMetricId": 151,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 124,
                                                "EngagementMetricId": 151,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 237,
                                                "EngagementMetricId": 151,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 350,
                                                "EngagementMetricId": 151,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 463,
                                                "EngagementMetricId": 151,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 576,
                                                "EngagementMetricId": 151,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 150,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 83,
                                        "Metric": {
                                            "MetricId": 83,
                                            "Title": "INC Service Impacting - Tier -Enhanced",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.8500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 33,
                                                "EngagementMetricId": 150,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 132,
                                                "EngagementMetricId": 150,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 245,
                                                "EngagementMetricId": 150,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 358,
                                                "EngagementMetricId": 150,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 471,
                                                "EngagementMetricId": 150,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 584,
                                                "EngagementMetricId": 150,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 164,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 97,
                                        "Metric": {
                                            "MetricId": 97,
                                            "Title": "Monthly consumption of Enhancement/Viability Hours",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 3,
                                        "TargetType": null,
                                        "Target": 1.0000,
                                        "Warning": 0.0000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 47,
                                                "EngagementMetricId": 164,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 129,
                                                "EngagementMetricId": 164,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": "Did not consume monthly alotment of hours"
                                            },
                                            {
                                                "ResultId": 242,
                                                "EngagementMetricId": 164,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 355,
                                                "EngagementMetricId": 164,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 468,
                                                "EngagementMetricId": 164,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 581,
                                                "EngagementMetricId": 164,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 145,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 78,
                                        "Metric": {
                                            "MetricId": 78,
                                            "Title": "SMALL Work Request Cost (WR < 16hr effort)",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 1.0000,
                                        "Warning": 0.8000,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 28,
                                                "EngagementMetricId": 145,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 125,
                                                "EngagementMetricId": 145,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 238,
                                                "EngagementMetricId": 145,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 351,
                                                "EngagementMetricId": 145,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 464,
                                                "EngagementMetricId": 145,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 577,
                                                "EngagementMetricId": 145,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 146,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 79,
                                        "Metric": {
                                            "MetricId": 79,
                                            "Title": "Work Request Cost (WR > 16hr effort)",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.2000,
                                        "Warning": 0.1500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 29,
                                                "EngagementMetricId": 146,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 128,
                                                "EngagementMetricId": 146,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 241,
                                                "EngagementMetricId": 146,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.6667,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 354,
                                                "EngagementMetricId": 146,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 467,
                                                "EngagementMetricId": 146,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 580,
                                                "EngagementMetricId": 146,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 147,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 80,
                                        "Metric": {
                                            "MetricId": 80,
                                            "Title": "Work Request Schedule",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 3,
                                        "TargetType": null,
                                        "Target": 1.0000,
                                        "Warning": 0.0000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 30,
                                                "EngagementMetricId": 147,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 100.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 133,
                                                "EngagementMetricId": 147,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": "Missed 1 of 2 scheduled work request dates"
                                            },
                                            {
                                                "ResultId": 246,
                                                "EngagementMetricId": 147,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 359,
                                                "EngagementMetricId": 147,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 472,
                                                "EngagementMetricId": 147,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 585,
                                                "EngagementMetricId": 147,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 142,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 75,
                                        "Metric": {
                                            "MetricId": 75,
                                            "Title": "Work Request Time to Respond  HIGH priority",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.9200,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 25,
                                                "EngagementMetricId": 142,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 117,
                                                "EngagementMetricId": 142,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 230,
                                                "EngagementMetricId": 142,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 343,
                                                "EngagementMetricId": 142,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 456,
                                                "EngagementMetricId": 142,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 569,
                                                "EngagementMetricId": 142,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 144,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 77,
                                        "Metric": {
                                            "MetricId": 77,
                                            "Title": "Work Request Time to Respond  LOW priority",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.9200,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 27,
                                                "EngagementMetricId": 144,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 136,
                                                "EngagementMetricId": 144,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 249,
                                                "EngagementMetricId": 144,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 362,
                                                "EngagementMetricId": 144,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 475,
                                                "EngagementMetricId": 144,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 588,
                                                "EngagementMetricId": 144,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 143,
                                        "EngagementId": 34,
                                        "Engagement": null,
                                        "MetricId": 76,
                                        "Metric": {
                                            "MetricId": 76,
                                            "Title": "Work Request Time to Respond  MEDIUM priority",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.9200,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 26,
                                                "EngagementMetricId": 143,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 123,
                                                "EngagementMetricId": 143,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 236,
                                                "EngagementMetricId": 143,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 349,
                                                "EngagementMetricId": 143,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 462,
                                                "EngagementMetricId": 143,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 575,
                                                "EngagementMetricId": 143,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "ClientId": 6,
                "ClientName": "BlueGreen",
                "Sector": "Commercial",
                "Contracts": [
                    {
                        "ContractId": 6,
                        "ClientId": 6,
                        "Client": null,
                        "Name": "Commercial",
                        "StartDate": null,
                        "EndDate": null,
                        "Engagements": [
                            {
                                "EngagementId": 6,
                                "ContractId": 6,
                                "Contract": null,
                                "Name": "Service Desk",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 39,
                                        "EngagementId": 6,
                                        "Engagement": null,
                                        "MetricId": 1,
                                        "Metric": {
                                            "MetricId": 1,
                                            "Title": "Abandon Percent",
                                            "Description": "Percent of Calls Offered that were Abandoned",
                                            "Formula": "Calls Abandoned/Calls Offered",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.0800,
                                        "Warning": 0.0600,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 70,
                                                "EngagementMetricId": 39,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.0400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 147,
                                                "EngagementMetricId": 39,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0510,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 260,
                                                "EngagementMetricId": 39,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.0430,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 373,
                                                "EngagementMetricId": 39,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0410,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 486,
                                                "EngagementMetricId": 39,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0460,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 599,
                                                "EngagementMetricId": 39,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0480,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 41,
                                        "EngagementId": 6,
                                        "Engagement": null,
                                        "MetricId": 21,
                                        "Metric": {
                                            "MetricId": 21,
                                            "Title": "Average On Hold Time",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 4,
                                        "TargetType": null,
                                        "Target": 2.0000,
                                        "Warning": 1.6667,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 72,
                                                "EngagementMetricId": 41,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 2.1833,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 146,
                                                "EngagementMetricId": 41,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.9667,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 259,
                                                "EngagementMetricId": 41,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 2.0833,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 372,
                                                "EngagementMetricId": 41,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.7667,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 485,
                                                "EngagementMetricId": 41,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 2.1667,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 598,
                                                "EngagementMetricId": 41,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 2.1500,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 40,
                                        "EngagementId": 6,
                                        "Engagement": null,
                                        "MetricId": 7,
                                        "Metric": {
                                            "MetricId": 7,
                                            "Title": "Average Speed to Answer",
                                            "Description": "Average Speed To Answer",
                                            "Formula": "Average time of all calls answered",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 4,
                                        "TargetType": null,
                                        "Target": 1.0000,
                                        "Warning": 0.6667,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 71,
                                                "EngagementMetricId": 40,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.6333,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 149,
                                                "EngagementMetricId": 40,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.8167,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 262,
                                                "EngagementMetricId": 40,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.6333,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 375,
                                                "EngagementMetricId": 40,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.7167,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 488,
                                                "EngagementMetricId": 40,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.6500,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 601,
                                                "EngagementMetricId": 40,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.6667,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 38,
                                        "EngagementId": 6,
                                        "Engagement": null,
                                        "MetricId": 3,
                                        "Metric": {
                                            "MetricId": 3,
                                            "Title": "First Level Resolution Rate",
                                            "Description": "% of L1 Tickets Handled Resolved by L1",
                                            "Formula": "Varied",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8000,
                                        "Warning": 0.8500,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 69,
                                                "EngagementMetricId": 38,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.7950,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 148,
                                                "EngagementMetricId": 38,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.7770,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 261,
                                                "EngagementMetricId": 38,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.7740,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 374,
                                                "EngagementMetricId": 38,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.7860,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 487,
                                                "EngagementMetricId": 38,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.7760,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 600,
                                                "EngagementMetricId": 38,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.7830,
                                                "Comment": ""
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "EngagementId": 29,
                                "ContractId": 6,
                                "Contract": null,
                                "Name": "Desk Side Support",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 43,
                                        "EngagementId": 29,
                                        "Engagement": null,
                                        "MetricId": 6,
                                        "Metric": {
                                            "MetricId": 6,
                                            "Title": "Customer Satisfaction",
                                            "Description": "% of Customer Feedback that is Positive",
                                            "Formula": "Positive Responses/All Responses",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 74,
                                                "EngagementMetricId": 43,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.8330,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 144,
                                                "EngagementMetricId": 43,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9330,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 257,
                                                "EngagementMetricId": 43,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 370,
                                                "EngagementMetricId": 43,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.8800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 483,
                                                "EngagementMetricId": 43,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 596,
                                                "EngagementMetricId": 43,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 42,
                                        "EngagementId": 29,
                                        "Engagement": null,
                                        "MetricId": 26,
                                        "Metric": {
                                            "MetricId": 26,
                                            "Title": "Incident Average Time to Respond",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 4,
                                        "TargetType": null,
                                        "Target": 120.0000,
                                        "Warning": 100.0000,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 73,
                                                "EngagementMetricId": 42,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 31.8000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 145,
                                                "EngagementMetricId": 42,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.4500,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 258,
                                                "EngagementMetricId": 42,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.4400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 371,
                                                "EngagementMetricId": 42,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.4500,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 484,
                                                "EngagementMetricId": 42,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.4800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 597,
                                                "EngagementMetricId": 42,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.3500,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 44,
                                        "EngagementId": 29,
                                        "Engagement": null,
                                        "MetricId": 29,
                                        "Metric": {
                                            "MetricId": 29,
                                            "Title": "Incident Resolution/MTTR - Moderate Priority",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 4,
                                        "TargetType": null,
                                        "Target": 480.0000,
                                        "Warning": 450.0000,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 75,
                                                "EngagementMetricId": 44,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 84.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 143,
                                                "EngagementMetricId": 44,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.6300,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 256,
                                                "EngagementMetricId": 44,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.9300,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 369,
                                                "EngagementMetricId": 44,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 2.1400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 482,
                                                "EngagementMetricId": 44,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.6400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 595,
                                                "EngagementMetricId": 44,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.4800,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 45,
                                        "EngagementId": 29,
                                        "Engagement": null,
                                        "MetricId": 36,
                                        "Metric": {
                                            "MetricId": 36,
                                            "Title": "PC Build/PC Image (Rebuild) MTTR ",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 2,
                                        "TargetType": null,
                                        "Target": 2.0000,
                                        "Warning": 1.7500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 76,
                                                "EngagementMetricId": 45,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.4400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 142,
                                                "EngagementMetricId": 45,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.5700,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 255,
                                                "EngagementMetricId": 45,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.4100,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 368,
                                                "EngagementMetricId": 45,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.8700,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 481,
                                                "EngagementMetricId": 45,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.1100,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 594,
                                                "EngagementMetricId": 45,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.7300,
                                                "Comment": ""
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "ClientId": 3,
                "ClientName": "Dexco",
                "Sector": "Commercial",
                "Contracts": [
                    {
                        "ContractId": 3,
                        "ClientId": 3,
                        "Client": null,
                        "Name": "Commercial",
                        "StartDate": null,
                        "EndDate": null,
                        "Engagements": [
                            {
                                "EngagementId": 3,
                                "ContractId": 3,
                                "Contract": null,
                                "Name": "Managed Maintenance RPA",
                                "EngagementMetrics": []
                            }
                        ]
                    }
                ]
            },
            {
                "ClientId": 5,
                "ClientName": "IDB",
                "Sector": "Commercial",
                "Contracts": [
                    {
                        "ContractId": 5,
                        "ClientId": 5,
                        "Client": null,
                        "Name": "Commercial",
                        "StartDate": null,
                        "EndDate": null,
                        "Engagements": [
                            {
                                "EngagementId": 5,
                                "ContractId": 5,
                                "Contract": null,
                                "Name": "Application Management Services",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 176,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 103,
                                        "Metric": {
                                            "MetricId": 103,
                                            "Title": "Completion of Work Request to Schedule",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8000,
                                        "Warning": 0.8500,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 56,
                                                "EngagementMetricId": 176,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 162,
                                                "EngagementMetricId": 176,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 275,
                                                "EngagementMetricId": 176,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": "0 out of 1 passed"
                                            },
                                            {
                                                "ResultId": 388,
                                                "EngagementMetricId": 176,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": "0 out of 1 passed"
                                            },
                                            {
                                                "ResultId": 501,
                                                "EngagementMetricId": 176,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 614,
                                                "EngagementMetricId": 176,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "6 out of 6 passed"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 180,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 107,
                                        "Metric": {
                                            "MetricId": 107,
                                            "Title": "Incident, Resolved – Critical",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.0085,
                                        "Warning": 0.0090,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 60,
                                                "EngagementMetricId": 180,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 168,
                                                "EngagementMetricId": 180,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 281,
                                                "EngagementMetricId": 180,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 394,
                                                "EngagementMetricId": 180,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 507,
                                                "EngagementMetricId": 180,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 620,
                                                "EngagementMetricId": 180,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.3333,
                                                "Comment": "1 out of 3 passed"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 181,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 108,
                                        "Metric": {
                                            "MetricId": 108,
                                            "Title": "Incident, Resolved – High",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 61,
                                                "EngagementMetricId": 181,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 163,
                                                "EngagementMetricId": 181,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 276,
                                                "EngagementMetricId": 181,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 389,
                                                "EngagementMetricId": 181,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 502,
                                                "EngagementMetricId": 181,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "1 out of 1 passed"
                                            },
                                            {
                                                "ResultId": 615,
                                                "EngagementMetricId": 181,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 182,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 109,
                                        "Metric": {
                                            "MetricId": 109,
                                            "Title": "Incident, Resolved – Medium",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 62,
                                                "EngagementMetricId": 182,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 159,
                                                "EngagementMetricId": 182,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 272,
                                                "EngagementMetricId": 182,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "41 out of 41 passed"
                                            },
                                            {
                                                "ResultId": 385,
                                                "EngagementMetricId": 182,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "2 out of 2 passed"
                                            },
                                            {
                                                "ResultId": 498,
                                                "EngagementMetricId": 182,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 611,
                                                "EngagementMetricId": 182,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "3 out of 3 passed"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 177,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 104,
                                        "Metric": {
                                            "MetricId": 104,
                                            "Title": "Incident, Response – Critical",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 57,
                                                "EngagementMetricId": 177,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 165,
                                                "EngagementMetricId": 177,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 278,
                                                "EngagementMetricId": 177,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 391,
                                                "EngagementMetricId": 177,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 504,
                                                "EngagementMetricId": 177,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 617,
                                                "EngagementMetricId": 177,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "5 out of 5 passed"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 178,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 105,
                                        "Metric": {
                                            "MetricId": 105,
                                            "Title": "Incident, Response – High",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 58,
                                                "EngagementMetricId": 178,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 160,
                                                "EngagementMetricId": 178,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 273,
                                                "EngagementMetricId": 178,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 386,
                                                "EngagementMetricId": 178,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 499,
                                                "EngagementMetricId": 178,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "1 out of 1 passed"
                                            },
                                            {
                                                "ResultId": 612,
                                                "EngagementMetricId": 178,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 179,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 106,
                                        "Metric": {
                                            "MetricId": 106,
                                            "Title": "Incident, Response – Medium",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 59,
                                                "EngagementMetricId": 179,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 161,
                                                "EngagementMetricId": 179,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 274,
                                                "EngagementMetricId": 179,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "41 out of 41 passed"
                                            },
                                            {
                                                "ResultId": 387,
                                                "EngagementMetricId": 179,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "2 out of 2 passed"
                                            },
                                            {
                                                "ResultId": 500,
                                                "EngagementMetricId": 179,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 613,
                                                "EngagementMetricId": 179,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "1 out of 1 passed"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 186,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 113,
                                        "Metric": {
                                            "MetricId": 113,
                                            "Title": "Service Request, Resolved – Critical",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 66,
                                                "EngagementMetricId": 186,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 167,
                                                "EngagementMetricId": 186,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 280,
                                                "EngagementMetricId": 186,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 393,
                                                "EngagementMetricId": 186,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 506,
                                                "EngagementMetricId": 186,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 619,
                                                "EngagementMetricId": 186,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": "0 out of 2 passed"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 187,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 114,
                                        "Metric": {
                                            "MetricId": 114,
                                            "Title": "Service Request, Resolved – High",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 67,
                                                "EngagementMetricId": 187,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.3300,
                                                "Comment": "Cleanup of TracerNow Still in Progress, Stat will increase upon completion"
                                            },
                                            {
                                                "ResultId": 169,
                                                "EngagementMetricId": 187,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.6670,
                                                "Comment": "2 out of 3 passed"
                                            },
                                            {
                                                "ResultId": 282,
                                                "EngagementMetricId": 187,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 395,
                                                "EngagementMetricId": 187,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": "0 out of 1 passed"
                                            },
                                            {
                                                "ResultId": 508,
                                                "EngagementMetricId": 187,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": "0 out of 1 passed"
                                            },
                                            {
                                                "ResultId": 621,
                                                "EngagementMetricId": 187,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": "0 out of 1 passed"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 188,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 115,
                                        "Metric": {
                                            "MetricId": 115,
                                            "Title": "Service Request, Resolved – Medium",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 68,
                                                "EngagementMetricId": 188,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.4300,
                                                "Comment": "Cleanup of TracerNow Still in Progress, Stat will increase upon completion"
                                            },
                                            {
                                                "ResultId": 164,
                                                "EngagementMetricId": 188,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.6670,
                                                "Comment": "4 out of 6 passed"
                                            },
                                            {
                                                "ResultId": 277,
                                                "EngagementMetricId": 188,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.7000,
                                                "Comment": "7 out of 10 passed"
                                            },
                                            {
                                                "ResultId": 390,
                                                "EngagementMetricId": 188,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.5000,
                                                "Comment": "2 out of 4 passed"
                                            },
                                            {
                                                "ResultId": 503,
                                                "EngagementMetricId": 188,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.3636,
                                                "Comment": "4 out of 11 passed"
                                            },
                                            {
                                                "ResultId": 616,
                                                "EngagementMetricId": 188,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "1 out of 1 passed"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 183,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 110,
                                        "Metric": {
                                            "MetricId": 110,
                                            "Title": "Service Request, Response – Critical",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 63,
                                                "EngagementMetricId": 183,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 157,
                                                "EngagementMetricId": 183,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 270,
                                                "EngagementMetricId": 183,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 383,
                                                "EngagementMetricId": 183,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 496,
                                                "EngagementMetricId": 183,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 609,
                                                "EngagementMetricId": 183,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 184,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 111,
                                        "Metric": {
                                            "MetricId": 111,
                                            "Title": "Service Request, Response – High",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 64,
                                                "EngagementMetricId": 184,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 166,
                                                "EngagementMetricId": 184,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 279,
                                                "EngagementMetricId": 184,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 392,
                                                "EngagementMetricId": 184,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "1 out of 1 passed"
                                            },
                                            {
                                                "ResultId": 505,
                                                "EngagementMetricId": 184,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "1 out of 1 passed"
                                            },
                                            {
                                                "ResultId": 618,
                                                "EngagementMetricId": 184,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 185,
                                        "EngagementId": 5,
                                        "Engagement": null,
                                        "MetricId": 112,
                                        "Metric": {
                                            "MetricId": 112,
                                            "Title": "Service Request, Response – Medium",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 65,
                                                "EngagementMetricId": 185,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.8600,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 158,
                                                "EngagementMetricId": 185,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 271,
                                                "EngagementMetricId": 185,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "10 out of 10 passed"
                                            },
                                            {
                                                "ResultId": 384,
                                                "EngagementMetricId": 185,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "4 out of 4 passed"
                                            },
                                            {
                                                "ResultId": 497,
                                                "EngagementMetricId": 185,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "11 out of 11 passed"
                                            },
                                            {
                                                "ResultId": 610,
                                                "EngagementMetricId": 185,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": "2 out of 2 passed"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "ClientId": 1,
                "ClientName": "PPL",
                "Sector": "Commercial",
                "Contracts": [
                    {
                        "ContractId": 1,
                        "ClientId": 1,
                        "Client": null,
                        "Name": "Commercial",
                        "StartDate": null,
                        "EndDate": null,
                        "Engagements": [
                            {
                                "EngagementId": 1,
                                "ContractId": 1,
                                "Contract": null,
                                "Name": "App Dev and Maintenance",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 204,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 123,
                                        "Metric": {
                                            "MetricId": 123,
                                            "Title": "CMDB Quality",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.2000,
                                        "Warning": 0.1700,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 16,
                                                "EngagementMetricId": 204,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.3500,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 194,
                                                "EngagementMetricId": 204,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.2800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 307,
                                                "EngagementMetricId": 204,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.2400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 420,
                                                "EngagementMetricId": 204,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.2400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 533,
                                                "EngagementMetricId": 204,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.4800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 646,
                                                "EngagementMetricId": 204,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.3400,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 202,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 121,
                                        "Metric": {
                                            "MetricId": 121,
                                            "Title": "Completion to Schedule",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 1.0000,
                                        "Warning": 1.0000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 14,
                                                "EngagementMetricId": 202,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.6700,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 185,
                                                "EngagementMetricId": 202,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.6677,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 298,
                                                "EngagementMetricId": 202,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 411,
                                                "EngagementMetricId": 202,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 524,
                                                "EngagementMetricId": 202,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 637,
                                                "EngagementMetricId": 202,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 201,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 120,
                                        "Metric": {
                                            "MetricId": 120,
                                            "Title": "Final Estimate Accuracy",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.2000,
                                        "Warning": 0.1500,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 13,
                                                "EngagementMetricId": 201,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.8300,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 197,
                                                "EngagementMetricId": 201,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.8200,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 310,
                                                "EngagementMetricId": 201,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9100,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 423,
                                                "EngagementMetricId": 201,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9100,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 536,
                                                "EngagementMetricId": 201,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 649,
                                                "EngagementMetricId": 201,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.9800,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 197,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 116,
                                        "Metric": {
                                            "MetricId": 116,
                                            "Title": "Incident Autonomy of Resolution",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 9,
                                                "EngagementMetricId": 197,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 192,
                                                "EngagementMetricId": 197,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 305,
                                                "EngagementMetricId": 197,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 418,
                                                "EngagementMetricId": 197,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 531,
                                                "EngagementMetricId": 197,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9900,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 644,
                                                "EngagementMetricId": 197,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 198,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 117,
                                        "Metric": {
                                            "MetricId": 117,
                                            "Title": "Incidents Reopened",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.1000,
                                        "Warning": 0.0800,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 10,
                                                "EngagementMetricId": 198,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.0329,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 195,
                                                "EngagementMetricId": 198,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0569,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 308,
                                                "EngagementMetricId": 198,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.0155,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 421,
                                                "EngagementMetricId": 198,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0561,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 534,
                                                "EngagementMetricId": 198,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0273,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 647,
                                                "EngagementMetricId": 198,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0424,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 206,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 125,
                                        "Metric": {
                                            "MetricId": 125,
                                            "Title": "ITSM Knowledge Article Quality",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.1500,
                                        "Warning": 0.1300,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 18,
                                                "EngagementMetricId": 206,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.0348,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 180,
                                                "EngagementMetricId": 206,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0456,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 293,
                                                "EngagementMetricId": 206,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.0345,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 406,
                                                "EngagementMetricId": 206,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0432,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 519,
                                                "EngagementMetricId": 206,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0359,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 632,
                                                "EngagementMetricId": 206,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0234,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 205,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 124,
                                        "Metric": {
                                            "MetricId": 124,
                                            "Title": "Management Reporting",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 3,
                                        "TargetType": null,
                                        "Target": 1.0000,
                                        "Warning": 0.0000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 17,
                                                "EngagementMetricId": 205,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 181,
                                                "EngagementMetricId": 205,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 294,
                                                "EngagementMetricId": 205,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 407,
                                                "EngagementMetricId": 205,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 520,
                                                "EngagementMetricId": 205,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 633,
                                                "EngagementMetricId": 205,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 193,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 132,
                                        "Metric": {
                                            "MetricId": 132,
                                            "Title": "P1 Incident Resolution",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 5,
                                                "EngagementMetricId": 193,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 190,
                                                "EngagementMetricId": 193,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 303,
                                                "EngagementMetricId": 193,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 416,
                                                "EngagementMetricId": 193,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 529,
                                                "EngagementMetricId": 193,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 642,
                                                "EngagementMetricId": 193,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 189,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 128,
                                        "Metric": {
                                            "MetricId": 128,
                                            "Title": "P1 Incident Response",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 1,
                                                "EngagementMetricId": 189,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 183,
                                                "EngagementMetricId": 189,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 296,
                                                "EngagementMetricId": 189,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 409,
                                                "EngagementMetricId": 189,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 522,
                                                "EngagementMetricId": 189,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 635,
                                                "EngagementMetricId": 189,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 194,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 133,
                                        "Metric": {
                                            "MetricId": 133,
                                            "Title": "P2 Incident Resolution",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.9300,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 6,
                                                "EngagementMetricId": 194,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 186,
                                                "EngagementMetricId": 194,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 299,
                                                "EngagementMetricId": 194,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 412,
                                                "EngagementMetricId": 194,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 525,
                                                "EngagementMetricId": 194,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 638,
                                                "EngagementMetricId": 194,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 190,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 129,
                                        "Metric": {
                                            "MetricId": 129,
                                            "Title": "P2 Incident Response",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 2,
                                                "EngagementMetricId": 190,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 179,
                                                "EngagementMetricId": 190,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 292,
                                                "EngagementMetricId": 190,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 405,
                                                "EngagementMetricId": 190,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 518,
                                                "EngagementMetricId": 190,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 631,
                                                "EngagementMetricId": 190,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 195,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 134,
                                        "Metric": {
                                            "MetricId": 134,
                                            "Title": "P3 Incident Resolution With Callout",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9800,
                                        "Warning": 0.9850,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 7,
                                                "EngagementMetricId": 195,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.7800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 187,
                                                "EngagementMetricId": 195,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.8600,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 300,
                                                "EngagementMetricId": 195,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9202,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 413,
                                                "EngagementMetricId": 195,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.8396,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 526,
                                                "EngagementMetricId": 195,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.8797,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 639,
                                                "EngagementMetricId": 195,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.9014,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 196,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 135,
                                        "Metric": {
                                            "MetricId": 135,
                                            "Title": "P3 Incident Resolution Without Callout",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 8,
                                                "EngagementMetricId": 196,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.8500,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 193,
                                                "EngagementMetricId": 196,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.8500,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 306,
                                                "EngagementMetricId": 196,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.8913,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 419,
                                                "EngagementMetricId": 196,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.8752,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 532,
                                                "EngagementMetricId": 196,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.8752,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 645,
                                                "EngagementMetricId": 196,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.8631,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 192,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 131,
                                        "Metric": {
                                            "MetricId": 131,
                                            "Title": "P3 Incident Response With Callout",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 4,
                                                "EngagementMetricId": 192,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 191,
                                                "EngagementMetricId": 192,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9966,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 304,
                                                "EngagementMetricId": 192,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 417,
                                                "EngagementMetricId": 192,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9859,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 530,
                                                "EngagementMetricId": 192,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 643,
                                                "EngagementMetricId": 192,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 191,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 130,
                                        "Metric": {
                                            "MetricId": 130,
                                            "Title": "P3 Incident Response Without Callout",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 3,
                                                "EngagementMetricId": 191,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9600,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 182,
                                                "EngagementMetricId": 191,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9670,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 295,
                                                "EngagementMetricId": 191,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9568,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 408,
                                                "EngagementMetricId": 191,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9508,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 521,
                                                "EngagementMetricId": 191,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9516,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 634,
                                                "EngagementMetricId": 191,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.9585,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 199,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 118,
                                        "Metric": {
                                            "MetricId": 118,
                                            "Title": "Problem Investigation",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 11,
                                                "EngagementMetricId": 199,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.4000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 196,
                                                "EngagementMetricId": 199,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.5000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 309,
                                                "EngagementMetricId": 199,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.3300,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 422,
                                                "EngagementMetricId": 199,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 535,
                                                "EngagementMetricId": 199,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 648,
                                                "EngagementMetricId": 199,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 203,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 122,
                                        "Metric": {
                                            "MetricId": 122,
                                            "Title": "Rework",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.1000,
                                        "Warning": 0.0800,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 15,
                                                "EngagementMetricId": 203,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.0800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 188,
                                                "EngagementMetricId": 203,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0200,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 301,
                                                "EngagementMetricId": 203,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.1200,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 414,
                                                "EngagementMetricId": 203,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0300,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 527,
                                                "EngagementMetricId": 203,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 640,
                                                "EngagementMetricId": 203,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0400,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 207,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 126,
                                        "Metric": {
                                            "MetricId": 126,
                                            "Title": "SOX Control Completion",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 1.0000,
                                        "Warning": 1.0000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 19,
                                                "EngagementMetricId": 207,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 184,
                                                "EngagementMetricId": 207,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 297,
                                                "EngagementMetricId": 207,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 410,
                                                "EngagementMetricId": 207,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 523,
                                                "EngagementMetricId": 207,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 636,
                                                "EngagementMetricId": 207,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 208,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 127,
                                        "Metric": {
                                            "MetricId": 127,
                                            "Title": "SOX System Section in AKT Notebook",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 1.0000,
                                        "Warning": 1.0000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 20,
                                                "EngagementMetricId": 208,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": null
                                            },
                                            {
                                                "ResultId": 198,
                                                "EngagementMetricId": 208,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 311,
                                                "EngagementMetricId": 208,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 424,
                                                "EngagementMetricId": 208,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 537,
                                                "EngagementMetricId": 208,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 650,
                                                "EngagementMetricId": 208,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 200,
                                        "EngagementId": 1,
                                        "Engagement": null,
                                        "MetricId": 119,
                                        "Metric": {
                                            "MetricId": 119,
                                            "Title": "Time to Estimate",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.9300,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 12,
                                                "EngagementMetricId": 200,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.6400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 189,
                                                "EngagementMetricId": 200,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.6400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 302,
                                                "EngagementMetricId": 200,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.5000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 415,
                                                "EngagementMetricId": 200,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 528,
                                                "EngagementMetricId": 200,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.4000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 641,
                                                "EngagementMetricId": 200,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "ClientId": 4,
                "ClientName": "SEKO Logistics",
                "Sector": "Commercial",
                "Contracts": [
                    {
                        "ContractId": 4,
                        "ClientId": 4,
                        "Client": null,
                        "Name": "Commercial",
                        "StartDate": null,
                        "EndDate": null,
                        "Engagements": [
                            {
                                "EngagementId": 4,
                                "ContractId": 4,
                                "Contract": null,
                                "Name": "Service Desk",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 169,
                                        "EngagementId": 4,
                                        "Engagement": null,
                                        "MetricId": 1,
                                        "Metric": {
                                            "MetricId": 1,
                                            "Title": "Abandon Percent",
                                            "Description": "Percent of Calls Offered that were Abandoned",
                                            "Formula": "Calls Abandoned/Calls Offered",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.0600,
                                        "Warning": 0.0400,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 49,
                                                "EngagementMetricId": 169,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Account GoLive in June"
                                            },
                                            {
                                                "ResultId": 199,
                                                "EngagementMetricId": 169,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0100,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 312,
                                                "EngagementMetricId": 169,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.0080,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 425,
                                                "EngagementMetricId": 169,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0110,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 538,
                                                "EngagementMetricId": 169,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0260,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 651,
                                                "EngagementMetricId": 169,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0410,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 170,
                                        "EngagementId": 4,
                                        "Engagement": null,
                                        "MetricId": 2,
                                        "Metric": {
                                            "MetricId": 2,
                                            "Title": "Answered < 30 Seconds",
                                            "Description": "Percent of Calls Answered within 30 Seconds",
                                            "Formula": "Calls Answered Within 30s/Calls Answered",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8000,
                                        "Warning": 0.8500,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 50,
                                                "EngagementMetricId": 170,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Account GoLive in June"
                                            },
                                            {
                                                "ResultId": 203,
                                                "EngagementMetricId": 170,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 316,
                                                "EngagementMetricId": 170,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9580,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 429,
                                                "EngagementMetricId": 170,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9660,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 542,
                                                "EngagementMetricId": 170,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9420,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 655,
                                                "EngagementMetricId": 170,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.8990,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 172,
                                        "EngagementId": 4,
                                        "Engagement": null,
                                        "MetricId": 6,
                                        "Metric": {
                                            "MetricId": 6,
                                            "Title": "Customer Satisfaction",
                                            "Description": "% of Customer Feedback that is Positive",
                                            "Formula": "Positive Responses/All Responses",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9200,
                                        "Warning": 0.9600,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 52,
                                                "EngagementMetricId": 172,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Account GoLive in June"
                                            },
                                            {
                                                "ResultId": 205,
                                                "EngagementMetricId": 172,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 318,
                                                "EngagementMetricId": 172,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 431,
                                                "EngagementMetricId": 172,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 544,
                                                "EngagementMetricId": 172,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 657,
                                                "EngagementMetricId": 172,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 175,
                                        "EngagementId": 4,
                                        "Engagement": null,
                                        "MetricId": 102,
                                        "Metric": {
                                            "MetricId": 102,
                                            "Title": "Email Response < 2 Hours",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.9200,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 55,
                                                "EngagementMetricId": 175,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Account GoLive in June"
                                            },
                                            {
                                                "ResultId": 200,
                                                "EngagementMetricId": 175,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9900,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 313,
                                                "EngagementMetricId": 175,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9760,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 426,
                                                "EngagementMetricId": 175,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9810,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 539,
                                                "EngagementMetricId": 175,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9180,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 652,
                                                "EngagementMetricId": 175,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.8870,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 171,
                                        "EngagementId": 4,
                                        "Engagement": null,
                                        "MetricId": 3,
                                        "Metric": {
                                            "MetricId": 3,
                                            "Title": "First Level Resolution Rate",
                                            "Description": "% of L1 Tickets Handled Resolved by L1",
                                            "Formula": "Varied",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.7000,
                                        "Warning": 0.7500,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 51,
                                                "EngagementMetricId": 171,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Account GoLive in June"
                                            },
                                            {
                                                "ResultId": 202,
                                                "EngagementMetricId": 171,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.6300,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 315,
                                                "EngagementMetricId": 171,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.7630,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 428,
                                                "EngagementMetricId": 171,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.7700,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 541,
                                                "EngagementMetricId": 171,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.8130,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 654,
                                                "EngagementMetricId": 171,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.7870,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 173,
                                        "EngagementId": 4,
                                        "Engagement": null,
                                        "MetricId": 10,
                                        "Metric": {
                                            "MetricId": 10,
                                            "Title": "Shift Left Index",
                                            "Description": "ToDo",
                                            "Formula": "ToDo",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 2,
                                        "TargetType": null,
                                        "Target": 1.0000,
                                        "Warning": 1.1000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 53,
                                                "EngagementMetricId": 173,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Account GoLive in June"
                                            },
                                            {
                                                "ResultId": 201,
                                                "EngagementMetricId": 173,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 314,
                                                "EngagementMetricId": 173,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 427,
                                                "EngagementMetricId": 173,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 540,
                                                "EngagementMetricId": 173,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 653,
                                                "EngagementMetricId": 173,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 174,
                                        "EngagementId": 4,
                                        "Engagement": null,
                                        "MetricId": 9,
                                        "Metric": {
                                            "MetricId": 9,
                                            "Title": "Voicemail Response Time",
                                            "Description": "Average Voicemail Response",
                                            "Formula": "Average of all voicemail handle times",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 2,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.9200,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 54,
                                                "EngagementMetricId": 174,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Account GoLive in June"
                                            },
                                            {
                                                "ResultId": 204,
                                                "EngagementMetricId": 174,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 317,
                                                "EngagementMetricId": 174,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 430,
                                                "EngagementMetricId": 174,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 543,
                                                "EngagementMetricId": 174,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 656,
                                                "EngagementMetricId": 174,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "Sector": "Public Sector",
        "Clients": [
            {
                "ClientId": 7,
                "ClientName": "CMSD",
                "Sector": "Public",
                "Contracts": [
                    {
                        "ContractId": 7,
                        "ClientId": 7,
                        "Client": null,
                        "Name": "Public",
                        "StartDate": null,
                        "EndDate": null,
                        "Engagements": [
                            {
                                "EngagementId": 7,
                                "ContractId": 7,
                                "Contract": null,
                                "Name": "Service Desk",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 47,
                                        "EngagementId": 7,
                                        "Engagement": null,
                                        "MetricId": 1,
                                        "Metric": {
                                            "MetricId": 1,
                                            "Title": "Abandon Percent",
                                            "Description": "Percent of Calls Offered that were Abandoned",
                                            "Formula": "Calls Abandoned/Calls Offered",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.0600,
                                        "Warning": 0.0400,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 78,
                                                "EngagementMetricId": 47,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.0070,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 152,
                                                "EngagementMetricId": 47,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0020,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 265,
                                                "EngagementMetricId": 47,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.0010,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 378,
                                                "EngagementMetricId": 47,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0930,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 491,
                                                "EngagementMetricId": 47,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0490,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 604,
                                                "EngagementMetricId": 47,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.0470,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 46,
                                        "EngagementId": 7,
                                        "Engagement": null,
                                        "MetricId": 2,
                                        "Metric": {
                                            "MetricId": 2,
                                            "Title": "Answered < 30 Seconds",
                                            "Description": "Percent of Calls Answered within 30 Seconds",
                                            "Formula": "Calls Answered Within 30s/Calls Answered",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 77,
                                                "EngagementMetricId": 46,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9810,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 156,
                                                "EngagementMetricId": 46,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9880,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 269,
                                                "EngagementMetricId": 46,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9970,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 382,
                                                "EngagementMetricId": 46,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.6180,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 495,
                                                "EngagementMetricId": 46,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.6860,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 608,
                                                "EngagementMetricId": 46,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.7550,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 50,
                                        "EngagementId": 7,
                                        "Engagement": null,
                                        "MetricId": 37,
                                        "Metric": {
                                            "MetricId": 37,
                                            "Title": "Cumulative Ticket Resolution Attainment",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9800,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 81,
                                                "EngagementMetricId": 50,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable"
                                            },
                                            {
                                                "ResultId": 155,
                                                "EngagementMetricId": 50,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 268,
                                                "EngagementMetricId": 50,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Not Measurable"
                                            },
                                            {
                                                "ResultId": 381,
                                                "EngagementMetricId": 50,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 494,
                                                "EngagementMetricId": 50,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 607,
                                                "EngagementMetricId": 50,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 51,
                                        "EngagementId": 7,
                                        "Engagement": null,
                                        "MetricId": 38,
                                        "Metric": {
                                            "MetricId": 38,
                                            "Title": "Cumulative Ticket Response Attainment",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9800,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 82,
                                                "EngagementMetricId": 51,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable"
                                            },
                                            {
                                                "ResultId": 151,
                                                "EngagementMetricId": 51,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 264,
                                                "EngagementMetricId": 51,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Not Measurable"
                                            },
                                            {
                                                "ResultId": 377,
                                                "EngagementMetricId": 51,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 490,
                                                "EngagementMetricId": 51,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 603,
                                                "EngagementMetricId": 51,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 52,
                                        "EngagementId": 7,
                                        "Engagement": null,
                                        "MetricId": 6,
                                        "Metric": {
                                            "MetricId": 6,
                                            "Title": "Customer Satisfaction",
                                            "Description": "% of Customer Feedback that is Positive",
                                            "Formula": "Positive Responses/All Responses",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.9200,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 83,
                                                "EngagementMetricId": 52,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable"
                                            },
                                            {
                                                "ResultId": 153,
                                                "EngagementMetricId": 52,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 266,
                                                "EngagementMetricId": 52,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Not Measurable"
                                            },
                                            {
                                                "ResultId": 379,
                                                "EngagementMetricId": 52,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 492,
                                                "EngagementMetricId": 52,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.6800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 605,
                                                "EngagementMetricId": 52,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.7300,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 49,
                                        "EngagementId": 7,
                                        "Engagement": null,
                                        "MetricId": 3,
                                        "Metric": {
                                            "MetricId": 3,
                                            "Title": "First Level Resolution Rate",
                                            "Description": "% of L1 Tickets Handled Resolved by L1",
                                            "Formula": "Varied",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.7000,
                                        "Warning": 0.7500,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 80,
                                                "EngagementMetricId": 49,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.5500,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 150,
                                                "EngagementMetricId": 49,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.5910,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 263,
                                                "EngagementMetricId": 49,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.6030,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 376,
                                                "EngagementMetricId": 49,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.5230,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 489,
                                                "EngagementMetricId": 49,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.5820,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 602,
                                                "EngagementMetricId": 49,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.6490,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 48,
                                        "EngagementId": 7,
                                        "Engagement": null,
                                        "MetricId": 16,
                                        "Metric": {
                                            "MetricId": 16,
                                            "Title": "Ticket Re-open Rate",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.0500,
                                        "Warning": 0.0400,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 79,
                                                "EngagementMetricId": 48,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable"
                                            },
                                            {
                                                "ResultId": 154,
                                                "EngagementMetricId": 48,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 267,
                                                "EngagementMetricId": 48,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Not Measurable"
                                            },
                                            {
                                                "ResultId": 380,
                                                "EngagementMetricId": 48,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 493,
                                                "EngagementMetricId": 48,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 606,
                                                "EngagementMetricId": 48,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": null,
                                                "Comment": ""
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "EngagementId": 32,
                                "ContractId": 7,
                                "Contract": null,
                                "Name": "Desk Side Support",
                                "EngagementMetrics": []
                            }
                        ]
                    }
                ]
            },
            {
                "ClientId": 10,
                "ClientName": "Lee County",
                "Sector": "Public",
                "Contracts": [
                    {
                        "ContractId": 10,
                        "ClientId": 10,
                        "Client": null,
                        "Name": "Public",
                        "StartDate": null,
                        "EndDate": null,
                        "Engagements": [
                            {
                                "EngagementId": 10,
                                "ContractId": 10,
                                "Contract": null,
                                "Name": "Service Desk",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 76,
                                        "EngagementId": 10,
                                        "Engagement": null,
                                        "MetricId": 2,
                                        "Metric": {
                                            "MetricId": 2,
                                            "Title": "Answered < 30 Seconds",
                                            "Description": "Percent of Calls Answered within 30 Seconds",
                                            "Formula": "Calls Answered Within 30s/Calls Answered",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 105,
                                                "EngagementMetricId": 76,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9300,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 174,
                                                "EngagementMetricId": 76,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9650,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 287,
                                                "EngagementMetricId": 76,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9100,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 400,
                                                "EngagementMetricId": 76,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.8580,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 513,
                                                "EngagementMetricId": 76,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.8000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 626,
                                                "EngagementMetricId": 76,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.8700,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 77,
                                        "EngagementId": 10,
                                        "Engagement": null,
                                        "MetricId": 42,
                                        "Metric": {
                                            "MetricId": 42,
                                            "Title": "Contact Acknowledgement ≤ 1 Hour",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 106,
                                                "EngagementMetricId": 77,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9900,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 175,
                                                "EngagementMetricId": 77,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 288,
                                                "EngagementMetricId": 77,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 401,
                                                "EngagementMetricId": 77,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 514,
                                                "EngagementMetricId": 77,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9300,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 627,
                                                "EngagementMetricId": 77,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.8800,
                                                "Comment": "Due the challenges with VM reporting, this SLO has not been reported as \"did not meet\"."
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 78,
                                        "EngagementId": 10,
                                        "Engagement": null,
                                        "MetricId": 4,
                                        "Metric": {
                                            "MetricId": 4,
                                            "Title": "First Call Resolution Rate",
                                            "Description": "% of L1 Resolved Tickets resolved during the first call",
                                            "Formula": "Varied",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.7000,
                                        "Warning": 0.7500,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 107,
                                                "EngagementMetricId": 78,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.7000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 177,
                                                "EngagementMetricId": 78,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.7600,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 290,
                                                "EngagementMetricId": 78,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.7000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 403,
                                                "EngagementMetricId": 78,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.7020,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 516,
                                                "EngagementMetricId": 78,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.7100,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 629,
                                                "EngagementMetricId": 78,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.7900,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 81,
                                        "EngagementId": 10,
                                        "Engagement": null,
                                        "MetricId": 45,
                                        "Metric": {
                                            "MetricId": 45,
                                            "Title": "Mean Time to Resolution (High Priority) ≤ 4 hours",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 110,
                                                "EngagementMetricId": 81,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 173,
                                                "EngagementMetricId": 81,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9600,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 286,
                                                "EngagementMetricId": 81,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 399,
                                                "EngagementMetricId": 81,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 512,
                                                "EngagementMetricId": 81,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 625,
                                                "EngagementMetricId": 81,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 80,
                                        "EngagementId": 10,
                                        "Engagement": null,
                                        "MetricId": 44,
                                        "Metric": {
                                            "MetricId": 44,
                                            "Title": "MTTR (Critical Priority) ≤ 2 hours",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 109,
                                                "EngagementMetricId": 80,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 176,
                                                "EngagementMetricId": 80,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 289,
                                                "EngagementMetricId": 80,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 402,
                                                "EngagementMetricId": 80,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 515,
                                                "EngagementMetricId": 80,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 628,
                                                "EngagementMetricId": 80,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 79,
                                        "EngagementId": 10,
                                        "Engagement": null,
                                        "MetricId": 43,
                                        "Metric": {
                                            "MetricId": 43,
                                            "Title": "Resolution Time (Excluding Low Priority) ≤ 3 BD",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9000,
                                        "Warning": 0.9500,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 108,
                                                "EngagementMetricId": 79,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 178,
                                                "EngagementMetricId": 79,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9600,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 291,
                                                "EngagementMetricId": 79,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9300,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 404,
                                                "EngagementMetricId": 79,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9130,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 517,
                                                "EngagementMetricId": 79,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9400,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 630,
                                                "EngagementMetricId": 79,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "EngagementId": 35,
                                "ContractId": 10,
                                "Contract": null,
                                "Name": "Application Development",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 166,
                                        "EngagementId": 35,
                                        "Engagement": null,
                                        "MetricId": 99,
                                        "Metric": {
                                            "MetricId": 99,
                                            "Title": "Online availability of County applications",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9990,
                                        "Warning": 0.9995,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 111,
                                                "EngagementMetricId": 166,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9990,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 171,
                                                "EngagementMetricId": 166,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9999,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 284,
                                                "EngagementMetricId": 166,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9998,
                                                "Comment": null
                                            },
                                            {
                                                "ResultId": 397,
                                                "EngagementMetricId": 166,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9999,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 510,
                                                "EngagementMetricId": 166,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9995,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 623,
                                                "EngagementMetricId": 166,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 167,
                                        "EngagementId": 35,
                                        "Engagement": null,
                                        "MetricId": 100,
                                        "Metric": {
                                            "MetricId": 100,
                                            "Title": "Online response time of County applications",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9990,
                                        "Warning": 0.9995,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 112,
                                                "EngagementMetricId": 167,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 172,
                                                "EngagementMetricId": 167,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 285,
                                                "EngagementMetricId": 167,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9999,
                                                "Comment": null
                                            },
                                            {
                                                "ResultId": 398,
                                                "EngagementMetricId": 167,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 511,
                                                "EngagementMetricId": 167,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9999,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 624,
                                                "EngagementMetricId": 167,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 0.9999,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 168,
                                        "EngagementId": 35,
                                        "Engagement": null,
                                        "MetricId": 101,
                                        "Metric": {
                                            "MetricId": 101,
                                            "Title": "Proactive patching/upgrades - commercial software",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 1.0000,
                                        "Warning": 1.0000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 113,
                                                "EngagementMetricId": 168,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 170,
                                                "EngagementMetricId": 168,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9990,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 283,
                                                "EngagementMetricId": 168,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": null
                                            },
                                            {
                                                "ResultId": 396,
                                                "EngagementMetricId": 168,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9999,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 509,
                                                "EngagementMetricId": 168,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 622,
                                                "EngagementMetricId": 168,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-10-01T00:00:00",
                                                "EndDate": "2022-10-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "ClientId": 8,
                "ClientName": "Seminole",
                "Sector": "Public",
                "Contracts": [
                    {
                        "ContractId": 8,
                        "ClientId": 8,
                        "Client": null,
                        "Name": "Public",
                        "StartDate": null,
                        "EndDate": "2022-09-30T00:00:00",
                        "Engagements": [
                            {
                                "EngagementId": 8,
                                "ContractId": 8,
                                "Contract": null,
                                "Name": "Service Desk",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 98,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 1,
                                        "Metric": {
                                            "MetricId": 1,
                                            "Title": "Abandon Percent",
                                            "Description": "Percent of Calls Offered that were Abandoned",
                                            "Formula": "Calls Abandoned/Calls Offered",
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.0100,
                                        "Warning": 0.0075,
                                        "Inverted": true,
                                        "Results": [
                                            {
                                                "ResultId": 86,
                                                "EngagementMetricId": 98,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.0050,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 219,
                                                "EngagementMetricId": 98,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0080,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 332,
                                                "EngagementMetricId": 98,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.0100,
                                                "Comment": "Due to a high volume outage occuring on 7/5, that day is excluded from this stat. The client understands and acknowledged this exclusion"
                                            },
                                            {
                                                "ResultId": 445,
                                                "EngagementMetricId": 98,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.0120,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 558,
                                                "EngagementMetricId": 98,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.0560,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 101,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 51,
                                        "Metric": {
                                            "MetricId": 51,
                                            "Title": "Correct Request Routing",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9900,
                                        "Warning": 0.9950,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 89,
                                                "EngagementMetricId": 101,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable"
                                            },
                                            {
                                                "ResultId": 221,
                                                "EngagementMetricId": 101,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 334,
                                                "EngagementMetricId": 101,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 447,
                                                "EngagementMetricId": 101,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 560,
                                                "EngagementMetricId": 101,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 99,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 49,
                                        "Metric": {
                                            "MetricId": 49,
                                            "Title": "Email Answered within Four Hours",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 87,
                                                "EngagementMetricId": 99,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9910,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 225,
                                                "EngagementMetricId": 99,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 338,
                                                "EngagementMetricId": 99,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9940,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 451,
                                                "EngagementMetricId": 99,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 564,
                                                "EngagementMetricId": 99,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9600,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 100,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 50,
                                        "Metric": {
                                            "MetricId": 50,
                                            "Title": "First Contact Resolution Rate",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.8500,
                                        "Warning": 0.9000,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 88,
                                                "EngagementMetricId": 100,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.8880,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 215,
                                                "EngagementMetricId": 100,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.8860,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 328,
                                                "EngagementMetricId": 100,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.8820,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 441,
                                                "EngagementMetricId": 100,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.8480,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 554,
                                                "EngagementMetricId": 100,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.8100,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 97,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 48,
                                        "Metric": {
                                            "MetricId": 48,
                                            "Title": "Live Answer Percentage",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9900,
                                        "Warning": 0.9950,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 85,
                                                "EngagementMetricId": 97,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9950,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 216,
                                                "EngagementMetricId": 97,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9920,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 329,
                                                "EngagementMetricId": 97,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9900,
                                                "Comment": "Due to a high volume outage occuring on 7/5, that day is excluded from this stat. The client understands and acknowledged this exclusion"
                                            },
                                            {
                                                "ResultId": 442,
                                                "EngagementMetricId": 97,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9880,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 555,
                                                "EngagementMetricId": 97,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9440,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 96,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 47,
                                        "Metric": {
                                            "MetricId": 47,
                                            "Title": "Percent of Calls Answered within 60 Seconds",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 84,
                                                "EngagementMetricId": 96,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9800,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 212,
                                                "EngagementMetricId": 96,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9810,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 325,
                                                "EngagementMetricId": 96,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9750,
                                                "Comment": "Due to a high volume outage occuring on 7/5, that day is excluded from this stat. The client understands and acknowledged this exclusion"
                                            },
                                            {
                                                "ResultId": 438,
                                                "EngagementMetricId": 96,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9590,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 551,
                                                "EngagementMetricId": 96,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.8330,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 102,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 52,
                                        "Metric": {
                                            "MetricId": 52,
                                            "Title": "Proper Escalations to IS",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9900,
                                        "Warning": 0.9950,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 90,
                                                "EngagementMetricId": 102,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable"
                                            },
                                            {
                                                "ResultId": 217,
                                                "EngagementMetricId": 102,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 330,
                                                "EngagementMetricId": 102,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 443,
                                                "EngagementMetricId": 102,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 556,
                                                "EngagementMetricId": 102,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 209,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 136,
                                        "Metric": {
                                            "MetricId": 136,
                                            "Title": "SCCM Patch Compliance",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9900,
                                        "Warning": 0.9950,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 104,
                                                "EngagementMetricId": 209,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.5900,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 210,
                                                "EngagementMetricId": 209,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.0200,
                                                "Comment": "21H2 rollout began this month, SCCM compliance should increase over the next couple of months"
                                            },
                                            {
                                                "ResultId": 323,
                                                "EngagementMetricId": 209,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.3800,
                                                "Comment": "Due to the End of Life for Windows version 1909, CAI, in cooperation with Seminole, is in the process of upgrading all computers to version 21H2. The compliance stats will continue to increase to normal levels over the next few months"
                                            },
                                            {
                                                "ResultId": 436,
                                                "EngagementMetricId": 209,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.5900,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 549,
                                                "EngagementMetricId": 209,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "Due to access restrictions, we were unable to measure this for the month of September"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 103,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 53,
                                        "Metric": {
                                            "MetricId": 53,
                                            "Title": "SD -  INC/SR Resolution Priority Adherence - P1",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 91,
                                                "EngagementMetricId": 103,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 223,
                                                "EngagementMetricId": 103,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "Unmeasurable at this time"
                                            },
                                            {
                                                "ResultId": 336,
                                                "EngagementMetricId": 103,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in July"
                                            },
                                            {
                                                "ResultId": 449,
                                                "EngagementMetricId": 103,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in August"
                                            },
                                            {
                                                "ResultId": 562,
                                                "EngagementMetricId": 103,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in September"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 104,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 54,
                                        "Metric": {
                                            "MetricId": 54,
                                            "Title": "SD -  INC/SR Resolution Priority Adherence - P2",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 92,
                                                "EngagementMetricId": 104,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 226,
                                                "EngagementMetricId": 104,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 339,
                                                "EngagementMetricId": 104,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 452,
                                                "EngagementMetricId": 104,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in August"
                                            },
                                            {
                                                "ResultId": 565,
                                                "EngagementMetricId": 104,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in September"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 105,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 55,
                                        "Metric": {
                                            "MetricId": 55,
                                            "Title": "SD -  INC/SR Resolution Priority Adherence - P3",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 93,
                                                "EngagementMetricId": 105,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9410,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 220,
                                                "EngagementMetricId": 105,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9740,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 333,
                                                "EngagementMetricId": 105,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9700,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 446,
                                                "EngagementMetricId": 105,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9950,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 559,
                                                "EngagementMetricId": 105,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 106,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 56,
                                        "Metric": {
                                            "MetricId": 56,
                                            "Title": "SD -  INC/SR Resolution Priority Adherence - P5",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 94,
                                                "EngagementMetricId": 106,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9360,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 224,
                                                "EngagementMetricId": 106,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9200,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 337,
                                                "EngagementMetricId": 106,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9200,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 450,
                                                "EngagementMetricId": 106,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9110,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 563,
                                                "EngagementMetricId": 106,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9950,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 111,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 61,
                                        "Metric": {
                                            "MetricId": 61,
                                            "Title": "SD -  INC/SR Response Priority Adherence - P1",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 99,
                                                "EngagementMetricId": 111,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 211,
                                                "EngagementMetricId": 111,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 324,
                                                "EngagementMetricId": 111,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in July"
                                            },
                                            {
                                                "ResultId": 437,
                                                "EngagementMetricId": 111,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in August"
                                            },
                                            {
                                                "ResultId": 550,
                                                "EngagementMetricId": 111,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in September"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 112,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 62,
                                        "Metric": {
                                            "MetricId": 62,
                                            "Title": "SD -  INC/SR Response Priority Adherence - P2",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 100,
                                                "EngagementMetricId": 112,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 213,
                                                "EngagementMetricId": 112,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 326,
                                                "EngagementMetricId": 112,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 439,
                                                "EngagementMetricId": 112,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in August"
                                            },
                                            {
                                                "ResultId": 552,
                                                "EngagementMetricId": 112,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in September"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 113,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 63,
                                        "Metric": {
                                            "MetricId": 63,
                                            "Title": "SD -  INC/SR Response Priority Adherence - P3",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 101,
                                                "EngagementMetricId": 113,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9580,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 218,
                                                "EngagementMetricId": 113,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9870,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 331,
                                                "EngagementMetricId": 113,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9850,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 444,
                                                "EngagementMetricId": 113,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.9910,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 557,
                                                "EngagementMetricId": 113,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.9760,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 114,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 64,
                                        "Metric": {
                                            "MetricId": 64,
                                            "Title": "SD -  INC/SR Response Priority Adherence - P5",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 102,
                                                "EngagementMetricId": 114,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.9560,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 214,
                                                "EngagementMetricId": 114,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9450,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 327,
                                                "EngagementMetricId": 114,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9260,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 440,
                                                "EngagementMetricId": 114,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.8790,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 553,
                                                "EngagementMetricId": 114,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.8560,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 115,
                                        "EngagementId": 8,
                                        "Engagement": null,
                                        "MetricId": 65,
                                        "Metric": {
                                            "MetricId": 65,
                                            "Title": "SD -  INC/SR Response Priority Adherence - P6",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 103,
                                                "EngagementMetricId": 115,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 222,
                                                "EngagementMetricId": 115,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.3330,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 335,
                                                "EngagementMetricId": 115,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.8000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 448,
                                                "EngagementMetricId": 115,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 561,
                                                "EngagementMetricId": 115,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 0.8570,
                                                "Comment": ""
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "EngagementId": 30,
                                "ContractId": 8,
                                "Contract": null,
                                "Name": "Desk Side Support",
                                "EngagementMetrics": [
                                    {
                                        "EngagementMetricId": 107,
                                        "EngagementId": 30,
                                        "Engagement": null,
                                        "MetricId": 57,
                                        "Metric": {
                                            "MetricId": 57,
                                            "Title": "DS -  INC/SR Resolution Priority Adherence - P1",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 95,
                                                "EngagementMetricId": 107,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No tickets in May"
                                            },
                                            {
                                                "ResultId": 206,
                                                "EngagementMetricId": 107,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in June"
                                            },
                                            {
                                                "ResultId": 319,
                                                "EngagementMetricId": 107,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 432,
                                                "EngagementMetricId": 107,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in August"
                                            },
                                            {
                                                "ResultId": 545,
                                                "EngagementMetricId": 107,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in September"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 108,
                                        "EngagementId": 30,
                                        "Engagement": null,
                                        "MetricId": 58,
                                        "Metric": {
                                            "MetricId": 58,
                                            "Title": "DS -  INC/SR Resolution Priority Adherence - P2",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 96,
                                                "EngagementMetricId": 108,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 207,
                                                "EngagementMetricId": 108,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 320,
                                                "EngagementMetricId": 108,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Data in July"
                                            },
                                            {
                                                "ResultId": 433,
                                                "EngagementMetricId": 108,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 546,
                                                "EngagementMetricId": 108,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": null,
                                                "Comment": "No Tickets in September"
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 109,
                                        "EngagementId": 30,
                                        "Engagement": null,
                                        "MetricId": 59,
                                        "Metric": {
                                            "MetricId": 59,
                                            "Title": "DS -  INC/SR Resolution Priority Adherence - P3",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 97,
                                                "EngagementMetricId": 109,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.7730,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 208,
                                                "EngagementMetricId": 109,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 321,
                                                "EngagementMetricId": 109,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.9480,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 434,
                                                "EngagementMetricId": 109,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.8790,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 547,
                                                "EngagementMetricId": 109,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    },
                                    {
                                        "EngagementMetricId": 110,
                                        "EngagementId": 30,
                                        "Engagement": null,
                                        "MetricId": 60,
                                        "Metric": {
                                            "MetricId": 60,
                                            "Title": "DS -  INC/SR Resolution Priority Adherence - P5",
                                            "Description": null,
                                            "Formula": null,
                                            "EngagementMetrics": null
                                        },
                                        "FrequencyId": 1,
                                        "Frequency": null,
                                        "ApproachText": "N/A",
                                        "GroupTypeId": 1,
                                        "GroupType": null,
                                        "TargetTypeId": 1,
                                        "TargetType": null,
                                        "Target": 0.9500,
                                        "Warning": 0.9700,
                                        "Inverted": false,
                                        "Results": [
                                            {
                                                "ResultId": 98,
                                                "EngagementMetricId": 110,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-05-01T00:00:00",
                                                "EndDate": "2022-05-31T00:00:00",
                                                "Result1": 0.8830,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 209,
                                                "EngagementMetricId": 110,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-06-01T00:00:00",
                                                "EndDate": "2022-06-30T00:00:00",
                                                "Result1": 0.9530,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 322,
                                                "EngagementMetricId": 110,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-07-01T00:00:00",
                                                "EndDate": "2022-07-31T00:00:00",
                                                "Result1": 0.8670,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 435,
                                                "EngagementMetricId": 110,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-08-01T00:00:00",
                                                "EndDate": "2022-08-31T00:00:00",
                                                "Result1": 0.7620,
                                                "Comment": ""
                                            },
                                            {
                                                "ResultId": 548,
                                                "EngagementMetricId": 110,
                                                "EngagementMetric": null,
                                                "StartDate": "2022-09-01T00:00:00",
                                                "EndDate": "2022-09-30T00:00:00",
                                                "Result1": 1.0000,
                                                "Comment": ""
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

export default enterpriseData