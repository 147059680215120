/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, Fragment, useEffect } from "react";
import myCaiApiService from "../../services/mycai-api-v1";
import { Progress } from "../fusion";
import { Eyebrow, Heading } from "cai-fusion"
import { Client, SLACard, SLADetailDrawer} from "../engagementReporting";  

const iconSearch = require("../../images/search-icon-light.png")

const EngagementReportingApp = (props) => {
    const [clientData, setClientData] = useState([])
    const [isLoadingClientData, setIsLoadingClientData] = useState(true)

    const [clientSearch, setClientSearch] = useState('')

    const [activeEngagement, setActiveEngagement] = useState(null)
    const [activeSLA, setActiveSLA] = useState(null)
    const [engagementData, setEngagementData] = useState(null)
    const [isLoadingEngagementData, setIsLoadingEngagementData] = useState(true)

    const [currentTab, setCurrentTab] = useState('sla')

    const [activeSLADetail, setActiveSLADetail] = useState(null)

    const [isLoadingSLADetailData, setIsLoadingSLADetailData] = useState(false)

    // INITIAL LOAD,
    // and, reload if token changes (re-auth)
    // or, is Test and Impersonation changes (reload new data)
    useEffect(() => {
        if (!isLoadingClientData) setIsLoadingClientData(true)

        if (props.accessToken) {
            myCaiApiService.GetEngagementReportingClientData().then((data) => {
                setClientData(data)
                setIsLoadingClientData(false)
            });
        }
    }, [props.accessToken, props.impersonation]);

    useEffect(() => {
        if (clientData.length > 0) {
            if(activeEngagement == null) {
                setActiveEngagement(clientData[0].engagements[0].id);
            }
        }
    }, [clientData]);

    useEffect(() => {
        if (!isLoadingEngagementData) setIsLoadingEngagementData(true)

        if (props.accessToken) {
            myCaiApiService.GetEngagementReportingEngagementData(props.accessToken, props.impersonation, activeEngagement).then((data) => {
                setEngagementData(data)
                setIsLoadingEngagementData(false)
            });
        }
    }, [activeEngagement]);

    useEffect(() => {
        if(activeSLA) {
            if (!isLoadingSLADetailData) setIsLoadingSLADetailData(true)

            if (props.accessToken) {
                myCaiApiService.GetEngagementReportingSLADetailData(props.accessToken, props.impersonation, activeSLA.slaId).then((data) => {
                    setActiveSLADetail(data)
                    setIsLoadingSLADetailData(false)
                });
            }
        } else {
            setIsLoadingSLADetailData(false)
            setActiveSLADetail(null)
        }
    }, [activeSLA]);

    const handleSearchFieldChange = (e) => {
        setClientSearch(e.target.value)
    }

    return (
        <Fragment>
            <div className="o-page-section l-mt-md l-pt-none">
                <div className="o-page-section__content-container o-page-section__content-container--med-wide-fixed-width l-pt-none l-mt-neg-sm">
                    <div className="l-row l-pt-sm">
                        <div className="col-3 o-drawer o-drawer--has-button-row o-drawer--light-gray o-drawer--left-narrow o-drawer--hybrid" id="folder-drawer" style={{ backgroundColor: "#ffffff", paddingTop: "0rem" }}>
                            <div className="o-drawer__overlay"></div>
                            <div className="o-drawer__wrapper">
                                <div className="o-drawer__content">
                                    <div className="o-drawer__heading-row">
                                        <div className="o-drawer__title">
                                            <Heading size={3} className="l-mb-none">Clients</Heading>
                                            { isLoadingClientData && <Progress /> }
                                        </div>
                                    </div>
                                    <div className="o-drawer__inner">
                                        <div className="o-filter">
                                            <form className="a-search-input l-mb-xs">
                                                <input className="a-search-input__text-box" type="text" placeholder="Search for a client..." aria-label="Search" id="search_field" name="search_field" value={clientSearch} onChange={ handleSearchFieldChange } />
                                                <button className="a-search-input__button" type="submit"><img className="a-search-input__img" src={ iconSearch } alt="search button icon" /></button>
                                            </form>
                                            {
                                                clientData?.filter(client => { return client.name.toLowerCase().includes(clientSearch.toLowerCase()) || clientSearch === ''}).map((client) => 
                                                    <Client client={ client } key={ "client" + client.clientId } setEngagement={ setActiveEngagement } activeEngagement={ activeEngagement }></Client>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <Eyebrow>{ engagementData?.clientName }</Eyebrow>
                            <Heading size={2}>{ engagementData?.engagementName }</Heading>
                            <div className="m-tab-row l-mt-sm">
                                <a href="#" className={ currentTab == 'sla' ? "m-tab-row__tab m-tab-row__tab--active" : "m-tab-row__tab" } onClick={() => setCurrentTab('sla')}>
                                    <span className="m-tab-row__tab-title">Service Level Agreements</span>
                                    <span className="m-tab-row__help">Key contractual and non-contractual SLAs</span>
                                </a>
                                <a href="#" className={ currentTab == 'workshare' ? "m-tab-row__tab m-tab-row__tab--active" : "m-tab-row__tab" } onClick={() => setCurrentTab('workshare')}>
                                    <span className="m-tab-row__tab-title">Workshare Reporting</span>
                                    <span className="m-tab-row__help">Breakdown of partner commitments</span>
                                </a>
                                <a href="#" className={ currentTab == 'milestone' ? "m-tab-row__tab m-tab-row__tab--active" : "m-tab-row__tab" } onClick={() => setCurrentTab('milestone')}>
                                    <span className="m-tab-row__tab-title">Milestone Tracking</span>
                                    <span className="m-tab-row__help">Summary of key milestones</span>
                                </a>
                            </div>
                            { isLoadingEngagementData && <Progress /> }
                            { currentTab == 'sla' && <Fragment>
                            {
                                engagementData?.slas?.filter(sla => { return sla.isPassing === false }).length > 0 &&
                                <Fragment>
                                    <Heading size={3} className="l-mt-sm">Failed SLAs</Heading>
                                    <div className="o-card-grid">
                                        {
                                            engagementData?.slas.filter(sla => { return sla.isPassing === false }).map((sla) =>
                                                <SLACard sla={sla} setSLADetail={setActiveSLA} key={"sla" + sla.slaId}></SLACard>
                                            )
                                        }
                                    </div>
                                </Fragment>
                            }
                            {
                                engagementData?.slas?.filter(sla => { return sla.isPassing === true }).length > 0 &&
                                <Fragment>
                                    <Heading size={3} className="l-mt-sm">Passed SLAs</Heading>
                                    <div className="o-card-grid">
                                        {
                                            engagementData?.slas.filter(sla => { return sla.isPassing === true }).map((sla) =>
                                                <SLACard sla={sla} setSLADetail={setActiveSLA} key={"sla" + sla.slaId}></SLACard>
                                            )
                                        }
                                    </div>
                                </Fragment>
                            }
                            </Fragment>}

                            { currentTab === 'workshare' && <Fragment>
                                Workshare Reporting
                            </Fragment>
                            }
                            { currentTab === 'milestone' && <Fragment>
                                <h3 className="a-heading a-heading--heading-3 l-mt-sm">Milestones</h3>
                                <table className="o-treegrid l-mt-sm">
                                <thead>
                                <tr className="o-treegrid__row o-treegrid__row--shown">
                                    <th className="o-treegrid__cell o-treegrid__cell--header o-treegrid__cell--48"></th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header">Milestone</th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header">Status</th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header">Est Start</th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header">Est Complete</th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header">Progress</th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header"></th>
                                </tr>
                                </thead>
                                <tbody>
                                        { engagementData?.milestonePhases?.map((phase) =>
                                        <Fragment>
                                            <tr className="o-treegrid__row o-treegrid__row--shown">
                                                <td className="o-treegrid__cell o-treegrid__cell--centered"><button className="a-collapse-toggle a-collapse-toggle--expanded"><svg className="a-collapse-toggle__svg-icon a-collapse-toggle__svg-icon--medium" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="m256,512C114.84,512,0,397.16,0,256S114.84,0,256,0s256,114.84,256,256-114.84,256-256,256Zm0-480c-123.52,0-224,100.48-224,224s100.48,224,224,224,224-100.48,224-224S379.52,32,256,32Z"></path><path d="m368,272h-224c-8.83,0-16-7.17-16-16s7.17-16,16-16h224c8.83,0,16,7.17,16,16s-7.17,16-16,16Z"></path><path className="a-collapse-toggle__icon-vertical" d="m256,384c-8.83,0-16-7.17-16-16v-224c0-8.83,7.17-16,16-16s16,7.17,16,16v224c0,8.83-7.17,16-16,16Z"></path></svg></button></td>
                                                <td className="o-treegrid__cell">
                                                    <div className="m-subject-highlight">
                                                        <div className="m-subject-highlight__content">
                                                            <p className="a-bold-text m-subject-highlight__title">{ phase.phaseName }</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="o-treegrid__cell">
                                                    <div className="m-status-block">
                                                        <div className="a-status-indicator a-status-indicator--green m-status-block__indicator"></div>
                                                        <span className="m-status-block__item">{ phase.status }</span>
                                                    </div>
                                                </td>
                                                <td className="o-treegrid__cell">{ phase.startDate }</td>
                                                <td className="o-treegrid__cell">{ phase.endDate }</td>
                                                <td className="o-treegrid__cell"></td>
                                                <td className="o-treegrid__cell o-treegrid__cell--actions"></td>
                                            </tr>

                                            { phase.milestones?.map((milestone) => 
                                                <tr aria-level="2" className="o-treegrid__row o-treegrid__row--striped-bg o-treegrid__row--level-2  o-treegrid__row--shown">
                                                    <td className="o-treegrid__cell o-treegrid__cell--centered"></td>
                                                    <td className="o-treegrid__cell">
                                                        <div className="m-subject-highlight">
                                                            <div className="m-subject-highlight__content">
                                                                <p className="a-bold-text m-subject-highlight__title">{ milestone.milestoneName }</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                <td className="o-treegrid__cell">
                                                        <div className="m-status-block">
                                                            <div className="a-status-indicator a-status-indicator--blue m-status-block__indicator"></div>
                                                            <span className="m-status-block__item">{ milestone.status }</span>
                                                        </div>
                                                </td>
                                                    <td className="o-treegrid__cell">{ milestone.startDate }</td>
                                                    <td className="o-treegrid__cell">{ milestone.endDate }</td>
                                                    <td className="o-treegrid__cell">
                                                        
                                                    </td>
                                                    <td className="o-treegrid__cell o-treegrid__cell--actions"><a href="#" className="a-button a-button--outline a-button--small o-treegrid__hidden-button">Details</a></td>
                                                </tr>
                                                )}
                                        </Fragment>
                                        )}
                                </tbody>
                            </table>
                            </Fragment>
                            } 
                        </div>
                    </div>
                </div>
            </div>

            <SLADetailDrawer sla={ activeSLADetail } isLoading={ isLoadingSLADetailData } setSLAActive={ setActiveSLA }></SLADetailDrawer>
        </Fragment>
    );
};

export default EngagementReportingApp;
