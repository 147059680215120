import { createContext, useState, useContext, useEffect } from "react";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";

const SkillsContext = createContext();

function SkillsProvider({ children }) {
    // WILL EVENTUALLY USE WHEN SKILLS BECOME AN API THING
	const apiServiceClient = useSymphonyApiService("v2");

    const [skills, setSkills] = useState([
        {
            name: "Image Creation",
            skillId: "eb39760e-742e-4ea5-bf05-c62a5f1dc9b9",
            additionalTokens: 180,
            included: true,
        },
        {
            name: 'Web Browsing',
            skillId: '3c1212df-6cee-4d78-a990-c469b874ff73',
            additionalTokens: 400,
            included: true
        },
        {
            name: "Visit Url",
            skillId: "a47b70df-483b-4bb1-a6b4-5c84b4ff1a88",
            additionalTokens: 300,
            included: true,
        },
        {
            name: "Data Analysis / Code Interpreter",
            skillId: "167759e4-b26f-47c2-bef3-817dbb105752",
            additionalTokens: 600,
            included: true,
        }
        // {
        //     name: 'Math',
        //     skillId: '8019f945-2e34-4cda-801c-1361b7421adb',
        //     additionalTokens: 230,
        //     included: false
        // }
    ]);

    const [skillTokenCount, setSkillTokenCount] = useState(0);

    /*
        - Get skills
        - Enable/disable skills
    */

    // skillTokenCount calculation
    useEffect(() => {
        let total = 0;
        if (skills) {
            skills.forEach((x) => {
                if (x.included) total += x.additionalTokens;
            });
        }
        setSkillTokenCount(total);
    }, [skills])

    // NFI call for API to eventually load skills.
    const loadSkills = async () => {
        // API call to get skills
        // Set skills
        return;
    }

    // Flips the "included" state of the skill selected.
    const toggleSkill = (index) => {
        console.log("[SKILLS] " + (skills[index].included ? "Disabling" : "Enabling") + " the skill '" + skills[index].name + "'");

        // Creates a deep copy of skills, changes the state, and then sets this as the new skills state.
        const newSkills = [...skills];
        newSkills[index].included = !newSkills[index].included;
        setSkills(newSkills);
    }

    // Get a list of only the IDs of all skills that are enabled. Used mainly for sendMessage.
    const getEnabledSkillIds = () => {
        return skills.filter((x) => x.included).map((x) => x.skillId);
    }

    // Get the total token count of all enabled skills.
    const getSkillTokenCount = () => {
        let total = 0;
        skills.forEach((skill) => {
            // Use the additionalTokens field to sum all enabled skills together.
            if (skill.included) total += skill.additionalTokens;
        });
        return total;
    }

	return (
		<SkillsContext.Provider
			value={{
                // State
				skills,
                setSkills,
                skillTokenCount,
                // Function
                toggleSkill,
                getEnabledSkillIds,
                getSkillTokenCount,
			}}
		>
			{children}
		</SkillsContext.Provider>
	);
}

// Hook to use the SkillsContext in a component
function useSkills() {
	const context = useContext(SkillsContext);
	if (context === undefined) {
		throw new Error(
			"useSkills must be used within a SkillsProvider"
		);
	}
	return context;
}

export { SkillsProvider, useSkills };
