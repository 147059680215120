import { Button, ButtonRow, Heading, ToastContext, Modal } from "cai-fusion";
import { useEffect, useContext, useState } from "react";
import { useFormsApiService } from "../../hooks/useFormsApi";
import { useNavigate }  from "react-router-dom";

const docLogo = require("../../images/icon-upload-doc.svg").default;

const FormSubmitted = () => {

    const navigate = useNavigate();


    return (
        <>
            {/* Form */}
            <div className="o-page-section o-page-section l-mt-md l-pt-none">
                <div className="o-page-section__content-container o-page-section__content-container--fixed-width l-pt-none l-mt-neg-sm">
                    <div className="l-row l-pt-sm">
                        <div className="o-page-section__content-container o-page-section__content-container--extra-narrow l-pt-none l-mt-neg-sm">
                            <div className="l-row l-pt-sm">
                                <div className="col-md-12 text-center">
                                    <img src={docLogo} alt="" className="a-responsive-image l-mb-sm" style={{width:"128px"}}/>
                                    <h1 className="a-heading a-heading--heading-1">Form Submitted</h1>
                                    <p className="a-medium-text a-muted-text">Your form has submitted successfully.</p>
                                    <p className="a-medium-text a-muted-text">Governance Risk and Compliance (GRC) has received your submission. Your review request will be assigned, and the Governance Owner will be posted on the Teams channel.</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="m-button-row--centered l-mt-sm">
                                                <a href="" className="a-button a-button--link m-button-row__button" 
                                                onClick={()=>{navigate(`/forms/governance/`, { replace: true });}}>Return to Forms Home</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormSubmitted;