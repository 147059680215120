import React, { useState, useCallback, useEffect, ChangeEvent, Fragment } from "react";

export interface TimeDurationInputProps {
    value: number;
    scale: 'd' | 'h' | 'm' | 's' | 'ms';
    className?: string;
    onValueChanged(newValue: number): undefined;
};

const defaultTimeDurationInputProps = {
    scale: 's',
    onValueChanged: () => { }
};

const TimeDurationInput = (props: TimeDurationInputProps) => {
    props = { ...defaultTimeDurationInputProps, ...props };

    const [duration, setDuration] = useState(convertFromValue(props.value, props.scale));

    useEffect(() => {
        const newDuration = convertFromValue(props.value, props.scale);
        if (newDuration !== duration) setDuration(newDuration)
    }, [props.value, props.scale])

    // const onInputChange = useCallback((target: any) => {
    // const onInputChange = useCallback(({ target: HTMLInputElement }) => {
    const onInputChange = useCallback((element: ChangeEvent<HTMLInputElement>) => {
        const target = element.target;
        setDuration(target.value);
        const newValue = convertToValue(target.value, props.scale);
        if (!isNaN(newValue)) props.onValueChanged(newValue);
    }, [props.onValueChanged, props.scale]);

    return (
        <Fragment>
            <input type="number" className={`col-md-10 ${props.className}`} value={duration} onChange={onInputChange} />
            <label className="col-md-2">{props.scale}</label>
        </Fragment>
    )
}

// export const DURATION_SCALE_CONVERSIONS = {
// ms: 1,
// s: 1000,
// m: 60000,
// h: 3600000,
// d: 86400000
// }
export const DURATION_SCALE_CONVERSIONS: { [key: string]: number } = {
    ms: 1,
    s: 1000,
    m: 60000,
    h: 3600000,
    d: 86400000
};

export function convertValueFromScale(value: number, scale: string) {
    return value * (DURATION_SCALE_CONVERSIONS[scale] || 1)
}

export function convertValueToScale(value: number, scale: string) {
    return value / (DURATION_SCALE_CONVERSIONS[scale] || 1)
}

export function convertValueToDuration(value: number) {
    const milliseconds = Math.round(value % 1000)
    const seconds = Math.floor(value / 1000 % 60)
    const minutes = Math.floor(value / 60000 % 60)
    const hours = Math.floor(value / 3600000 % 24)
    const days = Math.floor(value / 86400000)
    return [
        days && `${days}d`,
        hours && `${hours}h`,
        minutes && `${minutes}m`,
        seconds && `${seconds}s`,
        (milliseconds || !value) && `${milliseconds}ms`
    ].filter(x => !!x).join(' ')
}

export function convertDurationToValue(duration: string) {
    const matches = duration.trim().match(/^(\d+d)?\s*(\d+h)?\s*(\d+m)?\s*(\d+s)?\s*(\d+ms)?$/i)
    if (!matches) return parseFloat(duration)
    const [days, hours, minutes, seconds, milliseconds] = matches.slice(1).map(x => parseInt(x) || 0)
    return (((days * 24 + hours) * 60 + minutes) * 60 + seconds) * 1000 + milliseconds
}

export const convertFromValue = (value: number, scale: 'd' | 'h' | 'm' | 's' | 'ms') => convertValueToDuration(convertValueFromScale(value, scale))

export const convertToValue = (duration: string, scale: 'd' | 'h' | 'm' | 's' | 'ms') => convertValueToScale(convertDurationToValue(duration), scale)

export default TimeDurationInput;