import { useState, useRef } from "react";
import {useFloating, useInteractions, useHover, FloatingArrow, arrow } from '@floating-ui/react'

const SimpleToolTip = ({ refElement, text, placement , delay}) => {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);
    const { refs, floatingStyles, context,  } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: placement ?? "bottom",
        middleware: [
            arrow({
                element: arrowRef,
            })
        ]
    });

    const hover = useHover(context, {
        delay: delay ?? 250
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
    ]);

    return (
        <>
            <div ref={refs.setReference} {...getReferenceProps()}>
                {refElement !== undefined ? refElement : <></>}
            </div>
            {isOpen && (
                <div className="a-tooltip"
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                >
                    <FloatingArrow className="a-tooltip__arrow" ref={arrowRef} context={context} />
                    <span className="a-tooltip__content">{text}</span>
                </div>
            )}
        </>
    );
}; export default SimpleToolTip