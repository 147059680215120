import React, { useState, Fragment, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    NavLink,
} from "react-router-dom";
import myCaiApiService from "../../services/mycai-api-v1";
import { callMsGraphUsers } from "../../services/graph";
import { forEachChild } from "typescript";
import { useNavigate } from "react-router-dom";
// import PayrollNewPositionPanel from "../payroll/PayrollNewPositionPanel";

const imgAvatarGroup = require("../../images/avatar-group.png");
const annualizedHours = 1880;

// Create our number formatter.
var currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

var percentFormatter = new Intl.NumberFormat("en-US", {
    stle: "percent",

    minimumFractionDigits: 1,
    maximumFractionDigits: 2
});

const ChangeGroupRow = (props) => {


    //Some of these are not-needed at this time. (To-do Cleanup)
    const level = props.level
    const [id, changeId] = useState(props.id)
    const [changeGroupKey, changeChangeGroupKey] = useState(props.changeGroupKey)
    const visible = props.visible
    const extended = props.extended
    const [orgName, changeOrgName] = useState(props.orgName)
    const [orgOwner, changeOrgOwner] = useState(props.orgOwner)
    const [currentBaseComp, changeCurrentBaseComp] = useState(props.currentBaseComp)
    const [increasePercent, changeIncreasePercent] = useState(props.increasePercent)
    const [increaseAmt, changeIncreaseAmt] = useState(props.increaseAmt)
    const [proposedBaseComp, changeProposedBaseComp] = useState(props.proposedBaseComp)
    const [error, changeError] = useState(props.error)
    const [hasChildren, changeHasChildren] = useState(props.hasChildren)
    const [assignee, changeAssignee] = useState("")
    const [upn, setUpn] = useState(props.upn)

    const navigate = useNavigate();

    const obj = props.obj

    function handleDetailsClicked() {
        navigate(`${id}`); // if relative linking works, otherwise    navigate(`/payroll/${changeGroupId}`);
    }

    useEffect(() => {
        changeId(obj.id)
        changeChangeGroupKey(obj.changeGroupKey)
        changeOrgName(obj.orgName)
        changeOrgOwner(obj.orgOwner)
        changeCurrentBaseComp(obj.currentBaseComp)
        changeIncreasePercent(obj.increasePercent)
        changeIncreaseAmt(obj.increaseAmt)
        changeProposedBaseComp(obj.proposedBaseComp)
        changeError(obj.error)
        changeHasChildren(obj.hasChildren)
        changeAssignee(obj.assignee)
        setUpn(props.upn)
    })

    return (
        <Fragment>
            <tr aria-level={`${level}`} className={`o-treegrid__row o-treegrid__row--level-${level}  o-treegrid__row--${visible ? "shown" : "hidden"}`}>
                <td className="o-treegrid__cell o-treegrid__cell--32">
                    {hasChildren
                        ? <button className={`a-collapse-toggle ${extended ? " a-collapse-toggle--expanded" : ""}`}
                            onClick={() => { props.onExtend(id); }
                            }>
                            <svg className="a-collapse-toggle__svg-icon a-collapse-toggle__svg-icon--large" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="m256,512C114.84,512,0,397.16,0,256S114.84,0,256,0s256,114.84,256,256-114.84,256-256,256Zm0-480c-123.52,0-224,100.48-224,224s100.48,224,224,224,224-100.48,224-224S379.52,32,256,32Z" /><path d="m368,272h-224c-8.83,0-16-7.17-16-16s7.17-16,16-16h224c8.83,0,16,7.17,16,16s-7.17,16-16,16Z" /><path className="a-collapse-toggle__icon-vertical" d="m256,384c-8.83,0-16-7.17-16-16v-224c0-8.83,7.17-16,16-16s16,7.17,16,16v224c0,8.83-7.17,16-16,16Z" /></svg></button>
                        : ""
                    }
                </td>
                {/* Organization Cell */}
                <td className="o-treegrid__cell">
                    <div className="m-subject-highlight">
                        <div className="a-avatar m-subject-highlight__avatar"><img src={imgAvatarGroup} alt="" className="a-avatar__img" /></div>
                        <div className="m-subject-highlight__content">
                            <p className="a-bold-text m-subject-highlight__title">{orgName}</p>
                            <p className="a-muted-text m-subject-highlight__subtitle">{orgOwner}</p>
                            <p className="a-accent-text m-subject-highlight__meta">{changeGroupKey}</p>
                        </div>
                    </div>
                </td>
                {/* Current Base Comp Cell */}
                <td className="o-treegrid__cell">{currencyFormatter.format(currentBaseComp)}</td>
                {
                    // /* This logic maps either the warning or all of the compChange Data */
                    // error == null ?
                    //     <>
                    //         <td className="o-treegrid__cell"><strong>{percentFormatter.format(Math.round(increasePercent * 10000) / 100)}%</strong></td>
                    //         <td className="o-treegrid__cell">{currencyFormatter.format(increaseAmt)}</td>
                    //         <td className="o-treegrid__cell">{currencyFormatter.format(proposedBaseComp)}</td>
                    //     </>
                    //     :
                    //     <>
                    //         <td className="o-treegrid__cell" colSpan="3"><strong><span className={upn?.localeCompare(assignee, 'en-us', { sensitivity: 'base' }) ? "" : `${"a-warning-text"}`}>{error}</span></strong></td>
                    //     </>
                }
                <td className="o-treegrid__cell"><strong>{percentFormatter.format(Math.round(increasePercent * 10000) / 100)}%</strong></td>
                <td className="o-treegrid__cell">{currencyFormatter.format(increaseAmt)}</td>
                <td className="o-treegrid__cell">{currencyFormatter.format(proposedBaseComp)}</td>

                {/* The Details Button */}
                <td className="o-treegrid__cell o-treegrid__cell--actions">
                    <a href="#" className="a-button a-button--outline a-button--small o-treegrid__hidden-button" onClick={(e) => { handleDetailsClicked(); e.preventDefault(); }/* Todo add on-click logic */}>Details</a>
                </td>
            </tr>
        </Fragment>
    );
};

export default ChangeGroupRow;