import React, { useEffect, useState } from "react";

export interface InputProps {
    type: string;
    className?: string;
    value: string | ReadonlyArray<string> | number | undefined;
    required?: boolean | undefined;
    onValueChanged(newValue: string | ReadonlyArray<string> | number | undefined): undefined;
};

const defaultInputProps = {
    type: "text",
    className: "",
    required: false,
};

const Input = (props: InputProps) => {
    props = { ...defaultInputProps, ...props };

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        if (props.onValueChanged) 
            props.onValueChanged(value)
    }, [value])

    return (
        <input type={props.type} className={`a-text-input ${props.className}`} value={value} onChange={() => {}} />
    )
}

export default Input;