import React from "react";

const NavbarDropdown = (props) => {
    return (
        <li className="o-navbar__dropdown-list o-navbar__dropdown-list--right-align">
            {props.children}
        </li>
    )
}

export default NavbarDropdown;