import React, { useEffect, useState } from "react";
import Input from "./Input";

export interface EditableFormGroupProps {
    children?: React.ReactNode;
    isEditing?: boolean;
    label?: string;
}

const defaultEditableFormGroupProps = {
    isEditing: false,
    label: ""
}

const EditableFormGroup = (props: EditableFormGroupProps) => {
    props = { ...defaultEditableFormGroupProps, ...props };

    const [isEditing, setIsEditing] = useState(props.isEditing);

    useEffect(() => {
        if (setIsEditing)
            setIsEditing(props.isEditing);
    }, [props.isEditing])

    return (
        <div className="m-form-group">
            <label htmlFor="" className="a-label">{props.label}</label>
            {isEditing ?
                <input type="text" className="a-text-input" value="70,500" onChange={() => { }} />
                // <Input />
                :
                <p className="a-read-only-value">Operating</p>
            }
        </div>
    )
}

export default EditableFormGroup;