import React, { Fragment } from "react"

const Sidebar = props => {
  return (
    <Fragment>
      <div className="o-sidebar">{props.children}</div>
    </Fragment>
  )
}

export default Sidebar
