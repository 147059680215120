import { IFormsApiConfig } from "../../configs/IFormsApiConfig";
import { MyCAIApiService } from "../MyCAIApiService";

export class FormsApiService extends MyCAIApiService {
	public Forms: FormsService;


	constructor(config: IFormsApiConfig) {
		super(config);

		this.Forms = new FormsService(this);
		
	}
}

class FormsService {
	private apiService: FormsApiService;

	constructor(apiService: FormsApiService) {
		this.apiService = apiService;
	}

	public async getOpportunity(oppId: string): Promise<any> {
		const endpoint = `/Forms/${
			this.apiService.getConfig().apiVersion
		}/Opportunity?OppId=${oppId}`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async getOpportunityTypes(): Promise<any> {
		const endpoint = `/Forms/${
			this.apiService.getConfig().apiVersion
		}/Opportunity/types`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
	}

	public async submitOpportunity(obj: any): Promise<any> {
		const endpoint = `/Forms/${
			this.apiService.getConfig().apiVersion
		}/Opportunity`;
		return this.apiService.sendRequest({
			method: "post",
			endpoint,
			data: obj,
		});
	}
}

