import React from "react";
import { Heading } from "../Heading";
import { HeadingRow, HeadingRowActions, HeadingRowTitle } from "../HeadingRow";

const iconCloseSquare = require("../../../images/close-square.svg").default;

export interface DrawerProps {
  id?: string;
  side?: "right" | "left";
  size?: "full" | "narrow";
  heading?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
}

const defaultDrawerProps = {
  id: undefined,
  side: undefined,
  size: undefined,

  className: undefined,
};

const Drawer = (props: DrawerProps) => {
  props = { ...defaultDrawerProps, ...props };
  props.side = props.side ?? "right";
  props.size = props.size ?? "full";

  // o-drawer--has-button-row
  let classes =
    "o-drawer" +
    (props.side === "left"
      ? props.size === "narrow"
        ? " o-drawer--left-narrow"
        : " o-drawer--left"
      : props.size === "narrow"
      ? " o-drawer--narrow"
      : "") +
    (props.actions ? " o-drawer--has-button-row" : "");

  if (props.className && props.className.length > 0)
    classes = `${classes} ${props.className}`;
  return (
    <div className={classes} id={props.id ?? ""}>
      <div className="o-drawer__overlay"></div>
      <div className="o-drawer__wrapper">
        <HeadingRow>
          <HeadingRowTitle>
            <Heading size={3} className="l-mb-none">Employee Filter</Heading>
          </HeadingRowTitle>
          <HeadingRowActions>
            <div className="m-icon-row m-icon-row--right-align">
              <a
                href="#"
                id="drawerCloseBtn"
                className="a-icon m-icon-row__icon"
                title=""
              >
                <img src={iconCloseSquare} className="a-icon__img" />
              </a>
            </div>
          </HeadingRowActions>
        </HeadingRow>
        <div className="o-drawer__inner"></div>
        {props.actions && (
          <div className="o-drawer__fixed-btn-row">
            <div className="m-button-row">{props.actions}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Drawer;
