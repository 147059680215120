import React from "react";

const FormGroup = props => {
    return (
        <div className={`m-form-group ${props.className}`}>
            {props.children}
        </div>
    )
}

export default FormGroup;