import { useEffect, useRef } from "react";
import * as d3 from "d3";
import BarChartModel from "../../models/BarChartModel"

export interface BarChartProps {
    data: BarChartModel
}

const BarChart = (props: BarChartProps) => {
    const svgRef = useRef(null);
    
    const getColor = (value:number, goal:number, goalDirection:number) => {
        if(goalDirection > 0) {
            return value > goal ? '#078547' : '#E5443C'
        } else {
            return value < goal ? '#078547' : '#E5443C'
        }
    }

    useEffect(() => {
        let data = props.data.months;

        if(data == undefined) return;

        let max = data.reduce(function(prev, current) {
            return (prev.value > current.value) ? prev : current
        })

        // Set the dimensions and margins of the graph
        let margin = { top: 20, right: 20, bottom: 70, left: 60 }, //increased left margin
            width = 960 - margin.left - margin.right,
            height = 300 - margin.top - margin.bottom;

        // Append the svg object to the body of the page
        let svg = d3.select(svgRef.current)
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // clear all previous content on refresh
        const everything = svg.selectAll("*");
        everything.remove();

        let x = d3.scaleBand()
            .range([0, width])
            .domain(data.map(function (d) { return d.label; }))
            .padding(0.2);

        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x))
            .selectAll("text")
            .attr("transform", "translate(-10,0)rotate(-45)")
            .style("text-anchor", "end")
            .style("font-size", "20px")
            .style("font-weight", "bold");

        let y = d3.scaleLinear()
            .domain([0, max.value])
            .range([height, 0]);

        svg.append("g")
            .call(d3.axisLeft(y).ticks(5).tickFormat(d => `${d}`))
            .selectAll("text")
            .style("font-size", "20px")
            .style("font-weight", "bold");

        svg.selectAll("mybar")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", function (d) { return x(d.label) || 100; })
            .attr("y", function (d) { return y(d.value); })
            .attr("width", x.bandwidth())
            .attr("height", function (d) { return height - y(d.value); })
            .attr("fill", function (d) { return getColor(d.value, props.data.goal, props.data.goalDirection) });

        // Add the SLA Line
        svg.append('line')
            .attr('x1', 0)
            .attr('y1', y(props.data.goal)) // SLA line at goal
            .attr('x2', width)
            .attr('y2', y(props.data.goal))
            .attr('stroke-width', 2)
            .attr('stroke', '#707070')
            .attr('stroke-dasharray', '5,5');

    }, [props.data, svgRef.current]); // redraw chart if data changes

    return <div id="chart-area" style={{ width: "100%", height: "400px" }}>
        <svg ref={svgRef} />
    </div>;
};

export default BarChart;