import React, { useState, useEffect } from "react";

// Sample data structure for tips
const tipGroups = {
  TipsAndTricks: [
    {
      grouping: "Symphony Tips and Tricks",
      heading: "Creating with Care: Understanding Copyright & Plagiarism",
      text: "Imagine Symphony as a collaborative partner, brimming with ideas drawn from an expansive array of sources. While its ability to generate content is remarkable, it may occasionally produce material reminiscent of existing works. Take a moment to verify your content's originality. Tools like Copyscape or Grammarly's Plagiarism Checker can help you ensure everything is on the up-and-up. It's all about creating with peace of mind!"
    }
  ],
  ProTips: [
    {
      grouping: "Pro Tip",
      heading: "Crafting the Perfect Question",
      text: "Be Specific and Direct: To get the most accurate answer, phrase your question with as much detail as possible. Instead of asking \"How do I fix my computer?\", try \"How do I troubleshoot a connection issue on a Windows 10 laptop?\"",
    },
    {
      grouping: "Pro Tip",
      heading: "Iterative Inquiry",
      text: "Refine and Repeat. If the first answer isn't quite right, try breaking your question down into smaller, more detailed follow-ups. This can lead to clearer answers and better understanding."
    },
    {
      grouping: "Pro Tip",
      heading: "Focus with Keywords",
      text: "If you know what you want to do, identify the most important words related to your query and use them prominently. For instance, use \"Python list comprehension syntax\" instead of \"How do I shorten this code?\""
    },
    {
      grouping: "Pro Tip",
      heading: "Context Counts",
      text: "Provide Background Information. When asking for help, include any relevant context. Mention if you're working on a specific project, the tools you're using, or any constraints you're under."
    },
    {
      grouping: "Pro Tip",
      heading: "Attach Documents When Needed",
      text: "If your query involves a document, make sure to upload it. Symphony can analyze the content and provide more targeted help. Remember to check the data sources drawer if you're having trouble locating your uploaded file.",
    },
    {
      grouping: "Pro Tip",
      heading: "Data Privacy Practices",
      text: "Keep in mind that your interactions with Symphony are private. Your data remains within the network and isn't used to retrain the model, so you can discuss sensitive topics without worry."
    },
    {
      grouping: "Pro Tip",
      heading: "Feedback for Future Fixes",
      text: "If something doesn't seem right, use the \"Report an Issue\" button. Your input helps identify and fix problems, ensuring a better experience for everyone."
    },
    {
      grouping: "Pro Tip",
      heading: "Stay Curious and Updated",
      text: "As you use Symphony, you'll learn new ways to interact with the AI. Keep an eye on updates and experiment with different approaches to get the most out of your experience."
    },
    {
      grouping: "Pro Tip",
      heading: "Regenerate for a Refresh!",
      text: "Feeling like the answer missed the mark? No problem! Just click on the \"Regenerate Message\" icon to give Symphony another shot at providing the response you're looking for. It's like asking your question again without having to retype it!"
    },
    {
      grouping: "Pro Tip",
      heading: "Quick Edits, Better Answers!",
      text: "Oops, did you realize you didn't ask exactly what you meant? No worries! Just tap the edit icon to tweak your message. Once you've made your changes, hit send, and Symphony will spring into action with a new response."
    }
  ],
  FunFacts: [
    // {
    //   grouping: "Fun Fact",
    //   heading: "Symphony has read over 2000 documents",
    //   text: "The platform ingested a staggering 37,642,686 words from these documents. For reference, it would take a human approximately 345 consecutive 8-hour work days to read that many documents",
    // },
    // {
    //   grouping: "Fun Fact",
    //   heading: "Over 46,000 messages have been sent on the platform!",
    //   text: "Including over 20,000 lines of code.",
    // },
    // {
    //   grouping: "Fun Fact",
    //   heading: "Peak Usage Time is 3pm EST",
    //   text: "Guess we all need a little help over the afternoon slump.",
    // },
    {
      grouping: "Fun Fact",
      heading: "Symphony generated most of these tips!",
      text: "That's right! Our AI has been hard at work crafting helpful hints to make your experience smoother and more efficient."
    }
  ],
//   CorporateNews: [
//     {
//       grouping: "CAI News",
//       heading: "Inspiring Futures",
//       text: "CAI and SHE for Society Open Empowerment Hub in Bengaluru",
//       seeMore:
//         "https://cait.cai.io/news/empowering-futures-cai-and-she-for-society-open-empowerment-hub-in-bengaluru?utm_source=symphony_app&utm_medium=myCAI&utm_campaign=symphony_news_alpha&utm_content=inspiring_futures",
//     },
//     {
//       grouping: "CAI News",
//       heading: "Exciting News",
//       text: "CAI Learning Academy's Expansion into a New Facility at Cedar Crest College",
//       seeMore:
//         "https://cait.cai.io/news/exciting-news-cai-learning-academys-expansion-into-a-new-facility-at-cedar-crest-college?utm_source=symphony_app&utm_medium=myCAI&utm_campaign=symphony_news_alpha&utm_content=learning_academy",
//     },
//     {
//       grouping: "CAI News",
//       heading:
//         "CAI Earns Newsweek's America's Greatest Workplaces for Diversity",
//       text: "Each of you plays a critical role in shaping our culture. Thank you for making CAI a wonderful place to work.",
//       seeMore:
//         "https://cait.cai.io/news/cai-earns-newsweeks-americas-greatest-workplaces-for-diversity?utm_source=symphony_app&utm_medium=myCAI&utm_campaign=symphony_news_alpha&utm_content=newsweek_diversity_award",
//     },
//     {
//       grouping: "CAI News",
//       heading: "Carebridge Power Hour - EAP",
//       text: "Join us on Thursday, March 28th at 12 PM ET for a Carebridge Power Hour as we discuss stress management and free resources available to you through our Employee Assistance Program (EAP) by Carebridge.",
//       seeMore:
//         "https://cait.cai.io/calendar/all-users/carebridge-power-hour-eap?utm_source=symphony_app&utm_medium=myCAI&utm_campaign=symphony_news_alpha&utm_content=carebridge_eap_power_hour",
//     },
//   ],
  NewInSymphony: [
    // {
    //   grouping: "New in Symphony",
    //   heading: "Did you know Symphony can generate images?",
    //   text: "Open up the Modify Settings panel, and turn on 'Image Creation' under the Assistant Skills heading. Then, tell Symphony what type of image you want it to create!",
    // },
    {
      grouping: "New in Symphony",
      heading: "Let Symphony interpret your pictures!",
      text: "You can now upload images just like documents and Symphony will answer questions about them, including business process and technical architecture diagrams.",
    },
    {
      "grouping": "New in Symphony",
      "heading": "Shared Chat is here!",
      "text": "Experience our latest feature - Shared Chat! Users can now share their chat process with others, who can join in a read-only mode and follow the conversation as it unfolds in real time. Click the triple dots above the input box to share your chats."
    }
  ],
  // ComingSoonToSymphony: [{
  //   grouping: "Coming Soon to Symphony",
  //   heading: "Shared Chat!",
  //   text: "Looking ahead, our next release will include the much-anticipated Shared Chat functionality! This feature will allow users to share their chat process with others, who can follow along in a read-only capacity as the conversation progresses.",
  // }],
};

const LoadingTips = ({ isLoading, onTipAction }) => {
  const [currentTip, setCurrentTip] = useState(null);
  const [isTipVisible, setIsTipVisible] = useState(true);

  const getRandomTip = () => {
    // Get random group
    const groupNames = Object.keys(tipGroups);
    const randomGroupName =
      groupNames[Math.floor(Math.random() * groupNames.length)];
    const randomGroup = tipGroups[randomGroupName];

    // Get random tip from group
    const randomTip =
      randomGroup[Math.floor(Math.random() * randomGroup.length)];
    return randomTip;
  };

  const changeTip = () => {
    const newTip = getRandomTip();
    setCurrentTip(newTip);
    setIsTipVisible(true);
  };

  useEffect(() => {
    let interval;

    if (isLoading) {
      changeTip(); // Initialize with a random tip
      interval = setInterval(changeTip, 60000); // Change tip every few seconds
    } else {
      setCurrentTip(null); // Clear the tip when loading is done
    }

    return () => {
      clearInterval(interval); // Clear interval when the component is unmounted or isLoading changes
    };
  }, [isLoading]);

  const handleHide = () => {
    setIsTipVisible(false);
    onTipAction("hide", currentTip);
  };

  const handleSeeMore = () => {
    onTipAction("seeMore", currentTip);
    // Assuming seeMore contains a link, we can handle it differently if needed.
  };

  if (!isTipVisible) {
    return null;
  }

  return (
    <>
      {currentTip && (
        <div className="o-block o-block--dashed o-block--compact">
          <p className="a-eyebrow">{currentTip.grouping}</p>
          <h4 className="a-heading a-heading--heading-5 l-mt-xs l-mb-none">
            {currentTip.heading}
          </h4>
          <p className="a-muted-text a-subtle-text">{currentTip.text}</p>
          <div className="m-button-row m-button-row--left-aligned">
            {currentTip.seeMore && (
              <a
                href={currentTip.seeMore}
                target="_blank"
                data-modal-target="tipModal"
                className="a-button a-button--small a-button--outline m-button-row__button"
              >
                Learn more
              </a>
            )}
            <button
              className="a-button a-button--small a-button--link m-button-row__button"
              onClick={handleHide}
            >
              Hide
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingTips;
