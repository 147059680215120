import React, { Fragment } from "react";

export const MeritChangeTableHeaders = (props) => {
  return (
    <Fragment>
      <colgroup className="o-treegrid__colgroup">
        <col className="o-treegrid__col o-treegrid__col--48" span="1" />
        <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
        <col className="o-treegrid__col o-treegrid__col--small" span="1" />
        <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
        <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
        <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
        <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
        <col className="o-treegrid__col o-treegrid__col--small" span="1" />
      </colgroup>
      <thead>
        <tr className="o-treegrid__row o-treegrid__row--shown">
          <th className="o-treegrid__cell o-treegrid__cell--header o-treegrid__cell--centered">
            {props.children}
          </th>
          <th className="o-treegrid__cell o-treegrid__cell--header">Employee</th>
          <th className="o-treegrid__cell o-treegrid__cell--header">Perf Score</th>
          <th className="o-treegrid__cell o-treegrid__cell--header">Current Comp</th>
          <th className="o-treegrid__cell o-treegrid__cell--header">Base Comp Increase %</th>
          <th className="o-treegrid__cell o-treegrid__cell--header">Base Comp Increase Amount</th>
          <th className="o-treegrid__cell o-treegrid__cell--header">Proposed Comp</th>

          <th className="o-treegrid__cell o-treegrid__cell--header"></th>
        </tr>
      </thead>
    </Fragment>
  );
};
