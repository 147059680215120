import { useState, useRef } from "react";
import { Button, Heading } from "cai-fusion";

import { useTemplate } from "../contexts/TemplateContext";

const JobDescription = () => {

	const [jobTitle, setJobTitle] = useState("");
	const [responsibilities, setResponsibilities] = useState("");
	const [requirements, setRequirements] = useState("");
	const [location, setLocation] = useState("");

	const textWidthElement = useRef(null);

    const { executeTemplate } = useTemplate();

	const handleInputChange = (e, setInput) => {
		setInput(e.target.value);
		textWidthElement.current.textContent = e.target.value;
		const newWidth = textWidthElement.current.getBoundingClientRect().width + 10;
		e.target.style.width = newWidth + "px";
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const string = `As a technical recruiter, draft a job description for a ${jobTitle}. Some of the responsibilities will include ${responsibilities}. The requirements for this job include ${requirements}. The job is located in ${location}.`;
        executeTemplate(null, [string]);
	};

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin creating this job description, just fill in the blanks
				below and hit that Send Message button!
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<p className="o-nl-form__text">
					"As a technical recruiter, draft a job description for a
					<input
						type="text"
						placeholder="Job Title"
						className="o-nl-form__text-input"
						value={jobTitle}
						onChange={(e) => handleInputChange(e, setJobTitle)}
					/>
					. Some of the responsibilities will include
					<input
						type="text"
						placeholder="writing some code"
						className="o-nl-form__text-input"
						value={responsibilities}
						onChange={(e) => handleInputChange(e, setResponsibilities)}
					/>
					. The requirements for this job include
					<input
						type="text"
						placeholder="e.g. 10 years of experience"
						className="o-nl-form__text-input"
						value={requirements}
						onChange={(e) => handleInputChange(e, setRequirements)}
					/>
					. The job is located in
					<input
						type="text"
						placeholder="e.g. Allentown, PA"
						className="o-nl-form__text-input"
						value={location}
						onChange={(e) => handleInputChange(e, setLocation)}
					/>
					."
				</p>
				<div className="m-button-row">
					<Button onClick={handleSubmit} disabled={!location.trim()}>
						Send Message
					</Button>
				</div>
			</form>
			<span id="text-width" ref={textWidthElement}>
				And another text
			</span>
		</>
	);
};

export default JobDescription;