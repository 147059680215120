import React, { useState, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  Routes,
} from "react-router-dom";
import {
  Badge,
  Button,
  Eyebrow,
  Form,
  FormGroup,
  Heading,
  HeadingRow,
  HeadingRowActions,
  HeadingRowTitle,
  Option,
  Progress,
  Select,
} from "../fusion";
import axios from "axios";
import observabilityIntentService from "../../services/observability-service-v1";
import MarkdownRenderer from "../fusion/MarkdownRenderer";
import SymphonyBanner from "./symphonyBanner";
const icon_CloseSquare = require("../../images/close-square.svg").default;

const exampleData = {
  freeform: {
    name: "Freeform",
    input: "",
    placeholder: "Put your content here",
    hint: "The Freeform example is not tuned for any specific task and allows you to play around with prompt engineering. You can type any text or question and the AI will generate a response based on your input. This is an open-ended mode, so you can ask or write anything.",
    prompt: [],
  },
  summarize: {
    name: "Summarize Text",
    input: `At CAI, we’re people who help people\nAs a privately held company with a global footprint, our 8,500+ associates have the freedom and the focus to do what’s right for our clients, colleagues, and communities. We have a 40-year history of combining our dual strengths of talent and technology to deliver lasting results across the public and commercial sectors. And for nearly a decade, we’ve been national trailblazers in bringing neurodiversity to the enterprise. Let’s explore how we can power the possible—for you. We’re driven to make life better for our clients, our colleagues, and our communities. An increasingly virtual world could make interactions cold and soulless, but we always remember that there’s a person on the other side. People, working together to help other people, is how we make a difference. We believe that tools and technologies are most effective when they augment the ingenuity, expertise, insight, and creativity of the human beings using them. People are our lifeblood; modern technologies are our catalyst. When we combine the two, we can achieve anything. We work for a future where every person can live at the peak of possible. Human capability is almost limitless—and everyone has immense potential. We work to help people get the resources they need to convert that potential to the actual. And when we achieve that, when we transform even a small amount of potential to actual? That’s progress.`,
    placeholder: "Put the text to summarize here.",
    hint: "For this example, paste a longer piece of text into the input area. The AI will generate a concise summary of the provided text, highlighting the most important points.",
    prompt: [],
  },
  extract: {
    name: "Extract Data",
    input:
      "Hello. My name is Robert Smith. I’m calling from Contoso Insurance, Delaware. My colleague mentioned that you are interested in learning about our comprehensive benefits policy. Could you give me a call back at (555) 346-9322 when you get a chance so we can go over the benefits?",
    parameters: {
      types: [
        "Person Name",
        "Company Name",
        "Location",
        "Phone Number",
        "Purpose of Call",
      ],
    },
    placeholder: "Put the content you want to extract data from here.",
    hint: "In this example, paste the source text into the input area and specify the types of data you want to extract in the Parameters section. The AI will attempt to extract the requested information from the provided unstructured text.",
    prompt: [],
  },
  qanda: {
    name: "Q and A",
    input: `A neutron star is the collapsed core of a massive supergiant star, which had a total mass of between 10 and 25 solar masses, possibly more if the star was especially metal-rich. Neutron stars are the smallest and densest stellar objects, excluding black holes and hypothetical white holes, quark stars, and strange stars. Neutron stars have a radius on the order of 10 kilometres (6.2 mi) and a mass of about 1.4 solar masses. They result from the supernova explosion of a massive star, combined with gravitational collapse, that compresses the core past white dwarf star density to that of atomic nuclei.`,
    parameters: {
      questions: [
        "How are neutron stars created?",
        "What is the densest stellar object?",
      ],
    },
    placeholder: "Put the content you want to ask questions about here.",
    hint: "Place your source text in the input area and then add your questions in the Parameters section. The AI will generate answers to your questions based on the provided source text.",
    prompt: [],
  },
  summarizeInteraction: {
    name: "Summarize Service Desk Interaction",
    input: `User: Hi there, I’m off between August 25 and September 11. I saved up 4000 for a nice trip. If I flew out from San Francisco, what are your suggestions for where I can go?\nAgent: For that budget you could travel to cities in the US, Mexico, Brazil, Italy or Japan. Any preferences?\nUser: Excellent, I’ve always wanted to see Japan. What kind of hotel can I expect?\nAgent: Great, let me check what I have. First, can I just confirm with you that this is a trip for one adult?\nUser: Yes it is\nAgent: Great, thank you, In that case I can offer you 15 days at HOTEL Sugoi, a 3 star hotel close to a Palace. You would be staying there between August 25th and September 7th. They offer free wifi and have an excellent guest rating of 8.49/10. The entire package costs 2024.25USD. Should I book this for you?\nUser: That sounds really good actually. Please book me at Sugoi.\nAgent: I can do that for you! Can I help you with anything else today?\nUser: No, thanks! Please just send me the itinerary to my email soon.`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  writeEmail: {
    name: "Write an Email",
    input: `Write a professional but familiar email announcing the launch of a new GPT AI service. The target audience is semi-technical and the tone should be exciting.\nPlease ensure to include the following:\n-How generative text applies to the entire business landscape\n-Interested parties should reach out to the enterprise technology team to get started`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  generateJobDesc: {
    name: "Generate a Job Description",
    input: `Write a job description for the following job title: 'Business Intelligence Analyst'. The job description should outline the main responsibilities of the role, list the required qualifications, highlight unique benefits like flexible working hours and remote work policy, and provide information on how to apply.`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  generateInsights: {
    name: "Generate Insights",
    input: `Over the last five years, our company has tracked the sales performance of our five products: Product A, Product B, Product C, Product D, and Product E. Our analysis shows that Product A has consistently been the top-selling product, with an average of 10,000 units sold per year, generating $2 million in annual revenue and a profit margin of 15%. However, the profit margin for Product A has decreased over the past two years, indicating a need for re-evaluation of our pricing strategy. Product B and Product C have also shown steady sales and profitability, with an average of 7,500 units sold per year, generating $1.5 million in annual revenue, and a consistent profit margin of 20%. Product D has seen a slight decline in sales over the past two years, with an average of 5,000 units sold per year, generating $1 million in annual revenue, and a profit margin of 10%. Our analysis suggests that we may need to adjust our marketing or product development strategies to improve its performance. Product E has shown the lowest sales and profitability among the five products, with an average of 2,000 units sold per year, generating $400,000 in annual revenue, and a profit margin of 5%. Further investigation and potential changes to our product offering or marketing approach are necessary to improve the performance of Product E. Overall, our sales performance analysis suggests that we should focus on maintaining the profitability of our top-selling products while exploring ways to improve the performance of our less successful products.

Compare the performance of each product and determine which one is the most profitable.`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  validateText: {
    name: "Validate Some Text",
    input: `Please review the following text for any inconsistencies or falsehoods, and provide detailed explanations for any issues that you identify? Here's the text:

Christopher Columbus, the well-known Spanish explorer, famously sailed across the Atlantic Ocean in 1402. He discovered America, marking the first time any European had ever set foot in the New World. Aboard his ship, the Mayflower, Columbus and his crew faced many challenges including turbulent seas, starvation, and disease. Yet they persevered, reaching the shores of what is now New York City. Columbus' voyage opened the doors for mass European exploration and the eventual conquest and colonization of the Americas, forever changing the course of human history.`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  extractKeyPoints: {
    name: "Summarize Key Points from Report",
    input: `Below is an extract from the annual financial report of a company. Extract key financial number (if present), key internal risk factors, and key external risk factors.

# Start of Report
Revenue increased $7.5 billion or 16%. Commercial products and cloud services revenue increased $4.0 billion or 13%. O365 Commercial revenue grew 22% driven by seat growth of 17% and higher revenue per user. Office Consumer products and cloud services revenue increased $474 million or 10% driven by Consumer subscription revenue, on a strong prior year comparable that benefited from transactional strength in Japan. Gross margin increased $6.5 billion or 18% driven by the change in estimated useful lives of our server and network equipment. 
Our competitors range in size from diversified global companies with significant research and development resources to small, specialized firms whose narrower product lines may let them be more effective in deploying technical, marketing, and financial resources. Barriers to entry in many of our businesses are low and many of the areas in which we compete evolve rapidly with changing and disruptive technologies, shifting user needs, and frequent introductions of new products and services. Our ability to remain competitive depends on our success in making innovative products, devices, and services that appeal to businesses and consumers.
# End of Report`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  createQuiz: {
    name: "Create a Quiz",
    input: `Generate a multiple choice quiz from the text below. Quiz should contain at least 5 questions. Each answer choice should be on a separate line, with a blank line separating each question.

Hyperautomation is a cutting-edge, multidimensional approach to process automation that aims to optimize and streamline business operations by leveraging the power of advanced technologies, including artificial intelligence (AI), machine learning (ML), robotic process automation (RPA), process mining, and analytics. This holistic methodology combines these technologies in a synergistic manner to create an intelligent, interconnected, and adaptable automation ecosystem. Hyperautomation transcends the traditional boundaries of individual tools or solutions, enabling organizations to achieve end-to-end automation across their entire digital landscape. The ultimate goal of hyperautomation is to empower businesses to transform and optimize their processes, reduce manual and repetitive tasks, increase productivity, enhance efficiency, and drive innovation. By providing real-time insights and predictive analytics, hyperautomation enables decision-makers to make better-informed choices, proactively address potential issues, and adapt to dynamic market conditions. Furthermore, hyperautomation fosters a human-machine collaboration, freeing up employees to focus on high-value, strategic tasks, and ultimately, improve overall business performance and customer experiences.
    
Example:
Q1. What is hyperautomation?
A. A cutting-edge, multidimensional approach to process automation
B. A traditional approach to individual tools or solutions
C. A human-machine collaboration
D. A predictive analytics tool`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  summarizeMeeting: {
    name: "Summarize Meeting & Action Items",
    input: `Please structure the following notes as a meeting summary email including a brief executive summary and list out the action items grouped by who they're currently assigned to

Sarah, Tom, Emily, Mark, Lisa all here
Proj A 60% done, on track June 15
Proj B API troubles, 1 more week needed
Emily finished UX for Proj C, starting soon
Mark's marketing proj D, good response
Lisa, QA found critical bugs, fixes by Fri
Sarah sched meeting Proj B timeline w/ client
Tom API update next meeting
Emily, dev team kick-off for Proj C UX
Mark, marketing report end of the month
Lisa, bug-free build Proj E update
Update tasks on proj management tool!
New collab tool? Tom checking options
Team-building, long weekend? Emily's poll`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  documentFunction: {
    name: "Document Function",
    input: `private static string GetText(string path, string filename)
{
    var reader = File.OpenText($"{AppendPathSeparator(path)}{filename}");
    var text = reader.ReadToEnd();
    return text;

    string AppendPathSeparator(string filepath)
    {
        return filepath.EndsWith(@"\") ? filepath : filepath + @"\";
    }
}`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  optimizeFunction: {
    name: "Optimize Function",
    input: `static long NaiveFactorial(int n)
    {
        if (n == 0 || n == 1)
        {
            return 1;
        }
        else
        {
            return n * NaiveFactorial(n - 1);
        }
    }`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  writeRecursive: {
    name: "Write Recursive Function",
    input: `Given a .NET6 model called a TreeNode with the properties 
- Id
- ParentId, which is the Id of this Node's parent, or null if it's the root
- Children, which is a List of additional TreeNodes
- Items, which is a List of additional objects about the node
    
Write the .NET6 code that would take a root node and remove all nodes that have no children and no items, starting at the end of the tree and working up to the root.`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  naturalLangToSQL: {
    name: "Convert Natural Language to SQL",
    input:
      "Given a set of SQL tables with the following properties\n\nEmployee(id, name, department_id)\nDepartment(id, name, address)\nSalary_Payments(id, employee_id, amount, date)\n\nWrite a SQL Server query to list the names of the departments which employed more than 10 employees in the last 3 months.",
    placeholder: "",
    hint: "",
    prompt: [],
  },
  writeUnitTests: {
    name: "Write Unit Tests",
    input: `Please provide a Python unit test example for a simple function that calculates the factorial of a given number. The function should be named 'factorial' and the unit tests should use the unittest framework.`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  generateBusinessClass: {
    name: "Generate New Business Class",
    input: `Write a C# class for a basic inventory management system, including properties for product name, product ID, stock quantity, price, and methods for adding and removing stock, calculating total inventory value, and displaying product information. Ensure the class has appropriate error handling and validation, and utilizes public and private access modifiers where necessary.`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  creative: {
    name: "Do a Creative Task",
    input: `You are a world-famous chef who has just discovered a new, unusual ingredient that can be used in cooking. Describe the ingredient, its origin, how you discovered it, and create a unique and innovative recipe that highlights its flavor and texture. Also, provide a detailed account of the dish's presentation and how it can be enjoyed by those who taste it.`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  templateNewProject: {
    name: "Template New Project",
    input: `Please provide a Python project template for a command line based calculator application that can perform addition, subtraction, multiplication, and division operations. Include comments to explain the purpose of each part of the code.`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  createCRUD: {
    name: "Create CRUD Methods",
    input: `Create the csharp CRUD methods for a .net 6 webapi and a model called "Inventory"`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  createUI: {
    name: "Create Reactjs UI",
    input: `Write me the reactjs frontend for a one page application that acts as a ChatGPT playground?
The users should have the ability to interact with ChatGPT API via a front end that looks like standard messaging apps. The user should also be able to configure the assistant that they're talking to, providing the system message, and some few-shot examples if needed.
Pre-populate a list of example assistants, most should be interesting, some should be funny concepts.`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  createReactForm: {
    name: "Create UI Editable Form",
    input: `Write the reactjs code for a data entry front end, where the data is a list of objects that renders as a list of the most recent 3, and has a "show more" button to render the rest.
Clicking on one of the items in the list opens a side panel that allows you to edit the properties of the object. Clicking cancel on the side panel discards the edits, and clicking save sends them to an API using axios PUT methods.
Assume the objects have 4 types of fields, a time duration in seconds, a percentage that's stored as a number from 0 to 1 but needs to be rendered as a number from 0 to 100, a simple whole number numerical field, and a boolean that's represented by a checkbox`,
    placeholder: "",
    hint: "",
    prompt: [],
  },
  topicClustering: {
    name: "Topic Clustering",
    input: `Cluster the following news headlines into topic categories based on patterns seen within the text. Also mention reasoning behind how these categories were defined. 

Output format:
{
"topic_name": "",
"headlines": [],
"reasoning": ""
}

Input news headlines:
1. "From books to presentations in 10s with AR + ML"
2. "Demo from 1993 of 32-year-old Yann LeCun showing off the World's first Convolutional Network for Text Recognition"
3. "First Order Motion Model applied to animate paintings"
4. "First Republic Bank's stock plunged on Tuesday after it said depositors withdrew more than $100 billion during last month's crisis"
5. "United Airlines stock down over 5% premarket trading"
6. "Bitcoin was nearly $20,000 a year ago today"

Output:`,
    placeholder: "",
    hint: "",
  },
  creative: {
    name: "Unexpectedly Creative Task",
    input: `Imagine a parallel universe where animals have developed advanced civilizations and possess human-like intelligence, while humans exist in their natural habitats. Describe a day in the life of an animal ambassador visiting the human sanctuary, detailing their observations, cultural exchanges, and the potential lessons learned from the experience.`,
    placeholder: "",
    hint: "Recommend maximizing the temperature for this one.",
    prompt: [],
  },
};

const CAIGPTPlayground = (props) => {
  const [exampleName, setExampleName] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [example, setExample] = useState("freeform");
  const [placeholder, setPlaceholder] = useState("");
  const [exampleHint, setExampleHint] = useState("");
  const [questions, setQuestions] = useState([]);
  const [extractTypes, setExtractTypes] = useState([]);
  const [temperature, setTemperature] = useState(0.3);
  const [topP, setTopP] = useState(0.95);
  const [maxTokens, setMaxTokens] = useState(1000);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    setExampleName(exampleData[example].name);
    setInputText(exampleData[example].input);
    setQuestions(exampleData[example].parameters?.questions || []);
    setExtractTypes(exampleData[example].parameters?.types || []);
    setPlaceholder(exampleData[example].placeholder);
    setExampleHint(exampleData[example].hint);
    setOutputText("");
  }, [example]);

  const handleExampleChange = (event) => {
    setExample(event.target.value);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleTemperatureChange = (event) => {
    setTemperature(event.target.value);
  };

  const handleTopPChange = (event) => {
    setTopP(event.target.value);
  };

  const handleMaxTokensChange = (event) => {
    setMaxTokens(event.target.value);
  };

  const handleSubmit = async () => {
    setIsRunning(true);
    try {
      let prompt;

      switch (example) {
        case "freeform":
          prompt = inputText;
          break;
        case "summarize":
          prompt = `Please summarize the following text:\n\n${inputText}\n`;
          break;
        case "qanda":
          //   prompt = `Please answer the following questions from the text below\n${questions.join("\n")}\n\n${inputText}`
          prompt = `${inputText}\n\nPlease answer the following questions from the text above, number each answer on a new line.\n\n${questions
            .map((q) => `-${q}`)
            .join("\n")}\n\n`;
          break;
        case "extract":
          prompt = `Extract ${extractTypes.join(
            ", "
          )} from the text below, in json format, with the keys in snake case.\n\n${inputText}`;
          break;
        case "documentFunction":
          prompt = `Please provide triple slash documentation for the following C# function, and add comments to the code in the function:\n\n${inputText}\n`;
          break;
        case "summarizeInteraction":
          prompt = `Generate a summary of the below conversation in the following format:\nCustomer problem:\nOutcome of the conversation:\nAction items for follow-up:\nCustomer budget:\nDeparture city:\nDestination city:\n${inputText}`;
          break;
        case "optimizeFunction":
          prompt = `Please optimize the following function:\n\n${inputText}`;
          break;
        default:
          prompt = inputText;
      }

      prompt = `${prompt}\nIf any code is necessary, please write that code in markdown.`;

      const payload = {
        prompt,
        temperature: parseFloat(temperature),
        top_p: parseFloat(topP),
        max_tokens: parseInt(maxTokens),
      };

      const api_endpoint = "https://odinai.azurewebsites.net/api/Predict";
      const function_key =
        "Nq-4ZQu09VDORPpw5lEUccnWShXd9CQG1KQl9FfUxi8AAzFubQxATw==";

      // Add custom headers here
      const headers = {
        "Content-Type": "application/json",
        "X-ODIN-model-request": "davinci-003",
        // "X-ODIN-model-request": "gpt-3.5-base-completions",
        "x-functions-key": function_key,
      };

      const response = await axios.post(api_endpoint, payload, {
        headers,
      });

      let responseText = "";
      let errored = false;

      if (response.data) {
        if (response.data.output) {
          responseText = response.data.output;
        } else {
          if (typeof response.data === "object") {
            responseText = JSON.stringify(response.data, null, 2);
          } else {
            let text = response.data;
            while (text.startsWith("\n")) {
              text = text.substr(1);
            }
            responseText = text;
          }
        }
      } else {
        errored = true;
        responseText = "Error: Invalid response from API.";
      }

      setOutputText(responseText);

      observabilityIntentService.LogEntry({
        log_time: new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
        log_level: errored ? "Error" : "Info",
        log_text: JSON.stringify({
          user: props.graphData?.userPrincipalName ?? null,
          impersonation: props.impersonation?.userPrincipalName ?? null,
          request: payload,
          response: errored ? response : responseText,
        }),
        application_area: "CAI NLP Playground",
      });
    } catch (error) {
      setOutputText(`Error: ${error.message}`);

      observabilityIntentService.LogEntry({
        log_time: new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
        log_level: "Error",
        log_text: JSON.stringify({
          user: props.graphData?.userPrincipalName ?? null,
          impersonation: props.impersonation?.userPrincipalName ?? null,
          request: JSON.stringify({
            prompt,
            temperature: temperature,
            top_p: topP,
            max_tokens: maxTokens,
          }),
          response: error,
        }),
        application_area: "CAI NLP Playground",
      });
    }

    setIsRunning(false);
  };

  return (
    <div className="o-page-section o-page-section l-mt-md l-pt-none">
      <div className="o-page-section__content-container o-page-section__content-container--wide-fixed-width l-pt-none l-mt-neg-sm">
        <div className="l-row">
          <div className="col-md-12 col-lg-12 col-xl-12">
            <SymphonyBanner />
            <HeadingRow>
              <HeadingRowTitle>
                <Heading size={2}>
                  CAI NLP Playground <Eyebrow>Beta Testing</Eyebrow>
                </Heading>
                <Heading size={4}>{exampleName}</Heading>
              </HeadingRowTitle>
              <p>{exampleHint}</p>
              <HeadingRowActions>
                <div className="m-heading-row__actions-inner">
                  {/* <div className="m-button-row"></div> */}
                  <Select onChange={handleExampleChange} value={example}>
                    {Object.keys(exampleData).map((example) => (
                      <Option value={example}>
                        {exampleData[example].name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </HeadingRowActions>
            </HeadingRow>
          </div>
        </div>
        <div className="l-row" style={{ height: "65vh" }}>
          <div className="col-md-3">
            {/* <HeadingRow>
              <Heading size={5}>Task Parameters</Heading>
            </HeadingRow> */}
            <div className="l-row">
              <Form>
                {example === "qanda" && (
                  <FormGroup>
                    <Heading size={5}>Questions</Heading>
                    {questions.map((question, index) => (
                      <div key={index} className="row">
                        <div className="col-md-12">
                          <div className="m-form-group l-mb-none">
                            <>
                              <label htmlFor="" className="a-label">
                                Question {index + 1}:
                              </label>
                              <div className="row">
                                <div className="col-md-10">
                                  <input
                                    id={`question${index}`}
                                    type="text"
                                    className="a-text-input"
                                    value={question}
                                    onChange={(e) => {
                                      const newQuestions = [...questions];
                                      newQuestions[index] = e.target.value;
                                      setQuestions(newQuestions);
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={icon_CloseSquare}
                                    className="a-icon__img"
                                    onClick={(e) => {
                                      const newQuestions = [...questions];
                                      newQuestions.splice(index, 1);
                                      setQuestions(newQuestions);
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Button
                      onClick={(e) => {
                        setQuestions([...questions, ""]);
                        e.preventDefault();
                      }}
                    >
                      Add Question
                    </Button>
                  </FormGroup>
                )}

                {example === "extract" && (
                  <FormGroup>
                    <Heading size={5}>Data to Extract</Heading>
                    {extractTypes.map((type, index) => (
                      <div
                        key={index}
                        className="row"
                        style={{ paddingLeft: "0px" }}
                      >
                        <div className="col-md-12">
                          <div className="m-form-group l-mb-none">
                            <>
                              <label htmlFor="" className="a-label">
                                Type {index + 1}
                              </label>
                              <div className="row">
                                <div className="col-md-10">
                                  <input
                                    id={`type${index}`}
                                    type="text"
                                    className="a-text-input"
                                    value={type}
                                    onChange={(e) => {
                                      const newExtractTypes = [...extractTypes];
                                      newExtractTypes[index] = e.target.value;
                                      setExtractTypes(newExtractTypes);
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={icon_CloseSquare}
                                    className="a-icon__img"
                                    onClick={(e) => {
                                      const newExtractTypes = [...extractTypes];
                                      newExtractTypes.splice(index, 1);
                                      setExtractTypes(newExtractTypes);
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setExtractTypes([...extractTypes, ""]);
                      }}
                    >
                      Add Extraction Type
                    </Button>
                  </FormGroup>
                )}

                <FormGroup>
                  <label htmlFor="" className="a-label">
                    Temperature: {temperature}
                  </label>
                  <input
                    id="temperature"
                    type="range"
                    className="a-text-input"
                    step={0.01}
                    min={0}
                    max={1}
                    value={temperature}
                    onChange={handleTemperatureChange}
                  />
                  <span
                    className=""
                    style={{ color: "#777", cursor: "help", fontSize: "small" }}
                  >
                    Controls randomness. Higher values (e.g., 1) yield more
                    random results, while lower values (e.g., 0) produce more
                    focused outputs. Prompts that require creativity do better
                    with higher temperatures.
                  </span>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="a-label">
                    Top-p: {topP}
                  </label>
                  <input
                    id="topP"
                    type="range"
                    className="a-text-input"
                    step={0.1}
                    min={0}
                    max={1}
                    value={topP}
                    onChange={handleTopPChange}
                  />
                  <span
                    className=""
                    style={{ color: "#777", cursor: "help", fontSize: "small" }}
                  >
                    Controls diversity via nucleus sampling. Lower values (e.g.,
                    0.5) make the model focus on higher probability tokens,
                    while higher values (e.g., 0.9) allow for more diversity.
                  </span>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="a-label">
                    Max tokens: {maxTokens}
                  </label>
                  <input
                    id="maxTokens"
                    type="range"
                    className="a-text-input"
                    min={1}
                    max={2048}
                    step={1}
                    value={maxTokens}
                    onChange={handleMaxTokensChange}
                  />
                  <span
                    className=""
                    style={{ color: "#777", cursor: "help", fontSize: "small" }}
                  >
                    Limits the number of generated tokens in the output. Use a
                    lower value for shorter responses and a higher value for
                    longer responses.
                  </span>
                </FormGroup>
              </Form>
            </div>
          </div>
          <div className="col-md-9">
            <div
              className="l-row"
              // style={{ height: "60%", paddingBottom: "50px" }}
              style={{ paddingBottom: "50px" }}
            >
              <div className="col-md-12">
                <HeadingRow>
                  <Heading size={5}>Input</Heading>
                </HeadingRow>
                <textarea
                  // style={{ height: "80%", width: "100%" }}
                  style={{ width: "100%" }}
                  rows={8}
                  value={inputText}
                  onChange={handleInputChange}
                  placeholder={placeholder}
                />
                <Button primary onClick={handleSubmit} disabled={isRunning}>
                  Generate Output
                </Button>
              </div>
            </div>
            <div
              className="l-row"
              // style={{ height: "25%" }}
            >
              <div className="col-md-12">
                <HeadingRow>
                  <Heading size={5} style={{ paddingRight: "20px" }}>
                    Output
                  </Heading>
                  (read only)
                </HeadingRow>
                {!isRunning ? (
                  // <textarea
                  //   // style={{ height: "80%", width: "100%" }}
                  //   style={{ width: "100%" }}
                  //   value={outputText}
                  //   rows={10}
                  //   readOnly
                  // />
                  // <MarkdownRenderer
                  // style={{ width: "100%" }}
                  // content={outputText} />
                  <MarkdownRenderer
                    style={{ width: "100%" }}
                    content={outputText}
                  />
                ) : (
                  <Progress>Getting Output</Progress>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CAIGPTPlayground;
