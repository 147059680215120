import { createContext, useState, useContext, useEffect, useRef } from "react";
import { useChat } from "./ChatContext";
import { useAccess } from "./AccessContext";

const TypingContext = createContext();

function TypingProvider({ children }) {

	// referenced context components
	const { getChatId } = useChat();
	const { chatMembersRef } = useAccess();

	// data
	const [typingUsers, setTypingUsers] = useState([]);

    // refs for signalR
    const typingUsersRef = useRef();

	const receiveIsTyping = (isTypingEvent) => {
		let chatId = getChatId();
        if (isTypingEvent?.chatId === chatId) {
            //Get current typing user index
            const curIndex = typingUsersRef.current?.indexOf(
                (user) => user.userProfileId === isTypingEvent.userProfile.userProfileId
            );
            //Typing is happening in current chat
            if (isTypingEvent.isTyping) {
                //Start of typing
                if (curIndex === -1) {
                    //If no record of user typing - add user to typingUsers
                    const newTypingUsers = [
                        ...typingUsers,
                        chatMembersRef.current.find(
                            (x) => x.userProfileId === isTypingEvent.userProfile.userProfileId
                        ),
                    ];
                    setTypingUsers(newTypingUsers);
                }
            } else {
                //End of typing
                //If record of the user typing
                if (curIndex !== -1) {
                    const newTypingUsers = [...typingUsersRef.current];
                    delete newTypingUsers[curIndex];
                    newTypingUsers.filter((x) => x !== undefined);
                    setTypingUsers(newTypingUsers);
                }
            }
        } else {
            //This is a place for code to be executed if the typing is occuring in a chat that is not the current chat.
        }
    };

    // useEffect(() => {
    //     addMappingMethod(
    //         {
    //             //This method will be called when a new message is received from the user. This message with have an empty GUID since it is not yet finalized in the database.
    //             TextName: "ReceiveIsTyping",
    //             Method: receiveIsTyping,
    //         }
    //     );
	// }, [])

    useEffect(() => {
        typingUsersRef.current = typingUsers;
    }, [typingUsers]);

	return (
		<TypingContext.Provider
			value={{
                // States
				typingUsers,
                // Functions
                receiveIsTyping
			}}
		>
			{children}
		</TypingContext.Provider>
	);
}

// Hook to use the TypingContext in a component
function useTyping() {
	const context = useContext(TypingContext);
	if (context === undefined) {
		throw new Error(
			"useTyping must be used within a TypingProvider"
		);
	}
	return context;
}

export { TypingProvider, useTyping };
