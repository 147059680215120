import React from "react";

export interface FilterProps {
    className?: string;
    children?: React.ReactNode;
}

const defaultFilterProps = {
}


export const Filter = (props: FilterProps) => {
    props = { ...defaultFilterProps, ...props };

    return (
        <div className="o-filter">
            {props.children}
        </div>
    )
}

export interface FilterGroupProps {
    children?: React.ReactNode;
}

const defaultFilterGroupProps = {}

export const FilterGroup = (props: FilterGroupProps) => {
    props = { ...defaultFilterGroupProps, ...props };

    return (
        <div className="o-filter__group">
            {props.children}
        </div>
    )
}

export interface FilterGroupHeadingProps {
    children?: React.ReactNode;
}

const defaultFilterGroupHeadingProps = {}

export const FilterGroupHeading = (props: FilterGroupHeadingProps) => {
    props = { ...defaultFilterGroupHeadingProps, ...props };

    return (
        <p className="o-filter__group-heading">{props.children}</p>
    )
}