import { Heading } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";

const SummarizeDocument = () => {

	const { executeTemplate } = useTemplate();

	const summarizePrompt =
	"Summarize the provided document by identifying and extracting the main topics of interest, key dates, and any action items mentioned within the text. Please structure the summary in bullet points, grouping the information into categories for clarity. Ensure that the summary is concise but comprehensive enough to convey the critical elements of the document. If action items are associated with specific individuals or departments, include that detail. Highlight any deadlines or time-sensitive matters distinctly.";

    const handleUploads = async (files) => {
		executeTemplate(files, [summarizePrompt]);
    }

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin summarizing this document, just upload your document in
				the file drop and hit that Send Message button!
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<FileDisplay/>
				<div style={{ marginBottom: "20px" }}>
					<DnDFileUpload
						/*To-Do this doesn't allow for viewing the data uploaded*/
						notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1', 
							'.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx', 
							'.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
							'.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
                        overwriteHandleFiles={handleUploads}
					/>
				</div>
			</form>
		</>
	);
};

export default SummarizeDocument;