import React, { Fragment, useEffect, useState } from "react";
import {
  HeadingRow,
  HeadingRowTitle,
  HeadingRowActions,
  Heading,
  Filter,
  FilterGroup,
  FilterGroupHeading,
  Checkbox,
} from "../fusion";

const icon_CloseSquare = require("../../images/close-square.svg").default;
const icon_Search = require("../../images/search.png");

export const PayrollMeritChangeFilterPanel = (props) => {
  const [hidden, setHidden] = useState(true);
  const [changesMade, setChangesMade] = useState(false);

  const [panelDataFilterState, setPanelDataFilterState] = useState([]);

  useEffect(() => {
    setHidden(props.hidden);

    // if being shown, re-get the dataFilter state from the page to bind here
    if (!props.hidden) {
      if (props.dataFilterState) {
        setPanelDataFilterState(props.dataFilterState);
      }
    }
    // if being hidden, just wipe the filter state so the CHECKED state is definitely
    // set on show
    else if (props.hidden) {
      setPanelDataFilterState([]);
    }
  }, [props.hidden]);

  // on closing of the panel
  const closeClickedHandler = (changesMade) => {
    if (props.onClose) {
      props.onClose(changesMade);
    }
  };

  // on cancelling the change of the panel
  const cancelClickedHandler = (changesMade) => {
    if (props.onCancel) {
      props.onCancel(changesMade);
    }
  };

  // on applying changes to the panel
  const applyClickedHandler = () => {
    if (props.onApply) {
      props.onApply(panelDataFilterState);
    }
  };

  const resetPanelDataFilters = () => {
    // just uncheck everything
  };

  const handleCheckboxChange = (groupKey, key, isChecked) => {
    var newFilterState = panelDataFilterState.map((el) =>
      el["groupKey"] === groupKey
        ? {
            ...el,
            options: el.options.map((el) =>
              el["filterKey"] === key
                ? {
                    ...el,
                    isChecked: isChecked,
                  }
                : el
            ),
          }
        : el
    );

    setPanelDataFilterState(newFilterState);
  };

  return (
    <div
      className={`o-drawer o-drawer--has-button-row o-drawer--left-narrow ${
        hidden ? "o-drawer--hidden" : "o-drawer--shown"
      }`}
      id="filter-drawer"
    >
      <div
        className="o-drawer__overlay"
        style={{ backdropFilter: "none" }}
        onClick={(e) => {
          if (props.clickOutsideToClose) {
            closeClickedHandler(changesMade);
            e.preventDefault();
          }
        }}
      ></div>
      <div className="o-drawer__wrapper">
        <HeadingRow>
          <HeadingRowTitle>
            <Heading size={3} className="l-mb-none">
              Employee Filter
            </Heading>
          </HeadingRowTitle>
          <HeadingRowActions>
            <div className="m-icon-row m-icon-row--right-align">
              <a
                href="#"
                id="drawerCloseBtn"
                className="a-icon m-icon-row__icon"
                title="Close employee Details"
                onClick={(e) => {
                  closeClickedHandler(changesMade);
                  e.preventDefault();
                }}
              >
                <img src={icon_CloseSquare} className="a-icon__img" />
              </a>
            </div>
          </HeadingRowActions>
        </HeadingRow>
        <div className="o-drawer__inner">
          <Filter>
            {panelDataFilterState.map((filterGroup) => (
              <FilterGroup>
                {filterGroup.groupName && (
                  <FilterGroupHeading>
                    {filterGroup.groupName}
                  </FilterGroupHeading>
                )}
                {filterGroup.groupName !== "Performance Score" &&
                 filterGroup.groupName !== "Roles" &&
                  filterGroup.options.map((filter) => (
                    <Checkbox
                      isChecked={filter.isChecked}
                      onChange={(isChecked) => {
                        handleCheckboxChange(
                          filterGroup.groupKey,
                          filter.filterKey,
                          isChecked
                        );
                      }}
                    >
                      {filter.filterLabel}
                    </Checkbox>
                  ))}
                {filterGroup.groupName === "Performance Score" && (
                  <div className="l-row">
                    <div className="l-one-half-col">
                      {filterGroup.options
                        .filter(
                          (x) =>
                            x.displayValue === "1" ||
                            x.displayValue === "2" ||
                            x.displayValue === "3"
                        )
                        .map((filter) => {
                          return (
                            <Checkbox
                              isChecked={filter.isChecked}
                              onChange={(isChecked) => {
                                handleCheckboxChange(
                                  filterGroup.groupKey,
                                  filter.filterKey,
                                  isChecked
                                );
                              }}
                            >
                              {filter.filterLabel}
                            </Checkbox>
                          );
                        })}
                    </div>
                    <div className="l-one-half-col">
                      {filterGroup.options
                        .filter(
                          (x) =>
                            x.displayValue === "4" || x.displayValue === "5" || x.displayValue === "None"
                        )
                        .map((filter) => {
                          return (
                            <Checkbox
                              isChecked={filter.isChecked}
                              onChange={(isChecked) => {
                                handleCheckboxChange(
                                  filterGroup.groupKey,
                                  filter.filterKey,
                                  isChecked
                                );
                              }}
                            >
                              {filter.filterLabel}
                            </Checkbox>
                          );
                        })}
                    </div>
                  </div>
                )}
                {filterGroup.groupName === "Roles" && (
                  <Fragment>
                    {/* <form className="a-search-input l-mb-xs">
                      <input
                        id="role-search"
                        className="a-search-input__text-box"
                        type="text"
                        placeholder="Search roles..."
                        aria-label="Search Roles"
                        name="search_field"
                      />
                      <button className="a-search-input__button" type="submit">
                        <img
                          className="a-search-input__img"
                          src={icon_Search}
                          alt="search button icon"
                        />
                      </button>
                      <div className="a-search-input__results-dropdown">
                        <ul className="a-search-input__results">
                          <li className="a-search-input__result">
                            <button className="a-search-input__result-btn">
                              UX Designer
                            </button>
                          </li>
                          <li className="a-search-input__result">
                            <button className="a-search-input__result-btn">
                              Sr UX Designer
                            </button>
                          </li>
                        </ul>
                      </div>
                    </form> */}
                    {filterGroup.options.map((filter) => {
                      return (
                        <Checkbox
                          isChecked={filter.isChecked}
                          onChange={(isChecked) => {
                            handleCheckboxChange(
                              filterGroup.groupKey,
                              filter.filterKey,
                              isChecked
                            );
                          }}
                        >
                          {filter.filterLabel}
                        </Checkbox>
                      );
                    })}
                    {/* <button className="a-button a-button--link">
                      Show more
                    </button> */}
                  </Fragment>
                )}
              </FilterGroup>
            ))}
          </Filter>
        </div>
        <div className="o-drawer__fixed-btn-row">
          <div className="m-button-row">
            <button
              href="#"
              id="modalTrigger"
              className="a-button a-button--outline m-button-row__button"
              onClick={(e) => {
                if (props.onFilterReset) props.onFilterReset();
                resetPanelDataFilters();
                e.preventDefault();
              }}
            >
              Reset
            </button>
            <button
              className="a-button m-button-row__button"
              onClick={(e) => {
                e.preventDefault();
                applyClickedHandler();
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
