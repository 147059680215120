import React, { Fragment } from "react"

const StatRow = props => {
    return (
        <div className={`o-stat-row ${props.className}`}>
            {props.children}
        </div>
    )
}

export default StatRow;