import React from "react";
// import "https://synthesisstorage.z13.web.core.windows.net/dist/0.1/styles/toolkit.css";
// import '../../styles/toolkit.css';

// size?: 'small' | 'large' | 1 | 0;
export interface ButtonProps {
  children?: React.ReactNode;
  primary?: boolean;
  accent?: boolean;
  warn?: boolean;
  disabled?: boolean;
  hue?: string;
  onClick(): undefined;
  className?: string;
}

const defaultButtonProps = {
  primary: false,
  accent: false,
  warn: false,
  disabled: false,
  hue: undefined,
  onClick: () => {},
  className: undefined
};

// const Button = (props: ButtonProps) => {
const Button = (props: ButtonProps) => {
  props = { ...defaultButtonProps, ...props };

  let classes =
    "a-button" +
    (props.primary ? ""
      : props.accent ? ""
      : props.warn ? " a-button--destructive"
      : " a-button--outline");

    if (props.className && props.className.length > 0)
      classes = `${classes} ${props.className}`;

  // TODO BREAK INTO HELPER
  let hue =
    props.hue === "primary-100"
      ? "#A1DDEF"
      : props.hue === "primary-300"
      ? "#007983"
      : props.hue === "primary-500"
      ? "#0071BC"
      : undefined;

  // override until there's a class
  if (props.accent) {
    hue = "#007983";
  }

  return (
    <button
      className={classes}
      disabled={props.disabled}
      style={{
        backgroundColor: hue != undefined ? hue : undefined,
        borderColor: hue != undefined ? hue : undefined,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
