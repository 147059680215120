import React from "react";
import { Avatar } from "../Avatar";

const ProfileHighlight = props => {
    return (
        <div className="m-person-highlight">
            <Avatar src={props.imgSrc} alt="" />
            <div className="m-person-highlight__content">
                <span className="a-eyebrow">{props.title}</span>
                <h4 className="a-heading--heading-5">{props.name}</h4>
            </div>
        </div>
    );
};

export default ProfileHighlight;