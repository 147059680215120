import React, { useState, Fragment, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    NavLink,
    Routes,
} from "react-router-dom";
import myCaiApiService from "../../services/mycai-api-v1";
import { Progress } from "../fusion";
import { callMsGraphUsers } from "../../services/graph";
import BonusChangeGroupScreen from "../bonus/bonusChangeGroupScreen";
import BonusMeritChange from "../bonus/bonusMeritChanges";

const annualizedHours = 1880;

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const BonusPlanningApp = (props) => {
    const [ownedChangeGroups, setOwnedChangeGroups] = useState([]);
    const [assignedChangeGroups, setAssignedChangeGroups] = useState([]);
    const [seeAllChangeGroups, setSeeAllChangeGroups] = useState([]);
    const [loadingOrgs, setLoadingOrgs] = useState(true);
    const [changeGroups, setChangeGroups] = useState([]);
    const [upn, setUpn] = useState(props.upn);
    const [role, setRole] = useState(undefined);
    //Initial load to get changeGroup and merit change data. (The merit change data is nested inside the change group jsob obj's)
    useEffect(() => {
        setUpn(props.upn)
        if (!loadingOrgs) setLoadingOrgs(true);

        if (props.accessToken) {
            myCaiApiService
                .GetBonusChangeGroups(props.accessToken, props.impersonation?.employeeId)
                .then((data) => {
                    setOwnedChangeGroups(data[0]);
                    setChangeGroups(data[0]);
                    setAssignedChangeGroups(data[1]);
                    setSeeAllChangeGroups(data[2] ? data[2] : []);
                    console.log("Change Groups Data");
                    console.log(data);
                    setLoadingOrgs(false);
                })
                .catch((error) => console.error(error));


        }
    }, [props.accessToken, props.impersonation]);

    useEffect(() => {

        if (props.accessToken) {
            myCaiApiService
                .GetRole(props.accessToken, props.impersonation?.employeeId)
                .then((roleId) => {
                    setRole(roleId);
                })
        }
    }, [props.accessToken, props.impersonation]);
    const repullData = () => {
        if (props.accessToken) {
            setLoadingOrgs(true);
            myCaiApiService
                .GetBonusChangeGroups(props.accessToken, props.impersonation?.employeeId)
                .then((data) => {
                    setOwnedChangeGroups(data[0]);
                    setChangeGroups(data[0]);
                    setAssignedChangeGroups(data[1]);
                    setSeeAllChangeGroups(data[2] ? data[2] : []);
                    if (changeGroups && changeGroups.length > 0) {
                        if (changeGroups[0].discriminator === "owned") {
                            setChangeGroups(data[0]);
                        } else if (changeGroups[0].discriminator === "assigned") {
                            setChangeGroups(data[1]);
                        }
                        // basically catch "other" and discriminator undefined
                        else {
                            setChangeGroups(data[0]);
                        }
                    } else {
                        setChangeGroups(data[0]);
                    }
                    console.log("repullData");
                    console.log(data);
                    setLoadingOrgs(false);
                });
        }
    };

    return (
        <Fragment>
            <Routes>
                <Route
                    path={``}
                    exact
                    element={
                        <Fragment>
                            {loadingOrgs && <Progress>Loading Assigned Orgs</Progress>}
                            {!loadingOrgs && (
                                <BonusChangeGroupScreen
                                    ownedChangeGroups={ownedChangeGroups}
                                    assignedChangeGroups={assignedChangeGroups}
                                    seeAllChangeGroups={seeAllChangeGroups}
                                    loadingOrgs={loadingOrgs}
                                    changeGroupChange={(x) => {
                                        console.warn("changeGroupChange is getting called")
                                        console.log(x)
                                        setChangeGroups(x);
                                        console.log("Change Groups");
                                        console.log(changeGroups);
                                    }}
                                    accessToken={props.accessToken}
                                    impersonation={props.impersonation}
                                    onSubmit={() => {
                                        repullData();
                                    }}
                                    upn={upn}
                                />
                            )}
                        </Fragment>
                    }
                />
                <Route
                    path=":changeGroupId/*"
                    element={
                        <BonusMeritChange
                            data={changeGroups}
                            role={role}
                            accessToken={props.accessToken}
                            impersonation={props.impersonation}
                            upn={upn}
                            isLoading={loadingOrgs}
                            onMeritUpdate={() => {
                                repullData();
                            }}
                        />
                    }
                />
            </Routes>
        </Fragment>
    );
};

export default BonusPlanningApp;
