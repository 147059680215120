import { useState, useRef } from "react";
import { Heading, Button } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";

const CodeDebugging = () => {
	const textWidthElement = useRef(null);

	const [problem, setProblem] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
    const [snippet, setSnippet] = useState("");
    const [files, setFiles] = useState("");

    const { isRunning, executeTemplate } = useTemplate();

    const handleSnippetInputChange = (e) => {
        setSnippet(e.target.value);
    };

	const handleInputChange = (e, setInput) => {
		setInput(e.target.value);
		textWidthElement.current.textContent = e.target.value;
		const newWidth =
			textWidthElement.current.getBoundingClientRect().width + 10;
		e.target.style.width = newWidth + "px";
	};


    // These two functions are what I needed to do to not immediately navigate when uploading a file.
    const deleteFiles = (fileInd) => {
        let filesCopy = [...files];
        filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd+1)]
        setFiles([...filesCopy]);
    }

    const handleFileUpload = async (newFiles) => {
        setFiles([...files, ...newFiles]);
        console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
    }

    // Function called when the "Send Message" button is hit.
    const handleSubmit = async () => {
        let prompt = `I am working on a code project and I am encountering an issue with ${problem}.`;
        if (files.length > 0) {
            prompt = `${prompt}\r\n\r\nI have attached the relevant files.`;
        }
        if (snippet && snippet.length > 0) {
            prompt = `${prompt} Here is a snippet of my code:\r\n\r\n${snippet}`;
        }
        prompt = `${prompt}\r\n\r\nCan you help me understand why I am getting ${errorMsg} and how to fix it?`

		executeTemplate(files, [prompt]);
    }

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin getting help debugging your code, just upload your code
				and related files in the file drop, fill out relevant fields,
				and hit that Send Message button!
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<p className="o-nl-form__text">
					"I am working on a code project and I am encountering an
					issue with
					<input
						type="text"
						placeholder="Specific Problem"
						className="o-nl-form__text-input"
						value={problem}
						onChange={(e) => handleInputChange(e, setProblem)}
					/>
					. Here are the relevant files:
				</p>
                <FileDisplay filesOverride={files} deleteOverride={deleteFiles}/>
				<div style={{ marginBottom: "20px" }}>
					<DnDFileUpload
						notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1', 
							'.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx', 
							'.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
							'.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
                        overwriteHandleFiles={handleFileUpload}
					/>
				</div>
				<p className="o-nl-form__text">
                    And/Or any relevant code snippets (markdown supported):
                </p>
                <textarea
                    className="a-textarea-input"
                    name=""
                    id=""
                    rows="2"
                    value={snippet}
                    onChange={handleSnippetInputChange}
                ></textarea>
                <p className="o-nl-form__text">
                    Can you help me understand why I am getting
					<input
						type="text"
						placeholder="Error Message or Unexpected Result"
						className="o-nl-form__text-input"
                        style={{width: "300px"}}
						value={errorMsg}
						onChange={(e) => handleInputChange(e, setErrorMsg)}
					/>
					and how to fix it?"
				</p>
				<div className="m-button-row">
					<Button onClick={handleSubmit} disabled={files.length === 0 || isRunning}>
						Send Message
					</Button>
				</div>
			</form>
            <span id="text-width" ref={textWidthElement}>
                Error Message or Unexpected Result And More Length
			</span>
		</>
	);
};

export default CodeDebugging;