import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Heading, Eyebrow } from "cai-fusion";
import {
  HeadingRow,
  HeadingRowActions,
  HeadingRowTitle,
} from "../fusion/HeadingRow";
import { Modal } from "../fusion";
import { Form } from "../fusion/Input";
import myCaiApiService from "../../services/mycai-api-v1";

const icon_CloseSquare = require("../../images/close-square.svg").default;

const annualizedHours = 1880;
const prorationKey = {
  1: 1.0,
  2: 1.0,
  3: 1.0,
  4: 1.0,
  5: 0.92,
  6: 0.83,
  7: 0.75,
  8: 0.66,
  9: 0.583,
  10: 0.50,
  11: 0.42,
  12: 0.33,
};

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const BonusDetailsPanel = (props) => {
  // props.clickOutsideToClose = true;
  // Navigation Hooks
  const params = useParams();

  const [focusedData, setFocusedData] = useState({});
  const [objectiveData, setObjectiveData] = useState([]);
  const [prevObjectiveData, setPrevObjectiveData] = useState([]);
  const [frequencies, setFrequencies] = useState({ 1: "Each", 2: "One-time", 3: "Monthly", 4: "Quarterly", 5: "Annual" });
  const [meritStatuses, setMeritStatuses] = useState({ 0: "HR Rejected", 1: "Executive Review", 2: "HR Admin Review", 3: "HR Review", 4: "Financial Review", 5: "Finalizing" });
  const [manualOverrideStatus, setManualOverrideStatus] = useState(0);

  const [hidden, setHidden] = useState(true);
  const [editable, setEditable] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [empDetExp, setEmpDetExp] = useState(true);

  const [currentBaseComp, setCurrentBaseComp] = useState(0.0);
  const [currentBonus, setCurrentBonus] = useState(0.0);
  const [currentCommission, setCurrentCommission] = useState(0.0);
  const [currentTotalComp, setCurrentTotalComp] = useState(0.0);
  const [annualizedCurrentBase, setAnnualizedCurrentBase] = useState(0.0);

  const [proposedBaseComp, setProposedBaseComp] = useState(0.0);
  const [proposedBonus, setProposedBonus] = useState(0.0);
  const [proposedCommission, setProposedCommission] = useState(0.0);
  const [proposedTotalComp, setProposedTotalComp] = useState(0.0);

  const [proratedBase, setProratedBase] = useState(0.0);
  const [proratedIncrease, setProratedIncrease] = useState(0.0);
  const [prorationMultiplier, setProrationMultiplier] = useState(1.0);

  const [bonusRemaining, setBonusRemaining] = useState(0.0);
  // TODO: Worry about bonus limit later for now
  const [bonusLimitHit, setBonusLimitHit] = useState(true);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningModalData, setWarningModalData] = useState(null);

  // const [pctCompChange, setPctCompChange] = useState(null);

  // for tracking statefulness on the form
  const [increaseDollars, setIncreaseDollars] = useState(0.0);
  const [increasePercentage, setIncreasePercentage] = useState(0.0);
  // const [newBase, setNewBase] = useState(0.0);

  const [status, setStatus] = useState(null);
  const [role, setRole] = useState(null);
  const [statusReason, setStatusReason] = useState("");
  // propogate from attribute
  useEffect(() => {
    setHidden(props.hidden);

    // if being shown
    if (!props.hidden) {
      if (props.data && params.employeeId) {
        let chosenData = props.data.filter((x) => x.id === params.employeeId);
        if (chosenData.length > 0) {
          // console.log(`props.role: ${props.role}`);
          // console.log(`chosenData[0]`);
          // // console.log(`Role Type: ${typeof props.role}`)
          // console.log(`status Type: ${typeof chosenData[0].status}`)
          // console.log(chosenData[0]);
          // console.log(`status === "": ${chosenData[0].status}`);
          setRole(props.role);
          setFocusedData(chosenData[0]);
        }
      }
      if (props.accessToken && params.employeeId) {
        myCaiApiService
          .GetBonusObjectives(props.accessToken, params.employeeId, props.impersonation?.employeeId)
          .then((data) => {
            // console.log("Objective Data");
            // console.log(data);
            setObjectiveData(data.result);
            // console.log("here");
            calculateBonusTotal(data.result);

            console.log(`L: ${props.bonusSumLimit}, C: ${props.bonusSumCurrent}`);
            if (props.bonusSumLimit && props.bonusSumCurrent) {
              setBonusRemaining(props.bonusSumLimit - props.bonusSumCurrent);
              setBonusLimitHit((props.bonusSumLimit - props.bonusSumCurrent) < 0);
            console.log(props.bonusSumLimit - props.bonusSumCurrent);
            console.log((props.bonusSumLimit - props.bonusSumCurrent) < 0);
            }
          })
          .catch((error) => console.error(error));
      }
    }
  }, [props.hidden, props.data, params.employeeId]);

  // just for outputting purposes
  // useEffect(() => {
  //   console.log(`${focusedData.employeeName}'s data:`);
  //   console.log(focusedData)
  // }, [focusedData])
  // handle form changes
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.value;

    if (
      name === "increaseDollars" ||
      name === "increasePercentage" ||
      name === "newBase"
    ) {
      const reInt = /^\-?[0-9\b]+$/;
      const reFlt1 = /^\-?[0-9]+(\.[0-9])?$/;
      const reFlt2 = /^\-?[0-9]+(\.[0-9][0-9])?$/;

      // if value is not blank, then test the regex.. int/one decimal/two decimal
      if (
        value === "" ||
        reInt.test(value) ||
        reFlt1.test(value) ||
        reFlt2.test(value)
      ) {
        let floatValue = parseFloat(value);
        if (name === "newBase") {
          setProposedBaseComp(floatValue);

          // let newCompNumber = value;
          let pctIncrease =
            ((floatValue - currentBaseComp) / currentBaseComp) * 100;
          let dollarIncrease = floatValue - currentBaseComp;

          setIncreaseDollars(dollarIncrease);
          setIncreasePercentage(pctIncrease);

          if (!changesMade) {
            setChangesMade(true);
          }
        } else if (name === "increaseDollars") {
          let newCompNumber = currentBaseComp + floatValue;
          let pctIncrease =
            ((newCompNumber - currentBaseComp) / currentBaseComp) * 100;

          setProposedBaseComp(newCompNumber);

          setIncreaseDollars(floatValue);
          setIncreasePercentage(pctIncrease);

          if (!changesMade) {
            setChangesMade(true);
          }
        } else if (name === "increasePercentage") {
          let percent = 1.0 + floatValue / 100;
          let newCompNumber = currentBaseComp * percent;
          let dollarIncrease = newCompNumber - currentBaseComp;

          setProposedBaseComp(newCompNumber);

          setIncreaseDollars(dollarIncrease);
          setIncreasePercentage(floatValue);

          if (!changesMade) {
            setChangesMade(true);
          }
        }
      }
    }
  };
  // when data is passed to the panel, set it and set defaults
  // as well as reseting change state
  useEffect(() => {

    if (focusedData) {
      // setProposedBaseComp(focusedData.proposedBaseComp ?? 0.0);
      if (focusedData.curBaseComp) setCurrentBaseComp(focusedData.curBaseComp);
      if (focusedData.currentBonus) setCurrentBonus(focusedData.currentBonus);
      if (focusedData.currentCommission)
        setCurrentCommission(focusedData.currentCommission);

      const currentBase =
        focusedData &&
        focusedData.curCompGrade &&
        (focusedData.curCompGrade === "Salary"
          ? focusedData.curBaseComp
          : focusedData.curCompGrade === "Hourly"
            ? focusedData.curBaseComp *
            annualizedHours *
            (focusedData.ftePercentage ?? 1.0)
            : 0.0);
      setAnnualizedCurrentBase(currentBase);
      const currentBonus = focusedData.currentBonus ?? 0.0;
      const currentCommission = focusedData.currentCommission ?? 0.0;
      const currentTotal = currentBase + currentBonus + currentCommission;

      let prorationMultiplier = focusedData.lastCompChange
        ? (!isNaN(Number(focusedData.lastCompChange.substring(focusedData.lastCompChange.indexOf("/") + 1))) &&
          Number(focusedData.lastCompChange.substring(focusedData.lastCompChange.indexOf("/") + 1)) < 2022 ? 1.0 :
          prorationKey[
          focusedData.lastCompChange.substring(
            0,
            focusedData.lastCompChange.indexOf("/")
          )
          ])
        : 1.0;
      setProrationMultiplier(prorationMultiplier);

      setCurrentTotalComp(currentTotal);

      if (focusedData.proposedBaseComp) {
        setProposedBaseComp(focusedData.proposedBaseComp);
      }
      if (focusedData.proposedBonus)
        setProposedBonus(focusedData.proposedBonus);
      if (focusedData.proposedBonus && focusedData.currentBonus) {
        let increase = focusedData.proposedBonus - focusedData.currentBonus;
        setIncreaseDollars(increase);
        setIncreasePercentage((increase / focusedData.currentBonus * 100));
      }
      if (focusedData.proposedCommission)
        setProposedCommission(focusedData.proposedCommission);

      setChangesMade(false);
    }
  }, [
    focusedData,
    setCurrentBaseComp,
    setCurrentBonus,
    setCurrentCommission,
    setCurrentTotalComp,
    setProposedBaseComp,
    setProposedBonus,
    setProposedCommission,
  ]);

  // if either proposed base comp or proposed base are changed, update the total comp
  useEffect(() => {
    if (setProposedTotalComp) {
      let fltBase = 0.0;
      let fltBonus = 0.0;

      if (proposedBaseComp !== "" && focusedData && focusedData.curCompGrade) {
        if (focusedData.curCompGrade === "Salary")
          fltBase = parseFloat(proposedBaseComp);
        else if (focusedData.curCompGrade === "Hourly")
          fltBase =
            parseFloat(proposedBaseComp) *
            annualizedHours *
            (focusedData.ftePercentage ?? 1.0);

        // This felt like the right place to update the increase dollar, increase percentage and new base
        // states... but it isn't.. leads to small quirks in behavior.. so don't.
      }

      // TODO
      let proratedIncrease =
        (fltBase - annualizedCurrentBase) * prorationMultiplier;
      setProratedIncrease(proratedIncrease);

      let proratedBaseComp = annualizedCurrentBase + proratedIncrease;
      setProratedBase(proratedBaseComp);

      setProposedTotalComp(
        proratedBaseComp +
        focusedData.proposedBonus +
        focusedData.proposedCommission
      );
    }
  }, [proposedBaseComp, setProposedTotalComp]);

  // when the total proposed comp is changed, set the percent value
  // useEffect(() => {
  //     if (focusedData) {

  //         if (currentTotalComp > 0) {
  //             const compChange = proposedTotalComp - currentTotalComp;
  //             const pctCompChange = (compChange / currentTotalComp) * 100;
  //             setPctCompChange(pctCompChange);
  //         }
  //         else {
  //             setPctCompChange(null);
  //         }
  //     } else {
  //         setPctCompChange(null);
  //     }
  // }, [proposedTotalComp]);

  const calculateBonusTotal = (objectives = objectiveData) => {
    // console.log("OPB: %d", proposedBonus);
    // console.log(`calculateBonusTotal`);
    // console.log(objectives);
    var bonusTotal = 0;
    var bonusMults = { 1: 1, 2: 1, 3: 12, 4: 4, 5: 1 };
    for (let obj of objectives) {
      bonusTotal += obj.targetAmount * bonusMults[obj.frequencyTypeId];
    }
    if (bonusTotal != proposedBonus) {
      //console.log("New: %d, Old: %d", bonusTotal, currentBonus);
      setProposedBonus(bonusTotal);
      if (currentBonus != 0) {
        var newID = bonusTotal - currentBonus;
        setIncreaseDollars(newID);
        var newIP = (newID / currentBonus) * 100;
        // console.log("NID: %d, NIP: %d", newID, newIP);
        setIncreasePercentage(newIP);
      }
      if (bonusRemaining - bonusTotal >= 0) {
        setBonusLimitHit(false);
        console.log("Under Limit");
      } else {
        setBonusLimitHit(true);
        console.log("Limit Exceeded");
      }
      // console.log("NPB: %d", proposedBonus);
    }
    return bonusTotal;
  };

  // on closing of the panel
  const closeClickedHandler = (changesMade) => {
    if (props.onClose) {
      props.onClose(changesMade);
    }
  };

  // on cancelling the change of the panel
  const cancelClickedHandler = (changesMade) => {
    if (props.onCancel) {
      props.onCancel(changesMade);
    }
  };

  // on save
  const saveClickedHandler = () => {
    if (props.onSave) {
      var updatedData = JSON.parse(JSON.stringify(focusedData));
      updatedData.proposedCommission = proposedCommission;
      updatedData.proposedBonus = proposedBonus;
      props.onSave(updatedData);
    }
  };
  // on save status
  const onSaveStatus = (newStatusId, statusReason) => {
    // added this call so that it can update the status on data reload
    if (props.onSave) {
      var updatedData = JSON.parse(JSON.stringify(focusedData));
      updatedData.status = newStatusId ? newStatusId : "";
      updatedData.statusReason = statusReason;
      props.onSave(updatedData);
    }
  }
  // update the status function
  const updateStatus = (newStatusId, direction, statusText = "no status reason") => {
    console.log("focusedData");
    console.log(focusedData);
    console.log(`statusText: ${statusText}`);
    let lastStatusId = focusedData.status ?? 0;
    myCaiApiService
      .UpdateStatus(props.accessToken, params.employeeId, newStatusId, lastStatusId, statusText, direction, props.impersonation?.userPrincipalName, props.impersonation?.employeeId)
      .then((data) => {
        console.log("Update status has worked");
        onSaveStatus(newStatusId, statusText);
      })
      .catch((error) => console.error(error));
}
// on submit
const submitClickedHandler = () => {
  let statusId;
  switch (role) {
    case null:
      statusId = 1;
      break;
    case 0:
      statusId = 1;
      break;
    default:
      statusId = 3;
      break;
  }

  let tempStatusReason = statusReason ?
    statusReason
    : (
      focusedData.statusReason ?
        focusedData.statusReason :
        null
    );

  // console.log(`tempstatusreason: ${tempStatusReason}`);
  let direction = "approved";
  updateStatus(statusId, direction, tempStatusReason);
  setStatus(statusId);
}
const approveRejectHandler = (wasApproved) => {
  let statusId;

  var containsStretch = false;
  var containsEach = false;
  
  objectiveData.forEach(e => {
    if (e.stretch = true)
      containsStretch = true;
    if (e.frequencyTypeId == 1)
      containsEach = true;
  });
  //console.log(`Role: ${role}`);
  switch (role) {
    case 1:
      if (wasApproved) {
        if (containsStretch || containsEach || (increasePercentage != undefined && (Math.round(increasePercentage * 100 + Number.EPSILON) / 100) > 10))
          statusId = 2;
        else
          statusId = 3;
      }
      else
        statusId = 0;
      break;
    case 2:
      if (wasApproved)
        statusId = 3;
      else
        statusId = 1;
      break;
    case 3:
      if (wasApproved)
        statusId = 4;
      else {
        if (containsStretch || containsEach || (increasePercentage != undefined && (Math.round(increasePercentage * 100 + Number.EPSILON) / 100) > 10))
          statusId = 2;
        else
          statusId = 0;
      }
      break;
    case 4:
      if (wasApproved)
        statusId = 5;
      else {
        if (containsStretch || containsEach || (increasePercentage != undefined && (Math.round(increasePercentage * 100 + Number.EPSILON) / 100) > 10))
          statusId = 2;
        else
          statusId = 1;
      }
      break;
    case 5:
      if (wasApproved)
        statusId = 6;
      else {
        if (containsStretch || containsEach || (increasePercentage != undefined && (Math.round(increasePercentage * 100 + Number.EPSILON) / 100) > 10))
          statusId = 2;
        else
          statusId = 1;
      }
      break;
    default:
      // console.log("Break got hit in approveRejectHandler");
      break;
  }
  // console.log(`statusId: ${statusId}`);
  let tempStatusReason = statusReason ?
    statusReason
    : (
      focusedData.statusReason ?
        focusedData.statusReason :
        null
    );
  let direction = wasApproved ? "approved" : "rejected"
  //console.log(statusId);
  //console.log(containsStretch);
  //console.log(containsEach);
  updateStatus(statusId, direction, tempStatusReason);
  setStatus(statusId);
}
const manualStatusHandler = (statusId) => {
  switch (role) {
    case 6:
      let tempStatusReason = statusReason ?
        statusReason
        : (
          focusedData.statusReason ?
            focusedData.statusReason :
            null
        );
      // TODO: update this method signature so that it can handle approve or rejected, i left if off for now.
      updateStatus(statusId, tempStatusReason);
      setStatus(statusId);
      break;
    default:
      console.log("Shouldn't hit this in function manualStatusHandler");
      break;
  }
}

  const updateObjectives = () => {
    console.log(`Function: updateObjectives`);
    console.log(`useState: objectiveData[0].targetAmount: ${objectiveData[0].targetAmount}`);
    myCaiApiService
      .UpdateBonusObjectives(props.accessToken, params.employeeId, objectiveData, props.impersonation?.employeeId)
      .then(() => {
        console.log("Update Objectives was successful");
        myCaiApiService
          .GetBonusObjectives(props.accessToken, params.employeeId, props.impersonation?.employeeId)
          .then((data) => {
            console.log("Get Objectives was successful");
            console.log(data.result)
            setObjectiveData(data.result.slice());
            myCaiApiService
              .UpdateCommissionAmount(props.accessToken, params.employeeId, proposedCommission, props.impersonation?.employeeId)
              .then(() => {
                console.log(`Commission Updated: $${proposedCommission}`);
              }).catch((error) => console.error(error));
            myCaiApiService
              .UpdateBonusAmount(props.accessToken, proposedBonus, params.employeeId, props.impersonation?.employeeId)
              .then(() => {
                console.log(`Bonus Updated: $${proposedBonus}`);
                saveClickedHandler();
              }).catch((error) => console.error(error));
          }).catch((error) => console.error(error));
      }).catch((error) => console.error(error));
  }
  const onCancelClick = (e) => {
    myCaiApiService
      .GetBonusObjectives(props.accessToken, params.employeeId, props.impersonation?.employeeId)
      .then((data) => {
        console.log(data.result);
        setObjectiveData(data.result.slice());
      })
      .catch((error) => console.error(error));
    setEditable(false);
    e.preventDefault();
  }
  const onSaveClick = (e) => {
    updateObjectives();
    setEditable(false);
    e.preventDefault();
  }
  const onApproveClick = (e) => {
    if (!bonusLimitHit) {
      approveRejectHandler(true);
      closeClickedHandler(changesMade);
      updateObjectives();
    } else {
      setWarningModalData({
        title:
          "You Cannot Approve This Change",
        body: (
          <div className="l-row">
            <div className="l-one-col">
              <p>
                This change cannot be approved because this year's bonus total is currently higher than the budget.
              </p>
            </div>
          </div>
        ),
        cancel: "Cancel",
        confirm: "Accept",
      });
      setShowWarningModal(true);
    }
    e.preventDefault();
  }

  return (
    <div className={`o-drawer o-drawer--wide ${hidden ? "o-drawer--hidden" : "o-drawer--shown"}`} >
      <div
        className="o-drawer__overlay"
        style={{ backdropFilter: "none" }}
        onClick={(e) => {
          if (props.clickOutsideToClose) {
            closeClickedHandler(changesMade);
            e.preventDefault();
          }
        }}></div>
      <div className="o-drawer__wrapper" id="readOnlyDrawer">
        <div className="o-drawer__inner">
          <HeadingRow>
            <HeadingRowTitle>
              <Eyebrow>{focusedData && focusedData.jobTitle}</Eyebrow>
              <Heading size={3} className="l-mb-none">
                {focusedData && focusedData.employeeName}
              </Heading>
            </HeadingRowTitle>
            <HeadingRowActions>
              <div className="m-icon-row m-icon-row--right-align">
                <a
                  href="#"
                  id="drawerCloseBtn"
                  className="a-icon m-icon-row__icon"
                  title="Close employee Details"
                  onClick={(e) => {
                    closeClickedHandler(changesMade);
                    e.preventDefault();
                  }}
                >
                  <img src={icon_CloseSquare} className="a-icon__img" />
                </a>
              </div>
            </HeadingRowActions>
          </HeadingRow>
          <div className={`m-collapsible-panel m-collapsible-panel--shaded l-mt-sm ${empDetExp ? 'm-collapsible-panel--expanded' : ''}`}>
            <button class="m-collapsible-panel__heading"
              onClick={(e) => {
                setEmpDetExp(!empDetExp);
                e.preventDefault();
              }}
            >
              <Heading size={4} class="m-collapsible-panel__heading-label">Employee Details</Heading>
              <span class="m-collapsible-panel__collapse-toggle">
                <svg class="m-collapsible-panel__collapse-icon" xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08" aria-hidden="true">
                  <g id="Layer_2" data-name="Layer 2" transform="translate(-6.46 -6.46)">
                    <g id="Layer_1" data-name="Layer 1">
                      <rect class="m-collapsible-panel__collapse-icon-horizontal" id="Rectangle_45" data-name="Rectangle 45" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect>
                      <rect class="m-collapsible-panel__collapse-icon-vertical" id="Rectangle_46" data-name="Rectangle 46" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect>
                    </g>
                  </g>
                </svg>
              </span>
            </button>
            <div class="m-collapsible-panel__contents">
              <div className="row">
                <div className="col-md-6">
                  <div className="m-form-group">
                    <label htmlFor="" className="a-label">
                      Company Type
                    </label>
                    <p className="a-read-only-value">{focusedData.companyType}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="m-form-group">
                    <label htmlFor="" className="a-label">
                      Company
                    </label>
                    <p className="a-read-only-value">{focusedData.company}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="m-form-group">
                    <label htmlFor="" className="a-label">
                      Enterprise Type
                    </label>
                    <p className="a-read-only-value">
                      {focusedData.enterpriseType}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="m-form-group">
                    <label htmlFor="" className="a-label">
                      Division
                    </label>
                    <p className="a-read-only-value">{focusedData.division}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="m-form-group">
                    <label htmlFor="" className="a-label">
                      Cost Center
                    </label>
                    <p className="a-read-only-value">
                      {focusedData &&
                        focusedData.costCenter &&
                        focusedData.costCenter.substring(0, 6)}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="m-form-group">
                    <label htmlFor="" className="a-label">
                      Last Comp Change
                    </label>
                    <p className="a-read-only-value">
                      {focusedData.lastCompChange}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="m-form-group">
                    <label htmlFor="" className="a-label">
                      Last Performance Evaluation Score
                    </label>
                    <p className="a-read-only-value">
                      {focusedData.lastReviewLevel
                        ? focusedData.lastReviewLevel
                        : "No Performance Evaluation on Record"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="m-form-group">
                    <label htmlFor="" className="a-label">
                      Merit Change Status
                    </label>
                    <p className="a-read-only-value">
                      {focusedData.status
                        ? meritStatuses[focusedData.status]
                        : "No Status on Record"}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="m-form-group">
                    <label htmlFor="" className="a-label">
                      Merit Change Status Reason
                    </label>
                    <p className="a-read-only-value">
                      {focusedData.statusReason
                        ? focusedData.statusReason
                        : "No Reason on Record"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Form className="l-mt-sm">
            <div className="row l-mb-xs l-mt-sm">
              <div className="col-12">
                <Heading size={3}>Commission Target</Heading>
                {!editable ?
                  (<p>{formatter.format(proposedCommission)}</p>) :
                  (<input
                    defaultValue={proposedCommission}
                    onInput={(e) => {
                      setProposedCommission(e.target.value);
                    }}
                  >
                  </input>)
                }
              </div>
            </div>
            <div className="row l-mb-xs l-mt-sm">
              <div className="col-12">
                <Heading size={3}>Bonus Plan</Heading>
                {(proposedBonus === undefined ||
                  proposedBonus === 0 ||
                  proposedBonus === "0") && (
                    <div className="m-callout m-callout--shaded l-mt-xs">
                      <svg
                        className="m-callout__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 358.38"
                      >
                        <g id="Layer_2">
                          <g id="Engine_Warning">
                            <path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z" />
                          </g>
                        </g>
                      </svg>
                      <div className="m-callout__content">
                        <h4 className="m-callout__title">
                          You've specified a 0% bonus.
                        </h4>
                        {/* <p className="m-callout__text a-muted-text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusamus odio pariatur rem, natus ullam voluptatibus.</p> */}
                      </div>
                    </div>
                  )}
                {((proposedBonus != undefined &&
                  currentBonus != undefined &&
                  proposedBonus < currentBonus) || (proposedCommission != undefined &&
                    currentCommission != undefined &&
                    proposedCommission < currentCommission)) && (
                    <div className="m-callout m-callout--shaded l-mt-xs">
                      <svg
                        className="m-callout__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 358.38"
                      >
                        <g id="Layer_2">
                          <g id="Engine_Warning">
                            <path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z" />
                          </g>
                        </g>
                      </svg>
                      <div className="m-callout__content">
                        <h4 className="m-callout__title">
                          You've specified a negative change in total target
                          compensation.
                        </h4>
                        {proposedBonus != undefined &&
                          currentBonus != undefined &&
                          proposedBonus < currentBonus && (
                            <p className="m-callout__text a-muted-text">
                              This individual's target bonus has decreased,
                              impacting the overall compensation calculation.
                            </p>
                          )}
                        {proposedCommission != undefined &&
                          currentCommission != undefined &&
                          proposedCommission < currentCommission && (
                            <p className="m-callout__text a-muted-text">
                              This individual's target commission has decreased,
                              impacting the overall compensation calculation.
                            </p>
                          )}
                      </div>
                    </div>
                  )}
                {(currentBonus === undefined ||
                  currentBonus === 0 ||
                  currentBonus === "0") && (proposedBonus > currentBonus) && (
                    <div className="m-callout m-callout--shaded l-mt-xs">
                      <svg
                        className="m-callout__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 358.38"
                      >
                        <g id="Layer_2">
                          <g id="Engine_Warning">
                            <path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z" />
                          </g>
                        </g>
                      </svg>
                      <div className="m-callout__content">
                        <h4 className="m-callout__title">
                          You've specified a new increase.
                        </h4>
                        <p className="m-callout__text a-muted-text">
                          You'll be asked to provide a business justification
                          when submitting a new bonus plan where one did not previously exist
                          and it will be reviewed by your HRBP.
                        </p>
                      </div>
                    </div>
                  )}
                {(Math.round(increasePercentage * 100 + Number.EPSILON) / 100) > 10 && (
                  <div className="m-callout m-callout--shaded l-mt-xs">
                    <svg
                      className="m-callout__icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 358.38"
                    >
                      <g id="Layer_2">
                        <g id="Engine_Warning">
                          <path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z" />
                        </g>
                      </g>
                    </svg>
                    <div className="m-callout__content">
                      <h4 className="m-callout__title">
                        You've specified a {Math.round(increasePercentage * 100 + Number.EPSILON) / 100}% increase.
                      </h4>
                      {increasePercentage != undefined &&
                        (Math.round(increasePercentage * 100 + Number.EPSILON) / 100) > 10 && (
                          <p className="m-callout__text a-muted-text">
                            You'll be asked to provide a business justification
                            when submitting a bonus plan with a change greater
                            than 10% and it will be reviewed by your HRBP.
                          </p>
                        )}
                    </div>
                  </div>
                )}
                {(Math.round(increasePercentage * 100 + Number.EPSILON) / 100) < -10 && (
                  <div className="m-callout m-callout--shaded l-mt-xs">
                    <svg
                      className="m-callout__icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 358.38"
                    >
                      <g id="Layer_2">
                        <g id="Engine_Warning">
                          <path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z" />
                        </g>
                      </g>
                    </svg>
                    <div className="m-callout__content">
                      <h4 className="m-callout__title">
                        You've specified a {Math.round(increasePercentage * 100 + Number.EPSILON) / 100}% increase.
                      </h4>
                      {increasePercentage != undefined &&
                        (Math.round(increasePercentage * 100 + Number.EPSILON) / 100) < -10 && (
                          <p className="m-callout__text a-muted-text">
                            You'll be asked to provide a business justification
                            when submitting a bonus plan with a change greater
                            than 10% and it will be reviewed by your HRBP.
                          </p>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-md-6">
                  <div class="o-stat-row o-stat-row--left-aligned l-mt-sm l-mb-sm">
                    <div class="o-stat-row__stat m-stat m-stat--positive">
                      <span class="m-stat__value">{formatter.format(proposedBonus)}</span>
                      <span class="m-stat__value"></span>
                      <span class="m-stat__label">Total Target Bonus</span>
                    </div>
                  </div>
                </div>
                {!editable || focusedData.status === '0' ? <div></div> :
                  (<div class="col-md-6 h-vert-center-col">
                    <div class="m-button-row">
                      <a
                        href="#"
                        className="a-button a-button--outline m-button-row__button"
                        onClick={(e) => {
                          var newObj = objectiveData.slice();
                          var tempObj = {
                            deleted: false,
                            frequencyTypeId: 1,
                            hrFlag: false,
                            id: null,
                            meritId: params.employeeId,
                            objective: "",
                            stretch: false,
                            targetAmount: 0
                          };
                          newObj.push(tempObj);
                          // console.log("Add Objective");
                          // console.log(objectiveData)
                          setObjectiveData(newObj);
                          calculateBonusTotal();
                          e.preventDefault();
                        }}
                      >
                        Add Objective
                      </a>
                    </div>
                  </div>)
                }
              </div>
            </div>
            {objectiveData.map((x, i) => (!x.deleted &&
              <div class="row l-mt-xs">
                <div class="col-12">
                  <div class="o-block">
                    <div class="row">
                      <div class="col-md-6">
                        <h4 class="a-heading a-heading--heading-4">2024 Objective #{i + 1}</h4>
                      </div>
                      <div class="col-md-6">
                        {!editable || focusedData.status === '0' ? <div></div> :
                          <div class="m-icon-row m-icon-row--right-align">
                            <a
                              href="#"
                              className="a-icon-link m-icon-row__icon"
                              onClick={(e) => {
                                console.log("Delete %d", i)
                                var newObj = objectiveData.slice();
                                newObj[i].deleted = true;
                                newObj[i].targetAmount = 0;
                                console.log(newObj);
                                setObjectiveData(newObj);
                                calculateBonusTotal();
                                e.preventDefault();
                              }}
                            >
                              <div class="a-icon-link__inner">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-inject-url="http://localhost:3000/dist/assets/toolkit/images/icon-trash.svg" class="a-icon-link__icon">
                                  <path d="M436 60h-89.185l-9.75-29.238A44.945 44.945 0 00294.379 0h-76.758a44.975 44.975 0 00-42.7 30.762L165.182 60H76c-24.814 0-45 20.186-45 45v30c0 16.708 15.041 15 31.183 15H466c8.291 0 15-6.709 15-15v-30c0-24.814-20.186-45-45-45zm-239.187 0l6.57-19.746A14.996 14.996 0 01217.621 30h76.758c6.46 0 12.188 4.116 14.224 10.254L315.18 60H196.813zM64.666 182l23.917 289.072C90.707 494.407 109.97 512 133.393 512h245.215c23.423 0 42.686-17.593 44.824-41.06L447.336 182H64.666zM181 437c0 19.773-30 19.854-30 0V227c0-19.773 30-19.854 30 0v210zm90 0c0 19.773-30 19.854-30 0V227c0-19.773 30-19.854 30 0v210zm90 0c0 19.773-30 19.854-30 0V227c0-8.291 6.709-15 15-15s15 6.709 15 15v210z"></path>
                                </svg>
                                <span class="a-icon-link__text">Remove</span>
                              </div>
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                    <div class="row l-mt-xs">
                      <div class="col-md-6">
                        <div class="m-form-group m-form-group--inline">
                          <label for="" class="a-label">Frequency</label>
                          {!editable ?
                            (<p class="a-read-only-value">{frequencies[x.frequencyTypeId]}</p>) :
                            (<select
                              defaultValue={x.frequencyTypeId}
                              disabled={focusedData.status === '0'}
                              onInput={(e) => {
                                var newObj = objectiveData.slice();
                                newObj[i].frequencyTypeId = e.target.value;
                                // console.log(newObj);
                                console.log("Frequency");
                                console.log(objectiveData)
                                setObjectiveData(newObj);
                                calculateBonusTotal();
                                e.preventDefault();
                              }}
                            >
                              <option value="1">Each</option>
                              <option value="2">One-time</option>
                              <option value="3">Monthly</option>
                              <option value="4">Quarterly</option>
                              <option value="5">Annual</option>
                            </select>)
                          }
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="m-form-group m-form-group--inline">
                          <label for="" class="a-label">Target Amount</label>
                          {!editable ?
                            (<p class="a-read-only-value">{formatter.format(x.targetAmount)}</p>) :
                            (<input
                              defaultValue={x.targetAmount}
                              disabled={focusedData.status === '0'}
                              onInput={(e) => {
                                var newObj = objectiveData.slice();
                                var newAmt = parseFloat(e.target.value);
                                console.log(`newAmt: ${newAmt}`);
                                newObj[i].targetAmount = isNaN(newAmt) ? 0 : newAmt;
                                setObjectiveData(newObj);
                                calculateBonusTotal();
                                e.preventDefault();
                              }}
                            ></input>)
                          }
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="m-form-group">
                          <label for="" class="a-label">Objectives</label>
                          {!editable ?
                            (<p class="a-read-only-value">{x.objective}</p>) :
                            (<input
                              defaultValue={x.objective}
                              onInput={(e) => {
                                var newObj = objectiveData.slice();
                                newObj[i].objective = e.target.value;
                                //console.log(newObj);
                                // console.log(`Objectives`);
                                // console.log(objectiveData);
                                setObjectiveData(newObj);
                                e.preventDefault();
                              }}
                              style={{ height: "20vh", width: "47vw" }}
                            ></input>)
                          }
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked={x.stretch}
                            disabled={!editable || focusedData.status === '0'}
                            onInput={(e) => {
                              var newObj = objectiveData.slice();
                              newObj[i].stretch = e.target.checked;
                              //console.log(newObj);
                              setObjectiveData(newObj);
                              e.preventDefault();
                            }}>
                          </input>
                          <span>Stretch Objective</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Form>
        </div>
        <div class="m-form-group">
          <label for="" className="a-label">Status Reason</label>
          <textarea rows="1" class="a-textarea-input" onChange={e => setStatusReason(e.target.value)}></textarea>
        </div>
        <Modal
          show={showWarningModal}
          title={warningModalData && warningModalData.title}
          body={warningModalData && warningModalData.body}
          cancel={warningModalData && warningModalData.cancel}
          confirm={warningModalData && warningModalData.confirm}
          onClose={
            warningModalData && warningModalData.onClose
              ? warningModalData.onClose
              : () => {
                setShowWarningModal(false);
              }
          }
          onCancel={
            warningModalData && warningModalData.onCancel
              ? warningModalData.onCancel
              : () => {
                setShowWarningModal(false);
              }
          }
          onConfirm={
            warningModalData && warningModalData.onConfirm
              ? warningModalData.onConfirm
              : () => {
                setShowWarningModal(false);
              }
          }
        />
        <div class="o-drawer__fixed-btn-row">
          <div className="m-button-row">
            {/* waits for data to load */}
            {(focusedData) ? (<>
              {/* checks if it is read-only or ready to be processed*/}
              {(role === focusedData.status || !focusedData.status) ? (<>
                {/** checks if needs to be submitted or approve/reject */}
                {((focusedData.status <= 1 || !focusedData.status) &&
                  (focusedData.manager === props.impersonation.userPrincipalName)) ? (<>
                    {editable ? (<>
                      <a
                        href="#"
                        id="modalTrigger"
                        className="a-button a-button--outline m-button-row__button"
                        onClick={(e) => {
                          onCancelClick(e);
                        }}
                      >
                        Cancel
                      </a>
                      <a
                        href="#"
                        className="a-button m-button-row__button"
                        onClick={(e) => {
                          onSaveClick(e)
                        }}
                      >
                        Save
                      </a></>) : (<>
                        <a
                          href="#"
                          className="a-button m-button-row__button"
                          onClick={(e) => {
                            setEditable(true);
                            e.preventDefault();
                          }}
                        >
                          Edit
                        </a>
                        <a
                          href="#"
                          className={`a-button m-button-row__button ${bonusLimitHit ? `a-button--disabled` : ``}`}
                          disabled={bonusLimitHit}
                          onClick={(e) => {
                            if (!bonusLimitHit) {
                              submitClickedHandler();
                              closeClickedHandler(changesMade);
                            } else {
                              setWarningModalData({
                                title: "You Cannot Submit This Change",
                                body: (
                                  <div className="l-row">
                                    <div className="l-one-col">
                                      <p>
                                        This change cannot be submitted because this year's bonus total is currently higher than the budget.
                                      </p>
                                    </div>
                                  </div>
                                ),
                                cancel: "Cancel",
                                confirm: "Accept",
                              });
                              setShowWarningModal(true);
                            }
                            e.preventDefault();
                          }}
                        >
                          {/* if status is 0 display resubmit option button */}
                          {(focusedData.status === '0') ? "Resubmit" : "Submit"}
                        </a>
                      </>)}
                  </>) : (<>
                    {(focusedData.status === '1' || focusedData.status === '2') ? (
                      <>
                        {editable ? (<>
                          <a
                            href="#"
                            id="modalTrigger"
                            className="a-button a-button--outline m-button-row__button"
                            onClick={(e) => {
                              onCancelClick(e);
                            }}
                          >
                            Cancel
                          </a>
                          <a
                            href="#"
                            className="a-button m-button-row__button"
                            onClick={(e) => {
                              onSaveClick(e)
                            }}
                          >
                            Save
                          </a></>) : (<>
                            <a
                              href="#"
                              className="a-button m-button-row__button"
                              onClick={(e) => {
                                setEditable(true);
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </a>
                          </>)}
                      </>
                    ) : (
                      <></>
                    )
                    }
                    <a
                      href="#"
                      className="a-button a-button--outline m-button-row__button"
                      data-modal-target="sendBackModal"
                      onClick={(e) => {
                        if (statusReason !== "") {
                          approveRejectHandler(false);
                          closeClickedHandler(changesMade);
                          //cancelClickedHandler(changesMade);
                          myCaiApiService
                            .GetBonusObjectives(props.accessToken, params.employeeId, props.impersonation?.employeeId)
                            .then((data) => {
                              // console.log(data.result);
                              setObjectiveData(data.result.slice());
                            })
                            .catch((error) => console.error(error));
                          setEditable(false);
                          //setProposedCommission(focusedData.proposedCommission);
                          // console.log(focusedData);
                          e.preventDefault();
                        } else {
                          setWarningModalData({
                            title:
                              "You Cannot Reject This Change",
                            body: (
                              <div className="l-row">
                                <div className="l-one-col">
                                  <p>
                                    This change cannot be rejected because it requries a reason for the rejection.
                                  </p>
                                </div>
                              </div>
                            ),
                            cancel: "Cancel",
                            confirm: "Accept",
                          });
                          setShowWarningModal(true);
                        }
                      }}
                    >
                      Send Back For Changes
                    </a>
                    <a
                      href="#"
                      className={`a-button m-button-row__button ${bonusLimitHit || editable ? `a-button--disabled` : ``}`}
                      onClick={(e) => {
                        onApproveClick(e);
                      }}
                    >
                      Approve
                    </a>
                  </>
                )}
              </>) : (
                <div>
                  Read-Only
                </div>
              )}
            </>) : (
              <div>
                loading...
              </div>
            )}
          </div>
          {(role === 6) ?
            (<div className="m-button-row">
              <select
                defaultValue={0}
                onInput={(e) => {
                  setManualOverrideStatus(e.target.value);
                  e.preventDefault();
                }}
                style={{ marginRight: '10px', padding: '10px', borderRadius: '5px' }}
              >
                <option value="NULL">Not Submitted</option>
                <option value="0">HR Rejected</option>
                <option value="1">Executive Review</option>
                <option value="2">HR Admin Review</option>
                <option value="3">HR Review</option>
                <option value="4">Financial Review</option>
                <option value="5">Finalizing</option>
              </select>
              <a
                href="#"
                className="a-button a-button--outline m-button-row__button"
                data-modal-target="sendBackModal"
                onClick={(e) => {
                  manualStatusHandler(manualOverrideStatus);
                  e.preventDefault();
                }}
                style={{ background: '#e74c3c', color: 'white', padding: '10px 20px', borderRadius: '5px', textDecoration: 'none' }}
              >
                Manual Override
              </a>
            </div>) :
            <></>}
        </div>
      </div>

    </div>

  );
};

export default BonusDetailsPanel;