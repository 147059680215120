import React from "react";

export interface CardProps {
    children?: React.ReactNode,
    className?: ""
}

const defaultCardProps = {
    className: ""
}

const Card = (props: CardProps) => {
    return (
        <div className={`o-card ${props.className}`}>
            {props.children}
        </div>
    )
}

export default Card;