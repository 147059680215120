import React, { useEffect, useState } from "react";

import { ReactComponent as IconChatHistory } from "../../../images/icon-chat-history.svg";
import { ReactComponent as IconPinned } from "../../../images/icon-pinned.svg";
import { ReactComponent as IconSharedChat } from "../../../images/icon-shared-chat.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Progress } from "../Progress";

const ChatHistoryItem = ({ id, name, isPinned, isShared, onClick, index }) => {
  // Navigation Hooks
  const params = useParams();
  const [isActive, setIsActive] = useState(params.ChatId === id);

  useEffect(() => {
    setIsActive(params.ChatId === id);
  }, [params.ChatId, id]);

  return (
    <li
      className={`m-vertical-tabs__item ${
        isActive ? "m-vertical-tabs__item--active" : ""
      }`}
      role="tab"
      id={`tab${index + 1}`}
      tabIndex={index}
      aria-controls={`tabpanel${index + 1}`}
      aria-selected="true"
    >
      <a
        className="m-vertical-tabs__link"
        onClick={(e) => {
          e.preventDefault();
          onClick(id, name);
        }}
        // href={`symphony/${id}`}
        href={`/symphony/${id}`}
      >
        <div className="m-vertical-tabs__icon-wrapper">
          {isPinned ? (
            <IconPinned className="m-vertical-tabs__icon"></IconPinned>
          ) : isShared ? (
            <IconSharedChat className="m-vertical-tabs__icon"></IconSharedChat>
          ) : (
            <IconChatHistory className="m-vertical-tabs__icon"></IconChatHistory>
          )}
        </div>
        <span className="m-vertical-tabs__label">{name}</span>
      </a>
    </li>
  );
};

const ChatHistoryLoadingItem = () => {
  return (
    <li
      className="m-vertical-tabs__item m-vertical-tabs__item--active"
      role="tab"
      id="tab1"
      tabIndex="0"
      aria-controls="tabpanel1"
      aria-selected="true"
    >
      <a href="#" className="m-vertical-tabs__link">
        <div className="m-vertical-tabs__icon-wrapper">
          <div className="a-skeleton a-skeleton--icon"></div>
        </div>
        <div className="a-skeleton a-skeleton--line"></div>
      </a>
    </li>
  );
}

const ChatHistory = ({ chats, maxShown, onChatClick, isLoading }) => {
  const [displayCount, setDisplayCount] = useState(maxShown);

  const showMore = () => {
    setDisplayCount((prevDisplayCount) =>
      Math.min(prevDisplayCount + maxShown, chats.length)
    );
  };

  const showLess = () => {
    setDisplayCount(maxShown);
  };

  const showAll = () => {
    setDisplayCount(chats.length);
  };

  if (!chats) {
    chats = [];
  }

  const displayedChats = chats.slice(0, displayCount);

  return (
    <>
      {isLoading ? (
        // <Progress>Updating Chat History</Progress>
        <nav className="m-vertical-tabs m-vertical-tabs--loading l-mt-sm">
          <ul
            className="m-vertical-tabs__list"
            role="tablist"
            aria-orientation="vertical"
          >
            <ChatHistoryLoadingItem />
            <ChatHistoryLoadingItem />
            <ChatHistoryLoadingItem />
            <ChatHistoryLoadingItem />
            <ChatHistoryLoadingItem />
          </ul>
        </nav>
      ) : (
        <>
          <nav className="m-vertical-tabs">
            <ul
              className="m-vertical-tabs__list"
              role="tablist"
              aria-orientation="vertical"
            >
              {displayedChats.map((chat, index) => (
                <ChatHistoryItem
                  key={index}
                  {...chat}
                  onClick={onChatClick}
                  index={index}
                  id={chat.chatId}
                />
              ))}
            </ul>
          </nav>
          {displayCount < chats.length && (
            <button className="a-button a-button--link" onClick={showMore}>
              Show More
            </button>
          )}
          {displayCount > maxShown && displayCount < chats.length && (
            <>
              <button className="a-button a-button--link" onClick={showLess}>
                Show Less
              </button>
              <button
                className="a-button a-button--link"
                onClick={() => setDisplayCount(chats.length)}
              >
                Show All
              </button>
            </>
          )}
          {displayCount === chats.length && (
            <button className="a-button a-button--link" onClick={showLess}>
              Show Less
            </button>
          )}
          {/* {chats.length > maxShown ? (
            <a className="a-button a-button--link" onClick={toggleExpanded}>
              {isExpanded
                ? "View Less history"
                : chats.length > maxShown && "View full history"}
            </a>
          ) : (
            <></>
          )} */}
        </>
      )}
    </>
  );
};

export default ChatHistory;
