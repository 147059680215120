import { useState } from "react";
import { Heading, Button } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";

const AnalyzeRFP = () => {
	const analyzeRFPprompt = [
		"Assume that the attached files are all part of an RFP. Describe the proposal schedule or timeline. When is the intended contract start date and what is the contract term?",
		"What are the primary objectives of the client? Additionally, what is the intended scope of work, including any specific requirements or services to be provided?",
		"Is there an onsite requirement?",
		"Could you provide detailed information on the evaluation criteria for this project? Furthermore, are there any specific pricing or cost requirements?",
		"Are there any litigation requirements or previously canceled contracts that should be aware of?",
		"What are the mandatory or minimum requirements for this project?",
		"How many references are we required to provide?",
		"What kind of experience or specific qualifications is the company expected to possess?",
		"Are we required to name the Key Personnel for this project? Are we required to provide resumes or certifications of Key Personnel?",
		"Are we required to provide SOC 1 or SOC 2 , HITRUST, or International Standards Organization (ISO) certificates or audits in our proposal?",
		"What are the insurance requirements for this project? Additionally, could you provide details on the liability requirements?",
	]

    const { isRunning, executeTemplate } = useTemplate();
	const [files, setFiles] = useState([]);

	const deleteFiles = (fileInd) => {
        let filesCopy = [...files];
        filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd+1)]
        setFiles([...filesCopy]);
    }

    const handleUploads = async () => {
		executeTemplate(files, [...analyzeRFPprompt]);
    }
	const handleFileUpload = async (newFiles) => {
        setFiles([...files, ...newFiles]);
        console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
    }

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin analyzing this RFP, just upload your RFP document in
				the file drop and hit that Send Message button!
				<br />
				Do not be alarmed, this template asks multiple predefined
				questions in a row. Leave the chat open to complete the
				automation.
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<FileDisplay filesOverride={files} deleteOverride={deleteFiles}/>
				<div style={{ marginBottom: "20px" }}>
					<DnDFileUpload
						/*To-Do this doesn't allow for viewing the data uploaded*/
						notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1', 
							'.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx', 
							'.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
							'.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
						overwriteHandleFiles={handleFileUpload}
					/>
				</div>
				<div className="m-button-row">
					<Button onClick={handleUploads} disabled={files.length === 0 || isRunning}>
						Send Message
					</Button>
				</div>
			</form>
		</>
	);
};

export default AnalyzeRFP;