import { Drawer, Heading } from "cai-fusion";
import NewChatButton from "../components/newChatButton";
import { ChatHistory, ChatSearch } from "../../fusion/Chat";
import { useChatHistory } from "../contexts/ChatHistoryContext";
import { useChat } from "../contexts/ChatContext";

const ChatHistoryDrawer = () => {
    const { chats, loadingChats, searchChats } = useChatHistory();
    const { selectChat } = useChat();

    return (
        <Drawer
            placement='left' 
            size='narrow'
            type='hybrid'
            header={
                <div className="o-drawer__heading-row">
                    <div className="o-drawer__title">
                        <Heading size={3}>My Chats</Heading>
                    </div>
                </div>
            }
            id="folder-drawer"
            className="col-3 o-drawer--light-gray"
            style={{ backgroundColor: "#ffffff", paddingTop: "0rem" }}
        >
            <NewChatButton />
            <ChatSearch onSearchChats={(message) => searchChats(message)} />
            <ChatHistory
				chats={chats}
				onChatClick={(chatId, chatName) => selectChat(chatId)}
				maxShown={10}
				isLoading={loadingChats}
			/>
        </Drawer>
    )
}

export default ChatHistoryDrawer;