import React from "react";

const CardHeaderMeta = props => {
    return (
        <div className="o-card__meta">
            {props.children}
        </div>
    )
}

export default CardHeaderMeta;