import React from "react";

const Chip = props => {
    return (
        <div className={`a-chip ${props.className}`}>
            {props.children}
            <p className="a-chip__label">{props.label}</p>
        </div>
    )
}

export default Chip;