export const EngagementClientData = [
  {
    clientId: 1,
    clientName: "Shelby Company Ltd.",
    engagements: [
      {
        engagementId: 100,
        engagementName: "Service Desk",
        isPassing: false,
      },
      {
        engagementId: 101,
        engagementName: "AMS",
        isPassing: false,
      },
      {
        engagementId: 102,
        engagementName: "Desk Side Services",
        isPassing: true,
      },
    ],
  },
  {
    clientId: 2,
    clientName: "Bluth Company",
    engagements: [
      {
        engagementId: 200,
        engagementName: "Service Desk",
        isPassing: true,
      },
      {
        engagementId: 201,
        engagementName: "AMS",
        isPassing: true,
      },
    ],
  },
  {
    clientId: 3,
    clientName: "Dunder Mifflin Inc.",
    engagements: [
      {
        engagementId: 300,
        engagementName: "Service Desk",
        isPassing: false,
      },
      {
        engagementId: 301,
        engagementName: "AMS",
        isPassing: false,
      },
    ],
  },
];

export const EngagementData = [
  {
    clientId: 1,
    clientName: "Shelby Company Ltd.",
    engagementId: 100,
    engagementName: "Service Desk",
    slas: [
      {
        slaId: 1,
        slaName: "CMDB Quality",
        score: 31.7,
        unitOfMeasure: "%",
        goal: 20,
        goalDirection: -1,
        isPassing: false,
      },
      {
        slaId: 2,
        slaName: "P3 Incident Resolution with Callout",
        score: 82.2,
        unitOfMeasure: "%",
        goal: 90,
        goalDirection: 1,
        isPassing: false,
      },
      {
        slaId: 3,
        slaName: "Incidents Reopened",
        score: 7.2,
        unitOfMeasure: "%",
        goal: 10,
        goalDirection: -1,
        isPassing: true,
      },
      {
        slaId: 4,
        slaName: "P2 Incident Resolution",
        score: 93.4,
        unitOfMeasure: "%",
        goal: 90,
        goalDirection: 1,
        isPassing: true,
      },
    ],
  },
  {
    clientId: 1,
    clientName: "Shelby Company Ltd.",
    engagementId: 101,
    engagementName: "AMS",
    slas: [
      {
        slaId: 1,
        slaName: "CMDB Quality",
        score: 12.6,
        unitOfMeasure: "%",
        goal: 20,
        goalDirection: -1,
        isPassing: true,
      },
      {
        slaId: 2,
        slaName: "P3 Incident Resolution with Callout",
        score: 82.2,
        unitOfMeasure: "%",
        goal: 90,
        goalDirection: 1,
        isPassing: false,
      },
      {
        slaId: 3,
        slaName: "Incidents Reopened",
        score: 7.2,
        unitOfMeasure: "%",
        goal: 10,
        goalDirection: -1,
        isPassing: true,
      },
      {
        slaId: 4,
        slaName: "P2 Incident Resolution",
        score: 93.4,
        unitOfMeasure: "%",
        goal: 90,
        goalDirection: 1,
        isPassing: true,
      },
    ],
  },
  {
    clientId: 1,
    clientName: "Shelby Company Ltd.",
    engagementId: 102,
    engagementName: "Desk Side Services",
    slas: [
      {
        slaId: 1,
        slaName: "P2 Incident Response",
        score: 3.2,
        unitOfMeasure: "hr",
        goal: 4,
        goalDirection: -1,
        isPassing: true,
      },
      {
        slaId: 2,
        slaName: "Management Reporting",
        score: "Pass",
        unitOfMeasure: "",
        isPassing: true,
      },
      {
        slaId: 3,
        slaName: "Incidents Reopened",
        score: 7.2,
        unitOfMeasure: "%",
        goal: 10,
        goalDirection: -1,
        isPassing: true,
      },
      {
        slaId: 4,
        slaName: "P2 Incident Resolution",
        score: 93.4,
        unitOfMeasure: "%",
        goal: 90,
        goalDirection: 1,
        isPassing: true,
      },
    ],
  },
];

export const EngagementSLADetailData = [
  {
    slaId: 1,
    slaName: "P2 Incident Response",
    slaDescription:
      "This SLA ensures timely resolution of priority 3 incidents by providing a mechanism for immediate callouts and dedicated resources, minimizing disruption and maximizing response efficiency.",
    comments: [
      {
        commentId: 1,
        commentWarning: true,
        commentDate: "2023-08-17T15:07:50Z",
        commentDetail:
          "Our entire team found themselves embroiled in the most compelling, high-stakes game of 'Where's Waldo?' the office has ever seen. You know, the one with the extraordinarily elusive Waldo hiding amongst a sea of remarkably similar yet slightly different characters. Great news is, we found Waldo! Celebrations all around. However, the not-so-great news is that in the process of finding our stripe-clad friend, we may have allowed our P3 incident resolution times to slip a bit.",
        commentAuthor: "Peter Detweiler",
      },
    ],
    chartData: {
      goal: 90,
      goalDirection: 1,
      months: [
        { month: "August", value: 81.2, label: "Aug '22" },
        { month: "September", value: 91.3, label: "Sep '22" },
        { month: "October", value: 90.1, label: "Oct '22" },
        { month: "November", value: 83.7, label: "Nov '22" },
        { month: "December", value: 89.9, label: "Dec '22" },
        { month: "January", value: 96.4, label: "Jan '23" },
        { month: "February", value: 91.7, label: "Feb '23" },
        { month: "March", value: 89.1, label: "Mar '23" },
        { month: "April", value: 90.9, label: "Apr '23" },
        { month: "May", value: 92.2, label: "May '23" },
        { month: "June", value: 85.4, label: "Jun '23" },
        { month: "July", value: 91.6, label: "Jul '23" },
      ],
    },
  },
  {
    slaId: 2,
    slaName: "Management Reporting",
    slaDescription:
      "This SLA ensures timely resolution of priority 3 incidents by providing a mechanism for immediate callouts and dedicated resources, minimizing disruption and maximizing response efficiency.",
    comments: [
      {
        commentId: 1,
        commentWarning: false,
        commentDate: "2023-05-17T15:07:50Z",
        commentDetail:
          "Our entire team found themselves embroiled in the most compelling, high-stakes game of 'Where's Waldo?' the office has ever seen. You know, the one with the extraordinarily elusive Waldo hiding amongst a sea of remarkably similar yet slightly different characters. Great news is, we found Waldo! Celebrations all around. However, the not-so-great news is that in the process of finding our stripe-clad friend, we may have allowed our P3 incident resolution times to slip a bit.",
        commentAuthor: "Peter Detweiler",
      },
    ],
    chartData: {
      goal: 50,
      goalDirection: 1,
      months: [
        { month: "August", value: 81.2, label: "Aug '22" },
        { month: "September", value: 91.3, label: "Sep '22" },
        { month: "October", value: 90.1, label: "Oct '22" },
        { month: "November", value: 83.7, label: "Nov '22" },
        { month: "December", value: 89.9, label: "Dec '22" },
        { month: "January", value: 96.4, label: "Jan '23" },
        { month: "February", value: 91.7, label: "Feb '23" },
        { month: "March", value: 89.1, label: "Mar '23" },
        { month: "April", value: 90.9, label: "Apr '23" },
        { month: "May", value: 92.2, label: "May '23" },
        { month: "June", value: 85.4, label: "Jun '23" },
        { month: "July", value: 91.6, label: "Jul '23" },
      ],
    },
  },
  {
    slaId: 3,
    slaName: "Incidents Reopened",
    slaDescription:
      "This SLA ensures timely resolution of priority 3 incidents by providing a mechanism for immediate callouts and dedicated resources, minimizing disruption and maximizing response efficiency.",
    comments: [
      {
        commentId: 1,
        commentWarning: true,
        commentDate: "2023-08-17T15:07:50Z",
        commentDetail:
          "Our entire team found themselves embroiled in the most compelling, high-stakes game of 'Where's Waldo?' the office has ever seen. You know, the one with the extraordinarily elusive Waldo hiding amongst a sea of remarkably similar yet slightly different characters. Great news is, we found Waldo! Celebrations all around. However, the not-so-great news is that in the process of finding our stripe-clad friend, we may have allowed our P3 incident resolution times to slip a bit.",
        commentAuthor: "Peter Detweiler",
      },
      {
        commentId: 2,
        commentWarning: true,
        commentDate: "2023-07-17T15:07:50Z",
        commentDetail:
          "Our entire team found themselves embroiled in the most compelling, high-stakes game of 'Where's Waldo?' the office has ever seen. You know, the one with the extraordinarily elusive Waldo hiding amongst a sea of remarkably similar yet slightly different characters. Great news is, we found Waldo! Celebrations all around. However, the not-so-great news is that in the process of finding our stripe-clad friend, we may have allowed our P3 incident resolution times to slip a bit.",
        commentAuthor: "Bill Blaettler",
      },
      {
        commentId: 3,
        commentWarning: true,
        commentDate: "2023-06-17T15:07:50Z",
        commentDetail:
          "Our entire team found themselves embroiled in the most compelling, high-stakes game of 'Where's Waldo?' the office has ever seen. You know, the one with the extraordinarily elusive Waldo hiding amongst a sea of remarkably similar yet slightly different characters. Great news is, we found Waldo! Celebrations all around. However, the not-so-great news is that in the process of finding our stripe-clad friend, we may have allowed our P3 incident resolution times to slip a bit.",
        commentAuthor: "Harrison Heim",
      },
    ],
  },
  {
    slaId: 4,
    slaName: "P2 Incident Resolution",
    slaDescription:
      "This SLA ensures timely resolution of priority 3 incidents by providing a mechanism for immediate callouts and dedicated resources, minimizing disruption and maximizing response efficiency.",
    comments: [
      {
        commentId: 1,
        commentWarning: true,
        commentDate: "2023-07-17T15:07:50Z",
        commentDetail:
          "Our entire team found themselves embroiled in the most compelling, high-stakes game of 'Where's Waldo?' the office has ever seen. You know, the one with the extraordinarily elusive Waldo hiding amongst a sea of remarkably similar yet slightly different characters. Great news is, we found Waldo! Celebrations all around. However, the not-so-great news is that in the process of finding our stripe-clad friend, we may have allowed our P3 incident resolution times to slip a bit.",
        commentAuthor: "Peter Detweiler",
      },
    ],
  },
];

// export const EngagementMilestoneData = [
//   {
//     phaseId: 1,
//     phaseName: "Phase 1",
//     status: "In Progress",
//     milestones: [
//       {
//         milestoneId: 1,
//         milestoneName: "Milestone 1",
//         status: "In Progress",
//         startDate: "7/17/23",
//         endDate: "8/12/23",
//         progress: 0.6
//       },
//       {
//         milestoneId: 2,
//         milestoneName: "Milestone 2",
//         status: "Completed",
//         startDate: "7/17/23",
//         endDate: "8/12/23",
//         progress: 1.0
//       }
//     ]
//   }
// ]

export const EngagementPartnerCategoryData = [
  {
    clientId: 1,
    clientName: "Shelby Company Ltd.",
    engagementId: 100,
    engagementName: "Service Desk",
    totalPartnerSpend: 1102852,
    targetSpend: 12,
    actualSpend: 11.4,
    unitOfMeasure: "%",   
    partners: [
      {
        partnerId: 1,
        partnerName: "MBE Partners",
        target: 8,
        targeToDate: 7.2,
        unitOfMeasure: "%",
        chartData: {
          months: [
            { month: "October", value: 80000, label: "Oct '22" },
            { month: "December", value: 200000, label: "Dec '22" },
            { month: "February", value: 300000, label: "Feb '22" },
            { month: "April", value: 480000, label: "Apr '22" },
            { month: "June", value: 550000, label: "Jun '22" },
            { month: "August", value: 650000, label: "Aug '23" },
            
          ],
        },
      },
      {
        partnerId: 2,
        partnerName: "VBE Partners",
        target: 4,
        targeToDate: 4.2,
        unitOfMeasure: "%",
        chartData: {
          months: [
            { month: "October", value: 40000, label: "Oct '22" },
            { month: "December", value: 110000, label: "Dec '22" },
            { month: "February", value: 180000, label: "Feb '22" },
            { month: "April", value: 280000, label: "Apr '22" },
            { month: "June", value: 350000, label: "Jun '22" },
            { month: "August", value: 400000, label: "Aug '23" },
          ],
        },
      },
    ],
  },
];

export const EngagementIndividualPartnerCategoryData = [
  {
    partnerClassId: 1,
    partnerClassName: "MBE Partners",
    totalPartnerSpend: 0,
    revenue: 0,
    target: 0,
    unitOfMeasure: "%",
    individualPartners: [
      {
        individualPartnerId: 1,
        individualPartnerName: "Nexatech Solutions",
        target: 4,
        targeToDate: 2.9,
        unitOfMeasure: "%",
        chartData: {
          months: [
            //No chart data found
          ],
        },
      },
      {
        individualPartnerId: 2,
        individualPartnerName: "Virtuoso",
        target: 2,
        targeToDate: 2.1,
        unitOfMeasure: "%",
        chartData: {
          months: [
          //No chart data found
          ],
        },
      },
      {
        individualPartnerId: 3,
        individualPartnerName: "Byte Bridge",
        target: 2,
        targeToDate: 2.2,
        unitOfMeasure: "%",
        chartData: {
          months: [
           //No chart data found
          ],
        },
      },
    ],
  },
  {
    partnerId: 2,
    partnerName: "VBE Partners",
    totalPartnerSpend: 412370,
    Revenue: 4.2,
    target: 4,
    unitOfMeasure: "%",
    individualPartners: [
      {
        individualPartnerId: 1,
        individualPartnerName: "Infinitus",
        target: 2,
        targeToDate: 1.8,
        unitOfMeasure: "%",
        chartData: {
          months: [
           //No chart data found
          ],
        },
      },
      {
        individualPartnerId: 2,
        individualPartnerName: "Pinnacletech Partners",
        target: 2,
        targeToDate: 2.4,
        unitOfMeasure: "%",
        chartData: {
          months: [
           //No chart data found
          ],
        },
      },
    ],
  },
];
