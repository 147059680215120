import React from "react";

// const imgLoading = require("../../../images/ripple-loading.svg")
// const imgLoading = require("../../../images/ripple-loading.svg").default;
import { ReactComponent as RippleLoading } from "../../../images/ripple-loading.svg";

export const Progress = (props) => {
    // TODO, determine vs indeterminate
    // TODO, circular vs linear
    return (
        <div>
            {/* <div className="m-loader l-mt-xl"> */}
            <div className={`m-loader ${props.inLine ? "m-loader--inline" : ""}`}>
                <RippleLoading className="m-loader__img" />
                <p className="m-loader__label">{props.children}</p>
            </div>
        </div>
    )
}