import { useState } from "react";
import ClientModel from "../../models/ClientModel"
import { PassIcon, FailIcon } from "../engagementReporting"

export interface ClientProps {
    client: ClientModel;
    setEngagement: Function;
    activeEngagement: number;
}

const Client = (props: ClientProps) => {

    //const initialExpanded = props.client.engagements.find(engagement => engagement.engagementId === props.activeEngagement || 0) !== undefined
    const [isExpanded, toggleExpanded] = useState(false);

    const handleClick = (id:string) => { props.setEngagement(id)}

    return (
        <div className={ isExpanded ? "o-filter__group--expanded" : "o-filter__group" }>
            <div className="o-filter__group-heading">
                <span className="o-filter__heading-label">{props.client.name}</span>
                <button className="o-filter__group-collapse-toggle" aria-expanded="false" onClick={(e) => { toggleExpanded(!isExpanded) }}>
                    <svg className="o-filter__collapse-icon" xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08">
                        <g id="Layer_2" data-name="Layer 2" transform="translate(-6.46 -6.46)">
                            <g id="Layer_1" data-name="Layer 1">
                                <rect className="o-filter__collapse-icon-horizontal" id="Rectangle_45" data-name="Rectangle 45" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect>
                                <rect className="o-filter__collapse-icon-vertical" id="Rectangle_46" data-name="Rectangle 46" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <div className="o-filter__group-contents">
                <nav className="m-vertical-tabs l-ml-xs">
                    <ul className="m-vertical-tabs__list" role="tablist" aria-orientation="vertical">
                        {(props.client.engagements ?? []).map((engagement) => (
                            <li className="m-vertical-tabs__item" role="tab" key={"engagement" + engagement.id} aria-selected="true">
                                <a href="#" className="m-vertical-tabs__link" onClick={ (e) => { e.preventDefault(); handleClick(engagement.id) } }>
                                    {
                                        engagement.isPassing ? 
                                            <PassIcon /> : <FailIcon />
                                    }
                                    <span className="m-vertical-tabs__label">{engagement.name}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Client;