import { createContext, useContext, useEffect, useState } from "react";
import { getMsGraphUserPhotoUrl } from "../../../services/graph";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useAccess } from "./AccessContext";
import { useAuthentication } from "../../../contexts/AuthContext";



const ProfilePictureContext = createContext();

function ProfilePictureProvider({ children }) {

    const { graphAccessToken } = useAuthentication();
    const [userProfileImages, setUserProfileImages] = useState({});
    const apiServiceClient = useSymphonyApiService("v2");
    const { chatMembers } = useAccess()

    useEffect(() => {
        populateProfileImages();
    }, [chatMembers])

    const populateProfileImages = async () => {
        const newImages = { ...userProfileImages }
        for (const member of chatMembers) {
            const img = await getImage(member.userProfile);
            newImages[member.userProfileId] = img;
        }
        setUserProfileImages(newImages);
    }

    const getImage = async (userProfile) => {

        if (userProfileImages[userProfile.userProfileId]) return userProfileImages[userProfile.userProfileId];
        // Deep copy of profile image list to append new one to.
        //const newUserProfileImages = { ...userProfileImages };

        return await getMsGraphUserPhotoUrl(graphAccessToken, userProfile.identifier)
            .then((photo) => {
                const url = window.URL || window.webkitURL;
                const newPhoto = url.createObjectURL(photo);
                //newUserProfileImages[userProfile.userProfileId] = newPhoto;
                //setUserProfileImages(newUserProfileImages);
                return newPhoto;
            })
            .catch((error) => {
                console.warn("The user does not have a profile picture. Defaulting to the user's initials.\n", error);
                // call the name abbreviation endpoint - Using pfpUPN.Split for now,but we should probably add last name to the userProfile context and obfuscate this Avatar link creation behind the API.
                const newPhoto = `https://ui-avatars.com/api/?name=${userProfile.givenName} ${userProfile.familyName}`;
                //newUserProfileImages[userProfile.userProfileId] = newPhoto;
                //setUserProfileImages(newUserProfileImages);
                return newPhoto
            });
    }

    
    return (
        <ProfilePictureContext.Provider
            value={{
                // Data
                userProfileImages

                // Functions

            }}
        >
            {children}
        </ProfilePictureContext.Provider>
    );
}

// Hook to use the MessagesContext in a component
function useProfilePictures() {
    const context = useContext(ProfilePictureContext);
    if (context === undefined) {
        throw new Error(
            "userProfilePictures must be used within a ProfilePictureProvider"
        );
    }
    return context;
}

export { ProfilePictureProvider, useProfilePictures };
