import { useContext } from "react";
import { SettingsContext } from "../../contexts/SettingsContext";
import { NavLink } from "react-router-dom";

const SettingsLink = () => {

  const { isSymphonyAdmin } = useContext(SettingsContext);

  return (
    <>
      { isSymphonyAdmin && <li className="o-top-menu__link-item">
        <NavLink to={"/settings/admin/customization"} className="o-top-menu__link">
          System Settings
        </NavLink>
        {/* <a href="/settings/admin/customization" className="o-top-menu__link">
          System Settings
        </a> */}
      </li>}
    </>
  )
}

export default SettingsLink;
