import { useState, useRef } from "react";
import { Heading, Button } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";

const ExtractInfo = () => {
	const textWidthElement = useRef(null);
    const [info, setInfo] = useState("");
    const [files, setFiles] = useState([]);

    const { isRunning, executeTemplate } = useTemplate();

	const handleInputChange = (e, setInput) => {
		setInput(e.target.value);
		textWidthElement.current.textContent = e.target.value;
		const newWidth =
			textWidthElement.current.getBoundingClientRect().width + 10;
		e.target.style.width = newWidth + "px";
	};

    // These two functions are what I needed to do to not immediately navigate when uploading a file.
    const deleteFiles = (fileInd) => {
        let filesCopy = [...files];
        filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd+1)]
        setFiles([...filesCopy]);
    }

    const handleFileUpload = async (newFiles) => {
        setFiles([...files, ...newFiles]);
        console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
    }

    // Function called when the "Send Message" button is hit.
    const handleSubmit = async () => {
        let prompt = `I have attached a document containing information that I need to extract specific details from and format them in JSON. Please extract the following information:\r\n${info}.\r\n\r\nFormat the extracted information in the following JSON structure:\r\n{\r\n\t"detail1": "value1",\r\n\t"detail2": "value2",\r\n\t"detail3": "value3"\r\n}\r\n\r\nThank you!`;
        executeTemplate(files, [prompt]);
    }

    return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin extracting information from your document, just upload the document, 
                fill out the fields you want to see 
				and hit that Send Message button!
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<p className="o-nl-form__text">
					"I have a document containing information that I need to extract specific
                    details from and format them in JSON. Please extract the following information:
					<input
						type="text"
						placeholder="Detailed, Comma-separated List of Information to Extract"
						className="o-nl-form__text-input"
                        style={{width: "500px"}}
						value={info}
						onChange={(e) => handleInputChange(e, setInfo)}
					/>
					".
				</p>
                <FileDisplay filesOverride={files} deleteOverride={deleteFiles}/>
				<div style={{ marginBottom: "20px" }}>
					<DnDFileUpload
						notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1', 
							'.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx', 
							'.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
							'.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
                        overwriteHandleFiles={handleFileUpload}
					/>
				</div>
				<div className="m-button-row">
					<Button onClick={handleSubmit} disabled={files.length === 0 || isRunning}>
						Send Message
					</Button>
				</div>
			</form>
            <span id="text-width" ref={textWidthElement}>
                Error Message or Unexpected Result And More Length
			</span>
		</>
	);
};

export default ExtractInfo;