import React, { Fragment } from "react";

export interface HeadingProps {
  children?: React.ReactNode;
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  className?: string;
}

const defaultHeadingProps = {
  // size: 1,
};

const Heading = (props: HeadingProps) => {
  props = { ...defaultHeadingProps, ...props };
  if (props.size === undefined) props.size = 1;

  let classes =
    props.size === 1
      ? "a-heading--heading-1"
      : props.size === 2
      ? "a-heading--heading-2"
      : props.size === 3
      ? "a-heading--heading-3"
      : props.size === 4
      ? "a-heading--heading-4"
      : props.size === 5
      ? "a-heading--heading-5"
      : props.size === 6
      ? "a-heading--heading-6"
      : "";

  if (props.className && props.className.length > 0)
    classes = `${classes} ${props.className}`;

  return <h1 className={`a-heading ${classes}`}>{props.children}</h1>;
};

export default Heading;
