/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment, useEffect } from "react";
import enterpriseData from "../../data/EnterpriseData";
import { Block } from "../fusion/Block";
import { Checkbox } from "../fusion/Checkbox";
import { Heading } from "cai-fusion";
import TimeDurationInput from "../TimeDurationInput";
import { Button, HeadingRow, HeadingRowTitle, Progress } from "../fusion";
import myCaiApiService from "../../services/mycai-api-v1";

const icon_CloseSquare = require("../../images/close-square.svg").default;

const EnterpriseDataReportingApp = (props) => {
    const [sourceData, setSourceData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(true);

    const [visibleItems, setVisibleItems] = useState(3);

    const [clients, setClients] = useState([]);
    const [activeClient, setActiveClient] = useState(null);

    const [showDetails, setShowDetails] = useState(false);

    const [monthlyData, setMonthlyData] = useState(null);
    const [metricsStore, setMetricsStore] = useState([]);
    const [updatedResult, setUpdatedResults] = useState([]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function monthNumberToName(monthNumber) {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return monthNames[monthNumber - 1];
    }

    function formatMonthYear(dateString) {
        const year = dateString.slice(0, 4);
        const monthNumber = parseInt(dateString.slice(5, 7), 10);
        const monthName = monthNumberToName(monthNumber);
        return `${monthName} ${year}`;
    }

    function sortDescending(a, b) {
        return new Date(b.StartDate) - new Date(a.StartDate);
    }

    function addMonthlyData(data, visibleItems) {
        data.sectors.forEach((sector) => {
            sector.clients.forEach((client) => {
                client.engagements.forEach((engagement) => {
                    engagement.MonthlyData = {};
                    engagement.VisibleItems = visibleItems;
                    const engagementName = engagement.name
                    engagement.resultSets.forEach((resultSet) => {
                        engagement.MonthlyData[resultSet.resultMonth] = []
                        resultSet.results.forEach((result) => {

                            engagement.MonthlyData[resultSet.resultMonth].push({
                                ResultId: result.resultId,
                                EngagementMetricId: result.engagementMetricId,
                                MetricTitle: result.metricTitle,
                                Result: result.result,
                                StartDate: resultSet.resultSetStartDate,
                                MetricDescription: result.description,
                                TargetTypeId: result.targetTypeId,
                                Published: result.published,
                                Submitted: result.submitted,
                                Comment: result.comment
                            });
                        });
                    });

                    // Convert the MonthlyData object to an array and sort it by descending date
                    engagement.MonthlyData = Object.entries(engagement.MonthlyData)
                        .map(([monthYear, metrics]) => ({
                            MonthYear: monthYear,
                            Metrics: metrics.sort(sortDescending),
                            EngName: engagementName
                        }))
                        .sort(
                            (a, b) =>
                                new Date(b.Metrics[0].StartDate) -
                                new Date(a.Metrics[0].StartDate)
                        );
                });
            });
        });

        return data;
    }

    function monthNameToNumber(monthName) {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return monthNames.indexOf(monthName) + 1;
    }

    function monthIsOpen(monthYear) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed
        const currentYear = currentDate.getFullYear();
        const currentDay = currentDate.getDate();

        const [monthName, year] = monthYear.split(" ");
        const inputMonth = monthNameToNumber(monthName);
        const inputYear = parseInt(year, 10);

        const isLastMonth =
            (inputYear === currentYear && inputMonth === currentMonth - 1) ||
            (inputYear === currentYear - 1 &&
                currentMonth === 1 &&
                inputMonth === 12);

        return isLastMonth && currentDay <= 15;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // INITIAL LOAD,
    // and, reload if token changes (re-auth)
    // or, is Test and Impersonation changes (reload new data)
    useEffect(() => {
        if (!isLoadingData) setIsLoadingData(true);

        setMetricsStore([]);
        setMonthlyData(null);

        if (props.accessToken) {
            // setSourceData(mockData);
            myCaiApiService.GetEnterpriseReportingData().then((data) => {
                let monthlyData = addMonthlyData(data, 3);
                setSourceData(monthlyData);
                setIsLoadingData(false);
                console.log(data);
            });
        }
    }, [props.accessToken, props.impersonation]);

    // Use source data to drive the UI, effect when source data is changed
    useEffect(() => {
        if (sourceData) {
            let clients = [].concat.apply(
                [],
                sourceData.sectors.map((sector) => sector["clients"])
            );
            clients.sort((a, b) =>
                a.ClientName > b.ClientName ? 1 : b.ClientName > a.ClientName ? -1 : 0
            );
            setClients(clients);
        }
    }, [sourceData]);

    const handleClientClicked = (client) => {
        setActiveClient(client);
    };

    useEffect(() => { }, [activeClient]);

    useEffect(() => {
        if (clients.length > 0 && activeClient == null) {
            setActiveClient(clients[0]);
        } else {
            const client = clients.find((cl) => cl.id === activeClient.id)
            setActiveClient(client ? client : clients[0])
        }
    }, [clients]);

    const handleEditClicked = (selectedMonthlyData) => {

        let copyOfMonthly = JSON.parse(JSON.stringify(selectedMonthlyData));
        setMonthlyData(copyOfMonthly);

        let copyOf = JSON.parse(JSON.stringify(selectedMonthlyData.Metrics));
        setMetricsStore(copyOf);
    };

    const handleChange = (index, object, newValue) => {
        object.Result = newValue;


        if (!updatedResult.some(elem => { return object.ResultId === elem })) {
            let newUpdated = [
                ...updatedResult,
                object.ResultId
            ]
            setUpdatedResults(newUpdated)
        }




        let newMetrics = [
            ...monthlyData.Metrics.slice(0, index),
            object,
            ...monthlyData.Metrics.slice(index + 1),
        ];
        setMonthlyData({ ...monthlyData, Metrics: newMetrics });
    };

    const handleCommentChange = (index, object, newValue) => {
        object.Comment = newValue;

        if (!updatedResult.some(elem => { return object.ResultId === elem })) {
            let newUpdated = [
                ...updatedResult,
                object.ResultId
            ]
            setUpdatedResults(newUpdated)
        }




        let newMetrics = [
            ...monthlyData.Metrics.slice(0, index),
            object,
            ...monthlyData.Metrics.slice(index + 1),
        ];
        setMonthlyData({ ...monthlyData, Metrics: newMetrics });
    };

    const handleCancel = () => {
        const newMonthly = monthlyData;
        newMonthly.Metrics = metricsStore;

        setMonthlyData(newMonthly);

        handleClose();
    };

    const handleClose = () => {
        setMetricsStore([]);
        setMonthlyData(null);
    };

    const handleSaveEdit = async (editedMetrics) => {
        // await axios.put('', editedItem);

        // setSourceData(sourceData.map((item) => ));
        // setData(data.map((item) => (item.id === editedItem.id ? editedItem : item)));

        setIsLoadingData(true)

        const activeClientId = activeClient?.id;

        const updates = [];

        monthlyData.Metrics.map((x) => {
            if (updatedResult.some(elem => {
                return elem === x.ResultId
            })) {
                updates.push(
                    {
                        id: x.ResultId,
                        resultValue: x.Result,
                        comment: x.Comment
                    }
                )
            }
        })

        handleClose();
        myCaiApiService.BulkUpdateEnterpriseReportingResult(props.accessToken, props.impersonation, updates).then(() => {
            myCaiApiService.GetEnterpriseReportingData(props.accessToken, props.impersonation).then((data) => {
                let monthlyData = addMonthlyData(data, 3);
                setSourceData(monthlyData);
                setIsLoadingData(false);
                console.log(data);
            })
        })
    };

    return (
        <Fragment>
            {/* <PageContent sectionId="enterprise-data-reporting-app-content"> */}
            <div className="o-page-section l-mt-md l-pt-none">
                <div className="o-page-section__content-container o-page-section__content-container--med-wide-fixed-width l-pt-none l-mt-neg-sm">
                    <div className="l-row l-pt-sm">
                        <div className="col-md-3">
                            <Heading size={3}>Clients</Heading>
                            {isLoadingData && <Progress />}
                            <Heading size={4}>Public Sector</Heading>
                            <nav className="m-vertical-tabs">
                                <ul
                                    className="m-vertical-tabs__list"
                                    role="tablist"
                                    aria-orientation="vertical"
                                >
                                    {/* Repeat */}
                                    {clients.filter((x) => x.sector == "Public").map((client) => (
                                        <li
                                            key={client.id}
                                            className={`m-vertical-tabs__item ${client["id"] === activeClient?.id ?? -1
                                                    ? "m-vertical-tabs__item--active"
                                                    : ""
                                                }`}
                                            role="tab"
                                            tabIndex={0}
                                            aria-selected="true"
                                        >
                                            <a
                                                href=""
                                                className="m-vertical-tabs__link"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleClientClicked(client);
                                                }}
                                            >
                                                <svg
                                                    className="m-vertical-tabs__icon"
                                                    enableBackground="new 0 0 24 24"
                                                    height="512"
                                                    viewBox="0 0 24 24"
                                                    width="512"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="m13.03 1.87-10.99-1.67c-.51-.08-1.03.06-1.42.39-.39.34-.62.83-.62 1.34v21.07c0 .55.45 1 1 1h3.25v-5.25c0-.97.78-1.75 1.75-1.75h2.5c.97 0 1.75.78 1.75 1.75v5.25h4.25v-20.4c0-.86-.62-1.59-1.47-1.73zm-7.53 12.88h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm5 9h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                    <path d="m22.62 10.842-7.12-1.491v14.649h6.75c.965 0 1.75-.785 1.75-1.75v-9.698c0-.826-.563-1.529-1.38-1.71zm-2.37 10.158h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </svg>
                                                <span className="m-vertical-tabs__label">
                                                    {client["name"]}
                                                </span>
                                                {/* <span className="a-badge a-badge--negative m-vertical-tabs__badge">
                          8
                        </span> */}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                            <Heading size={4}>Commercial Sector</Heading>
                            <nav className="m-vertical-tabs">
                                <ul
                                    className="m-vertical-tabs__list"
                                    role="tablist"
                                    aria-orientation="vertical"
                                >
                                    {/* Repeat */}
                                    {clients.filter((x) => x.sector == "Commercial").map((client) => (
                                        <li
                                            key={client.id}
                                            className={`m-vertical-tabs__item ${client["id"] === activeClient?.id ?? -1
                                                ? "m-vertical-tabs__item--active"
                                                : ""
                                                }`}
                                            role="tab"
                                            tabIndex={0}
                                            aria-selected="true"
                                        >
                                            <a
                                                href=""
                                                className="m-vertical-tabs__link"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleClientClicked(client);
                                                }}
                                            >
                                                <svg
                                                    className="m-vertical-tabs__icon"
                                                    enableBackground="new 0 0 24 24"
                                                    height="512"
                                                    viewBox="0 0 24 24"
                                                    width="512"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="m13.03 1.87-10.99-1.67c-.51-.08-1.03.06-1.42.39-.39.34-.62.83-.62 1.34v21.07c0 .55.45 1 1 1h3.25v-5.25c0-.97.78-1.75 1.75-1.75h2.5c.97 0 1.75.78 1.75 1.75v5.25h4.25v-20.4c0-.86-.62-1.59-1.47-1.73zm-7.53 12.88h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm5 9h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                    <path d="m22.62 10.842-7.12-1.491v14.649h6.75c.965 0 1.75-.785 1.75-1.75v-9.698c0-.826-.563-1.529-1.38-1.71zm-2.37 10.158h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </svg>
                                                <span className="m-vertical-tabs__label">
                                                    {client["name"]}
                                                </span>
                                                {/* <span className="a-badge a-badge--negative m-vertical-tabs__badge">
                          8
                        </span> */}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-9">
                            <Heading size={2}>
                                {activeClient?.ClientName ?? ""} Engagements
                            </Heading>
                            {isLoadingData && <Progress />}
                            {(activeClient?.engagements ?? []).map(
                                (engagement) => (
                                    <Block className="l-mt-sm l-mb-sm">
                                        <Heading size={5}>{engagement.name} Monthly Data</Heading>
                                        <table className="o-treegrid o-treegrid--compact l-mr-sm">
                                            <colgroup className="o-treegrid__colgroup">
                                                <col
                                                    className="o-treegrid__col o-treegrid__col--medium"
                                                    span={1}
                                                />
                                                <col
                                                    className="o-treegrid__col o-treegrid__col--large"
                                                    span={1}
                                                />
                                                <col
                                                    className="o-treegrid__col o-treegrid__col--medium"
                                                    span={1}
                                                />
                                            </colgroup>
                                            <tbody>
                                                {engagement.MonthlyData.slice(
                                                    0,
                                                    engagement.VisibleItems
                                                ).map((monthlyData) => (
                                                    <tr className="o-treegrid_row o-treegrid_row--shown">
                                                        <td className="o-treegrid__cell">
                                                            <span className="a-subtle-text">
                                                                <strong>{monthlyData.MonthYear}</strong>
                                                            </span>
                                                        </td>
                                                        {monthIsOpen(monthlyData.MonthYear) ? (
                                                            <>
                                                                <td className="o-treegrid__cell">
                                                                    <p className="a-subtle-text l-mb-none">
                                                                        This data will automatically be submitted on
                                                                        the 15th.
                                                                    </p>
                                                                    {monthlyData.Metrics.filter(
                                                                        (metric) =>
                                                                            (metric.Result === null ||
                                                                                metric.Result === undefined) && metric.Submitted === false
                                                                    ).length > 0 ? (
                                                                        <p className="a-subtle-text a-warning-text l-mb-none">
                                                                            {
                                                                                monthlyData.Metrics.filter(
                                                                                    (metric) =>
                                                                                        (metric.Result === null ||
                                                                                            metric.Result === undefined) && metric.Submitted === false
                                                                                ).length
                                                                            }{" "}
                                                                            required fields are missing.
                                                                        </p>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </td>
                                                                <td className="o-treegrid__cell">
                                                                    <Button
                                                                        className="a-button--small a-button--full-width"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleEditClicked(monthlyData);
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                </td>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <td className="o-treegrid__cell">
                                                                    <span className="a-subtle-text">
                                                                        This data has been submitted.
                                                                    </span>
                                                                    {
                                                                        monthlyData.Metrics.filter(
                                                                            (metric) =>
                                                                                (metric.Result === null ||
                                                                                    metric.Result === undefined) && metric.Submitted === false
                                                                        ).length > 0 ? (
                                                                            <p className="a-subtle-text a-warning-text l-mb-none">
                                                                                {
                                                                                    monthlyData.Metrics.filter(
                                                                                        (metric) =>
                                                                                            (metric.Result === null ||
                                                                                                metric.Result === undefined) && metric.Submitted === false
                                                                                    ).length
                                                                                }{" "}
                                                                                required fields are missing.
                                                                            </p>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                </td>
                                                                <td className="o-treegrid__cell">
                                                                    <Button
                                                                        className="a-button--small a-button--full-width"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleEditClicked(monthlyData);
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                </td>
                                                            </>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {engagement.VisibleItems <
                                            engagement.MonthlyData.length && (
                                                <Button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        engagement.VisibleItems =
                                                            engagement.MonthlyData.length;
                                                        // hack to force render cycles.. visible items only used in the loading of data
                                                        setVisibleItems(
                                                            visibleItems === engagement.VisibleItems
                                                                ? -1
                                                                : engagement.VisibleItems
                                                        );
                                                    }}
                                                >
                                                    Show More
                                                </Button>
                                            )}
                                    </Block>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* </PageContent> */}
            <div
                className={`o-drawer o-drawer--has-button-row ${monthlyData === null ? "o-drawer--hidden" : "o-drawer--shown"
                    }`}
                id="data-entry-drawer"
            >
                <div
                    className="o-drawer__overlay"
                    onClick={(e) => {
                        e.preventDefault();
                        handleCancel();
                    }}
                ></div>
                <div className="o-drawer__wrapper">
                    <div className="m-heading-row">
                        <div className="m-heading-row__title">
                            <span className="a-eyebrow">{monthlyData?.MonthYear ?? ""}</span>
                            <h3 className="a-heading a-heading--heading-3 l-mb-none">
                                {activeClient?.name}: {monthlyData?.EngName}
                            </h3>
                        </div>
                        <div className="m-heading-row__actions">
                            <div className="m-icon-row m-icon-row--right-align">
                                <a
                                    href="#"
                                    id="drawerCloseBtn"
                                    className="a-icon m-icon-row__icon"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleClose();
                                    }}
                                >
                                    <img
                                        src={icon_CloseSquare}
                                        className="a-icon__img"
                                        alt="Close"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="o-drawer__inner">
                        <form className="o-form l-mt-sm">
                            <div className="row">
                                <div className="col-md-12">
                                    {!monthlyData ||
                                        !monthlyData.Metrics ||
                                        (monthlyData.Metrics.length == 0 && (
                                            <label>
                                                No metrics are being recorded for the selected
                                                engagement.
                                            </label>
                                        ))}
                                    {monthlyData &&
                                        monthlyData.Metrics &&
                                        monthlyData.Metrics.map((metric, index) => (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="m-form-group l-mb-none" key={metric.ResultId}>
                                                            <label for={metric.MetricTitle} className="a-label">
                                                                {metric.MetricTitle}
                                                            </label>
                                                            <span className="a-muted-text">
                                                                {metric.MetricDescription}{" "}
                                                                {metric.TargetTypeId == 1
                                                                    ? "(as a Percent)"
                                                                    : metric.TargetTypeId == 2
                                                                        ? "(as a Number)"
                                                                        : metric.TargetTypeId == 3
                                                                            ? "(check for Pass, uncheck for Fail)"
                                                                            : metric.TargetTypeId == 4
                                                                                ? "(as a Time Duration)"
                                                                                : ""}
                                                            </span>
                                                            {/*  */}
                                                            {(metric.TargetTypeId == 1 ||
                                                                metric.TargetTypeId == 2) && (
                                                                <input
                                                                    type="number"
                                                                    step="0.01"
                                                                    id={`${metric.MetricTitle}`}
                                                                    name={`${metric.MetricTitle}`}
                                                                    defaultValue={metric.Result ? Number((metric.Result * (metric.TargetTypeId == 1 ? 100 : 1)).toFixed((metric.TargetTypeId == 1 ? 2 : 4))) : ''}
                                                                    className="a-text-input"
                                                                    disabled={metric.Result == null && metric.Submitted}
                                                                    onChange={(e) => e.target.value}
                                                                    onBlur={(e) => {
                                                                            handleChange(
                                                                                index,
                                                                                metric,
                                                                                e.target.value / (metric.TargetTypeId == 1 ? 100 : 1))
                                                                    }
                                                                     

                                                                        }
                                                                    />
                                                                )}
                                                            {metric.TargetTypeId == 3 && (
                                                                <Checkbox
                                                                    label="Did Pass?"
                                                                    id={`${metric.MetricTitle}`}
                                                                    name={`${metric.MetricTitle}`}
                                                                    isChecked={metric.Result === 1 ? true : false}
                                                                    disabled={metric.Result == null && metric.Submitted}
                                                                    onChange={(isChecked) =>
                                                                        handleChange(index, metric, isChecked ? 1 : 0)
                                                                    }
                                                                />
                                                            )}
                                                            {/* {(metric.TargetTypeId == 4) && <TimeDurationInput scale="s" value={metric.Result} id={`${metric.MetricTitle}`} name={`${metric.MetricTitle}`} className="a-text-input" />} */}
                                                            {metric.TargetTypeId == 4 && (
                                                                <div className="l-row l-mt-xs">
                                                                    <div className="col-md-4">
                                                                        <div className="m-form-group m-form-group--inline l-mb-none">
                                                                            <label for={metric.MetricTitle} className="a-label">Hours</label>
                                                                            <input type="number" className="a-text-input" value={(metric.Result - (metric.Result % 60)) / 60}
                                                                            onChange={(newVal) => {
                                                                                const hrs = Number(newVal.target.value);
                                                                                const mins = Number(metric.Result % 60 - metric.Result % 1);
                                                                                const secs = Number((metric.Result % 1 * 60).toFixed(0));

                                                                                const decMins = hrs * 60 + mins + secs / 60;

                                                                                handleChange(index, metric, decMins);
                                                                                }}
                                                                                disabled={metric.Result == null && metric.Submitted}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="m-form-group m-form-group--inline l-mb-none">
                                                                            <label for={metric.MetricTitle} className="a-label">Min</label>
                                                                            <input type="number" className="a-text-input" value={(metric.Result % 60 - metric.Result % 1)}
                                                                                onChange={(newVal) =>
                                                                                {
                                                                                    const hrs = Number((metric.Result - (metric.Result % 60)) / 60);
                                                                                    const mins = Number(newVal.target.value);
                                                                                    const secs = Number((metric.Result % 1 * 60).toFixed(0));

                                                                                    const decMins = hrs * 60 + mins + secs / 60;

                                                                                    handleChange(index, metric, decMins);
                                                                                }}
                                                                                disabled={metric.Result == null && metric.Submitted}
                                                                            />
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="m-form-group m-form-group--inline l-mb-none">
                                                                            <label for={metric.MetricTitle} className="a-label">Sec</label>
                                                                            <input type="number" className="a-text-input" value={Number((metric.Result % 1 * 60).toFixed(0))}
                                                                                onChange={(newVal) => {
                                                                                    const hrs = Number((metric.Result - (metric.Result % 60)) / 60);
                                                                                    const mins = Number(metric.Result % 60 - metric.Result % 1);
                                                                                    const secs = Number(newVal.target.value);

                                                                                    const decMins = hrs * 60 + mins + secs / 60;

                                                                                    handleChange(index, metric, decMins);
                                                                                }}
                                                                                disabled={metric.Result == null && metric.Submitted}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {metric.Comment == null ? <></> :
                                                            <div className="o-block o-block--compact l-mt-xs">
                                                                <div className="m-form-group l-mb-none">
                                                                    <label for={metric.MetricTitle} className="a-label">Comment</label>
                                                                    <textarea id={`${metric.MetricTitle}Comment`} name={`${metric.MetricTitle}Comment`} className="a-textarea-input" onChange={((text) => {
                                                                        handleCommentChange(index, metric, text.target.value)
                                                                    })}>{metric.Comment}</textarea>
                                                                </div>
                                                            </div>
                                                        }
                                                        <a className="a-icon-link m-icon-row__icon l-mb-xs" href="#">
                                                            <div className="a-icon-link__inner">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="a-icon-link__icon"></svg>
                                                                <span className="a-icon-link__text" onClick={() => handleCommentChange(index, metric, metric.Comment == null ? "" : null)}>{metric.Comment == null ? "Add comment" : "Remove comment"}</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-3 l-pt-sm">
                                                        <label className={`a-checkbox l-mt-xs`} >
                                                            <span>N/A</span>
                                                            <input type="checkbox"
                                                                checked={metric.Result == null && metric.Submitted}
                                                                onChange={() => { handleChange(index, metric, metric.Result == null && metric.Submitted ? 0 : null); metric.Submitted = true }}
                                                            />
                                                            <div className="b-input"></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="o-drawer__fixed-btn-row">
                        <div className="m-button-row">
                            <button
                                className="a-button a-button--outline m-button-row__button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleCancel();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="a-button m-button-row__button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSaveEdit(monthlyData.Metrics);
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default EnterpriseDataReportingApp;
