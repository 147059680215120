import React from "react";

export interface BreadcrumbProps {
  children?: React.ReactNode;
  data?: Array<BreadcrumbItem>;
  className?: string;
}

const defaultBreadcrumbProps = {
  className: "",
};

export interface BreadcrumbItem {
  displayName: string;
  onClick(): undefined;
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  props = { ...defaultBreadcrumbProps, ...props };

  return (
    <nav className={`m-breadcrumbs ${props.className}`}>
      <ul className="m-breadcrumbs__trail">
        {props.data &&
          props.data.slice(0, -1).map((x) => (
            <li className="m-breadcrumbs__item">
              <a
                href=""
                className="m-breadcrumbs__link"
                onClick={(e) => {
                  e.preventDefault();
                  x.onClick();
                }}
              >
                {x.displayName}
              </a>
            </li>
          ))}
        {props.data &&
          props.data.slice(-1).map((x) => (
            <li className="m-breadcrumbs__item m-breadcrumbs__item--active">
              <a
                href=""
                className="m-breadcrumbs__link"
                style={{ cursor: "default", pointerEvents: "none" }}
                onClick={(e) => {
                    e.preventDefault();
                    x.onClick();
                }}
              >
                {x.displayName}
              </a>
            </li>
          ))}
      </ul>
    </nav>
  );
};
