import React, { useState, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  useParams,
  Routes,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import {
  Block,
  Breadcrumb,
  Button,
  FilterManager,
  FilterManagerTerm,
  FilterManagerTerms,
  Heading,
  HeadingRow,
  HeadingRowActions,
  HeadingRowTitle,
  Modal,
  Progress,
  StatRow,
  StatRowItem,
} from "../fusion";
import { BonusMeritChangeFilterPanel } from "./bonusChangeFilterPanel";
import { BonusDetailsPanel } from "./bonusDetailsPanel";
import { MeritChangeRow } from "./bonusChangeRow";
import myCaiApiService from "../../services/mycai-api-v1";
import { BonusMeritChangeTableHeaders } from "./bonusChangeTableHeaders";
import { BonusMeritChangeBulkActionModal } from "./bonusChangeBulkAction";
import { sort } from "d3";

const icon_Cancel = require("../../images/cancel-white.svg").default;

const annualizedHours = 1880;

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 1
});

const BonusMeritChange = (props) => {
  // Navigation Hooks
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Loading States
  const [loadingChangeGroup, setLoadingChangeGroup] = useState(true);

  // new data model for getting all uses
  const [newData, setNewData] = useState([]);
  // Data States
  // source is all the available data
  const [sourceData, setSourceData] = useState([]);
  // grouped is all the data sorted into regions
  const [groupedData, setGroupedData] = useState({});
  // display is what is bound to the UI, with filters applied
  const [displayData, setDisplayData] = useState([]);
  // tracks current filters
  const [dataFilters, setDataFilters] = useState([]);

  //tracks current region tab
  const [regionSet, setRegionSet] = useState("USA");

  // basically just for the header data
  const [rootChangeGroup, setRootChangeGroup] = useState(null);
  const [upn, setUpn] = useState(props.upn);
  const [orgSumaryData, setOrgSummaryData] = useState(null);

  // Row selection states
  // track whether the actions control is disabled or not
  const [actionsAvailable, setActionsAvailable] = useState(
    displayData &&
    displayData.filter((x) => {
      return x.selected;
    }).length !== 0
  );
  // track data selected to apply action to
  const [selectedData, setSelectedData] = useState([]);
  // if we should check off the "selected" item in the header
  const [allSelected, setAllSelected] = useState(false);
  // track the state of the "parent" thinking about the rows as a selection group
  const [groupIsChecked, setGroupIsChecked] = useState(false);
  // track selected index for the actions drop down
  const [selectedActionIndex, setSelectedActionIndex] = useState(0);
  const [showActionsModal, setShowActionsModal] = useState(false);

  const [meritUpdate, setMeritUpdate] = useState(false);
  // WHEN THE GROUP IS CHANGED
  const checkboxGroupIsCheckedChangeHandler = () => {
    // console.log(`Group is checked: ${!groupIsChecked}`);
    setGroupIsChecked(!groupIsChecked);
  };

  const onCheckboxGroupChange = (groupIsChecked) => {
    // do nothing, handle only in groupMemberChange Handler
    var newDisplayState = displayData.map((el) =>
      el["isEligible"] && el["assignedToUser"]
        ? {
          ...el,
          selected: groupIsChecked,
        }
        : el
    );
    setDisplayData(newDisplayState);
  };

  const onCheckboxGroupMemberChange = (key, checked) => {
    // console.log(`${key}: ${checked}`);

    var newDisplayState = displayData.map((el) =>
      el["employeeId"] === key // && el["isEligible"] && el["assignedToUser"]
        ? {
          ...el,
          selected: checked,
        }
        : el
    );
    setDisplayData(newDisplayState);
    // setNewData(newDisplayState);
  };

  useEffect(() => {
    let newSelectedState = displayData.filter(
      (x) => x.selected // && x.isEligible && x.assignedToUser
    );
    // console.log("New Selected State");
    // console.log(newSelectedState);
    setSelectedData(newSelectedState);
  }, [displayData]);

  useEffect(() => {
    console.log("The display Data Changed");
  }, [displayData])

  // WHEN REGION IS CHANGED
  const regionTabChangeHandler = (newRegionSet) => {
    setRegionSet(newRegionSet);

    if (newRegionSet == "IND") {
      setDisplayData(groupedData["IND"]);
    } else if (newRegionSet == "PHL") {
      setDisplayData(groupedData["PHL"]);
    } else {
      setDisplayData(groupedData["USA"]);
    }
  };

  // WHEN REGION IS UPDATED AFTER FIRST LOAD
  const regionUpdate = (newRegionSet) => {
    setRegionSet(newRegionSet);

    if (newRegionSet == "IND") {
      return groupedData["IND"];
    } else if (newRegionSet == "PHL") {
      return groupedData["PHL"];
    } else {
      return groupedData["USA"];
    }
  };


  // WHEN A MEMBER IS CHECKED
  const checkboxMemberIsCheckedChangeHandler = (key, isChecked) => {
    onCheckboxGroupMemberChange(key, isChecked);
  };

  useEffect(() => {
    onCheckboxGroupChange(groupIsChecked);
  }, [groupIsChecked]);

  // Details States
  // sets the details bound to the panel.. also drives panel visibility??
  const [detailsData, setDetailsData] = useState(null);
  // details modal is the confirmation modal for save/cancel
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState(null);

  // Filter and Filter Panel
  const [showFilterPanel, setShowFilterPanel] = useState(false);

  // Page State
  const [isLoading, setIsLoading] = useState(false);

  const handleAction = () => {
    // console.log(selectedData);
    switch (selectedActionIndex) {
      case 0:
        console.log("SET INCREASE %");
        break;
      case 1:
        console.log("SET INCREASE $");
        break;
      default:
        return;
    }
  };

  const removeFilter = (groupKey, filterKey) => {
    changeFilterState(groupKey, filterKey, false);
  };

  const turnOnFilter = (groupKey, filterKey) => {
    changeFilterState(groupKey, filterKey, true);
  };

  const changeFilterState = (groupKey, filterKey, newIsChecked) => {
    var newFilterState = dataFilters.map((el) =>
      el["groupKey"] === groupKey
        ? {
          ...el,
          options: el.options.map((el) =>
            el["filterKey"] === filterKey
              ? {
                ...el,
                isChecked: newIsChecked,
              }
              : el
          ),
        }
        : el
    );

    setDataFilters(newFilterState);
  };

  const resetFiltersClickedHandler = () => {
    // console.log("Reset Filters");

    var newFilterState = dataFilters.map((el) =>
      el["groupKey"] === el["groupKey"]
        ? {
          ...el,
          options: el.options.map((el) =>
            el["filterKey"] === el["filterKey"]
              ? {
                ...el,
                isChecked: false,
              }
              : el
          ),
        }
        : el
    );

    setDataFilters(newFilterState);
  };

  useEffect(() => {
    if (props.isLoading) console.log("Is Loading");
    if (!props.isLoading) console.log("Is No Longer Loading");

    setIsLoading(props.isLoading);
  }, [props.isLoading, setIsLoading]);

  const getChildGroupData = (data) => {
    let listOfMeritChanges = [];
    if (data.childChangeGroups.length == 0) {
      // console.log("base case is s called")
      return data.meritChanges;
    }
    else if (data.childChangeGroups.length != 0) {
      //  console.log("following case is called")
      listOfMeritChanges = listOfMeritChanges.concat(data.meritChanges);
      for (let i = 0; i < data.childChangeGroups.length; i++) {
        listOfMeritChanges = listOfMeritChanges.concat(getChildGroupData(data.childChangeGroups[i]));
      }
      return listOfMeritChanges;
    }
  }

  const searchHierarchy = (element, key) => {
    if (element.changeGroupId === key) {
      return element;
    } else if (element.childChangeGroups != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.childChangeGroups.length; i++) {
        result = searchHierarchy(element.childChangeGroups[i], key);
      }
      return result;
    }
    return null;
  };

  const searchRootGroup = (rootGroup, meritChangeList) => {
    if (rootGroup?.meritChanges) {
      meritChangeList = meritChangeList.concat(rootGroup.meritChanges);
    }
    if (rootGroup?.childChangeGroups != null) {
      for (let i = 0; i < rootGroup.childChangeGroups.length; i++) {
        meritChangeList = meritChangeList.concat(
          searchRootGroup(rootGroup.childChangeGroups[i], [])
        );
      }
    }
    return meritChangeList;
  };

  useEffect(() => {
    if (props.data) {
      // console.log("props.data in merit changes");
      // console.log(props.data);
      // flatten and filter here
      let targetChangeGroupId = params.changeGroupId;
      // this is dumb, there should only be one, since it's one change group Id.. it's too late though
      // all the code already accounts for this being an array and I can't go back now
      let rootChangeGroups = props.data.map((x) =>
        searchHierarchy(x, targetChangeGroupId)
      );
      // let rootChangeGroups = [props.data[0]];
      let rootChangeGroup = rootChangeGroups.filter((x) => x != null)[0];
      let meritChangeList = [];
      for (let i = 0; i < rootChangeGroups.length; i++) {
        let rootGroup = rootChangeGroups[i];
        meritChangeList = meritChangeList.concat(
          searchRootGroup(rootGroup, [])
        );
      }
      for (let i = 0; i < meritChangeList.length; i++) {
        meritChangeList[i]["assignedTo"] =
          props.data.map((y) =>
            searchHierarchy(y, meritChangeList[i]["changeGroupId"])
          ).filter((x) => x != null)[0]?.assignedUPN ?? "";
        meritChangeList[i]["assignedToUser"] =
          meritChangeList[i]["assignedTo"].localeCompare(props.upn, 'en-us', { sensitivity: 'accent' }) === 0;
      }
      setSourceData(meritChangeList);
      // console.log(`RootChangeGroup`);
      // console.log(rootChangeGroup);
      setRootChangeGroup(rootChangeGroup);
      // setOrgSummaryData()
    }
  }, [props.data]);

  // useEffect(() => {
  //   if (props.data) {
  //     let finalData = [];

  //     // console.log("props.data")
  //     // console.log(props.data);
  //     finalData = getChildGroupData(props.data[0]);

  //     // console.log("finalData");
  //     // console.log(finalData);
  //     let filterData = [];
  //     // checks whether or not the group is owned or assigned
  //     if (props.data[0].discriminator === "owned") {
  //       filterData = finalData;
  //     } else {
  //       // if assigned then only show bonus plans that have been submitted
  //       filterData = finalData.filter((val) => {
  //         return val.status != "";
  //       });
  //     }
  //     let sortedData = filterData.sort((a, b) => a.status > b.status ? 1 : -1);
  //     // removes duplicates
  //     sortedData = sortedData.filter((item, index) => {
  //       return sortedData.indexOf(item) === index;
  //     });

  //     console.log("Final Sorted Data");
  //     console.log(sortedData);
  //     setNewData(sortedData);
  //   }
  // }, [props.role, props.data])

  useEffect(() => {
    const _filterOptions = [];
    // _filterOptions.push({
    //   groupName: undefined,
    //   groupKey: "onlyAssignedToMe",
    //   options: [
    //     {
    //       filterKey: "onlyMe",
    //       filterPolarity: "restrict",
    //       filterFunction: (data) => data.assignedToUser === true,
    //       displayProperty: "",
    //       displayValue: "Only changes assigned to me",
    //       filterLabel: "Only show merit changes currently assigned to me",
    //       isChecked: true,
    //       filterGroup: "onlyAssignedToMe",
    //     },
    //   ],
    // });
    // _filterOptions.push({
    //   groupName: undefined,
    //   groupKey: "noIncrease",
    //   options: [
    //     {
    //       filterKey: "noIncreaseSpecified",
    //       filterPolarity: "restrict",
    //       filterFunction: (data) =>
    //         (data.proposedBaseComp === null ||
    //           data.proposedBaseComp === 0.0 ||
    //           data.reviewed === false) &&
    //         data.isEligible === true,
    //       displayProperty: "",
    //       displayValue: "Employees with no increase specified",
    //       filterLabel: "Only show employees with no increase specified",
    //       isChecked: false,
    //       filterGroup: "noIncrease",
    //     },
    //   ],
    // });
    // _filterOptions.push({
    //   groupName: undefined,
    //   groupKey: "ineligible",
    //   options: [
    //     {
    //       filterKey: "ineligible",
    //       filterPolarity: "restrict",
    //       filterFunction: (data) => data.isEligible === false,
    //       displayProperty: "",
    //       displayValue: "Only ineligible employees",
    //       filterLabel: "Only show associates ineligible for an increase",
    //       isChecked: false,
    //       filterGroup: "ineligible",
    //     },
    //     {
    //       filterKey: "eligible",
    //       filterPolarity: "restrict",
    //       filterFunction: (data) => data.isEligible === true,
    //       displayProperty: "",
    //       displayValue: "Only eligible employees",
    //       filterLabel: "Only show associates eligible for an increase",
    //       isChecked: false,
    //       filterGroup: "ineligible",
    //     },
    //   ],
    // });
    // _filterOptions.push({
    //   groupName: "Performance Score",
    //   groupKey: "performance",
    //   options: [1, 2, 3, 4, 5].map((performanceScoreValue) => {
    //     return {
    //       filterKey: `performanceScore_${performanceScoreValue}`,
    //       filterPolarity: "include",
    //       filterFunction: (data) =>
    //         data.lastReviewLevel === performanceScoreValue,
    //       displayProperty: "Perf Score:",
    //       displayValue: String(performanceScoreValue),
    //       filterLabel: String(performanceScoreValue),
    //       isChecked: false,
    //       filterGroup: "performanceScore",
    //     };
    //   }),
    // });
    // _filterOptions
    //   .filter((x) => x.groupKey === "performance")[0]
    //   .options.push({
    //     filterKey: `performanceScore_none`,
    //     filterPolarity: "include",
    //     filterFunction: (data) =>
    //       data.lastReviewLevel === null || data.lastReviewLevel === undefined,
    //     displayProperty: "Perf Score:",
    //     displayValue: "None",
    //     filterLabel: "None",
    //     isChecked: false,
    //     filterGroup: "performanceScore",
    //   });

    // // add role based filters based on source
    // _filterOptions.push({
    //   groupName: "Roles",
    //   groupKey: "role",
    //   options: sourceData
    //     .map((x) => x.jobTitle)
    //     .filter((value, index, self) => self.indexOf(value) === index)
    //     .sort((a, b) => a.localeCompare(b))
    //     .map((role) => {
    //       return {
    //         filterKey: `role_${role}`,
    //         filterPolarity: "include",
    //         filterFunction: (data) => data.jobTitle === role,
    //         displayProperty: "Role:",
    //         displayValue: role,
    //         filterLabel: role,
    //         isChecked: false,
    //         filterGroup: "role",
    //       };
    //     }),
    // });

    setDataFilters(_filterOptions);
  }, [setDataFilters, sourceData]);


  // When source data is changed, assume it' a brand new page
  useEffect(() => {
    // set display data from source, only when source changes
    var newGrouped = {
      USA: [],
      IND: [],
      PHL: [],
    };

    for (var i = 0; i < sourceData.length; i++) {
      var row = sourceData[i];
      if (row.company == "PHL CAI-STA Philippines, Inc.") {
        newGrouped["PHL"].push(row);
      } else if (row.company == "INDIA CAI Info India Private Ltd.") {
        newGrouped["IND"].push(row);
      } else {
        newGrouped["USA"].push(row);
      }

      if (newGrouped["USA"].length == 0) {
        if (newGrouped["IND"].length == 0) {
          setRegionSet("PHL");
        } else {
          setRegionSet("IND");
        }
      }
    }
    console.log(newGrouped);
    setGroupedData(newGrouped);

    //setDisplayData(newGrouped["USA"]);

    // checks if its a merit update or just a regular load
    if (!meritUpdate)
      setDisplayData(sourceData);

    console.log(sourceData);
    // don't reset data filters.. either it's empty or persist state
    // setDataFilters(dataFilters);

    setGroupIsChecked(false);
    // onCheckboxGroupChange(false);
  }, [sourceData]);

  // When selected rows changes, set if actions are available, and set if all are selected
  useEffect(() => {
    if (selectedData) {
      console.log(`Num Selected: ${selectedData.length}`);

      setActionsAvailable(selectedData.length !== 0);

      let eligbileDisplayData = displayData.filter(
        (x) => x.isEligible === true && x.assignedToUser === true
      );

      setAllSelected(
        selectedData.length > 0 &&
        selectedData.length === eligbileDisplayData.length
      );
    }
  }, [selectedData]);

  // Do filtering when data filter is changed
  useEffect(() => {
    // console.log("UPDATE DISPLAY DATA");

    var filteredData = sourceData;
    setMeritUpdate(false);

    let appliedDataFilters = dataFilters
      .map((fg) => fg.options)
      .flat()
      .filter((f) => f.isChecked);

    // Each group is (AND) with eachother
    // AND (OR) for options within the group
    let groupsRepresented = appliedDataFilters
      .map((df) => df.filterGroup)
      .filter((value, index, array) => array.indexOf(value) === index);
    for (let k = 0; k < groupsRepresented.length; k++) {
      let group = groupsRepresented[k];
      let groupFilters = appliedDataFilters.filter(
        (x) => x.filterGroup === group
      );

      if (groupFilters.length > 0) {
        // Only include data from each GROUP.. (this is the AND between groups)
        filteredData = filteredData.filter((data) => {
          // if matches at least one group filter.. (this is the OR inside a group)
          for (let i = 0; i < groupFilters.length; i++) {
            let filter = groupFilters[i];
            // short circuit the loop and include in the data
            if (filter.filterFunction(data)) {
              return true;
            }
          }
          // didn't match any group filters
          return false;
        });
      }
    }
    setDisplayData(filteredData);
  }, [dataFilters]);

  // Initital data load!
  useEffect(() => {
    if (!loadingChangeGroup) setLoadingChangeGroup(true);

    if (props.accessToken) {
      // do api call if needed...
    }
  }, [props.accessToken, props.impersonation]);

  return (
    <Fragment>
      <div className="o-page-section o-page-section l-mt-md l-pt-none">
        <div className="o-page-section__content-container o-page-section__content-container--wide-fixed-width l-pt-none l-mt-neg-sm">
          <div className="l-row">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <Breadcrumb
                data={[
                  {
                    displayName: "Organizations",
                    onClick: () => {
                      navigate(`/bonus/`, { replace: true });
                    },
                  },
                  {
                    displayName:
                      rootChangeGroup &&
                      rootChangeGroup.name &&
                      rootChangeGroup.name.substring(
                        0,
                        rootChangeGroup.name.indexOf(" (")
                      ),
                    onClick: () => { },
                  },
                ]}
              />
              <HeadingRow>
                <HeadingRowTitle>
                  <Heading size={2}>
                    {rootChangeGroup &&
                      rootChangeGroup.name &&
                      rootChangeGroup.name.substring(
                        0,
                        rootChangeGroup.name.indexOf(" (")
                      )}
                  </Heading>
                </HeadingRowTitle>
                <HeadingRowActions>
                  <div className="m-heading-row__actions-inner">
                    <div className="m-button-row">
                      {/* <button className={`a-button ${sourceData.filter(
                        (data) =>
                          data.proposedBaseComp === null ||
                          data.proposedBaseComp == 0.0 ||
                          (data.reviewed === false && data.isEligible)
                      ).length > 0 ? "a-button--disabled" : ""}`} onClick={() => {
                        myCaiApiServiceSubmitChangeGroups(props.accessToken, params.changeGroupId, props.impersonation?.employeeId).then(() => {
                          props.onMeritUpdate().then(() => {
                            navigate(`/payroll/`, {
                              replace: true,
                            });
                          })
                        })
                      }}>
                        Submit Increases
                      </button> */}
                    </div>
                  </div>
                </HeadingRowActions>
              </HeadingRow>
            </div>
          </div>
          <div className="l-row">
            <div className="col-md-12">
              <Block className="l-mt-sm l-pt-md l-pb-sm">
                <Heading size={4}>
                  {isLoading
                    ? "Loading Updated Compensation Numbers"
                    : "Organization Bonus Summary"}
                </Heading>
                <StatRow className="o-stat-row--left-aligned l-mt-sm">
                  {isLoading ? (
                    <div className={`o-stat-row__stat m-stat`}>
                      <Progress />
                    </div>
                  ) : (
                    <StatRowItem
                      value={
                        rootChangeGroup &&
                        rootChangeGroup.previousBonusTarget &&
                        formatter.format(rootChangeGroup.previousBonusTarget)
                      }
                      label={"2023 Target Bonus Total"}
                    />
                  )}
                  {isLoading ? (
                    <div className={`o-stat-row__stat m-stat`}>
                      <Progress />
                    </div>
                  ) : (
                    <StatRowItem
                      value={
                        rootChangeGroup &&
                        rootChangeGroup.currentBonusTarget &&
                        formatter.format(rootChangeGroup.currentBonusTarget)
                      }
                      label={"2024 Target Bonus Total"}
                    />
                  )}
                  {isLoading ? (
                    <div className={`o-stat-row__stat m-stat`}>
                      <Progress />
                    </div>
                  ) : (
                    <StatRowItem
                      value={
                        rootChangeGroup &&
                        rootChangeGroup.currentBonusTarget &&
                        rootChangeGroup.previousBonusTarget &&
                        String(
                          percentFormatter.format(
                            (
                              (rootChangeGroup.currentBonusTarget - rootChangeGroup.previousBonusTarget) /
                              rootChangeGroup.previousBonusTarget
                            )
                          )
                        )
                      }
                      label={"YoY Proposed Target Bonus Change Percentage"}
                    />
                  )}
                </StatRow>
              </Block>

              <FilterManager className="l-mt-sm l-mb-md">
                <Button
                  className="o-filter-manager__btn"
                  id="filter-btn"
                  onClick={(e) => {
                    setShowFilterPanel(true);
                    e.preventDefault();
                  }}
                >
                  Filter (
                  {dataFilters
                    .map(
                      (filterGroup) =>
                        filterGroup.options.filter((filter) => filter.isChecked)
                          .length
                    )
                    .reduce((a, b) => a + b, 0)}
                  )
                </Button>
                <FilterManagerTerms>
                  {dataFilters.map((filterGroup) => {
                    return filterGroup.options.map((filter) => {
                      return (
                        filter.isChecked && (
                          <FilterManagerTerm key={filter.key}>
                            <p className="a-chip__label">
                              <span className="a-chip__category">
                                {filter.displayProperty}
                              </span>{" "}
                              {filter.displayValue}
                            </p>
                            <button
                              className="a-chip__remove-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFilter(
                                  filterGroup.groupKey,
                                  filter.filterKey
                                );
                              }}
                            >
                              <img src={icon_Cancel} className="a-chip__icon" />
                            </button>
                          </FilterManagerTerm>
                        )
                      );
                    });
                  })}
                  <button
                    className="a-button a-button--link a-button--small"
                    onClick={(e) => {
                      e.preventDefault();
                      resetFiltersClickedHandler();
                    }}
                  >
                    Clear all
                  </button>
                </FilterManagerTerms>
              </FilterManager>
              <div className="l-row">
                <div className="l-one-half-col">
                  <form action="" className="m-bulk-action-menu">
                    <select
                      name="actionSelector"
                      id="actionSelector"
                      className="a-select-input m-bulk-action-menu__select"
                      disabled={!actionsAvailable}
                      onChange={(e) => {
                        setSelectedActionIndex(e.target.selectedIndex);
                      }}
                    >
                      <option value="" selected={true}>
                        Set ineligible Employees
                      </option>
                      {/* <option value="">
                        Set dollar increase for selected employees
                      </option> */}
                    </select>
                    <Button
                      disabled={!actionsAvailable}
                      onClick={(e) => {
                        setShowActionsModal(true);
                        e.preventDefault();
                      }}
                    >
                      Go
                    </Button>
                  </form>
                </div>
                {/* <div className="l-one-half-col">
                  {sourceData.filter(
                    (data) =>
                      data.proposedBaseComp === null ||
                      data.proposedBaseComp == 0.0 ||
                      (data.reviewed === false &&
                        data.isEligible &&
                        data.assignedToUser)
                  ).length > 0 && (
                      <div className="m-callout m-callout--right-aligned m-callout--condensed l-mt-xs">
                        <svg
                          className="m-callout__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 358.38"
                        >
                          <g id="Layer_2">
                            <g id="Engine_Warning">
                              <path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z" />
                            </g>
                          </g>
                        </svg>
                        <div className="m-callout__content">
                          <h4 className="m-callout__title">
                            {
                              sourceData.filter(
                                (data) =>
                                  (data.proposedBaseComp === null ||
                                    data.proposedBaseComp == 0.0 ||
                                    (data.reviewed === false &&
                                      data.isEligible)) &&
                                  data.assignedToUser
                              ).length
                            }{" "}
                            assigned employees have no increase specified.
                          </h4>
                        </div>
                        <a
                          href="#"
                          className="a-button a-button--link m-callout__action"
                          onClick={(e) => {
                            turnOnFilter("noIncrease", "noIncreaseSpecified");
                            e.preventDefault();
                          }}
                        >
                          Show
                        </a>
                      </div>
                    )}
                </div> */}
              </div>


              <table className="o-treegrid l-mt-sm">
                <BonusMeritChangeTableHeaders>
                  <label className="a-checkbox a-checkbox--hidden-label">
                    <span>Select all Eligible Displayed Employees</span>
                    <input
                      type="checkbox"
                      defaultChecked={false}
                      // checked={props.allSelected}
                      checked={groupIsChecked}
                      onChange={checkboxGroupIsCheckedChangeHandler}
                    />
                    <div className="b-input"></div>
                  </label>
                </BonusMeritChangeTableHeaders>
                <tbody>
                  {displayData.map((x, index) => (
                    <MeritChangeRow
                      data={x}
                      employeeId={x.id}
                      role={props.role}
                      accessToken={props.accessToken}
                      impersonation={props.impersonation}
                      upn={props.upn}
                      key={index}
                      parentIsChecked={groupIsChecked}
                      parentAssignedUpn={x.assignedTo}
                      assignedToUser={x.assignedToUser}
                      onChange={(checked) => {
                        checkboxMemberIsCheckedChangeHandler(
                          x.employeeId,
                          checked
                        );
                      }}
                      onDetailsClicked={() => {
                        // console.log(x);
                        navigate(x.id);
                      }}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <BonusMeritChangeFilterPanel
        hidden={!showFilterPanel}
        clickOutsideToClose={true}
        dataFilterState={dataFilters}
        onFilterReset={() => {
          resetFiltersClickedHandler();
        }}
        onClose={() => {
          // todo, modal
          setShowFilterPanel(false);
        }}
        onCancel={() => {
          setShowFilterPanel(false);
        }}
        onApply={(panelDataFilterState) => {
          // console.log("Apply Changes");
          setDataFilters(panelDataFilterState);
          setShowFilterPanel(false);
        }}
      />
      <Routes>
        <Route
          path=":employeeId"
          element={
            <BonusDetailsPanel
              // source data.. then it'll filter by route
              data={displayData}
              role={props.role}
              accessToken={props.accessToken}
              impersonation={props.impersonation}
              bonusSumLimit={rootChangeGroup?.budget}
              bonusSumCurrent={rootChangeGroup?.currentBonusTarget}
              hidden={false}
              clickOutsideToClose={true}
              onClose={(changesMade) => {
                if (changesMade) {
                  setDetailsModalData({
                    title:
                      "You haven't saved the changes you've made on this page.",
                    body: (
                      <div className="l-row">
                        <div className="l-one-col">
                          <p>
                            You haven't saved the changes you've made on this
                            page.
                          </p>
                          <p>Want to go back and do that before moving on?</p>
                        </div>
                      </div>
                    ),
                    cancel: "Discard my changes",
                    confirm: "Go back and save",
                    onConfirm: () => {
                      setShowDetailsModal(false);
                    },
                  });
                  setShowDetailsModal(true);
                } else {
                  navigate(`/bonus/${params.changeGroupId}`, {
                    replace: true,
                  });
                }
              }}
              onCancel={(changesMade) => {
                if (changesMade) {
                  setDetailsModalData({
                    title:
                      "You haven't saved the changes you've made on this page.",
                    body: (
                      <div className="l-row">
                        <div className="l-one-col">
                          <p>
                            You haven't saved the changes you've made on this
                            page.
                          </p>
                          <p>Want to go back and do that before moving on?</p>
                        </div>
                      </div>
                    ),
                    cancel: "Discard my changes",
                    confirm: "Go back and save",
                    onConfirm: () => {
                      setShowDetailsModal(false);
                    },
                  });
                  setShowDetailsModal(true);
                } else {
                  navigate(`/bonus/${params.changeGroupId}`, {
                    replace: true,
                  });
                }
              }}
              onSave={(updatedData) => {
                // console.log(updatedData);
                // for (var i in newData){
                //   //console.log(i);
                //   if (newData[i].id == updatedData.id){
                //     //console.log("here");
                //     newData[i].status = updatedData.status;
                //     newData[i].statusReason = updatedData.statusReason;
                //     newData[i].proposedCommission = updatedData.proposedCommission;
                //     newData[i].proposedBonus = updatedData.proposedBonus;
                //     //console.log(newData[i]);
                //     break;
                //   }
                // }
                props.onMeritUpdate();
                //setShowDetailsModal(false);

                /*updatedData?.proposedBaseComp != null
                  ? myCaiApiService
                    .UpdateMeritChangeAmount(
                      props.accessToken,
                      updatedData?.proposedBaseComp,
                      updatedData.taskId,
                      props.impersonation
                    )
                    .then(() => {
                      if (props.onMeritUpdate) {
                        props.onMeritUpdate();
                      }
                      navigate(`/bonus/${params.changeGroupId}`, {
                        replace: true,
                      });
                      // location.reload();
                    })
                  : console.log("Unable to update Merit Change");*/
              }}
            />
          }
        />
      </Routes>
      {/* modals */}
      <Modal
        show={showDetailsModal}
        title={detailsModalData && detailsModalData.title}
        body={detailsModalData && detailsModalData.body}
        cancel={detailsModalData && detailsModalData.cancel}
        confirm={detailsModalData && detailsModalData.confirm}
        onClose={
          detailsModalData && detailsModalData.onClose
            ? detailsModalData.onClose
            : () => {
              setShowDetailsModal(false);
            }
        }
        onCancel={
          detailsModalData && detailsModalData.onCancel
            ? detailsModalData.onCancel
            : () => {
              setShowDetailsModal(false);
              navigate(`/payroll/${params.changeGroupId}`, { replace: true });
            }
        }
        onConfirm={
          detailsModalData && detailsModalData.onConfirm
            ? detailsModalData.onConfirm
            : () => {
              setShowDetailsModal(false);
            }
        }
      />
      <BonusMeritChangeBulkActionModal
        data={displayData}
        remainingAllocation={
          rootChangeGroup?.currentTargetComp * 1.02 -
          rootChangeGroup?.proposedTargetComp
        }
        hidden={!showActionsModal}
        selected={selectedData}
        clickOutsideToClose={true}
        onClose={(changesMade) => {
          setShowActionsModal(false);
        }}
        onCancel={(changesMade) => {
          setShowActionsModal(false);
        }}
        onSave={(updatedData) => {
          myCaiApiService
            .BulkUpdateMeritChangeEligibilities(
              props.accessToken,
              updatedData,
              props.impersonation?.employeeId
            )
            .then(() => {
              props.onMeritUpdate();
            });
          setShowActionsModal(false);
          setSelectedData([]);
        }}
      />
    </Fragment>
  );
};

export default BonusMeritChange;
