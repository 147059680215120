import { useEffect, useRef } from "react";
import { Eyebrow } from "cai-fusion";
import ChatTemplates from "../fusion/Chat/ChatTemplates";
import SymphonyChatInput from "./components/symphonyChatInput";
import SymphonyChat from "./components/symphonyChat";

// Contexts
import { useMessages } from "./contexts/MessageContext";
import { useAccess } from "./contexts/AccessContext";
import { useTemplate } from "./contexts/TemplateContext";
import { useChat } from "./contexts/ChatContext";

// Templates
import JobDescription from "./templates/jobDescription";
import SummarizeDocument from "./templates/summarizeDocument";
import AnalyzeRFP from "./templates/analyzeRFP";
import ProofreadNDS from "./templates/proofreadNDS";
import CodeDebugging from "./templates/codeDebugging";
import ConceptExplanation from "./templates/conceptExplanation";
import CodeReview from "./templates/codeReview";
import FindLearning from "./templates/findLearning";
import ExtractInfo from "./templates/extractInfo";

const SymphonyMainView = ({
	lockInput,
	editingMessageId,
	setEditingMessageId,
	setHideSettings,
	setHideDataDrawer,
	setHideFileUploadModal,
	setHideDeleteChatModal,
	setHidePlagiarismSnackbar,
	setHideRenameChatModal,
	setHideShareDrawer,
	setHideAuditLogDrawer,
}) => {

	const { messages } = useMessages();
	const { canSendMessage } = useAccess();
	const { isChatInContext } = useChat();
	const { templateId } = useTemplate();

	let ChatInputComponent = (
		<SymphonyChatInput
			onModifySettings={() => setHideSettings(false)}
			onManageDataSources={() => setHideDataDrawer(false)}
			onAttach={() => setHideFileUploadModal(false)}
			onDeleteChat={() => setHideDeleteChatModal(false)}
			disableInput={lockInput}
			onRenameChat={() => setHideRenameChatModal(false)}
			onShareChat={() => setHideShareDrawer(false)}
		/>
	);

	if (isChatInContext() || (messages && messages.length > 0)) {
		return (
			<>
				<Eyebrow>Beginning of chat</Eyebrow>
				<SymphonyChat
					lockInput = {lockInput}
					editingMessageId = {editingMessageId}
					setEditingMessageId = {setEditingMessageId}
					setHidePlagiarismSnackbar = {setHidePlagiarismSnackbar}
					setHideAuditLogDrawer = {setHideAuditLogDrawer}
				/>
				{canSendMessage && ChatInputComponent}
			</>
		);
	} else {
		return (
			<>
				{
					templateId === 0 ? <><ChatTemplates/>{ChatInputComponent}</> :
					templateId === 1 ? <JobDescription /> :
					templateId === 2 ? <SummarizeDocument/> : 
					templateId === 3 ? <AnalyzeRFP/> : 
					templateId === 4 ? <ProofreadNDS/> : 
					templateId === 5 ? <CodeDebugging/> : 
					templateId === 6 ? <ConceptExplanation/> :
					templateId === 7 ? <CodeReview/> :
					templateId === 8 ? <FindLearning/> :
					templateId === 9 ? <ExtractInfo/> :
					<></>
				}
			</>
		);
	}
};

const ResizingInput = ({ placeholder, value, onChange }) => {
	const inputRef = useRef(null);
	const textWidthRef = useRef(null);

	const handleInputChange = (event) => {
		textWidthRef.current.textContent = event.target.value;
		const newWidth =
			textWidthRef.current.getBoundingClientRect().width + 10;
		inputRef.current.style.width = `${newWidth}px`;
		onChange(event); // Pass event to parent handler
	};

	useEffect(() => {
		const newWidth =
			textWidthRef.current.getBoundingClientRect().width + 10;
		inputRef.current.style.width = `${newWidth}px`;
	}, [value]);

	return (
		<>
			<input
				ref={inputRef}
				type="text"
				placeholder={placeholder}
				className="o-nl-form__text-input"
				value={value}
				onChange={handleInputChange}
			/>
			<div style={{ display: "none" }} ref={textWidthRef}>
				{value}
			</div>
		</>
	);
};

export default SymphonyMainView;
