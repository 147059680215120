import { createContext, useState, useContext } from "react";
import { useResource } from "./ResourceContext";
import { useMessages } from "./MessageContext";

const TemplateContext = createContext();

function TemplateProvider({ children }) {

    const [isRunning, setIsRunning] = useState(false);
    const [templateId, setTemplateId] = useState(0);

    const { handleFilesUpload } = useResource();
    const { sendMessage } = useMessages();

    // Function for uploading files and then sending messages.
    const executeTemplate = async (files = [], messages = [], messageOverrides = []) => {
        // isRunning state can be used to do something like a popup that indicates when the template is generating.
        setIsRunning(true);
        console.log("[TEMPLATE] Initiating the template.")

        // Uploading any files that need to be uploaded.
        if (files && files.length !== 0) {
            console.log("[TEMPLATE] Uploading the files...")
            await handleFilesUpload(files);
        }

        // Sending any messages that need to be sent. Needs to be done one at a time, hence the for loop.
        if (messages && messages.length !== 0) {
            for (let i = 0; i < messages.length; i++) {
                console.log("[TEMPLATE] Sending message", i+1, "of", messages.length);
                try {
                    // Send the message, supply an override if there is a valid one for the message.
                    await sendMessage(messages[i], (messageOverrides.length > i ? messageOverrides[i] : null));
                }
                catch {
                    console.log("[TEMPLATE] Error, terminated at message ", i+1);
                    setIsRunning(false);
                    return;
                }
            }
        }

        setIsRunning(false);
    }

	return (
		<TemplateContext.Provider
			value={{
                // States
                templateId,
                setTemplateId,
                isRunning,
                // Function.
                executeTemplate,
			}}
		>
			{children}
		</TemplateContext.Provider>
	);
}

// Hook to use the TemplateContext in a component
function useTemplate() {
	const context = useContext(TemplateContext);
	if (context === undefined) {
		throw new Error(
			"useTemplate must be used within a TemplateProvider"
		);
	}
	return context;
}

export { TemplateProvider, useTemplate };
