import React from "react";

const Form = props => {
    return (
        <form name={props.name} className={`o-form ${props.className ?? ""}`} action={props.action}>
            {props.children}
        </form>
    )
}

export default Form;