
import { ReactComponent as IconCloseSquare } from "../../../images/cancel.svg";
import { Button, Modal } from "cai-fusion";

const EulaModal = ({ content, onAccept, onDecline , isHidden=false}) => {

	content = { __html: content }

	return (
		<Modal
			id="eula"
			title="User License Agreement"
			onClose={(e) => {
				onDecline();
				e.preventDefault();
			}}
			isHidden={isHidden}
			actions={
				<>
					<Button outline onClick={(e) => { e.preventDefault(); onDecline() }} children="I Do Not Agree"/>
					<Button onClick={(e) => { e.preventDefault(); onAccept() }} children="I agree"/>
				</>
			}
		>
			<div className="l-row">
				<div className="l-one-col" dangerouslySetInnerHTML={content} >
				</div>
			</div>
		</Modal>
	)
}

export default EulaModal;

