import React, { Fragment } from "react"

const PageContent = props => {

  return (
    <div
      className="o-page-section o-page-section l-mt-sm l-pt-none"
      id={props.sectionId}
    >
      <div className="o-page-section__content-container o-page-section__content-container--no-padding-left o-page-section__content-container--full-width l-pt-none l-mt-neg-sm">
        {/* <div className="l-row">{props.children}</div> */}
        {props.children}
      </div>
    </div>
  )
}

export default PageContent
