import { useMemo, useEffect } from 'react';
import { useAuthentication } from '../contexts/AuthContext';
import { formsApiConfig } from '../configs/formsApiConfig';
import { apiConfig as mycaiApiConfig } from "../configs/mycaiApiConfig";
import { FormsApiService } from '../services/mycaiApis/FormsServiceApi';

// Define a custom hook that creates and updates the SymphonyApiService instance
export const useFormsApiService = () => {
    const {
        isAuthenticated,
        apiAccessToken,
        refreshAccessToken
    } = useAuthentication();

    // Use useMemo to create a memoized instance of the service
    const apiServiceClient = useMemo(() => {
        return new FormsApiService({
            baseURL: mycaiApiConfig.baseURL,
            accessToken: apiAccessToken,
            apiVersion: formsApiConfig.apiVersion,
            refreshAccessTokenFunction: refreshAccessToken,
        });
    }, []); // Empty dependency array ensures the service is created only once

    // Use useEffect to update the access token when it changes
    useEffect(() => {
        if (apiAccessToken) {
            apiServiceClient.updateAccessToken(apiAccessToken);
        }
    }, [apiAccessToken, isAuthenticated, apiServiceClient]);

    return apiServiceClient;
};
