import React from "react"
import { browserName, browserVersion } from "react-device-detect"
import { supportConfig } from "../../configs/supportConfig"

// https://github.com/duskload/react-device-detect/blob/master/docs/selectors.md

//TODO use env variables
const ReportBugLink = props => {
  const reportBugHandler = (e) => {
      const bugReportLink = `mailto:${supportConfig.supportContact}?${supportConfig.supportAuxiliary === undefined ? "" : `cc=${supportConfig.supportAuxiliary}&`}subject=MyCAI%20Issue%20Report&body=%7B%7BSimple%20description%20of%20the%20problem%20you're%20having%20or%20something%20that%20you%20need%20to%20be%20changed%7D%7D%0D%0A%0D%0Aon%20${new Date().toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" })}%0D%0Aby%20${props.reportedBy}%0D%0Ausing%20${browserName}%20v${browserVersion}%0D%0A%0D%0AmyCAI%20${process.env.REACT_APP_VERSION}%20${process.env.REACT_APP_BUILD_NUMBER}%20${process.env.REACT_APP_BUILD_TAG}`
    window.location.href = bugReportLink;
    e.preventDefault();
  }

  return (
    <li className="o-top-menu__link-item">
      <a href="#" className="o-top-menu__link" onClick={reportBugHandler}>
        Report an Issue &#x2197;
      </a>
    </li>
  )
}

export default ReportBugLink
