import React, { useState, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  Routes,
} from "react-router-dom";
import {
  Badge,
  Button,
  Eyebrow,
  Form,
  FormGroup,
  Heading,
  HeadingRow,
  HeadingRowActions,
  HeadingRowTitle,
  Option,
  Progress,
  Select,
} from "../fusion";
import axios from "axios";
import "./caigptChat.css";
import MarkdownRenderer from "../fusion/MarkdownRenderer";
import observabilityIntentService from "../../services/observability-service-v1";
import { exampleAssistants } from "./caigptAssistants";
import SymphonyBanner from "./symphonyBanner";

const iconCopy = require("../../images/img_solid_copy.svg").default;

const models = [
  {
    displayName: "GPT-3.5 Turbo 0301",
    odinAIName: "gpt-3.5-base",
    tokenLimit: 4096,
  },
  {
    displayName: "GPT-3.5 Turbo 0613",
    odinAIName: "gpt-3.5-0613-base",
    tokenLimit: 4096,
  },
  {
    displayName: "GPT-4 0314",
    odinAIName: "gpt-4-32k-base",
    tokenLimit: 32768,
  },
  {
    displayName: "GPT-4 0613",
    odinAIName: "gpt-4-32k-0613-base",
    tokenLimit: 32768,
  },
];

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const CAIGPTChat = (props) => {
  const [assistant, setAssistant] = useState(exampleAssistants[0]);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const [systemMessage, setSystemMessage] = useState("");

  const [temperature, setTemperature] = useState(0.7);
  const [topP, setTopP] = useState(0.95);
  const [maxTokens, setMaxTokens] = useState(1000);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0.0);
  const [presencePenalty, setPresencePenalty] = useState(0.0);
  const [isRunning, setIsRunning] = useState(false);

  const [activeTab, setActiveTab] = useState("Configuration");
  const [activeModel, setActiveModel] = useState(models[3]);

  // At the beginning of your component, create a state for the session ID:
  const [sessionId, setSessionId] = useState(uuidv4()); // assuming uuidv4 is a function that generates unique IDs

  // when assistant changes, update the system message to the new assistant
  useEffect(() => {
    setSystemMessage(assistant?.systemMessage ?? "");
    setMessages([
      ...messages,
      {
        role: "info",
        content: `Assistant changed to "${assistant?.name}". Changing assistants mid-chat might have unpredictable results.`,
      },
    ]);
  }, [assistant]);

  const sendMessage = async (userContent, messages) => {
    if (userContent.trim() === "") return;

    setIsRunning(true);

    let cnv = [...messages, { role: "user", content: userContent }];
    setMessages(cnv);
    setInput("");

    // get rid of all the debug or error messages
    cnv = cnv.filter((msg) => msg.role === "user" || msg.role === "assistant");

    // always prepend system message, since they can change assistants mid-chat.
    // add data sources to system message
    let dataSourcesString = "";
    if (dataSources.length > 0) {
      dataSourcesString = dataSources
        .map((ds) => "The contents of " + ds.name + " is: " + ds.content)
        .join("\n\n");
      dataSourcesString = `\n\nThis is what you know:\n\n${dataSourcesString}`;
    }
    cnv.unshift({
      role: "system",
      content: `${systemMessage}${dataSourcesString}`,
    });

    const requestBody = {
      conversation: cnv,
      temperature: parseFloat(temperature),
      top_p: parseFloat(topP),
      max_tokens: parseInt(maxTokens),
      frequency_penalty: parseFloat(frequencyPenalty),
      presence_penalty: parseFloat(presencePenalty),
    };

    const api_endpoint = "https://odinai.azurewebsites.net/api/Predict";
    const function_key =
      "Nq-4ZQu09VDORPpw5lEUccnWShXd9CQG1KQl9FfUxi8AAzFubQxATw==";
    const model_request = activeModel.odinAIName;

    // Add custom headers here
    const headers = {
      "Content-Type": "application/json",
      "X-ODIN-model-request": model_request,
      "x-functions-key": function_key,
    };

    try {
      const response = await axios.post(api_endpoint, requestBody, {
        headers,
      });
      let newMessages = [
        ...messages,
        { role: "user", content: userContent },
        { role: "assistant", content: response.data },
      ];

      setMessages(newMessages);
      countTokens("", newMessages);
      // Log both request and response
      observabilityIntentService.LogEntry({
        log_time: new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
        log_level: "Info",
        log_text: JSON.stringify({
          user: props.graphData?.userPrincipalName ?? null,
          impersonation: props.impersonation?.userPrincipalName ?? null,
          request: requestBody,
          response: response.data,
        }),
        application_area: "CAI NLP Chat",
      });
    } catch (error) {
      console.error("Error:", error);
      let newMessages = [
        ...messages,
        { role: "user", content: userContent },
        { role: "error", content: "An error occurred. Please try again." },
      ];
      setMessages(newMessages);
      countTokens("", newMessages);

      // Log the request and the error
      observabilityIntentService.LogEntry({
        log_time: new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
        log_level: "Error",
        log_text: JSON.stringify({
          user: props.graphData?.userPrincipalName ?? null,
          impersonation: props.impersonation?.userPrincipalName ?? null,
          request: requestBody,
          response: error,
        }),
        application_area: "CAI NLP Chat",
      });
    } finally {
      setIsRunning(false);
    }
  };

  const countTokens = async (userContent, messages) => {
    let cnv = [...messages, { role: "user", content: userContent }];

    // get rid of all the debug or error messages
    cnv = cnv.filter((msg) => msg.role === "user" || msg.role === "assistant");

    // always prepend system message, since they can change assistants mid-chat.
    // add data sources to system message
    let dataSourcesString = "";
    if (dataSources.length > 0) {
      dataSourcesString = dataSources
        .map((ds) => "The contents of " + ds.name + " is: " + ds.content)
        .join("\n\n");
      dataSourcesString = `\n\nThis is what you know:\n\n${dataSourcesString}`;
    }
    cnv.unshift({
      role: "system",
      content: `${systemMessage}${dataSourcesString}`,
    });

    const requestBody = {
      conversation: cnv,
      temperature: parseFloat(temperature),
      top_p: parseFloat(topP),
      max_tokens: parseInt(maxTokens),
      frequency_penalty: parseFloat(frequencyPenalty),
      presence_penalty: parseFloat(presencePenalty),
    };

    const api_endpoint = "https://odinai.azurewebsites.net/api/CountTokens";
    const function_key =
      "eJaSfvj37uqsWBWAZWI2qcMn2LNIv8xZKU-YF7fyq5-IAzFusUx1Aw==";
    const model_request = activeModel.odinAIName;

    // Add custom headers here
    const headers = {
      "Content-Type": "application/json",
      "X-ODIN-model-request": model_request,
      "x-functions-key": function_key,
    };

    try {
      const response = await axios.post(api_endpoint, requestBody, {
        headers,
      });
      let usedTokens = parseInt(response.data);
      setUsedTokens(usedTokens);
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (!event.shiftKey) {
        event.preventDefault();
        sendMessage(input, messages);
      }
    }
  };

  const handleChangeAssistant = (event) => {
    let requestedAssistance = exampleAssistants.filter(
      (ass) => ass.name === event.target.value
    );
    if (requestedAssistance.length > 0) setAssistant(requestedAssistance[0]);
  };

  const handleChangeActiveModel = (event) => {
    let requestedModel = models.filter(
      (model) => model.displayName === event.target.value
    );
    if (requestedModel.length > 0) setActiveModel(requestedModel[0]);
  };

  const handleAssistantTextChange = (event) => {
    setSystemMessage(event.target.value);
  };

  const handleTemperatureChange = (event) => {
    setTemperature(event.target.value);
  };

  const handleTopPChange = (event) => {
    setTopP(event.target.value);
  };

  const handleMaxTokensChange = (event) => {
    setMaxTokens(event.target.value);
  };

  const handleFrequencyPenaltyChange = (event) => {
    setFrequencyPenalty(event.target.value);
  };

  const handlePresencePenaltyChange = (event) => {
    setPresencePenalty(event.target.value);
  };

  const changeTabHandler = (newTab) => {
    setActiveTab(newTab);
  };

  const [displayBubbles, setDisplayBubbles] = useState(false);

  useEffect(() => {
    if (!isRunning) return;

    const typingAnimation = setInterval(() => {
      setDisplayBubbles((prevState) => !prevState);
    }, 500);

    return () => clearInterval(typingAnimation);
  }, [isRunning]);

  const handleRegenerateThisResponse = (index) => {
    // get the text of the message before this one
    const text = messages[index - 1].content;
    // remove previous messages including this
    const newMessages = messages.slice(0, index - 1);
    // send this message again
    sendMessage(text, newMessages);
  };

  const handleEditFromHere = (index) => {
    // get the text of this message
    const text = messages[index].content;
    // remove previous messages including this message
    const newMessages = messages.slice(0, index);
    setMessages(newMessages);
    // set the textarea text to this message text
    setInput(text);
  };

  const minTokens = 0;
  const [tokenLimit, setTokenLimit] = useState(0);
  const [usedTokens, setUsedTokens] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [projectedProgress, setProjectedProgress] = useState(0);

  useEffect(() => {
    if (!activeModel) return;
    setTokenLimit(activeModel.tokenLimit);

    // if max tokens is greater than the token limit of the model, set it to the token limit
    if (maxTokens > activeModel.tokenLimit) {
      setMaxTokens(activeModel.tokenLimit);
    }
  }, [activeModel]);

  // calculate tokens
  useEffect(() => {
    // when input changes, recalculate usedTokens
    if (!input) return;
    countTokens(input, messages);
  }, [input, systemMessage]);

  // if input or maxTokens changes, update the token used count or token projected count
  useEffect(() => {
    if (!usedTokens || !maxTokens || !tokenLimit) return;

    const total = tokenLimit - minTokens;

    const currentProgress = ((usedTokens - minTokens) / total) * 100;
    setCurrentProgress(currentProgress);

    const projectedProgress =
      ((usedTokens + parseInt(maxTokens) - minTokens) / total) * 100;
    setProjectedProgress(projectedProgress);
  }, [maxTokens, usedTokens, tokenLimit]);

  // data sources
  const [dataSources, setDataSources] = useState([]);
  const [readingFile, setReadingFile] = useState(false);

  const onFileChange = async (e) => {
    let file = e.target.files[0];

    const api_endpoint = "https://thothaifuncs.azurewebsites.net/api/Read";
    const function_key =
      "RgMBtNNw32WTYTd9g81TqkwzkeWz1N-YcKx91avRtBveAzFupRF2Qw==";

    // Add custom headers here
    const headers = {
      "Content-Type": "application/json",
      "x-functions-key": function_key,
    };

    // create the payload
    const formData = new FormData();
    formData.append("file", file);

    try {
      setReadingFile(true);
      const response = await axios.post(api_endpoint, formData, {
        headers,
      });
      let newDataSource = {
        name: file.name,
        content: response.data.text,
      };
      console.log(newDataSource);
      setDataSources([...dataSources, newDataSource]);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setReadingFile(false);
    }
  };

  const handleFilesUpload = async (e) => {
    let files = e.target.files;

    const api_endpoint = "https://thothaifuncs.azurewebsites.net/api/Read";
    const function_key =
      "RgMBtNNw32WTYTd9g81TqkwzkeWz1N-YcKx91avRtBveAzFupRF2Qw==";

    // Add custom headers here
    const headers = {
      "Content-Type": "application/json",
      "x-functions-key": function_key,
    };

    for (let file of files) {
      // create the payload
      const formData = new FormData();
      formData.append("file", file);

      try {
        setReadingFile(true);
        const response = await axios.post(api_endpoint, formData, { headers });
        let newDataSource = {
          name: file.name,
          content: response.data.text,
        };
        console.log(newDataSource);
        setDataSources((prevDataSources) => [
          ...prevDataSources,
          newDataSource,
        ]);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setReadingFile(false);
      }
    }
  };

  return (
    <div className="o-page-section o-page-section l-mt-md l-pt-none">
      <div className="o-page-section__content-container o-page-section__content-container--med-wide-fixed-width l-pt-none l-mt-neg-sm">
        <div className="l-row">
          <SymphonyBanner />
          <div className="col-md-4">
            <div className="m-tab-row l-mt-sm">
              {/* Configuration Tab */}
              <a
                className={`m-tab-row__tab ${
                  activeTab == "Configuration" ? " m-tab-row__tab--active" : ""
                }`}
                onClick={(e) => {
                  changeTabHandler("Configuration");
                  e.preventDefault();
                }}
              >
                <span className="m-tab-row__tab-title">Configuration</span>
                <span className="m-tab-row__help">
                  Assistant personality and capabilities here.
                </span>
              </a>
              {/* Parameters */}
              <a
                className={`m-tab-row__tab ${
                  activeTab == "Parameters" ? " m-tab-row__tab--active" : ""
                }`}
                onClick={(e) => {
                  changeTabHandler("Parameters");
                  e.preventDefault();
                }}
              >
                <span className="m-tab-row__tab-title">Parameters</span>
                <span className="m-tab-row__help">
                  Edit parameters such as temperature, top-p, and LLM model.
                </span>
              </a>
              {/* Data Sources Tab */}
              <a
                className={`m-tab-row__tab ${
                  activeTab == "Data Sources" ? "m-tab-row__tab--active" : ""
                }`}
                onClick={(e) => {
                  changeTabHandler("Data Sources");
                  e.preventDefault();
                }}
              >
                <span className="m-tab-row__tab-title">Data Sources</span>
                <span className="m-tab-row__help">
                  Manage access to custom data sources here.
                </span>
              </a>
            </div>
            {activeTab === "Configuration" ? (
              <div>
                <HeadingRow>
                  <HeadingRowTitle>
                    <Heading size={4}>Assistant Setup</Heading>
                  </HeadingRowTitle>
                </HeadingRow>
                <Form>
                  <FormGroup>
                    {/* <label htmlFor="" className="a-label">
                          Choose an assistant:
                        </label> */}
                    <Select
                      onChange={handleChangeAssistant}
                      value={assistant.name}
                    >
                      {exampleAssistants.map((assistant) => (
                        <Option value={assistant.name} key={assistant.name}>
                          {assistant.name}
                        </Option>
                      ))}
                    </Select>
                  </FormGroup>
                  <Form>
                    <FormGroup>
                      <label className="a-label">Assistant Instructions</label>
                      <textarea
                        style={{ width: "100%" }}
                        rows={3}
                        value={systemMessage}
                        onChange={handleAssistantTextChange}
                      />
                    </FormGroup>
                  </Form>
                  <FormGroup>
                    <label className="a-label">Assistant Capabilities</label>
                    <p>Coming Soon.</p>
                  </FormGroup>
                </Form>
              </div>
            ) : activeTab === "Parameters" ? (
              <div>
                <HeadingRow>
                  <HeadingRowTitle>
                    <Heading size={4}>Parameters Configuration</Heading>
                  </HeadingRowTitle>
                </HeadingRow>
                <Form>
                  <FormGroup>
                    <label htmlFor="" className="a-label">
                      Select LLM Model
                    </label>
                    <Select
                      onChange={handleChangeActiveModel}
                      value={activeModel.displayName}
                    >
                      {models.map((model, index) => (
                        <Option
                          value={model.displayName}
                          key={model.odinAIName}
                        >
                          {model.displayName}
                        </Option>
                      ))}
                    </Select>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="" className="a-label">
                      Temperature: {temperature}
                    </label>
                    <input
                      id="temperature"
                      type="range"
                      className="a-text-input"
                      step={0.01}
                      min={0}
                      max={1}
                      value={temperature}
                      onChange={handleTemperatureChange}
                    />
                    <span
                      className=""
                      style={{
                        color: "#777",
                        cursor: "help",
                        fontSize: "small",
                      }}
                    >
                      The 'temperature' setting changes how wild or tame the
                      responses are. If you set it to a higher number, like 1,
                      you'll get more surprising and creative answers. If you
                      set it to a lower number, like 0, the answers will be more
                      predictable and focused. But be careful with changing this
                      setting and the 'Top P' setting at the same time - it's
                      best to adjust one or the other, not both.
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="" className="a-label">
                      Top-p: {topP}
                    </label>
                    <input
                      id="topP"
                      type="range"
                      className="a-text-input"
                      step={0.05}
                      min={0}
                      max={1}
                      value={topP}
                      onChange={handleTopPChange}
                    />
                    <span
                      className=""
                      style={{
                        color: "#777",
                        cursor: "help",
                        fontSize: "small",
                      }}
                    >
                      The 'Top P' setting is another way to control how unusual
                      or typical the responses are. Think of it as a filter for
                      the chat's word choices. If you set it to a lower number
                      like 0.5, the chat will mostly stick to common and
                      expected words and phrases, giving you straightforward and
                      predictable answers. But if you turn it up to a higher
                      number like 0.9, the chat has the freedom to pick from a
                      wider range of words, including some that might be less
                      common or more surprising. This allows for more creative
                      and unexpected responses. Just like with the temperature
                      setting, you can use Top P to make the chat more
                      imaginative or more focused, but remember, it's usually
                      best to adjust either the temperature or Top P, not both
                      at the same time.
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="" className="a-label">
                      Max tokens: {maxTokens}
                    </label>
                    <input
                      id="maxTokens"
                      type="range"
                      className="a-text-input"
                      min={1}
                      max={tokenLimit}
                      step={1}
                      value={maxTokens}
                      onChange={handleMaxTokensChange}
                    />
                    <span
                      className=""
                      style={{
                        color: "#777",
                        cursor: "help",
                        fontSize: "small",
                      }}
                    >
                      The 'max tokens' setting lets you control how long or
                      short the answers are. If you want shorter and more
                      concise answers, set this number lower. If you prefer
                      longer and more detailed responses, just turn the number
                      up.
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="" className="a-label">
                      Frequency Penalty: {frequencyPenalty}
                    </label>
                    <input
                      id="frequencyPenalty"
                      type="range"
                      className="a-text-input"
                      step={0.01}
                      min={0}
                      max={2}
                      value={frequencyPenalty}
                      onChange={handleFrequencyPenaltyChange}
                    />
                    <span
                      className=""
                      style={{
                        color: "#777",
                        cursor: "help",
                        fontSize: "small",
                      }}
                    >
                      The 'Frequency Penalty' setting helps to make the
                      conversation more varied and interesting. By turning this
                      setting up, you tell the chat not to repeat the same words
                      or phrases too often. If a word has been used a lot
                      already, the chat will be less likely to use it again.
                      It's like encouraging the chat to use different words and
                      expressions, so the conversation doesn't get repetitive.
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="" className="a-label">
                      Presence Penalty: {presencePenalty}
                    </label>
                    <input
                      id="presencePenalty"
                      type="range"
                      className="a-text-input"
                      step={0.01}
                      min={0}
                      max={2}
                      value={presencePenalty}
                      onChange={handlePresencePenaltyChange}
                    />
                    <span
                      className=""
                      style={{
                        color: "#777",
                        cursor: "help",
                        fontSize: "small",
                      }}
                    >
                      The 'Presence Penalty' setting is like a guide that
                      encourages the conversation to keep moving to new topics.
                      By setting this number higher, you tell the chat to avoid
                      repeating words or ideas that have already been used. This
                      nudges the conversation to explore new subjects and ideas
                      instead of sticking to the same ones. It's like having a
                      friend who always wants to keep the conversation fresh and
                      exciting by talking about different things.
                    </span>
                  </FormGroup>
                </Form>
              </div>
            ) : activeTab === "Data Sources" ? (
              <div>
                <HeadingRow>
                  <p>
                    Ask questions about your own data by uploading one or more
                    documents, or referencing any number of URLs.
                  </p>
                </HeadingRow>
                <Form>
                  <FormGroup>
                    <input
                      type="file"
                      multiple // enable multiple file selection
                      onChange={handleFilesUpload} // use the new handler function
                      disabled={readingFile}
                    />
                    {readingFile && (
                      <Progress>Reading File's Contents</Progress>
                    )}
                    {dataSources.length > 0 && (
                      <>
                        <p
                          style={{
                            fontSize: "small",
                            cursor: "help",
                            color: "gray",
                          }}
                        >
                          Now, when you ask chat questions, these documents will
                          be part of the assistant's knowledge when it responds.
                        </p>
                        <ul>
                          {dataSources.map((dataSource, index) => (
                            <li key={index}>
                              <p>{dataSource.name}</p>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </FormGroup>
                </Form>
              </div>
            ) : (
              <div>
                <HeadingRow>
                  <p>Invalid Tab Selection</p>
                </HeadingRow>
              </div>
            )}
          </div>
          <div className="col-md-8">
            <HeadingRow>
              <HeadingRowTitle>
                <Heading size={5}>Chat Session</Heading>
              </HeadingRowTitle>
              <HeadingRowActions>
                <div className="m-heading-row__actions-inner">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setMessages([
                        {
                          role: "info",
                          content: `New Chat with ${assistant.name}`,
                        },
                      ]);
                      setSessionId(uuidv4());
                    }}
                  >
                    New Chat
                  </Button>
                </div>
              </HeadingRowActions>
            </HeadingRow>
            <div className="l-row">
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    // width: "90%",
                    overflowY: "auto",
                    border: "1p solid #ccc",
                    padding: "1rem",
                    marginTop: "1rem",
                    backgroundColor: "#f8f8f8",
                  }}
                >
                  {messages.map((message, index) => (
                    <div key={index} className={`Message ${message.role}`}>
                      <div className="message-content">
                        <MarkdownRenderer content={message.content} />
                      </div>
                      {/* <image src={iconCopy} onClick={() => navigator.clipboard.writeText(message.content)} /> */}
                      {(message.role === "user" ||
                        message.role === "assistant") && (
                        <a
                          style={{
                            fontSize: "small",
                            cursor: "pointer",
                            color: message.role === "user" ? "gray" : "gray",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(message.content);
                          }}
                        >
                          Copy Message Text
                        </a>
                      )}
                      {message.role === "assistant" && (
                        <>
                          <br />
                          <a
                            style={{
                              fontSize: "small",
                              cursor: "pointer",
                              color: message.role === "user" ? "gray" : "gray",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleRegenerateThisResponse(index);
                              // navigator.clipboard.writeText(message.content);
                            }}
                          >
                            Regenerate this Response
                          </a>
                        </>
                      )}
                      {message.role === "user" && (
                        <>
                          <br />
                          <a
                            style={{
                              fontSize: "small",
                              cursor: "pointer",
                              color: message.role === "user" ? "gray" : "gray",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleEditFromHere(index);
                            }}
                          >
                            Edit from Here
                          </a>
                        </>
                      )}
                    </div>
                  ))}
                  {isRunning && (
                    <div
                      className={`typing-bubbles ${
                        displayBubbles ? "display" : ""
                      }`}
                    >
                      <div className="typing-bubble"></div>
                      <div className="typing-bubble"></div>
                      <div className="typing-bubble"></div>
                    </div>
                  )}
                </div>
                <Form>
                  <FormGroup>
                    {/* <input
                      type="text"
                      className="a-text-input"
                      placeholder="Type your message here..."
                      value={input}
                      onChange={(event) => setInput(event.target.value)}
                      // onKeyPress={handleKeyPress}
                      onKeyDown={handleKeyPress}
                    /> */}
                    <textarea
                      rows={2}
                      className="a-text-input"
                      placeholder="Type your message here..."
                      value={input}
                      onChange={(event) => setInput(event.target.value)}
                      onKeyDown={handleKeyPress}
                    />
                  </FormGroup>
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: "small" }}>
                        Use Shift+Enter to add a new line. Input supports
                        markdown.
                      </p>
                    </div>
                    <div className="col-md-6 token-counter">
                      <div className="progress-bar">
                        <div
                          className="current-progress"
                          style={{
                            width: `${currentProgress}%`,
                            // backgroundColor: currentColor,
                            backgroundColor: "blue",
                          }}
                        />
                        <div
                          className="future-progress"
                          style={{
                            width: `${projectedProgress}%`,
                            // backgroundColor: futureProgressColor,
                            backgroundColor: "orange",
                          }}
                        />
                      </div>
                      <p style={{ fontSize: "small" }}>
                        {usedTokens + parseInt(maxTokens)}/ {tokenLimit} tokens
                        ({usedTokens} tokens used in conversation and data
                        sources + {maxTokens} max tokens for response)
                      </p>
                    </div>
                  </div>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      sendMessage(input, messages);
                    }}
                  >
                    Send
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CAIGPTChat;
