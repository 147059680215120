import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useAuthentication } from "../../../contexts/AuthContext";
import { useSymphonyApiService } from '../../../hooks/useSymphonyApi';

const ModelsContext = createContext();

function ModelsProvider({ children }) {
    const { apiAccessToken, isAuthenticated, login } = useAuthentication();

    const apiServiceClient = useSymphonyApiService();

    const [models, setModels] = useState([]);
    const [loadingModels, setLoadingModels] = useState(true);
    const [error, setError] = useState(null);

    const loadModels = async () => {
        setLoadingModels(true);
        try {
            const data = await apiServiceClient.Models.getAllModels();
            setModels(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoadingModels(false);
        }
    };

    const createModel = async (model) => {
        try {
            const newModel = await apiServiceClient.Models.createModel(model);
            setModels([...models, newModel]);
        } catch (err) {
            setError(err);
        }
    };

    const updateModel = async (id, updatedModel) => {
        try {
            const result = await apiServiceClient.Models.updateModel(id, updatedModel);
            setModels(models.map(m => m.id === id ? result : m));
        } catch (err) {
            setError(err);
        }
    };

    const deleteModel = async (id) => {
        try {
            await apiServiceClient.Models.deleteModel(id);
            setModels(models.filter(m => m.id !== id));
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        loadModels();
    }, [apiAccessToken]);

    return (
        <ModelsContext.Provider value={{ models, loadingModels, error, createModel, updateModel, deleteModel }}>
            {children}
        </ModelsContext.Provider>
    );
}

function useModels() {
    const context = useContext(ModelsContext);
    if (!context) {
        throw new Error('useModels must be used within a ModelsProvider');
    }
    return context;
}

export { ModelsProvider, useModels };