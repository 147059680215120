import { useChat } from "../contexts/ChatContext";
import { useTemplate } from "../contexts/TemplateContext";

const NewChatButton = () => {
  const { leaveChat } = useChat(); 
  const { setTemplateId } = useTemplate();

  return (
    <a
      className="a-button a-button--outline l-mb-sm"
      href={`/symphony/`}
      onClick={(e) => {
        e.preventDefault();
        console.log("New Chat Started");
        setTemplateId(0);
        leaveChat();
        document.title = "Symphony - New chat";
      }}
    >
      Start New Chat
    </a>
  );
};

export default NewChatButton;
