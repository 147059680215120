import { Badge, Progress } from "../../fusion";
import { useResource } from "../contexts/ResourceContext";

// If filesOverride is provided, the file display will use the deleteOverride function passed in.
const FileDisplay = ({filesOverride = null, deleteOverride}) => {
  const { files, deleteChatResource } = useResource();

  return(
    <>
      <table className="o-treegrid o-treegrid--compact l-mr-sm">
        <colgroup className="o-treegrid__colgroup">
          <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
          <col className="o-treegrid__col o-treegrid__col--48" span="1" />
        </colgroup>
        <tbody>
          {filesOverride?.length === 0 && files.length === 0
            ? <tr><td>No files selected.</td></tr>
            : (filesOverride ?? files).map((file, index) => (
                <tr
                  className="o-treegrid__row o-treegrid__row--shown"
                  key={index}
                >
                  <td className="o-treegrid__cell">
                    <span className="a-subtle-text">
                      <strong>{file.resourceName}</strong>
                    </span>
                    {file?.pendingUpload && (
                      <span style={{ marginLeft: "20px" }}>
                        <Badge hue="primary-500" value="New" />
                      </span>
                    )}
                  </td>
                  <td className="o-treegrid__cell">
                    {!filesOverride && file.uploading ? (
                      <Progress inLine="true">Uploading</Progress>
                    ) : (
                      <button
                        className="a-button a-button--outline a-button--small a-button--full-width"
                        onClick={(e) => {
                          filesOverride ? deleteOverride(index) : deleteChatResource(file);
                          e.preventDefault();
                        }}
                      >
                        Remove
                      </button>
                    )}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
}

export default FileDisplay;
