import { useContext, useEffect, useRef, useState } from "react";
import { ToastContext } from "cai-fusion";

const DnDFileUpload = ({ notAllowedFileTypes, overwriteHandleFiles, overwriteDisplayText = null }) => {
    const {createWarningToast} = useContext(ToastContext);

    const resetFileInput = () => {
        if(fileUploadInputRef.current){
            fileUploadInputRef.current.value = "";
        }
    };

    const [dragActive, setDragActive] = useState(false);

    const fileUploadInputRef = useRef();

    const handleFiles = (files) => {
        const notAllowedFiles = [];
        const allowedFiles = [...files].filter(file => {
            notAllowedFileTypes = notAllowedFileTypes || [];
            const notAllowed = notAllowedFileTypes.some(ext => file.name.toLowerCase().endsWith(ext))
            
            if(notAllowed){
                notAllowedFiles.push(file.name);
                return false;
            }
            return true;
        });
        let fileObjects = allowedFiles.map((file) => {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.onload = (e) => {
                    resolve({
                        resourceName: file.name,
                        content: e.currentTarget.result,
                        //pendingUpload: true,
                        uploading: true, 
                        type: file.type
                    });
                };
                reader.onerror = () => {
                    reject(new Error(`Failed to read file ${file.name}.`));
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(fileObjects).then((objs) => overwriteHandleFiles(objs));
        if (notAllowedFiles.length > 0) {
            //setToastMessage("Sorry, these file types aren't not allowed, refer to my accepted file types and try to save them as that!\n" + notAllowedFiles.join('\n'));
            createWarningToast(
                <div>
                    <h4> Unsupported File Type </h4>
                    <p>Sorry I can't read that, try saving it as a different extension!</p>
                    <ul>
                    {notAllowedFiles.map((badFile, index) => (
                        <li key={index}>
                        <strong>File Name: </strong> {badFile}<br />
                        </li>
                    ))}
                    </ul>
                </div>
            );
        }
        resetFileInput();
    }
    //resetFileInput: Allows to upload the same file even if it is bad file (mainly saves time for bad file testing)
    useEffect(() => {
        resetFileInput();
    }, []);

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };


    return (
        <>
            <div className="m-file-uploader">
                <button className="m-file-uploader__dialog-btn"
                    onClick={(e) => { fileUploadInputRef?.current?.click(); e.preventDefault() }}
                    onMouseOver={(e) => { console.log("hovering"); e.preventDefault(); }}
                    onDragEnter={handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                    onDragLeave={handleDrag}
                    onDrop={handleDrop}
                    onDragOver={handleDrag}
                >
                    {overwriteDisplayText ?? "Choose file or drag files here."}
                </button>
            </div>
            < input
                type="file"
                id="file"
                ref={fileUploadInputRef}
                style={{ display: "none" }}
                multiple
                //accept={allowedFileTypes.join(',')}
                onChange={(e) => handleFiles(e.target.files)}
            />
        </>
    )
}
export default DnDFileUpload