import React, { Fragment } from "react";

export const BonusMeritChangeTableHeaders = (props) => {
  return (
    <Fragment>
      <colgroup className="o-treegrid__colgroup">
        <col className="o-treegrid__col o-treegrid__col--48" span="1" />
        <col className="o-treegrid__col o-treegrid__col--x-wide" span="1" />
        <col className="o-treegrid__col o-treegrid__col--wide" span="1" />
        <col className="o-treegrid__col o-treegrid__col--wide" span="1" />
        <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
        <col className="o-treegrid__col o-treegrid__col--wide" span="1" />
        <col className="o-treegrid__col o-treegrid__col--wide" span="1" />
        <col className="o-treegrid__col o-treegrid__col--small" span="1" />
      </colgroup>
      <thead>
        <tr className="o-treegrid__row o-treegrid__row--shown">
          <th className="o-treegrid__cell o-treegrid__cell--header o-treegrid__cell--centered">
            {props.children}
          </th>
          <th className="o-treegrid__cell o-treegrid__cell--header">Employee</th>
          <th className="o-treegrid__cell o-treegrid__cell--header">2023 Target Bonus</th>
          <th className="o-treegrid__cell o-treegrid__cell--header">Proposed 2024 Target Bonus</th>
          <th className="o-treegrid__cell o-treegrid__cell--header">Bonus Change </th>
          {/* <th className="o-treegrid__cell o-treegrid__cell--header">Increase Amt</th> */}
          <th className="o-treegrid__cell o-treegrid__cell--header">2024 Proposed Commission</th>
          <th className="o-treegrid__cell o-treegrid__cell--header">Status</th>
          <th className="o-treegrid__cell o-treegrid__cell--header"></th>
        </tr>
      </thead>
    </Fragment>
  );
};
