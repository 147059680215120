import axios from "axios";
import { graphConfig } from "../configs/authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function getGraphUserData(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

////////////// https://graph.microsoft.com/v1.0/users?$search="displayName:wa"&$orderby=displayName&$count=true
/////////////
export async function callMsGraphUsers(accessToken, searchString = undefined) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");

  const options = {
    method: "GET",
    headers: headers,
  };

  let url = graphConfig.graphUsersEndpoint;
  if (searchString) {
      /*url = url + `?$search="displayName:${searchString}"&$filter=endswith(mail,'@cai.io')&$orderby=displayName`;*/
      url = url + `?$search="displayName:${searchString}"&$orderby=displayName`;
  }

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
///////////

export async function searchAzureGroups(accessToken, searchString = undefined) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");

  const options = {
    method: "GET",
    headers: headers,
  };

  let url = graphConfig.graphGroupsEndpoint;
  if (searchString) {
    url = url + `?$search="displayName:${searchString}"&$filter=securityEnabled eq true`;
  }

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getMsGraphPhotoUrl(accessToken) {
  const bearer = `Bearer ${accessToken}`;

  return axios
    .get(graphConfig.graphMeImgEndpoint, {
      headers: { Authorization: bearer },
      responseType: "blob",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.error(error));
}

export async function getMsGraphGroup(accessToken, groupId) {
  const bearer = `Bearer ${accessToken}`;

  return axios
    .get(`${graphConfig.graphGroupsEndpoint}/${groupId}`, {
      headers: { Authorization: bearer }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.error(error));
}

// Also note that if your tenant has a large number of groups, the response may be paginated. In this case, you'll have to follow the @odata.nextLink property in the response to get the next page of groups.
export async function getMsGraphGroups(accessToken) {
  const bearer = `Bearer ${accessToken}`;

  return axios
    .get(`${graphConfig.graphGroupsEndpoint}`, {
      headers: { Authorization: bearer }
    })
    .then((response) => {

      if (response.data['@odata.nextLink']) {
        return getMsGraphGroupsInternal(response.data['@odata.nextLink'], accessToken, response.data);
      } else {
        return response.data;
      }
    })
    .catch((error) => console.error(error));
}

// recursive internal for paginating groups
const getMsGraphGroupsInternal = async (url, accessToken, groups = []) => {
  try {
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}` // replace with the actual access token
      }
    });

    const newGroups = groups.concat(response.data.value);

    if (response.data['@odata.nextLink']) {
      return getMsGraphGroupsInternal(response.data['@odata.nextLink'], accessToken, newGroups);
    } else {
      return newGroups;
    }
  } catch (error) {
    console.error(error);
  }
}


 export async function getMsGraphUserPhotoUrl(accessToken, upn) {
   const bearer = `Bearer ${accessToken}`;

   // graphUserImgEndpoint: "https://graph.microsoft.com/v1.0/users/{upn}/photo/$value"
   return axios
     .get(`${graphConfig.graphUsersEndpoint}/${upn}/photos/240x240/$value`, {
       headers: { Authorization: bearer },
       responseType: "blob",
     })
     .then((response) => {
       return response.data;
     })
     .catch((error) => console.error(error));
 }