import { Heading } from "cai-fusion";

const ChatSuggestions = ({ suggestions, onSuggestionSelected }) => {
  if (!suggestions) {
    suggestions = [];
  }

  return (
    <div className="m-chat-suggestions">
      <Heading size={4} className="l-mb-xs">
        Suggested follow-up questions
      </Heading>
      <ul className="m-chat-suggestions__list">
        {suggestions.map((suggestion, index) => (
          <li className="m-chat-suggestions__suggestion" key={index}>
            <button
              className="m-chat-suggestions__button"
              onClick={(e) => {
                e.preventDefault();
                if (onSuggestionSelected) onSuggestionSelected(suggestion);
              }}
            >
              {suggestion}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatSuggestions;
