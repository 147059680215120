import { useMemo, useEffect } from 'react';
import { useAuthentication } from '../contexts/AuthContext';
import { symphonyApiConfig } from '../configs/symphonyApiConfig';
import { apiConfig as mycaiApiConfig } from "../configs/mycaiApiConfig";
import { SymphonyApiService } from '../services/mycaiApis/SymphonyServiceApi';
import { SymphonyApiServiceV2 } from '../services/mycaiApis/SymphonyServiceApiV2';

// Define a custom hook that creates and updates the SymphonyApiService instance
export const useSymphonyApiService = (apiVersion = null) => {
    const {
        isAuthenticated,
        apiAccessToken,
        refreshAccessToken
    } = useAuthentication();

    // Use useMemo to create a memoized instance of the service
    const apiServiceClient = useMemo(() => {
        const apiVersionToUse = apiVersion ?? symphonyApiConfig.apiVersion;
        if (apiVersionToUse === "v1") {
            return new SymphonyApiService({
                baseURL: mycaiApiConfig.baseURL,
                accessToken: apiAccessToken,
                apiVersion: symphonyApiConfig.apiVersion,
                refreshAccessTokenFunction: refreshAccessToken,
            });
        }
        else {
            return new SymphonyApiServiceV2({
                baseURL: mycaiApiConfig.baseURL,
                accessToken: apiAccessToken,
                apiVersion: symphonyApiConfig.apiVersion,
                refreshAccessTokenFunction: refreshAccessToken,
            });
        }
    }, []); // Empty dependency array ensures the service is created only once

    // Use useEffect to update the access token when it changes
    useEffect(() => {
        if (apiAccessToken) {
            apiServiceClient.updateAccessToken(apiAccessToken);
        }
    }, [apiAccessToken, isAuthenticated, apiServiceClient]);

    return apiServiceClient;
};
