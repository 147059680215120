import React from "react";

//Upload Feedback Creator
function createUserFeedback(addedFiles, statusShare, createToast) {
    const uploadResourceUserMessages = {
        "success": {
            header: 'Data Source(s) Added',
            subHeader: 'Symphony will use these in the generation of future responses.',
        },
        "warning": {
            header: 'Some Files had Errors during Upload',
            subHeader: 'Review the reason for failure and retry.',
        },
        "error": {
            header: 'All Files had Errors during Upload',
            subHeader: 'Review the reason for failure and retry.',
        },
        "refresh": { // This is the default key
            header: "It's Not You, It's Me...",
            subHeader: 'Something unexpected happened during the upload.\nRefresh the page and try again! Your Chat is saved!',
        },
    };
    
    const getUploadStatus = (addedFiles) => {
        let allFail = addedFiles.every(file => !file.isSuccess);
        let anyFail = addedFiles.some(file => !file.isSuccess);
        let status;
        if (allFail) {
            status = 'error';
        } else if (anyFail) {
            status = 'warning';
        } else {
            status = 'success';
        }
        return status;
    };

    const uploadStatus = getUploadStatus(addedFiles);
    statusShare(uploadStatus);
    const fileList = addedFiles.map((file, index) => (
        <li key={index}>
            <strong>File Name: </strong> {file.resourceName}
            {!file.isSuccess && (
                <>
                    <br />
                    <strong>Reason: </strong> {file.Message}
                </>
            )}
        </li>
    ));

    const userMessage = uploadResourceUserMessages[uploadStatus];
    const { header: head, subHeader: sub } = userMessage;
    const toastMessage = (
        <>
            <h4>{head}</h4>
            <div>{sub}</div>
            {fileList && fileList.length > 0 && <ul>{fileList}</ul>}
        </>
    );
    createToast(toastMessage, uploadStatus);
}

export { createUserFeedback };
