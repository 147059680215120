import { Fragment } from "react";
import SLAModel from "../../models/SLAModel"

export interface SLACardProps {
    sla: SLAModel;
    setSLADetail: Function;
}

const SLACard = (props: SLACardProps) => {

    const handleClick = (sla:SLAModel) => { props.setSLADetail(sla) }

    return (
        <a className={ props.sla.isPassing ? "m-sla-card m-sla-card--positive o-card-grid__card" : "m-sla-card m-sla-card--negative o-card-grid__card" } onClick={ (e) => { e.preventDefault(); handleClick(props.sla) } }>
            <div className="m-sla-card__inner">
                <div className="m-sla-card__meta">

                    <h4 className="m-sla-card__title">{ props.sla.slaName }</h4>

                </div>
                <div className="m-sla-card__metric">
                    <span className="m-sla-card__value">{ props.sla.score }{ props.sla.unitOfMeasure }</span>
                    {
                        props.sla.goal && 
                        <Fragment>
                            <div className="a-tag">
                                <p className="a-tag__label">Goal: { props.sla.goalDirection > 0 ? <span>&gt;</span> : <span>&lt;</span> } { props.sla.goal }{ props.sla.unitOfMeasure }</p>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
            <div className="m-sla-card__status-icon">
            {
                props.sla.isPassing ? 
                    <svg className="m-sla-card__status-icon" viewBox="0 -65 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m444.175781 0-260.871093 242.011719-110.324219-117.734375-72.980469 68.386718 178.234375 190.207032 333.765625-309.351563zm0 0"></path></svg>
                    :
                    <svg className="m-sla-card__status-icon" enableBackground="new 0 0 64 64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="m24.0981445 4 3.288086 34.6196289h9.227539l3.288086-34.6196289z"></path><path d="m32 47.1186523c-3.5517578 0-6.440918 2.8891602-6.440918 6.4404297 0 3.5517578 2.8891602 6.440918 6.440918 6.440918s6.440918-2.8891602 6.440918-6.440918c0-3.5512695-2.8891602-6.4404297-6.440918-6.4404297z"></path></svg>
            }
            </div>
        </a> 
    )
}

export default SLACard;