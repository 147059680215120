import React from "react";

export interface BlockProps {
    children?: React.ReactNode;
    className?: string;
};

const defaultBlockProps = {
    className: ""
}

const Block = (props: BlockProps) => {
    props = { ...defaultBlockProps, ...props };

    return (
        <div className={`o-block ${props.className}`}>
            {props.children}
        </div>
    )
}

export default Block;