import React, { Fragment, useEffect } from "react";
import { Heading } from "cai-fusion";
import PageContent from "../fusion/PageContent";

const appLauncherHint = require("../../images/appLauncher_help.png");

const HomePage = ({ onHomePageLoad, onHomePageUnload }) => {
    useEffect(() => {
        onHomePageLoad();

        // Specify how to clean up after this effect
        return () => {
            onHomePageUnload();
        };
    }, [onHomePageLoad, onHomePageUnload]);

    return (
        <Fragment>
            <PageContent sectionId="home-content">
                <div className="l-row">
                    <div className="col-md-1 col-log-2 col-xl-2"></div>
                    <div className="col-md-10 col-lg-8 col-xl-8 l-pt-sm">
                        <div className="row">
                            <div className="col-md-12">
                                {/* <Heading size={4}>
                                    Welcome to the myCAI Home Page!
                                </Heading>
                                <br/>
                                <Heading size={4}>
                                    Please select one of your assigned CAI applications using the "My CAI Apps" App Launcher Button.
                                </Heading>
                                <Heading size={5}>
                                    The App Launcher Button is found above, on the far right side of the top blue bar.
                                </Heading> */}
                                {/* <img src={appLauncherHint} style={{height: "50%"}} alt="App Launcher Button in the Top of the NavBar" /> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 col-log-2 col-xl-2"></div>
                </div>
            </PageContent>
        </Fragment>
    );
}

export default HomePage;