
import { Fragment } from "react";
import dayjs from "dayjs";
import SLADetailModel from "../../models/SLADetailModel";
import { BarChart } from "../engagementReporting";

export interface SLADetailProps {
    isLoading: boolean
    sla: SLADetailModel;
    setSLAActive: Function;
}

const SLADetailDrawer = (props: SLADetailProps) => {

    const handleClose = (e:any) => { props.setSLAActive(null) }

    return (
        <div className={ (props.sla || props.isLoading) ? "o-drawer o-drawer--has-button-row o-drawer--shown": "o-drawer o-drawer--has-button-row" } id="data-entry-drawer">
                <div className="o-drawer__overlay" onClick={handleClose}></div>
                <div className="o-drawer__wrapper">
                    <div className="m-heading-row">
                        <div className="m-heading-row__title">
                            <span className="a-eyebrow">{ dayjs().format("MMMM YYYY") }</span>
                            <h3 className="a-heading a-heading--heading-3 l-mb-none">{ props.sla?.slaName }</h3>

                        </div>
                        <div className="m-heading-row__actions">
                            <div className="m-icon-row m-icon-row--right-align">
                                <a href="#" id="drawerCloseBtn" className="a-icon m-icon-row__icon" title="Close SLA Details" onClick={handleClose}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.152 219.152" data-inject-url="https://synthesisstorage.blob.core.windows.net/dist/assets/toolkit/images/close-square.svg" className="a-icon__img"><path d="M211.652 0H7.5A7.5 7.5 0 000 7.5v204.152a7.5 7.5 0 007.5 7.5h204.152c4.143 0 7.5-3.357 7.5-7.5V7.5a7.5 7.5 0 00-7.5-7.5zm-7.5 204.152H15V15h189.152v189.152z"></path><path d="M53.235 165.917a7.477 7.477 0 005.303 2.196 7.477 7.477 0 005.303-2.196l45.735-45.733 45.733 45.733a7.48 7.48 0 005.304 2.196 7.5 7.5 0 005.304-12.803l-45.732-45.733 45.734-45.733a7.5 7.5 0 00-10.607-10.607L109.578 98.97 63.842 53.233A7.501 7.501 0 0053.236 63.84l45.734 45.735-45.735 45.735a7.5 7.5 0 000 10.607z"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="o-drawer__inner">
                        <form className="o-form l-mt-sm">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 className="a-heading a-heading--heading-5">SLA Description</h6>
                                    <p className="">This SLA ensures timely resolution of priority 3 incidents by providing a mechanism for immediate callouts and dedicated resources, minimizing disruption and maximizing response efficiency.</p>
                                        { props.sla?.comments?.map((comment) =>
                                            <div className="m-callout m-callout--vertical m-callout--shaded l-mt-sm" key={ "comment" + comment.commentId }>
                                                { comment.commentWarning && <svg className="m-callout__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 358.38"><g id="Layer_2"><g id="Engine_Warning"><path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z"></path></g></g></svg> }
                                                <div className="m-callout__content">
                                                    <h4 className="m-callout__title">{ dayjs(comment.commentDate).format("MMMM YYYY") } Comment</h4>
                                                    <p className="m-callout__text a-muted-text">
                                                        { comment.commentDetail }<br/>
                                                    {/* <strong>- { comment.commentAuthor }</strong>  */}
                                                    </p>
                                                </div>
                                            </div>
                                        ) }
                                    
                                    { props.sla?.chartData &&
                                        <Fragment>
                                            <h6 className="a-heading a-heading--heading-5 l-mt-md">12 Month Performance</h6>
                                            <BarChart data={ props.sla.chartData }></BarChart>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="o-drawer__fixed-btn-row">
                        <div className="m-button-row">
                            <button className="a-button m-button-row__button" onClick={handleClose}>Close
                        </button></div>
                    </div>

                </div>
        </div>
    )
    }

export default SLADetailDrawer;