import React, { Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import NavbarAvatarMenu from "./NavbarAvatarMenu";

// const imgSiftLogo = require("../images/sift-logo.png");

const Navbar = (props) => {
  useEffect(() => { });

  return (
    <nav className="o-navbar">
      <div className="o-navbar__inner">
        <a className="o-navbar__brand" href="#">
          {/* {props.logoSrc && (
            <img src={props.logoSrc} alt="" className="o-navbar__logo-svg" />
          )} */}
          {/* {process.env.REACT_APP_VERSION} */}
          {/* {process.env.REACT_APP_BUILD_TAG} */}
          {/* <span className="o-navbar__app-title">{props.appTitle}</span> */}
          <span className="o-navbar__app-title">{props.appTitle}</span>
          <span className="o-navbar__app-title-suffix">{props.appTitleSuffix}</span>
          <span className="o-navbar__app-subtitle">{props.appSubtitle}</span>
        </a>
        <ul className="o-navbar__primary-nav-list">
          {props.navigations &&
            props.navigations.map((navItem) => (
              <li className="o-navbar__nav-item" key={navItem.path} >
                <NavLink to={navItem.path} className="o-navbar__nav-item-link">
                  {navItem.title}
                </NavLink>
              </li>
            ))}
        </ul>
        <div className="o-navbar__right">
          {props.children}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
