import React from "react";
// import "https://synthesisstorage.z13.web.core.windows.net/dist/0.1/styles/toolkit.css";
// import '../../styles/toolkit.css';

export interface BadgeProps {
  value?: string;
  positive?: boolean;
  negative?: boolean;
  neutral?: boolean;
  hue?: string;
}

const defaultBadgeProps = {
  value: undefined,
  positive: false,
  negative: false,
  neutral: false,
  hue: undefined,
};

const Badge = (props: BadgeProps) => {
  props = { ...defaultBadgeProps, ...props };

  let classes =
    "a-badge " +
    (props.positive
      ? " a-badge--positive"
      : props.negative
      ? " a-badge--negative"
      : props.neutral
      ? "a-badge--neutral"
      : "");

  // TODO BREAK INTO HELPER
  let hue =
    props.hue == "primary-100"
      ? "#A1DDEF"
      : props.hue == "primary-300"
      ? "#007983"
      : props.hue == "primary-500"
      ? "#0071BC"
      : undefined;
  return (
    <span
      className={classes}
      style={{ backgroundColor: hue !== undefined ? hue : undefined }}
    >
      {props.value}
    </span>
  );
};

export default Badge;
