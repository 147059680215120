import React, { Fragment, useEffect, useState } from "react";
import {
    HeadingRow,
    HeadingRowTitle,
    HeadingRowActions,
    Heading,
    Filter,
    FilterGroup,
    FilterGroupHeading,
    Checkbox,
} from "../fusion";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    NavLink,
    useParams,
    Routes,
    useSearchParams,
    useNavigate,
} from "react-router-dom";
const icon_CloseSquare = require("../../images/close-square.svg").default;
const icon_Search = require("../../images/search.png");
const annualizedHours = 1880;
var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export const BonusMeritChangeBulkActionModal = (props) => {
    const [selected, setSelected] = useState(props.selected);
    const [increasePercentage, setIncreasePercentage] = useState(0);
    const [remainingAllocation, setRemainingAllocation] = useState(props.remainingAllocation)
    const [newRemainingAllocation, setNewRemainingAllocation] = useState(props.remainingAllocation)
    const [curComp, setCurComp] = useState(0.0);
    const [propComp, setPropComp] = useState(0.0);
    const [changesMade, setChangesMade] = useState(false);
    const [mode, setMode] = useState("percent");
    const [increaseAmt, setIncreaseAmt] = useState(0.0);
    const [ccId, setCcId] = useState(props.ccId);
    const [eligibility, setEligibility] = useState(false);
    const navigate = useNavigate();

    const getCurPropComp = () => {
        var comp = 0;
        var propComp = 0;

        selected?.map((x) => {
            if (x.curCompGrade == "Hourly") {
                comp += x.curBaseComp * annualizedHours * x.ftePercentage;
                propComp += x.proposedBaseComp * annualizedHours * x.ftePercentage;
            } else {
                comp += x.curBaseComp;
                propComp += x.proposedBaseComp;
            }

        })
        setCurComp(comp);
        setPropComp(propComp);
        setIncreaseAmt((propComp - comp));
    }

    const prepObj = () => {
        var obj = []
        selected?.map((x) => {
            obj.push({
                meritChangeId: x.id,
                eligibility: eligibility
            })
        })

        return obj;
    }

    useEffect(() => {
        if (props.hidden === false) {
            setSelected(props.selected);
            setRemainingAllocation(props.remainingAllocation);
            setNewRemainingAllocation(props.remainingAllocation);
            setCcId(props.ccId);
        }

        /*
        if (!(selected?.length > 0)) {
            props.onCancel();
        }
        */
    }, [props.hidden]);

    useEffect(() => {
        getCurPropComp();
        setIncreasePercentage(0.0);
    }, [selected])

    const handleEligible = (e) => {
        setChangesMade(true);
        setEligibility(true);
    }
    const handleIneligible = (e) => {
        setChangesMade(true);
        setEligibility(false);
    }
    const closeClickedHandler = (changesMade) => {
        if (props.onClose) {
            props.onClose(changesMade);
        }
    };

    return (
        <div className={`o-modal ${props.hidden ? 'o-modal--hidden' : 'o-modal--shown'}`} id="exampleModal">
            <div className="o-modal__overlay" onClick={(e) => {
                if (props.clickOutsideToClose) {
                    props.onClose(changesMade);
                }
                e.preventDefault();
            }}></div>
            <div className="o-modal__dialog o-modal__dialog--wide">
                <div className="o-modal__content">
                    <div className="o-modal__header">
                        <h3 className="o-modal__title">Bulk Change</h3>
                        <div className="m-icon-row m-icon-row--right-align">
                            <a
                                href="#"
                                id="bulkActionsCloseBtn"
                                className="a-icon m-icon-row__icon"
                                title="Close Bulk Actions"
                                onClick={(e) => {
                                    closeClickedHandler(changesMade);
                                    e.preventDefault();
                                }}
                            >
                                <img src={icon_CloseSquare} className="a-icon__img" />
                            </a>
                        </div>
                    </div>
                    <div className="o-modal__body">
                        <div className="l-row">
                            <div className="l-one-col">
                                <p>{`Would you like to SET ALL selected to ineligible?`}</p>
                            </div>
                        </div>
                        <div className="l-row l-mt-sm">
                            <div className="l-one-thirds-col">
                                <form action="" className="o-form">
                                    <div className="m-form-group l-mt-xs">
                                        <label htmlFor="" className="a-label">Eligible</label>
                                        <input
                                            type="radio"
                                            name="Eligible Radio"
                                            onChange={(e) => handleEligible(e)}
                                        />
                                        <label htmlFor="" className="a-label">InEligible</label>
                                        <input
                                            type="radio"
                                            name="Eligible Radio"
                                            onChange={(e) => handleIneligible(e)}
                                        />

                                    </div>
                                </form>
                            </div>
                            <div className="l-two-thirds-col">
                            </div>
                        </div>
                    </div>
                    <div className="o-modal__footer">
                        <div className="m-button-row">
                            <a href="#" className="m-button-row__button a-button a-button--outline" onClick={(e) => { props.onCancel(changesMade); e.preventDefault(); }}>Cancel</a>
                            <a href="#" className="m-button-row__button a-button" onClick={(e) => { props.onSave(prepObj()); e.preventDefault(); }}>Save</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
