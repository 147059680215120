// import React, { useState, useEffect } from "react";
import { Route, Routes }  from "react-router-dom";
import { Heading } from "cai-fusion";

// Submitted page
import FormSubmitted from "./formSubmitted";
// All forms
import GovernanceForms from "./formPages/governanceForms";

const FormsApp = () => {

    return (
        <Routes>
            <Route path="/completed/*" element={<FormSubmitted />} />
            <Route path="/governance/*" element={<GovernanceForms />} />
            <Route path="*" element={<DefaultFormPage />} />
        </Routes>
    )
}


const DefaultFormPage = () => {
    return (
        <div className="o-page-section o-page-section l-mt-md l-pt-none">
            <div className="o-page-section__content-container o-page-section__content-container--fixed-width l-pt-none l-mt-neg-sm">
                <div className="l-row l-pt-sm">
                    <div className="col-md-12 text-center">
                        <Heading size={1}>Welcome to Forms!</Heading>
                        <p className="a-text">You are currently on the Forms home stub. Please put in a URL that contains a valid form to start your experience!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormsApp;